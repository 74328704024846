import React from "react";
import { Link } from "react-router-dom";

import logoImg from "../../assets/images/Login Illustration.png";

import contactImg from "../../assets/images/contact.svg";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("New password is required")
        .min(8, "Password must be at least 8 characters"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: (values) => {
      // Your API call logic goes here
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");

      const formdata = new FormData();
      formdata.append("password", values.newPassword);
      formdata.append("password_confirmation", values.confirmPassword);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(
        `https://movssy.com/admin/api/reset_password/${token}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response?.status === true) {
            toast.success(response?.message);
            navigate("/login");
          } else {
            toast.error(response?.message);
          }
          console.log("🚀 ~ .then ~ response:", response);
        })
        .catch((error) => console.log("error", error));
      console.log("Form submitted with values:", values);
    },
  });

  return (
    <>
      <section className="relative ">
        <div className=" relative">
          <div className="md:flex ">
            <div className="xl:w-[50%] lg:w-[50%] md:w-[50%]">
              <div className="relative md:flex flex-col md:min-h-screen justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 md:px-10 py-10 px-4 z-1">
                <div className="text-center">
                  <h1 className="text-btnBackground font-bold text-3xl my-3">
                    Reset Password
                  </h1>
                </div>
                <div className="title-heading text-center md:my-auto my-20">
                  <form className="text-start" onSubmit={formik.handleSubmit}>
                    <div className="grid grid-cols-1">
                      <div className="mb-4">
                        <label
                          className="font-semibold text-btnBackground "
                          htmlFor="LoginEmail"
                        >
                          New Password
                        </label>
                        <input
                          type="password" // Change type to password
                          required=""
                          name="newPassword"
                          value={formik.values.newPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                          placeholder="New Password"
                        />
                        {formik.touched.newPassword &&
                          formik.errors.newPassword && (
                            <p className="text-red-500 mt-1 text-sm">
                              {formik.errors.newPassword}
                            </p>
                          )}
                      </div>
                      <div className="mb-4">
                        <label
                          className="font-semibold text-btnBackground "
                          htmlFor="LoginEmail"
                        >
                          Confirm Password
                        </label>
                        <input
                          type="password" // Change type to password
                          required=""
                          name="confirmPassword" //
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                          placeholder="Confirm Password"
                        />
                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword && (
                            <p className="text-red-500 mt-1 text-sm">
                              {formik.errors.confirmPassword}
                            </p>
                          )}
                      </div>

                      {/* {isPending && <Button value="Loading...." />} */}

                      <div className="mb-4">
                        <input
                          type="submit"
                          className="py-2 px-5 inline-block tracking-wide border align-middle duration-500 text-base text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md w-full"
                          value="Reset Password"
                        />
                      </div>

                      <div className="text-center">
                        <span className="text-slate-400 me-2">
                          Remember your password ?{" "}
                        </span>{" "}
                        <Link
                          to="/auth-login"
                          className="text-btnBackground dark:text-white font-bold inline-block"
                        >
                          Sign in
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="xl:w-[50%] lg:w-[50%] md:w-[50%] flex justify-center  bg-gray-200  rounded-tr-md rounded-br-md shadow-sm">
              <div>
                <div className="text-center">
                  <div>
                    <div className="relative max-w-xl mx-auto text-center py-6">
                      <h1 className=" font-bold text-3xl my-3 ">
                        Welcome Back To
                      </h1>
                      <h1 className=" font-bold text-5xl mb-3">Movssy</h1>
                    </div>
                    <div className="relative max-w-xl mx-auto text-center py-1">
                      <p className="text-lg ">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                    <img
                      src={logoImg}
                      className="max-w-xl mx-auto w-72 h-72 mt-6"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
