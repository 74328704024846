import React from "react";
import { FaWeightScale } from "react-icons/fa6";
export default function DimensionAndWidthCard(props) {
  return (
    <div className=" pb-2  w-40">
      <div className="flex items-center mb-1 ">
        <FaWeightScale className="text-btnBackground" />
        <h5 className=" ml-2 md:text-xl text-xl md:leading-normal leading-normal font-semibold text-black dark:text-white">
          {props.dimensionName}
        </h5>
      </div>

      <p className="text-slate-400 max-w-xl text-justify">{props.paragraph}</p>
      <div className="border-b-2  mb-2"></div>
    </div>
  );
}
