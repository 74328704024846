import React from "react";
import logo_light from "../../assets/images/logo-light.png";
import american_ex from "../../assets/images/payments/american-ex.png";
import discover from "../../assets/images/payments/discover.png";
import master_card from "../../assets/images/payments/master-card.png";
import paypal from "../../assets/images/payments/paypal.png";
import visa from "../../assets/images/payments/visa.png";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";

import {
  FaRegEnvelope,
  FaDribbble,
  FaLinkedin,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaRegFile,
  FaBehance,
  PiShoppingCart,
  MdKeyboardArrowRight,
} from "../../assets/icons/icons";
export default function Footer() {
  const footerLinks = [
    {
      liClass: "",
      route: "#",
      title: "Help center",
    },
    {
      liClass: "mt-[10px]",
      route: "/contact-us",
      title: "Contact us",
    },
    {
      route: "/parcel-tracking",
      title: "Tracking",
      liClass: "mt-[10px]",
    },
    {
      route: "/helpcenter-faqs",
      title: "FAQs",
      liClass: "mt-[10px]",
    },
  ];
  const footerCompany = [
    {
      liClass: "",
      route: "#",
      title: "Link 1",
    },
    {
      liClass: "",
      route: "#",
      title: "Link 2",
    },

    {
      liClass: "mt-[10px]",
      route: "#",
      title: "Link 3",
    },
    {
      route: "#",
      title: "Link 4",
      liClass: "mt-[10px]",
    },
    {
      route: "#",
      title: "Link 5",
      liClass: "mt-[10px]",
    },
    {
      route: "#",
      title: "Link 6",
      liClass: "mt-[10px]",
    },
    {
      route: "#",
      title: "Link 7",
      liClass: "mt-[10px]",
    },
  ];
  return (
    <div>
      <footer className="footer bg-lightGrey relative text-btnBackground dark:text-gray-200">
        <div className="container relative">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <div className="py-[60px] px-0">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                  <div className="lg:col-span-4 md:col-span-12">
                    <Link to="/#" className="text-[22px] focus:outline-none">
                      <span className="text-btnBackground">Movssy</span>
                    </Link>
                    <p className="mt-6 text-textHighlight">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nulla commodo felis quis turpis eleifend interdum. Morbi
                      non vehicula sem. Cras non molestie risus.
                    </p>
                    <ul className="list-none mt-5 space-x-1 space-y-1">
                      <li className="inline">
                        <Link
                          to="#"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <PiShoppingCart className="text-sm" />
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="#"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <FaDribbble className="text-sm" />
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="#"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <FaBehance className="text-sm" />
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="#"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <FaLinkedin className="text-sm" />
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="#"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <FaFacebookF className="text-sm" />
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="#"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <FaInstagram className="text-sm" />
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="#"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <FaTwitter className="text-sm" />
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="#"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <FaRegEnvelope className=" text-sm" />
                        </Link>
                      </li>
                      <li className="inline">
                        <Link
                          to="#"
                          target="_blank"
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                        >
                          <FaRegFile className="text-sm" />
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="lg:col-span-2 md:col-span-4">
                    <h5 className="tracking-[1px] text-btnBackground font-semibold">
                      Company
                    </h5>
                    <ul className="list-none footer-list mt-6">
                      {footerCompany.map((data, index) => (
                        <li key={index} className={data.liClass}>
                          <Link
                            to={data.route}
                            className="text-btnBackground hover:text-btnBackground duration-500 ease-in-out inline-flex items-center"
                          >
                            <MdKeyboardArrowRight className="text-xl me-1" />{" "}
                            {data.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="lg:col-span-3 md:col-span-4">
                    <h5 className="tracking-[1px] text-btnBackground font-semibold">
                      Support
                    </h5>
                    <ul className="list-none footer-list mt-6">
                      {footerLinks.map((data, index) => (
                        <li key={index} className={data.liClass}>
                          <Link
                            to={data.route}
                            className="text-btnBackground hover:text-btnBackground duration-500 ease-in-out inline-flex items-center"
                          >
                            <MdKeyboardArrowRight className="text-xl me-1" />{" "}
                            {data.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
