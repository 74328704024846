import React from "react";

export default function CourierCompaneis() {
  return (
    <div className="grid md:grid-cols-4 grid-cols-2 justify-center gap-[30px]">
      <div className="mx-auto py-4">
        <img
          src={
            "https://fe-assets.eurosender.com/pages/index/partners-colored/GLS.svg"
          }
          className="h-8 w-20"
          alt=""
        />
      </div>

      <div className="mx-auto py-4">
        <img
          src={
            "https://fe-assets.eurosender.com/pages/index/partners-colored/DPD.svg"
          }
          className="h-8 w-20"
          alt=""
        />
      </div>

      <div className="mx-auto py-4">
        <img
          src={
            "https://fe-assets.eurosender.com/pages/index/partners-colored/UPS.svg"
          }
          className="h-8 w-20"
          alt=""
        />
      </div>

      <div className="mx-auto py-4">
        <img
          src={
            "https://fe-assets.eurosender.com/pages/index/partners-colored/DHL.svg"
          }
          className="h-8 w-20"
          alt=""
        />
      </div>
    </div>
  );
}
