import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addAddress,
  fetchAddresses,
  addAddresss,
} from "../../store/slices/addressSlice";
import { useFormik } from "formik";
import { TailSpin } from "react-loader-spinner";
import * as Yup from "yup";
import { changeLanguagei18 } from "../utility/changeLanguagei18N";
import { useTranslation } from "react-i18next";
const validationSchema = Yup.object().shape({
  deliveryStreetName: Yup.string().required("Street no field is required"),
  deliveryUserName: Yup.string().required("This field is required"),
  deliveryPostalCode: Yup.string().required("This field is required"),
  deliveryCity: Yup.string().required("This field is required"),
  deliveryContactNo: Yup.string().required("This field is required"),
});
export default function AddressBooks() {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
    changeLanguagei18(languageCode);
  }, []);

  const formik = useFormik({
    initialValues: {
      deliveryUserName: "",
      deliveryStreetName: "",
      deliveryCity: "",
      deliveryPostalCode: "",
      deliveryContactNo: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsSubmitting(true);
      const formdata = new FormData();
      formdata.append("full_name", values.deliveryUserName);
      formdata.append("street", values.deliveryStreetName);
      formdata.append("city", values.deliveryCity);
      formdata.append("postal_code", values.deliveryPostalCode);
      formdata.append("contact", values.deliveryContactNo);
      dispatch(addAddress(formdata, token))
        .unwrap()
        .then((res) => {
          if (res?.status === true) {
            toast.success("Address created successfully");
            dispatch(addAddresss(res?.address_book));
            navigate("/user-dashboard/address-list");
            setIsSubmitting(false);
          } else {
            toast.error(res?.message);
            setIsSubmitting(false);
          }
        });
    },
  });
  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="grid grid-cols-1">
          <div className="profile-banner relative text-transparent rounded-md shadow dark:shadow-gray-700 overflow-hidden">
            <input
              id="pro-banner"
              name="profile-banner"
              type="file"
              className="hidden"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1">
          <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 ">
            <div className="grid grid-cols-1 gap-6">
              <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <h5 className="text-lg font-semibold mb-4 text-btnBackground">
                  {t("Delivery_Details")}
                </h5>
                <form onSubmit={formik.handleSubmit}>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 ">
                    <div>
                      <label className="form-label text-btnBackground font-medium">
                        {t("Full_Name")}
                      </label>
                      <div className="form-icon relative mt-2">
                        <input
                          name="deliveryUserName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.deliveryUserName}
                          type="text"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                        />
                      </div>
                      {formik.touched.deliveryUserName &&
                        formik.errors.deliveryUserName && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.deliveryUserName}
                          </p>
                        )}
                    </div>
                    <div>
                      <label className="form-label text-btnBackground font-medium">
                        {t("Street_No")}
                      </label>
                      <div className="form-icon relative mt-2">
                        <input
                          name="deliveryStreetName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.deliveryStreetName}
                          type="text"
                          className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                        />
                      </div>
                      {formik.touched.deliveryStreetName &&
                        formik.errors.deliveryStreetName && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.deliveryStreetName}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-4">
                    <div>
                      <label className="form-label text-btnBackground font-medium">
                        {t("Post_Code")}
                      </label>
                      <div className="form-icon relative mt-2">
                        <input
                          name="deliveryPostalCode"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.deliveryPostalCode}
                          type="number"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                        />
                      </div>
                      {formik.touched.deliveryPostalCode &&
                        formik.errors.deliveryPostalCode && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.deliveryPostalCode}
                          </p>
                        )}
                    </div>
                    <div>
                      <label className="form-label text-btnBackground font-medium">
                        {t("Town/City")}
                      </label>
                      <div className="form-icon relative mt-2">
                        <input
                          name="deliveryCity"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.deliveryCity}
                          type="text"
                          className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                        />
                      </div>
                      {formik.touched.deliveryCity &&
                        formik.errors.deliveryCity && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.deliveryCity}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="grid  grid-cols-1 gap-5 mt-4">
                    <div>
                      <label className="form-label text-btnBackground font-medium">
                        {t("Contact_Number")}
                      </label>
                      <div className="form-icon relative mt-2">
                        <input
                          name="deliveryContactNo"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.deliveryContactNo}
                          type="number"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                        />
                      </div>
                      {formik.touched.deliveryContactNo &&
                        formik.errors.deliveryContactNo && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.deliveryContactNo}
                          </p>
                        )}
                    </div>
                  </div>
                  <input
                    type="submit"
                    value={t("Save")}
                    disabled={isSubmitting}
                    className="py-2 cursor-pointer bg-btnBackground px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center  hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md mt-5"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
