import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaHandHoldingDollar } from "react-icons/fa6";
import imageP from "../../assets/images/consulting/1.jpg";
import image1 from "../../assets/images/consulting/2.jpg";
import image2 from "../../assets/images/consulting/3.jpg";
import ab03 from "../../assets/images/about/ab03.jpg";
import ab02 from "../../assets/images/about/ab02.jpg";
import ab01 from "../../assets/images/about/ab01.jpg";
import pro1 from "../../assets/images/portfolio/pro1.jpg";
import pro2 from "../../assets/images/portfolio/pro2.jpg";
import pro3 from "../../assets/images/portfolio/pro3.jpg";
import pro4 from "../../assets/images/portfolio/pro4.jpg";
import pro5 from "../../assets/images/portfolio/pro5.jpg";
import pro6 from "../../assets/images/portfolio/pro6.jpg";
import image3 from "../../assets/images/client/01.jpg";
import image4 from "../../assets/images/client/02.jpg";
import image5 from "../../assets/images/client/03.jpg";
import image6 from "../../assets/images/client/04.jpg";
import image7 from "../../assets/images/client/05.jpg";
import image8 from "../../assets/images/client/06.jpg";
import Select from "react-select";
import { CiDeliveryTruck } from "react-icons/ci";
import { FaMap } from "react-icons/fa";
import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";
import CookieModal from "../../component/cookieModal";
import ManagementTeam from "../../component/managementTeam";
import CompanyLogo from "../../component/companyLogo";
import { IoInformationCircleOutline } from "react-icons/io5";
import * as Icon from "react-feather";
import { FaArrowRight, FiAirplay, RiH3 } from "../../assets/icons/icons";

import TinySlider from "tiny-slider-react";
import CountUp from "react-countup";
import { FaStar } from "react-icons/fa6";
import {
  FaRegBuilding,
  LiaUniversitySolid,
  LuPizza,
  AiOutlineCheckCircle,
  BsCheckCircle,
} from "../../assets/icons/icons";

import DimensionAndWidthCard from "../../component/DimensionAndWidthCard";
import Button from "../../component/Button";
import RoadFrieght from "../../component/RoadFrieght";
import IndustrySpecificationSoltuions from "../../component/IndustrySpecificationSolutions";
import PallistedShipments from "../../component/PallistedShipments";
const settings = {
  container: ".tiny-single-item",
  items: 1,
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  speed: 400,
  gutter: 16,
};
export default function LtlTransport() {
  const [activeIndex, setActiveIndex] = useState(1);
  const AboutData = [
    {
      image: imageP,
      title: "About the pickup",
      desc: "All our services offer door-to-door delivery, with parcels delivered to the address of your choice.",
      number: 1,
    },
    {
      image: image1,
      title: "About the delivery",
      desc: "Parcels are picked up between 9 am and 6 pm, with some services offering same-day collection.",
      number: 2,
    },
  ];
  const project = [
    { value: "Austria", label: "Austria" },
    { value: "Belgium", label: "Belgium" },
    { value: "England", label: "England" },
    { value: "SouthAfrica", label: "SouthAfrica" },
  ];

  const OrderingAs = [
    { value: "Individual", label: "Individual" },
    { value: "Business", label: "Business" },
  ];
  const servicesData = [
    { value: "Document", label: "Document" },
    { value: "Package", label: "Package" },
    { value: "Pallet", label: "Pallet" },
    { value: "Van Delivery", label: "Van Delivery" },
    { value: "FTL & LTL", label: "FTL & LTL" },
    { value: "CustomOffer", label: "CustomOffer" },
  ];
  const accordionData = [
    {
      id: 1,
      title: "Correlation Analysis",
      content:
        "Receive heartfelt contributions instantly & straight to your bank. Guests can choose any amount of money to give",
    },
    {
      id: 2,
      title: "Drag-and-Drop Dashboard",
      content:
        "Receive heartfelt contributions instantly & straight to your bank. Guests can choose any amount of money to give",
    },
    {
      id: 3,
      title: "What do I need to do to start selling",
      title: "Smart Trend Detection ?",
      content:
        "Receive heartfelt contributions instantly & straight to your bank. Guests can choose any amount of money to give",
    },
    {
      id: 4,
      title: "What happens when I receive an order ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 5,
      title: "How does it work ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 6,
      title: "Do I need a designer to use Techwind ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 7,
      title: "What do I need to do to start selling ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 8,
      title: "What happens when I receive an order ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
  ];
  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };

  const accordionData1 = accordionData.filter((x) => x.id < 4);

  const services = [
    {
      feedback:
        '" Techwind made the processes so easy. Techwind instantly increased the amount of interest and ultimately saved us over $10,000. " ',
      name: "Christa Smith",
      role: "Manager",
      image: image3,
    },
    {
      feedback:
        '" I highly recommend Techwind as the new way to sell your home "by owner". My home sold in 24 hours for the asking price. Best $400 you could spend to sell your home. "',
      name: "Christa Smith",
      role: "Manager",
      image: image4,
    },
    {
      feedback:
        '" My favorite part about selling my home myself was that we got to meet and get to know the people personally. This made it so much more enjoyable! "',
      name: "Christa Smith",
      role: "Manager",
      image: image5,
    },
    {
      feedback: '" Great experience all around! Easy to use and efficient. "',
      name: "Christa Smith",
      role: "Manager",
      image: image6,
    },
    {
      feedback:
        '" Techwind made selling my home easy and stress free. They went above and beyond what is expected. "',
      name: "Christa Smith",
      role: "Manager",
      image: image7,
    },
    {
      feedback:
        '" Techwind is fair priced, quick to respond, and easy to use. I highly recommend their services! "',
      name: "Christa Smith",
      role: "Manager",
      image: image8,
    },
  ];
  const services2 = [
    {
      icon: LiaUniversitySolid,
      desc: "To send a large number of packages at once",
    },
    {
      icon: LuPizza,
      title: "Food & Commodities",
      desc: "When items are too bulky to be sent in packages",
    },
    {
      icon: FaRegBuilding,
      title: "Real estate",
      desc: "For moving furniture and large household appliances",
    },
  ];
  const ratingData = [
    {
      icon: pro1,
      name: "Ali",
      title: "You deliver in time",
      desc: "It is cheaper, l don’t have to print invoice and adress, you deliver in time ( so far).",
    },
    {
      icon: pro2,
      name: "Ahmed",
      title: "Very good communication",
      desc: "User-friendly booking system and very good communication for the pick-up and delivery.",
    },
    {
      icon: pro3,
      name: "Hammad",

      title: "Easy to navigate",
      desc: "Straight forward to use. Had my order done in minutes without fuss.",
    },
  ];
  const infoData = [
    {
      icon: "https://static.eurosender.com/wp-content/uploads/2020/05/29083303/Curtain-side-van.png",
      title: "Box van",
      desc: "465 x 165 x 180 cm or 13 cubic metres and 1000 kg capacity",
    },
    {
      icon: "https://static.eurosender.com/wp-content/uploads/2020/05/29083301/Box-van.png",
      title: "Curtain-side van",
      desc: "410 x 210 x 230 cm or 19 cubic metres and 1000 kg capacity (with tail-lift only 700 kg)",
    },
  ];
  const advantagesData = [
    {
      icon: Icon.Codesandbox,
      title: "Instant quotes ",
      desc: " for pallet shipping",
    },
    {
      icon: Icon.Send,
      title: "Real-time tracking",
      desc: "via our online tool",
    },
    {
      icon: Icon.Star,
      title: "Insured transport, ",
      desc: "scalable to your needs",
    },
  ];
  const ChecklistData = [
    {
      icon: Icon.Codesandbox,
      title: "About the pick-up",
    },
    {
      icon: Icon.Send,
      title: "About the delivery",
      desc: "I shipped a pallet from Italy to Czech Republic. It arrived in good conditions and within the time…",
    },
  ];
  const shippingData = [
    {
      icon: Icon.BarChart,
      title: "Pallet Transport ",
      desc: "Ideal for 1-5 pallete, optional tail-lift and manual handler",
    },
    {
      icon: Icon.Send,
      title: "Shared Truck (LTL)",
      desc: "I shipped a pallet from Italy to Czech Republic. It arrived in good conditions and within the time…",
    },
    {
      icon: Icon.Star,
      title: "Full Truck FTL",
      desc: "Booking system on Movssy is very easy and straight forward. You are given an idea of the cost…",
    },
    {
      icon: Icon.Star,
      title: "Van Delivery",
      desc: "Booking system on Movssy is very easy and straight forward. You are given an idea of the cost…",
    },
  ];
  const servecies1 = [
    {
      image: imageP,
      title: "Profitable Marketing",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 1,
    },
    {
      image: image1,
      title: "SEO Specialists",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 2,
    },
    {
      image: image2,
      title: "Audience Analysis",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 3,
    },
  ];
  const productData = [
    {
      image: pro1,
      title: "Techwind Personal Portfolio",
      name: "Creative",
    },
    {
      image: pro2,
      title: "Techwind Minimal Portfolio",
      name: "Minimal",
    },
    {
      image: pro3,
      title: "Techwind NFT Market",
      name: "Crypto",
    },
    {
      image: pro4,
      title: "Techwind Portfolio",
      name: "Portfolio",
    },
    {
      image: pro5,
      title: "Techwind Constuction",
      name: "Corporate",
    },
    {
      image: pro6,
      title: "Techwind SEO",
      name: "SEO",
    },
  ];
  return (
    <>
      <Navbar navClass="nav-light" />
      <section className="relative md:py-50 py-44  bg-right bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-blue-200 dark:bg-slate-900"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 items-center">
            <h4 className="font-bold lg:leading-normal leading-normal text-2xl lg:text-4xl mb-2 text-black dark:text-white">
              Less than truckload (LTL) shipping services
            </h4>
            <span className="text-gray-400 font-semibold mb-4 text-lg">
              Pay only for the truck space you need
            </span>
            <div className="py-4 md:flex md:justify-between md:items-center gap-[15px] ">
              <div className="flex justify-normal w-full items-center ">
                <FaHandHoldingDollar className="text-blue-600 w-7 h-7" />
                <span className="ml-4 font-bold">Available in Europe</span>
              </div>
              <div className="flex justify-normal w-full items-center ">
                <CiDeliveryTruck className="text-blue-600 w-7 h-7" />
                <span className="ml-4 font-bold">
                  Flexible routes & departure times
                </span>
              </div>
              <div className="flex justify-normal w-full items-center ">
                <FaMap className="text-blue-600 w-7 h-7" />
                <span className="ml-4 font-bold">Custom-made quotes</span>
              </div>
            </div>

            <p className="text-black text-lg text-justify dark:text-gray-400">
              Save on freight shipping expenses. With our shared truck
              transport, you can ship less than a truckload with the most
              cost-effective solution. Movssy is your connecting point to a wide
              network of European van and truck carriers. Get an instant quote
              with the booking tool, or reach out if you need a tailored quote
              for transporting LTL shipments.
              <div className="flex text-xl font-medium text-amber-400">
                <p className="text-green-600">30,000+</p>
                <ul className="text-xl ml-2 font-medium text-amber-400 list-none mb-2">
                  <li className="inline">
                    <i className="mdi mdi-star"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star"></i>
                  </li>
                </ul>
              </div>
            </p>

            <div className=" relative">
              <div className="grid grid-cols-1 justify-center">
                <div className="relative mt-10">
                  <div className="grid grid-cols-1">
                    <div>
                      <form className="p-6 bg-white dark:bg-slate-900 rounded-xl shadow-md dark:shadow-gray-700">
                        <div className="registration-form text-dark text-start">
                          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                            <div>
                              <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                {/* <LuSearch className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                                <Select
                                  className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                  options={project}
                                  placeholder="Pick up"
                                />
                              </div>
                            </div>

                            <div>
                              <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                {/* <AiOutlineHome className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                                <Select
                                  className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                  options={project}
                                  placeholder="Delivery"
                                />
                              </div>
                            </div>

                            <div>
                              <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                {/* <AiOutlineDollar className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                                <Select
                                  className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                  options={servicesData}
                                  placeholder={"Services"}
                                />
                              </div>
                            </div>

                            <div className="">
                              <input
                                type="submit"
                                id="search-buy"
                                name="search"
                                className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
                                value="Get a qoute"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative justify-center py-8 bg-blue-50 dark:bg-slate-700">
        <div className="container relative text-center  py-8 ">
          <div className="relative grid grid-cols-1  rounded mt-4 py-4 text-center">
            <p className=" text-blue-600 leading-medium max-w-xl mx-auto ">
              HOW IT WORKS
            </p>
            <p className=" text-black font-bold text-lg leading-medium max-w-xl mx-auto dark:text-white">
              Organising LTL freight shipping services with Movssy
            </p>
            <p className=" md:text-xl leading-medium mx-auto">
              We organise on-demand LTL freight shipments all over Europe. Each
              request is handled individually, so we can always make sure your
              specific needs are fulfilled. Read below some of the features
              included in all LTL shipping services.
            </p>
          </div>
        </div>
      </section>
      <section className="relative dark:bg-slate-800 md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <div className="lg:ms-5">
                {/* <div className="flex mb-4">
                  <span className="text-indigo-600 text-2xl font-bold mb-0">
                    <CountUp
                      className="counter-value text-6xl font-bold"
                      start={1}
                      end={15}
                    ></CountUp>
                    +
                  </span>
                  <span className="self-end font-semibold ms-2">
                    Years <br /> Experience
                  </span>
                </div> */}

                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                  Semi-direct transport service
                </h3>

                <p className="text-slate-400 max-w-xl">
                  The LTL shipping service is not fully direct since the truck
                  is shared between a few shipments, but it is considerably
                  faster than a{" "}
                  <span className="underline text-blue-400 cursor-pointer">
                    groupage pallet delivery service
                  </span>
                  . Here, the truck will not make so many stops at warehouses
                  and depots, so you will benefit from a semi-direct delivery
                </p>
              </div>
            </div>
            <div className="lg:col-span-5 md:col-span-6">
              <div className="grid grid-cols-2 gap-6 items-center">
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img
                      src={"https://placehold.co/600x400/EEE/31343C"}
                      className="shadow rounded-md"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative dark:bg-slate-800 md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-5 md:col-span-6">
              <div className="grid grid-cols-2 gap-6 items-center">
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img
                      src={"https://placehold.co/600x400/EEE/31343C"}
                      className="shadow rounded-md"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-span-7 md:col-span-6">
              <div className="lg:ms-5">
                {/* <div className="flex mb-4">
                  <span className="text-indigo-600 text-2xl font-bold mb-0">
                    <CountUp
                      className="counter-value text-6xl font-bold"
                      start={1}
                      end={15}
                    ></CountUp>
                    +
                  </span>
                  <span className="self-end font-semibold ms-2">
                    Years <br /> Experience
                  </span>
                </div> */}

                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                  Door-to-door deliveries
                </h3>

                <p className="text-slate-400 max-w-xl">
                  Collections and deliveries are performed at the address of
                  your choice, which will be agreed upon during the booking
                  process. A forklift, a dock and access for a semi-trailer must
                  be provided at the loading and unloading sites.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative dark:bg-slate-800 md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <div className="lg:ms-5">
                {/* <div className="flex mb-4">
                  <span className="text-indigo-600 text-2xl font-bold mb-0">
                    <CountUp
                      className="counter-value text-6xl font-bold"
                      start={1}
                      end={15}
                    ></CountUp>
                    +
                  </span>
                  <span className="self-end font-semibold ms-2">
                    Years <br /> Experience
                  </span>
                </div> */}

                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                  Flexible routes to fit your schedule
                </h3>

                <p className="text-slate-400 max-w-xl">
                  You define when and where your load needs to go, and we will
                  make it happen. Instead of offering just a handful of fixed
                  shipping routes, we are able to accommodate LTL shipping
                  requests all over Europe by collaborating with a vast network
                  of logistics providers.
                </p>
              </div>
            </div>
            <div className="lg:col-span-5 md:col-span-6">
              <div className="grid grid-cols-2 gap-6 items-center">
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img
                      src={"https://placehold.co/600x400/EEE/31343C"}
                      className="shadow rounded-md"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative justify-center py-8 mt-4 ">
        <div className="container relative   ">
          <div className="relative grid grid-cols-1  text-left  ">
            <div className="relative grid grid-cols-1 bg-blue-50 px-14 rounded mt-4 py-4 dark:bg-slate-800">
              <p className=" text-blue-600 text-lg font-semibold leading-medium max-w-xl ">
                PREMIUM SOLUTIONS
              </p>
              <h6 className=" md:text-2xl leading-medium text-xl font-bold ">
                More than just a truck delivery
              </h6>
              <p className=" md:text-xl leading-medium mt-4">
                More than a simple LTL carrier provider, Movssy is a one-stop
                logistics platform where you will find answers for your most
                complex shipping problems. We combine the capabilities of
                multiple players, providing scalable solutions that will evolve
                with your business.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="relative justify-center py-8 mt-4">
        <div className="container relative w-8/12 justify-center py-8">
          <div className="relative grid grid-cols-1 pb-8 mx-auto text-left">
            <h3 className="py-2 md:text-3xl leading-medium text-2xl font-semibold">
              Special services available
            </h3>
            <ul className=" list-item">
              <li className="mb-1 flex items-center">
                <BsCheckCircle className="text-indigo-600 text-base me-2" />{" "}
                <span className="me-2 underline text-indigo-600 cursor-pointer">
                  Time-critical shipping:
                </span>{" "}
                When every minute matters, you can count on our urgent freight
                solutions
              </li>
              <li className="mb-1 flex items-center">
                <BsCheckCircle className=" text-indigo-600 text-base me-2" />{" "}
                <span className="me-2 underline text-indigo-600 cursor-pointer">
                  Scheduled deliveries:
                </span>{" "}
                Book a slot for your shipment in advance, be it for tomorrow,
                next week or next month
              </li>
              <li className="mb-1 flex items-center">
                <BsCheckCircle className=" text-indigo-600 text-base me-2" />{" "}
                <span className="me-2 underline text-indigo-600 cursor-pointer">
                  Dedicated transport:
                </span>{" "}
                Exclusive vehicles for moving loads that are simply too
                important
              </li>
              <li className="mb-1 flex items-center">
                <BsCheckCircle className=" text-indigo-600 text-base me-2" />{" "}
                <span className="me-2 underline text-indigo-600 cursor-pointer">
                  Specialist delivery:
                </span>{" "}
                Professional courier services for loads with unique requirements
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="relative justify-center py-8 mt-4 ">
        <div className="container relative   ">
          <div className="relative grid grid-cols-1  text-left ">
            <h6 className=" md:text-2xl leading-medium text-xl font-bold mx-auto">
              Industry-specific solutions
            </h6>
            <p className="  text-lg  leading-medium max-w-xl mx-auto">
              With +60k businesses regularly using Movssy, we are proud to say
              we can adapt to various industry requirements. Here are a few
              examples of industry sectors that use our LTL freight shipping
              solutions:
            </p>

            <IndustrySpecificationSoltuions className="container relative mt-8 mb-8" />
          </div>
        </div>
      </section>
      <section className="relative justify-center py-8 mt-4">
        <div className="container relative w-8/12 justify-center py-8">
          <div className="relative grid grid-cols-1 pb-8 mx-auto text-left">
            <h3 className=" md:text-3xl leading-medium text-2xl font-bold">
              Important information for organising LTL shipments
            </h3>
            <h3 className=" leading-medium text-xl font-semibold max-w-xl mt-3">
              Customs on international deliveries
            </h3>

            <p className=" leading-medium font-thin max-w-xl   ">
              Shipments that cross international borders have to go through
              regular customs procedures. All the documents required for
              clearing the international LTL shipment should be provided by the
              shipper when booking the service.{" "}
              <span className="underline text-blue-400 cursor-pointer">
                Read more about freight customs procedures.
              </span>
            </p>
            <h3 className=" leading-medium text-xl font-semibold max-w-xl mt-3">
              Storage of goods
            </h3>

            <p className=" leading-medium font-thin max-w-xl   ">
              Besides LTL transport and distribution, we can also provide cargo
              storage services throughout Europe. Storage is available for
              short- or long-term shipments of any dimensions. Safe warehousing
              is provided by our logistics partners.{" "}
              <span className="underline text-blue-400 cursor-pointer">
                Read more about cargo warehousing.
              </span>
            </p>
          </div>
        </div>
      </section>

      <section className="relative justify-center py-8 mt-4 ">
        <div className="container relative   ">
          <div className="relative grid grid-cols-1  text-left ">
            <p className=" text-blue-600 text-xl font-semibold leading-medium max-w-xl mx-auto">
              WHY Movssy?
            </p>
            <h6 className=" md:text-2xl leading-medium text-xl font-bold mx-auto">
              What you get by shipping with us
            </h6>
            <div className="py-4 md:flex md:justify-between text-center md:items-center gap-[15px] ">
              <div className="flex flex-col  w-[30%] items-center my-1 ">
                <FaHandHoldingDollar className="text-blue-600 w-7 h-7" />
                <span className="ml-4 font-bold">
                  Dedicated support of expert logistics agents
                </span>
              </div>
              <div className="flex flex-col flex-wrap  w-[30%]  items-center my-1">
                <CiDeliveryTruck className="text-blue-600 w-7 h-7" />
                <span className="ml-4 font-bold">
                  Fast response times to get your goods moving quickly
                </span>
              </div>
              <div className="flex flex-col w-[30%] items-center my-1">
                <FaMap className="text-blue-600 w-7 h-7" />
                <span className="ml-4 font-bold">
                  One-stop-shop logistics platform
                </span>
              </div>
            </div>
            {/* <div className="flex text-blue-400 hover:text-blue-600 cursor-pointer items-center mx-auto">
              <Icon.ArrowRight className=" me-2" />
              <p className="">More about Movssy</p>
            </div> */}
          </div>
        </div>
      </section>
      <section className="relative justify-center md:py-24 py-16">
        <div className="container relative   ">
          <div className="relative grid grid-cols-1  text-center ">
            <h6 className=" md:text-2xl leading-medium text-xl font-bold mx-auto">
              Other shipping options for palletised shipments
            </h6>
            <p className="  text-lg  leading-medium max-w-xl mx-auto">
              Besides LTL shipping, we also offer other services for
              transporting palletised shipments.
            </p>

            <PallistedShipments className="container relative mt-8 mb-8" />
          </div>
        </div>
      </section>
      <Footer />
      <CookieModal />
    </>
  );
}
