import React from "react";
import { Link, useNavigate } from "react-router-dom";

import SimpleBarReact from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import { FiArrowRight } from "../../assets/icons/icons";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeLanguagei18 } from "../../pages/utility/changeLanguagei18N";

export default function Order() {
  const token = useSelector((state) => state.auth.token);
  const [data, setData] = React.useState([]);
  const navigate = useNavigate();
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);

  const { t, i18n } = useTranslation();
  const OrdersList = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/orders/limited", requestOptions)
      .then((response) => response.json())
      .then(({ status, message, orders }) => {
        if (status === true) {
          if (orders.length === 0) {
            setData(null);
          } else {
            setData(orders);
          }
        } else {
          toast.error(message);
          setData(null);
        }
      })
      .catch((error) => console.error(error));
  };
  React.useEffect(() => {
    OrdersList();
    changeLanguagei18(languageCode);
  }, [navigate]);
  const orderData = [
    {
      no: "01",
      id: "#tw001",
      date: "13th Mar 2023",
      price: "$253",
      status: "Delivered",
    },
    {
      no: "02",
      id: "#tw002",
      date: "5th May 2023",
      price: "$253",
      status: "New Order",
    },
    {
      no: "03",
      id: "#tw003",
      date: "19th June 2023",
      price: "$253",
      status: "Return",
    },
    {
      no: "04",
      id: "#tw004",
      date: "20th June 2023",
      price: "$253",
      status: "Cancel",
    },
    {
      no: "05",
      id: "#tw005",
      date: "31st Aug 2023",
      price: "$253",
      status: "New Order",
    },
    {
      no: "06",
      id: "#tw006",
      date: "1st Sep 2023",
      price: "$253",
      status: "Delivered",
    },
    {
      no: "07",
      id: "#tw007",
      date: "13th Sep 2023",
      price: "$253",
      status: "Delivered",
    },
  ];
  return (
    <>
      <div className="xl:col-span-5 lg:col-span-12 mt-6">
        <div className="relative overflow-hidden  dark:shadow-gray-700  dark:bg-slate-900">
          {/* <SimpleBarReact className="max-h-[400px]">
            <div className="relative overflow-x-auto block w-full">
              <table className="w-full text-start">
                <thead className="text-base">
                  <tr>
                    <th className="text-start p-4 min-w-[128px]">
                      {t("Status")}
                    </th>
                    <th className="text-start p-4 min-w-[192px]">
                      {t("ID Number")}
                    </th>
                    <th className="text-center p-4 min-w-[200px]">
                      {t("Destination")}
                    </th>
                    <th className="text-center p-4">{t("Amount")}</th>
                    <th className="text-center p-4 min-w-[150px]">
                      {t("Total Cost")}
                    </th>
                   
                    <th className="text-center p-4"></th>
                  </tr>
                </thead>
                <tbody>
                  {data === null ? (
                    <tr>
                      <th colSpan={7}>{t("No_Record_Found")}</th>
                    </tr>
                  ) : (
                    data.map((item, index) => {
                      return (
                        <tr key={index}>
                          <Link
                            to={`/user-dashboard/Order-details/${item?.id}`}
                          >
                            <th className="text-start underline cursor-pointer border-t border-gray-100 dark:border-gray-800 p-4">
                              {item?.id}
                            </th>
                          </Link>
                          <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                            <div className="flex items-center">
                              <span className="ms-2 font-medium">
                                {item.package_name === null
                                  ? "N/A"
                                  : item.package_name}
                              </span>
                            </div>
                          </td>
                          <td className="text-center border-t border-gray-100 dark:border-gray-800 p-4">
                            <span className="text-slate-400">
                              {item.created_at.split("T")[0]}
                            </span>
                          </td>
                          <td className="text-center border-t border-gray-100 dark:border-gray-800 p-4">
                            <span className="text-slate-400">
                              {item.orignal_amount === null
                                ? "N/A"
                                : item?.orignal_amount}
                            </span>
                          </td>
                          <td className="text-center border-t border-gray-100 dark:border-gray-800 p-4">
                            <span className="text-slate-400">
                              {item.pickup_date === null
                                ? "N/A"
                                : item.pickup_date.split(" ")[0]}
                            </span>
                          </td>
                          <td className="text-center border-t border-gray-100 dark:border-gray-800 p-4">
                            <span className="text-slate-400">
                              {item.orignal_amount === null
                                ? "N/A"
                                : item?.orignal_amount}
                            </span>
                          </td>
                          <td className="text-center border-t border-gray-100 dark:border-gray-800 p-4">
                            <span
                              className={`${
                                item.status === "Pending"
                                  ? "bg-yellow-600/10 dark:bg-yellow-600/20 text-yellow-600"
                                  : "bg-red-600/10 dark:bg-red-600/20 text-red-600 "
                              }  text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1`}
                            >
                              {item.status}
                            </span>
                          </td>
                         
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </SimpleBarReact> */}
          <SimpleBarReact className="max-h-[400px]">
            <div>
              <div className="flex justify-between">
                <div>
                  <p className="text-start font-semibold text-[15px] p-2 min-w-[120px]">
                    {t("ID Number")}
                  </p>
                </div>
                <div>
                  <p className="text-start font-semibold text-[15px] p-2 min-w-[120px]">
                    {t("Date")}
                  </p>
                </div>
                <div>
                  <p className="text-start font-semibold text-[15px] p-2 min-w-[120px]">
                    {t("Price")}
                  </p>
                </div>
                <div>
                  <p className="text-start font-semibold text-[15px] p-2 min-w-[120px]">
                    {t("Status")}
                  </p>
                </div>

                <div>
                  <p className="text-start font-semibold text-[15px] p-2"></p>
                </div>
              </div>
              <div>
                {data === null ? (
                  <div className="text-center p-4">{t("No_Record_Found")}</div>
                ) : (
                  data.map((item, index) => (
                    <Link
                      to={`/user-dashboard/Order-details/${item?.id}`}
                      key={index}
                      className="block"
                    >
                      <div className="border rounded-2xl mb-4 bg-gray-100 hover:bg-white cursor-pointer">
                        <div className="flex justify-between">
                          <div className="text-start p-2 bg-transparent min-w-[120px]">
                            #{item?.id}
                          </div>
                          <div className="text-start p-2 bg-transparent min-w-[120px]">
                            <span className="text-slate-400">
                              {item.pickup_date === null
                                ? "N/A"
                                : item.pickup_date.split(" ")[0]}
                            </span>
                          </div>

                          <div className="text-start p-2 bg-transparent min-w-[120px]">
                            <span className="text-slate-400">
                              {item.orignal_amount === null
                                ? "N/A"
                                : item?.orignal_amount}
                            </span>
                          </div>
                          <div className="text-start p-2 bg-transparent min-w-[120px]">
                            <span
                              className={`${
                                item.status === "Pending"
                                  ? "bg-yellow-600/10 dark:bg-yellow-600/20 text-yellow-600"
                                  : "bg-red-600/10 dark:bg-red-600/20 text-red-600"
                              } text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1`}
                            >
                              {item.status}
                            </span>
                          </div>
                          <div className="text-start p-2 bg-transparent  justify-center flex items-center">
                            <FiArrowRight />
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                )}
              </div>
            </div>
          </SimpleBarReact>
        </div>
      </div>
    </>
  );
}
