import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaWallet } from "react-icons/fa";

import { FaRegCreditCard } from "react-icons/fa";
import { IoLogoPaypal } from "react-icons/io5";
import "react-calendar/dist/Calendar.css";
import { FaGooglePay } from "react-icons/fa";
import { ArrowRight } from "react-feather";
import { BsBank } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { changeLanguagei18 } from "../utility/changeLanguagei18N";
export default function PaymentMethodDashboard() {
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);

  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
    changeLanguagei18(languageCode);
  }, []);
  const { t } = useTranslation();

  return (
    <div className=" grid grid-cols-12 ">
      <div className="container-fluid relative px-2 col-span-8">
        <div className="layout-specing">
          <div className="relative overflow-hidden  shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
            <div className="p-6 py-8 border-b border-gray-100 dark:border-gray-800">
              <h6 className="text-lg font-semibold">{t("You_didn't_add")}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
