import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  languageCode: "en",
};

const languageSlice = createSlice({
  name: "multiLangauge",
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.languageCode = action.payload;
    },
  },
});

export { languageSlice };
export const { changeLanguage } = languageSlice.actions;
export const languageReducer = languageSlice.reducer;
