import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import LogoLight from "../assets/images/logo-dark.png";
import { CiWallet } from "react-icons/ci";
import { PiAddressBookLight } from "react-icons/pi";
import SimpleBarReact from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { MdOutlineSupportAgent } from "react-icons/md";

import { FaBook } from "react-icons/fa";
import {
  AiOutlineLineChart,
  PiBrowsersBold,
  IoMdLogOut,
  AiOutlineAppstore,
  AiOutlineUser,
  TbBrandBlogger,
  AiOutlineShoppingCart,
  AiOutlineCamera,
  AiOutlineFile,
  MdOutlineEmail,
  LiaFileInvoiceDollarSolid,
  BiLogOutCircle,
  BiLayer,
} from "../assets/icons/icons";
import Select from "react-select";
import { toast } from "react-toastify";
import { changeLanguagei18 } from "../pages/utility/changeLanguagei18N";
import { changeLanguage } from "../store/slices/languageSlice";
import { FaWallet } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { revoke } from "../store/slices/authSlice";
import { revokePath } from "../store/slices/pathSlice";
import { revokeWallet } from "../store/slices/walletSlice";
import { IoPersonCircleOutline } from "react-icons/io5";
import { MdOutlineHome } from "react-icons/md";
import { getUser } from "../pages/utility/getUser";

export default function Sidebar() {
  const [manu, setManu] = useState("");
  const [userdetail, setUserData] = useState(getUser());

  console.log("🚀 ~ file: sidebar.js:28 ~ Sidebar ~ manu:", manu);
  const [subManu, setSubManu] = useState("");
  console.log("🚀 ~ file: sidebar.js:29 ~ Sidebar ~ subManu:", subManu);
  const location = useLocation();
  let [isLoading, setIsloading] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  let [user, setUser] = useState(false);
  const [modal, setModal] = useState(false);
  const [language, setLanguage] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const handlechangeLanguage = (option) => {
    setLanguage(option);
  };
  const LanguagesOption = [
    { value: "en", label: "English" },
    { value: "fr", label: "French" },
  ];
  const CurrencyOption = [
    { value: "EUR", label: "EUR" },
    { value: "CZK", label: "CZK" },
    { value: "DDK", label: "DDK" },
    { value: "GBP", label: "GBP" },
    { value: "PLN", label: "PLN" },
    { value: "RON", label: "RON" },
    { value: "SEK", label: "SEK" },
  ];
  const { t } = useTranslation();
  useEffect(() => {
    var current = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );
    setManu(current);
    setSubManu(current);
  }, [location.pathname.substring(location.pathname.lastIndexOf("/") + 1)]);
  const Logout = () => {
    setIsloading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch("https://movssy.com/admin/api/logout", requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          if (response.status === 401) {
            await dispatch(revoke());
            await dispatch(revokePath());
            await dispatch(revokeWallet());
            await sessionStorage.removeItem("User");
            await localStorage.removeItem("User");
            navigate("/login");
          } else {
            toast.success(`Http error ${response.status}`);
          }
        }
        return response.json();
      })
      .then(async (result) => {
        if (result?.message === "User Logout Successfully") {
          await sessionStorage.removeItem("User");
          await localStorage.removeItem("User");

          navigate("/login");
          toast.success("Logout Successfully");
          await dispatch(revoke());
          await dispatch(revokePath());
          setIsloading(false);
        } else {
          toast.error(result.message);
          setIsloading(false);
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    changeLanguagei18(languageCode);
  }, []);

  const saveChange = (event) => {
    event.preventDefault();
    setDisabled(true);
    dispatch(changeLanguage(language.value));
    changeLanguagei18(language.value);
    setDisabled(false);
    setModal(!modal);
  };
  return (
    <nav className="sidebar-wrapper sidebar-dark  border-l-2 bg-white   border-l-black ">
      <div className=" sidebar-content">
        <div className=" flex flex-col justify-center items-center">
          <h1 className="text-2xl font-bold text-btnBackground my-3">Movssy</h1>

          <div className="py-2  min-w-[240px] border mt-3 rounded-2xl bg-gray-200 shadow-xl ">
            <div className="dropdown block relative w-full">
              <button
                className="dropdown-toggle w-full"
                type="button"
                onClick={toggleDropdown}
              >
                <div className="flex justify-center items-center w-full space-x-2">
                  <div>
                    <span className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-[20px] text-center bg-gray-50 dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700 border border-gray-100 dark:border-gray-800 text-slate-900 dark:text-white rounded-full">
                      <IoPersonCircleOutline />
                    </span>
                  </div>
                  <div className="font-semibold text-start text-[16px] ms-1">
                    <h3 className="text-lg text-bold">
                      {userdetail?.User?.purpose === "business"
                        ? userdetail?.User?.company_name
                        : userdetail?.User?.first_name}
                    </h3>
                    <p className="text-sm">
                      {userdetail?.User?.purpose === "business"
                        ? "Company"
                        : "Personal"}
                    </p>
                  </div>
                </div>
              </button>
              <div
                className={`dropdown-menu relative   m-0 mt-4 z-10  min-w-[240px] overflow-hidden bg-gray-200 border border-none ${
                  isOpen ? "" : "hidden"
                }`}
              >
                <ul className="text-start">
                  <li>
                    <Link
                      to="/profile"
                      className="flex items-center font-medium py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"
                    >
                      <AiOutlineUser className="me-2" />
                      {t("Profile")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/index"
                      className="flex items-center font-medium py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"
                    >
                      <MdOutlineHome className="me-2" />
                      {t("Home")}
                    </Link>
                  </li>
                  <li>
                    <button
                      disabled={isLoading}
                      onClick={() => {
                        toggleDropdown();
                        setModal(!modal);
                      }}
                      className="flex cursor-pointer items-center font-medium py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"
                    >
                      <IoMdLogOut className="me-2" />
                      {t("Language_label")}
                    </button>
                  </li>
                  <li>
                    <button
                      disabled={isLoading}
                      onClick={Logout}
                      className="flex cursor-pointer items-center font-medium py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"
                    >
                      <IoMdLogOut className="me-2" />
                      {t("Logout")}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="flex items-center">
            <img src={LogoLight} className="w-24 h-24" alt="" />
          </div> */}
        </div>
        <SimpleBarReact style={{ height: "calc(100% - 100px)" }}>
          <ul className="sidebar-menu text-black border-t border-white/10">
            <li
              className={["", "user-dashboard"].includes(manu) ? "active" : ""}
            >
              <Link to="/user-dashboard">
                <AiOutlineLineChart className=" me-3 icon " /> {t("Dashboard")}
              </Link>
            </li>

            {/* <li
              className={`sidebar-dropdown ${
                [
                  "index-dark",
                  "index-rtl",
                  "index-dark-rtl",
                  "index-sidebar-light",
                  "index-sidebar-colored",
                ].includes(manu)
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to="#"
                onClick={(e) => {
                  setSubManu(subManu === "index-item" ? "" : "index-item");
                }}
              >
                <PiBrowsersBold className=" me-3 icon " />
                Layouts
              </Link>
              <div
                className={`sidebar-submenu ${
                  [
                    "index-dark",
                    "index-rtl",
                    "index-dark-rtl",
                    "index-sidebar-light",
                    "index-sidebar-colored",
                    "index-item",
                  ].includes(subManu)
                    ? "block"
                    : ""
                }`}
              >
                <ul>
                  <li className={manu === "index-dark" ? "active" : ""}>
                    <Link to="/index-dark">Dark Dashboard</Link>
                  </li>
                  <li className={manu === "index-rtl" ? "active" : ""}>
                    <Link to="/index-rtl">RTL Dashboard</Link>
                  </li>
                  <li className={manu === "index-dark-rtl" ? "active" : ""}>
                    <Link to="/index-dark-rtl">Dark RTL Dashboard</Link>
                  </li>
                  <li
                    className={manu === "index-sidebar-light" ? "active" : ""}
                  >
                    <Link to="/index-sidebar-light">Light Sidebar</Link>
                  </li>
                  <li
                    className={manu === "index-sidebar-colored" ? "active" : ""}
                  >
                    <Link to="/index-sidebar-colored">Colored Sidebar</Link>
                  </li>
                </ul>
              </div>
            </li> */}

            {/* <li
              className={`sidebar-dropdown ${
                ["chat", "email", "calendar"].includes(manu) ? "active" : ""
              }`}
            >
              <Link
                to="#"
                onClick={(e) => {
                  setSubManu(subManu === "app-item" ? "" : "app-item");
                }}
              >
                <AiOutlineAppstore className=" me-3 icon " />
                Apps
              </Link>
              <div
                className={`sidebar-submenu ${
                  ["chat", "email", "calendar", "app-item"].includes(subManu)
                    ? "block"
                    : ""
                }`}
              >
                <ul>
                  <li className={manu === "chat" ? "active" : ""}>
                    <Link to="/chat">Chat</Link>
                  </li>
                  <li className={manu === "email" ? "active" : ""}>
                    <Link to="/email">Email</Link>
                  </li>
                  <li className={manu === "calendar" ? "active" : ""}>
                    <Link to="/calendar">Calendar</Link>
                  </li>
                </ul>
              </div>
            </li> */}

            <li
              className={`sidebar-dropdown ${
                [
                  "profile",
                  "profile-billing",
                  "my-details",
                  "profile-social",
                  "profile-notification",
                  "profile-setting",
                ].includes(manu)
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to="#"
                onClick={(e) => {
                  setSubManu(subManu === "profile-item" ? "" : "profile-item");
                }}
              >
                <AiOutlineUser className=" me-3 icon " />
                {t("User_Profile")}
              </Link>
              <div
                className={`sidebar-submenu ${
                  [
                    "my-details",
                    "change-password",
                    "profile-notification",
                    "profile-setting",
                    "profile-item",
                  ].includes(subManu)
                    ? "block"
                    : ""
                }`}
              >
                <ul>
                  {/* <li className={manu === "profile" ? "active" : ""}>
                    <Link to="/profile">My Deatils</Link>
                  </li>
                  <li className={manu === "profile-billing" ? "active" : ""}>
                    <Link to="/profile-billing">Billing Info</Link>
                  </li> */}
                  <li className={manu === "my-details" ? "active" : ""}>
                    <Link to="/user-dashboard/my-details">
                      {t("My_Details")}
                    </Link>
                  </li>
                  <li className={manu === "change-password" ? "active" : ""}>
                    <Link to="/user-dashboard/change-password">
                      {t("Change_Password")}
                    </Link>
                  </li>
                  {/* <li
                    className={manu === "profile-notification" ? "active" : ""}
                  >
                    <Link to="/profile-notification">Notifications</Link>
                  </li> */}
                  <li className={manu === "profile-setting" ? "active" : ""}>
                    <Link to="/user-dashboard/profile-setting">
                      {" "}
                      {t("Settings")}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            {/* <li
              className={`sidebar-dropdown ${
                ["blog", "blog-detail"].includes(manu) ? "active" : ""
              }`}
            >
              <Link
                to="#"
                onClick={(e) => {
                  setSubManu(subManu === "blog-item" ? "" : "blog-item");
                }}
              >
                <TbBrandBlogger className=" me-3 icon " />
                Blog
              </Link>
              <div
                className={`sidebar-submenu ${
                  ["blog", "blog-detail", "blog-item"].includes(subManu)
                    ? "block"
                    : ""
                }`}
              >
                <ul>
                  <li className={manu === "blog" ? "active" : ""}>
                    <Link to="/blog">Blogs</Link>
                  </li>
                  <li className={manu === "blog-detail" ? "active" : ""}>
                    <Link to="/blog-detail">Blog Detail</Link>
                  </li>
                </ul>
              </div>
            </li> */}

            {/* <li
              className={`sidebar-dropdown ${
                ["shop", "shop-item-detail", "shop-cart", "checkout"].includes(
                  manu
                )
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to="#"
                onClick={(e) => {
                  setSubManu(subManu === "shop-item" ? "" : "shop-item");
                }}
              >
                <AiOutlineShoppingCart className="me-3 icon" />
                E-Commerce
              </Link>
              <div
                className={`sidebar-submenu ${
                  [
                    "shop",
                    "shop-item-detail",
                    "shop-cart",
                    "checkout",
                    "shop-item",
                  ].includes(subManu)
                    ? "block"
                    : ""
                }`}
              >
                <ul>
                  <li className={manu === "shop" ? "active" : ""}>
                    <Link to="/shop">Shop</Link>
                  </li>
                  <li className={manu === "shop-item-detail" ? "active" : ""}>
                    <Link to="/shop-item-detail">Shop Detail</Link>
                  </li>
                  <li className={manu === "shop-cart" ? "active" : ""}>
                    <Link to="/shop-cart">Shopcart</Link>
                  </li>
                  <li className={manu === "checkout" ? "active" : ""}>
                    <Link to="/checkout">Checkout</Link>
                  </li>
                </ul>
              </div>
            </li> */}

            {/* <li
              className={`sidebar-dropdown ${
                ["gallery-one", "gallery-two"].includes(manu) ? "active" : ""
              }`}
            >
              <Link
                to="#"
                onClick={(e) => {
                  setSubManu(subManu === "gallery-item" ? "" : "gallery-item");
                }}
              >
                <AiOutlineCamera className="me-3 icon" />
                Gallery
              </Link>
              <div
                className={`sidebar-submenu ${
                  ["gallery-one", "gallery-two", "gallery-item"].includes(
                    subManu
                  )
                    ? "block"
                    : ""
                }`}
              >
                <ul>
                  <li className={manu === "gallery-one" ? "active" : ""}>
                    <Link to="/gallery-one">Gallary One</Link>
                  </li>
                  <li className={manu === "gallery-two" ? "active" : ""}>
                    <Link to="/gallery-two">Gallery Two</Link>
                  </li>
                </ul>
              </div>
            </li> */}

            {/* <li
              className={`sidebar-dropdown ${
                [
                  "starter",
                  "faqs",
                  "pricing",
                  "team",
                  "privacy",
                  "terms",
                ].includes(manu)
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to="#"
                onClick={(e) => {
                  setSubManu(subManu === "page-item" ? "" : "page-item");
                }}
              >
                <AiOutlineFile className="me-3 icon" />
                Pages
              </Link>
              <div
                className={`sidebar-submenu ${
                  [
                    "starter",
                    "faqs",
                    "pricing",
                    "team",
                    "privacy",
                    "terms",
                    "page-item",
                  ].includes(subManu)
                    ? "block"
                    : ""
                }`}
              >
                <ul>
                  <li className={manu === "starter" ? "active" : ""}>
                    <Link to="/starter">Starter</Link>
                  </li>
                  <li className={manu === "faqs" ? "active" : ""}>
                    <Link to="/faqs">FAQs</Link>
                  </li>
                  <li className={manu === "pricing" ? "active" : ""}>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  <li className={manu === "team" ? "active" : ""}>
                    <Link to="/team">Team</Link>
                  </li>
                  <li className={manu === "privacy" ? "active" : ""}>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                  <li className={manu === "terms" ? "active" : ""}>
                    <Link to="/terms">Term & Condition</Link>
                  </li>
                </ul>
              </div>
            </li> */}

            {/* <li
              className={`sidebar-dropdown ${
                ["order-list", "documents"].includes(manu) ? "active" : ""
              }`}
            >
              <Link
                to="#"
                onClick={(e) => {
                  setSubManu(subManu === "invoice-item" ? "" : "invoice-item");
                }}
              >
                <LiaFileInvoiceDollarSolid className=" me-3 icon " />
                New Orders
              </Link>
              <div
                className={`sidebar-submenu ${
                  ["order-list", "documents", "invoice-item"].includes(subManu)
                    ? "block"
                    : ""
                }`}
              >
                <ul>
                  <li className={manu === "order-list" ? "active" : ""}>
                    <Link to="/order-list">New Order</Link>
                  </li>
                  <li className={manu === "documents" ? "active" : ""}>
                    <Link to="/documents">Documents</Link>
                  </li>
                </ul>
              </div>
            </li> */}

            {/* <li
              className={`sidebar-dropdown ${
                [
                  "email-confirmation",
                  "email-password-reset",
                  "email-alert",
                  "email-invoices",
                ].includes(manu)
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to="#"
                onClick={(e) => {
                  setSubManu(subManu === "mail-item" ? "" : "mail-item");
                }}
              >
                <MdOutlineEmail className=" me-3 icon " />
                Email Template
              </Link>
              <div
                className={`sidebar-submenu ${
                  [
                    "email-confirmation",
                    "email-password-reset",
                    "email-alert",
                    "email-invoices",
                    "mail-item",
                  ].includes(subManu)
                    ? "block"
                    : ""
                }`}
              >
                <ul>
                  <li className={manu === "email-confirmation" ? "active" : ""}>
                    <Link to="/email-confirmation">Confirmation</Link>
                  </li>
                  <li
                    className={manu === "email-password-reset" ? "active" : ""}
                  >
                    <Link to="/email-password-reset">Reset Password</Link>
                  </li>
                  <li className={manu === "email-alert" ? "active" : ""}>
                    <Link to="/email-alert">Alert</Link>
                  </li>
                  <li className={manu === "email-invoices" ? "active" : ""}>
                    <Link to="/email-invoices">Invoice</Link>
                  </li>
                </ul>
              </div>
            </li> */}

            <li
              className={`sidebar-dropdown ${
                ["order-list", "documents", "new-order"].includes(manu)
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to="#"
                onClick={(e) => {
                  setSubManu(subManu === "order-item" ? "" : "order-item");
                }}
              >
                <LiaFileInvoiceDollarSolid className=" me-3 icon " />
                {t("Orders")}
              </Link>
              <div
                className={`sidebar-submenu ${
                  [
                    "order-list",
                    "documents",
                    "new-order",
                    "order-item",
                  ].includes(subManu)
                    ? "block"
                    : ""
                }`}
              >
                <ul>
                  {/* <li className={manu === "documents" ? "active" : ""}>
                    <Link to="/documents">Documents</Link>
                  </li> */}
                  <li className={manu === "new-order" ? "active" : ""}>
                    <Link to="/user-dashboard/new-order">{t("New_Order")}</Link>
                  </li>
                  <li className={manu === "order-list" ? "active" : ""}>
                    <Link to="/user-dashboard/order-list">
                      {t("View_Orders")}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            <li
              className={`sidebar-dropdown ${
                ["wallet-topup", "cards", "payment-history"].includes(manu)
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to="#"
                onClick={(e) => {
                  setSubManu(subManu === "wallet-item" ? "" : "wallet-item");
                }}
              >
                <CiWallet className=" me-3 icon " />
                {t("Wallet")}
              </Link>
              <div
                className={`sidebar-submenu ${
                  [
                    "wallet-topup",
                    "cards",
                    "payment-history",
                    "wallet-item",
                  ].includes(subManu)
                    ? "block"
                    : ""
                }`}
              >
                <ul>
                  {/* <li className={manu === "documents" ? "active" : ""}>
                    <Link to="/documents">Documents</Link>
                  </li> */}
                  <li className={manu === "wallet-topup" ? "active" : ""}>
                    <Link to="/user-dashboard/wallet-topup">{t("Top_up")}</Link>
                  </li>
                  <li className={manu === "cards" ? "active" : ""}>
                    <Link to="/user-dashboard/cards">
                      {t("Payment_Method")}
                    </Link>
                  </li>
                  <li className={manu === "payment-history" ? "active" : ""}>
                    <Link to="/user-dashboard/payment-history">
                      {t("Payment_History")}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            {/* <li
              className={`sidebar-dropdown ${
                [
                  "auth-login",
                  "auth-signup",
                  "auth-signup-success",
                  "auth-re-password",
                  "auth-lock-screen",
                ].includes(manu)
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to="#"
                onClick={(e) => {
                  setSubManu(subManu === "auth-item" ? "" : "auth-item");
                }}
              >
                <BiLogOutCircle className="me-3 icon" />
                Authentication
              </Link>
              <div
                className={`sidebar-submenu ${
                  [
                    "auth-login",
                    "auth-signup",
                    "auth-signup-success",
                    "auth-re-password",
                    "auth-lock-screen",
                    "auth-item",
                  ].includes(subManu)
                    ? "block"
                    : ""
                }`}
              >
                <ul>
                  <li className={manu === "auth-login" ? "active" : ""}>
                    <Link to="/auth-login">Login</Link>
                  </li>
                  <li className={manu === "auth-signup" ? "active" : ""}>
                    <Link to="/auth-signup">Signup</Link>
                  </li>
                  <li
                    className={manu === "auth-signup-success" ? "active" : ""}
                  >
                    <Link to="/auth-signup-success">Signup Success</Link>
                  </li>
                  <li className={manu === "auth-re-password" ? "active" : ""}>
                    <Link to="/auth-re-password">Reset Password</Link>
                  </li>
                  <li className={manu === "auth-lock-screen" ? "active" : ""}>
                    <Link to="/auth-lock-screen">Lockscreen</Link>
                  </li>
                </ul>
              </div>
            </li> */}

            {/* <li
              className={`sidebar-dropdown ${
                ["comingsoon", "maintenance", "error", "thankyou"].includes(
                  manu
                )
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to="#"
                onClick={(e) => {
                  setSubManu(subManu === "special-page" ? "" : "special-page");
                }}
              >
                <BiLayer className="me-3 icon" />
                Miscellaneous
              </Link>
              <div
                className={`sidebar-submenu ${
                  [
                    "comingsoon",
                    "maintenance",
                    "error",
                    "thankyou",
                    "special-page",
                  ].includes(subManu)
                    ? "block"
                    : ""
                }`}
              >
                <ul>
                  <li className={manu === "comingsoon" ? "active" : ""}>
                    <Link to="/comingsoon">Comingsoon</Link>
                  </li>
                  <li className={manu === "maintenance" ? "active" : ""}>
                    <Link to="/maintenance">Maintenance</Link>
                  </li>
                  <li className={manu === "error" ? "active" : ""}>
                    <Link to="/error">Error</Link>
                  </li>
                  <li className={manu === "thankyou" ? "active" : ""}>
                    <Link to="/thankyou">Thank You</Link>
                  </li>
                </ul>
              </div>
            </li> */}
            <li className={["", "ticket"].includes(manu) ? "active" : ""}>
              <Link to="/user-dashboard/ticket">
                <MdOutlineSupportAgent className=" me-3 icon " /> {t("Support")}
              </Link>
            </li>
            <li className={["", "address-list"].includes(manu) ? "active" : ""}>
              <Link to="/user-dashboard/address-list">
                <PiAddressBookLight className=" me-3 icon " /> {t("Address")}
              </Link>
            </li>
          </ul>
        </SimpleBarReact>
      </div>
      <div className="p-5 border-t border-gray-100 dark:border-slate-800">
        <div
          className={`bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40 flex items-center justify-center ${
            modal ? "" : "hidden"
          }`}
        >
          <div className="relative w-full h-auto max-w-lg p-4">
            <div className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
              <button
                type="button"
                onClick={() => setModal(!modal)}
                className="absolute -top-4 -end-4 text-gray-400 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 hover:text-gray-900 rounded-full text-sm p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <div className="p-6 py-10 text-center ">
                <div className="pt-2">
                  <span className="text-xl lg:text-2xl font-bold dark:text-white">
                    {t("Change_Language")}
                  </span>
                  <div className="mt-1 text-center text-black  items-center    dark:text-white">
                    <p className="text-xs lg:text-base font-semibold text-gray-400 dark:text-white">
                      {t("Select_your_prefer_settings")}
                    </p>
                  </div>
                  <div className="mt-2 text-start">
                    <h4 className="text-xl font-semibold mt-6">
                      {t("Language")}
                    </h4>
                    <Select
                      className="bg-gray-50 p-2 rounded-sm dark:bg-slate-800 border-0"
                      options={LanguagesOption}
                      placeholder="Language"
                      value={language}
                      onChange={(option) => handlechangeLanguage(option)}
                    />
                  </div>
                  <div className="mt-2 text-start">
                    <h4 className="text-xl  font-semibold mt-6">
                      {t("Currency")}
                    </h4>
                    <Select
                      className="bg-gray-50 p-2 rounded-sm dark:bg-slate-800 border-0"
                      options={CurrencyOption}
                      placeholder="Currency"
                    />
                  </div>
                  <input
                    type="submit"
                    onClick={saveChange}
                    value={t("Save Changes")}
                    disabled={disabled}
                    className="py-2 px-5 cursor-pointer inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
