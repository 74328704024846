import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logoImg from "../../assets/images/Login Illustration.png";
import * as Icon from "react-feather";
import contactImg from "../../assets/images/contact.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../component/Button";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import BackButton from "../../component/BackButton";
import { toast } from "react-toastify";
const validationSchemaStep1 = Yup.object().shape({
  email: Yup.string().required("email is required"),
  phone: Yup.string().required("Phone number is required"),
  password: Yup.string().required("Password is required"),
});
const validationSchemaStep2 = Yup.object().shape({
  companyName: Yup.string().required("Company name is required"),
  country: Yup.object().required("Select country"),
  city: Yup.string().required("City is required"),
  vatID: Yup.string().required("Vat Id is required"),
  taxId: Yup.string().required("Tax Id is required"),
});
const validationSchemaStep3 = Yup.object().shape({
  monthlyShipments: Yup.object().required("Select monthly shipments"),
  shipmentMostly: Yup.object().required("Shipments mostly required"),
  identityD1: Yup.mixed()
    .required("Required")
    .test(
      "fileFormat",
      "Only PDF, JPEG, PNG, and GIF files are allowed",
      (value) => {
        if (value) {
          const supportedFormats = [
            "application/pdf",
            "image/jpeg",
            "image/png",
            "image/gif",
          ]; // Add supported formats here
          return supportedFormats.includes(value.type);
        }
        return true;
      }
    )
    .test("fileSize", "File size must not be more than 3MB", (value) => {
      if (value) {
        return value.size <= 3145728;
      }
      return true;
    }),
  identityD2: Yup.mixed()
    .required("Required")
    .test(
      "fileFormat",
      "Only PDF, JPEG, PNG, and GIF files are allowed",
      (value) => {
        if (value) {
          const supportedFormats = [
            "application/pdf",
            "image/jpeg",
            "image/png",
            "image/gif",
          ]; // Add supported formats here
          return supportedFormats.includes(value.type);
        }
        return true;
      }
    )
    .test("fileSize", "File size must not be more than 3MB", (value) => {
      if (value) {
        return value.size <= 3145728;
      }
      return true;
    }),
});
const validationSchemaIndividualAcoount = Yup.object().shape({
  name: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
  emailAddress: Yup.string().required("Email address is required"),
  phonenumber: Yup.string().required("Phone number is required"),
  password: Yup.string().required("Password is required"),
});
const validationSchemaIndividualAcoount2 = Yup.object().shape({
  birthDate: Yup.string().required("birthdate is required"),
  country: Yup.object().required("Select country"),
  city: Yup.string().required("City is required"),
  identityD1: Yup.mixed()
    .required("Required")
    .test(
      "fileFormat",
      "Only PDF, JPEG, PNG, and GIF files are allowed",
      (value) => {
        if (value) {
          const supportedFormats = [
            "application/pdf",
            "image/jpeg",
            "image/png",
            "image/gif",
          ]; // Add supported formats here
          return supportedFormats.includes(value.type);
        }
        return true;
      }
    )
    .test("fileSize", "File size must not be more than 3MB", (value) => {
      if (value) {
        return value.size <= 3145728;
      }
      return true;
    }),
  identityD2: Yup.mixed()
    .required("Required")
    .test(
      "fileFormat",
      "Only PDF, JPEG, PNG, and GIF files are allowed",
      (value) => {
        if (value) {
          const supportedFormats = [
            "application/pdf",
            "image/jpeg",
            "image/png",
            "image/gif",
          ]; // Add supported formats here
          return supportedFormats.includes(value.type);
        }
        return true;
      }
    )
    .test("fileSize", "File size must not be more than 3MB", (value) => {
      if (value) {
        return value.size <= 3145728;
      }
      return true;
    }),
});
export default function SignUp() {
  const navigate = useNavigate();
  const { role } = useParams();
  const [identityDoc1, setIdentityDoc1] = useState(null);
  const [identityDoc2, setIdentityDoc2] = useState(null);
  const [identityBDoc1, setIdentityBDoc1] = useState(null);
  const [identityBDoc2, setIdentityBDoc2] = useState(null);

  const handleRemoveIDoc1 = () => {
    setIdentityDoc1(null);
    formikIndividual2.setFieldValue("identityD1", "");
  };
  const handleRemoveIDoc2 = () => {
    setIdentityDoc2(null);
    formikIndividual2.setFieldValue("identityD2", "");
  };
  const handleRemoveBIDoc1 = () => {
    setIdentityBDoc1(null);
    formikBusinessStep3.setFieldValue("identityD1", "");
  };
  const handleRemoveBIDoc2 = () => {
    setIdentityBDoc2(null);
    formikBusinessStep3.setFieldValue("identityD2", "");
  };
  const [isPending, setIsPending] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [step, setStep] = useState(2);
  const [individualStep, setIndividualStep] = useState(1);

  const [countryArray, setCountryArray] = useState([]);
  const packageArray = [
    { value: "20-50", label: "20-50" },
    { value: "50-100", label: "50-100" },
    { value: "+200", label: "+200" },
  ];

  const getCountry = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/country", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.countries && result.countries.length > 0) {
          const countries = result?.countries.map((country) => ({
            value: country.country_code,
            label: country.country_name,
            id: country.id,
            city: country.city,
            zipCode: country.zip_code,
          }));
          setCountryArray(countries);
        }
      })
      .catch((error) => console.error(error));
  };
  const EstimatedShippments = [
    { value: "<5", label: "<5" },
    { value: "5-20", label: "5-20" },
    { value: "20+", label: "20+" },
  ];
  const shippmentsOption = [
    { value: "Domestic", label: "Domestic" },
    { value: "International", label: "International" },
  ];

  const handlePersonSubmit = async (e) => {
    // e.preventDefault();
    setIsPending(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    var formdata = new FormData();
    formdata.append("first_name", formikIndividual.values.name);
    formdata.append("surname", formikIndividual.values.lastname);
    formdata.append("email", formikIndividual.values.emailAddress);
    formdata.append("password", formikIndividual.values.password);
    formdata.append("purpose", role);
    formdata.append("phone", formikIndividual.values.phonenumber);
    formdata.append("city", formikIndividual2.values.city);
    formdata.append("birthdate", formikIndividual2.values.birthDate);
    formdata.append(
      "identity_document_front",
      formikIndividual2.values.identityD1
    );
    formdata.append(
      "identity_document_back",
      formikIndividual2.values.identityD2
    );

    formdata.append(
      "country",
      formikIndividual2.values.country && formikIndividual2.values.country.label
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    await fetch("https://movssy.com/admin/api/register", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("🚀 ~ file: authSignup.js:46 ~ .then ~ user:", data);
        if (data?.status === true) {
          navigate("/auth-signup-success");
        } else if (data?.errors) {
          setErrorMsg(data?.message);
          toast.error(data?.message);
        }
        setIsPending(false);
      })
      .catch((err) => {
        console.log("🚀 ~ file: authSignup.js:52 ~ HandleSubmit ~ err:", err);
        setIsPending(false);
        setErrorMsg(err.message);
      });
  };

  const formikBusinessStep1 = useFormik({
    initialValues: {
      email: "",
      phone: "",
      password: "",
    },
    validationSchema: validationSchemaStep1,
    onSubmit: (values) => {
      setStep(3);
    },
  });
  const formikBusinessStep2 = useFormik({
    initialValues: {
      companyName: "",
      country: "",
      city: "",
      vatID: "",
      taxId: "",
    },
    validationSchema: validationSchemaStep2,
    onSubmit: (values) => {
      setStep(4);
    },
  });
  const formikBusinessStep3 = useFormik({
    initialValues: {
      shipmentMostly: "",
      monthlyShipments: "",
      identityD2: "",
      identityD1: "",
    },
    validationSchema: validationSchemaStep3,
    onSubmit: (values) => {
      handleBusinessSubmit();
    },
  });
  const formikIndividual = useFormik({
    initialValues: {
      phonenumber: "",
      name: "",
      emailAddress: "",
      password: "",
      lastname: "",
    },
    validationSchema: validationSchemaIndividualAcoount,
    onSubmit: (values) => {
      setIndividualStep(2);
    },
  });
  const formikIndividual2 = useFormik({
    initialValues: {
      birthDate: "",
      city: "",
      country: "",
      identityD2: "",
      identityD1: "",
    },
    validationSchema: validationSchemaIndividualAcoount2,
    onSubmit: (values) => {
      handlePersonSubmit();
    },
  });
  const handleIDoc1Change = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIdentityDoc1(file);
      formikIndividual2.setFieldValue("identityD1", file);
    }
  };
  const handleIDoc2Change = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIdentityDoc2(file);
      formikIndividual2.setFieldValue("identityD2", file);
    }
  };
  const handleBIDoc1Change = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIdentityBDoc1(file);
      formikBusinessStep3.setFieldValue("identityD1", file);
    }
  };
  const handleBIDoc2Change = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIdentityBDoc2(file);
      formikBusinessStep3.setFieldValue("identityD2", file);
    }
  };
  const handleBusinessSubmit = async (e) => {
    // e.preventDefault();
    setIsPending(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    var formdata = new FormData();
    formdata.append("first_name", formikBusinessStep2.values.companyName);
    formdata.append("email", formikBusinessStep1.values.email);
    formdata.append("password", formikBusinessStep1.values.password);
    formdata.append("purpose", role);
    formdata.append("company_name", formikBusinessStep2.values.companyName);
    formdata.append("city", formikBusinessStep2.values.city);
    formdata.append(
      "identity_document_front",
      formikBusinessStep3.values.identityD1
    );
    formdata.append(
      "identity_document_back",
      formikBusinessStep3.values.identityD2
    );
    formdata.append(
      "country",
      formikBusinessStep2.values.country &&
        formikBusinessStep2.values.country.label
    );
    formdata.append("vat_id", formikBusinessStep2.values.vatID);
    formdata.append("tax_id", formikBusinessStep2.values.taxId);
    formdata.append("phone", formikBusinessStep1.values.phone);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    await fetch("https://movssy.com/admin/api/register", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log("🚀 ~ file: authSignup.js:46 ~ .then ~ user:", data);
        if (data?.status === true) {
          navigate("/auth-signup-success");
        }
        if (data?.errors) {
          setErrorMsg(data?.message);
          toast.error(data?.message);
        }
        setIsPending(false);
      })
      .catch((err) => {
        console.log("🚀 ~ file: authSignup.js:52 ~ HandleSubmit ~ err:", err);
        setIsPending(false);
        setErrorMsg(err.message);
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (role === "personal") {
      await handlePersonSubmit();
    } else if (role === "business") {
      await handleBusinessSubmit();
    }
  };
  React.useEffect(() => {
    getCountry();
  }, []);
  return (
    <>
      <section className="relative ">
        <div className=" relative">
          <div className="md:flex ">
            <div className="xl:w-[50%] lg:w-[50%] md:w-[50%]">
              <div className="relative md:flex flex-col md:min-h-screen justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 md:px-10 py-10 px-4 z-1">
                <div className="text-center">
                  <h1 className=" font-semibold text-btnBackground text-2xl my-3">
                    Join Movssy
                  </h1>
                </div>
                <div className="title-heading text-center md:my-auto my-20">
                  {role === "personal" ? (
                    <>
                      <form action="auth-signup-success" className="text-start">
                        {individualStep === 1 ? (
                          <div className="grid grid-cols-1">
                            <div className="mb-4">
                              <label
                                className="font-semibold"
                                htmlFor="RegisterName"
                              >
                                First Name:
                              </label>
                              <input
                                id="name"
                                type="text"
                                className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="Harry"
                                onChange={formikIndividual.handleChange}
                                onBlur={formikIndividual.handleBlur}
                                value={formikIndividual.values.name}
                              />
                              {formikIndividual.touched.name &&
                                formikIndividual.errors.name && (
                                  <p className="text-red-500 text-sm mt-1">
                                    {formikIndividual.errors.name}
                                  </p>
                                )}
                            </div>
                            <div className="mb-4">
                              <label
                                className="font-semibold"
                                htmlFor="RegisterName"
                              >
                                Last Name:
                              </label>
                              <input
                                id="lastname"
                                type="text"
                                className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="Last Name"
                                onChange={formikIndividual.handleChange}
                                onBlur={formikIndividual.handleBlur}
                                value={formikIndividual.values.lastname}
                              />
                              {formikIndividual.touched.lastname &&
                                formikIndividual.errors.lastname && (
                                  <p className="text-red-500 text-sm mt-1">
                                    {formikIndividual.errors.lastname}
                                  </p>
                                )}
                            </div>

                            <div className="mb-4">
                              <label
                                className="font-semibold"
                                htmlFor="LoginEmail"
                              >
                                Email Address:
                              </label>
                              <input
                                id="emailAddress"
                                type="email"
                                className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="name@example.com"
                                onChange={formikIndividual.handleChange}
                                onBlur={formikIndividual.handleBlur}
                                value={formikIndividual.values.emailAddress}
                              />
                              {formikIndividual.touched.emailAddress &&
                                formikIndividual.errors.emailAddress && (
                                  <p className="text-red-500 text-sm mt-1">
                                    {formikIndividual.errors.emailAddress}
                                  </p>
                                )}
                            </div>
                            <div className="mb-4">
                              <label
                                className="font-semibold"
                                htmlFor="LoginPassword"
                              >
                                Work Phone:
                              </label>
                              <input
                                id="phonenumber"
                                type="number"
                                className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="+1 909 892 278:"
                                onChange={formikIndividual.handleChange}
                                onBlur={formikIndividual.handleBlur}
                                value={formikIndividual.values.phonenumber}
                              />
                              {formikIndividual.touched.phonenumber &&
                                formikIndividual.errors.phonenumber && (
                                  <p className="text-red-500 text-sm mt-1">
                                    {formikIndividual.errors.phonenumber}
                                  </p>
                                )}
                            </div>

                            <div className="mb-4">
                              <label
                                className="font-semibold"
                                htmlFor="LoginPassword"
                              >
                                Password:
                              </label>
                              <input
                                id="password"
                                type="password"
                                className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="Password:"
                                onChange={formikIndividual.handleChange}
                                onBlur={formikIndividual.handleBlur}
                                value={formikIndividual.values.password}
                              />
                              {formikIndividual.touched.password &&
                                formikIndividual.errors.password && (
                                  <p className="text-red-500 text-sm mt-1">
                                    {formikIndividual.errors.password}
                                  </p>
                                )}
                            </div>

                            <div className="flex w-full mb-4">
                              <div className="flex justify-between w-full items-center mb-0">
                                <div>
                                  <Button
                                    value={"Back"}
                                    onClick={() => navigate("/")}
                                  />
                                </div>
                                <div>
                                  <Button
                                    value="Next Step"
                                    onClick={formikIndividual.handleSubmit}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="text-center">
                              <span className="text-slate-400 me-2">
                                Already have an account ?{" "}
                              </span>{" "}
                              <Link
                                to="/auth-login"
                                className="text-black dark:text-white font-bold inline-block"
                              >
                                Sign in
                              </Link>
                            </div>
                          </div>
                        ) : individualStep === 2 ? (
                          <div className="grid grid-cols-1">
                            <div className="grid md:grid-cols-2  lg:grid-cols-2 grid-cols-1 gap-2">
                              <div className="mb-4">
                                <label
                                  className="font-semibold"
                                  htmlFor="LoginPassword"
                                >
                                  Date of birth:
                                </label>
                                <div className="form-input   w-full py-1 px-1 h-9 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 end">
                                  <Flatpickr
                                    options={{
                                      altInput: true,
                                      enableTime: false,
                                      altFormat: "d M Y",
                                      dateFormat: "Y-m-d",
                                    }}
                                    style={{
                                      width: "30%",
                                      borderColor: "green",
                                    }}
                                    value={formikIndividual2.values.birthDate}
                                    onChange={(date) => {
                                      formikIndividual2.setFieldValue(
                                        "birthDate",
                                        date[0]
                                      );
                                    }}
                                  />
                                  {formikIndividual2.touched.birthDate &&
                                    formikIndividual2.errors.birthDate && (
                                      <p className="text-red-500 text-sm mt-1">
                                        {formikIndividual2.errors.birthDate}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="mb-4">
                                <label
                                  className="font-semibold"
                                  htmlFor="LoginPassword"
                                >
                                  Country:
                                </label>
                                <Select
                                  className=" bg-gray-50 dark:bg-slate-800 border-0"
                                  options={countryArray}
                                  placeholder="Select Country"
                                  onChange={(option) =>
                                    formikIndividual2.setFieldValue(
                                      "country",
                                      option
                                    )
                                  }
                                />
                                {formikIndividual2.touched.country &&
                                  formikIndividual2.errors.country && (
                                    <p className="text-red-500 text-sm mt-1">
                                      {formikIndividual2.errors.country}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="grid md:grid-cols-2  lg:grid-cols-2 grid-cols-1 gap-2">
                              <div className="mb-4">
                                <label
                                  className="font-semibold"
                                  htmlFor="LoginPassword"
                                >
                                  City:
                                </label>
                                <input
                                  id="city"
                                  type="text"
                                  onChange={formikIndividual2.handleChange}
                                  onBlur={formikIndividual2.handleBlur}
                                  value={formikIndividual2.values.city}
                                  className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                  placeholder="Enter City"
                                />
                                {formikIndividual2.touched.city &&
                                  formikIndividual2.errors.city && (
                                    <p className="text-red-500 text-sm mt-1">
                                      {formikIndividual2.errors.city}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="font-semibold"
                                  htmlFor="LoginPassword"
                                >
                                  Zip Code:
                                </label>
                                <input
                                  id="zipCode"
                                  type="text"
                                  // onChange={formikIndividual2.handleChange}
                                  // onBlur={formikIndividual2.handleBlur}
                                  // value={formikIndividual2.values.city}
                                  className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                  placeholder="Enter zip code"
                                />
                                {/* {formikIndividual2.touched.city &&
                                formikIndividual2.errors.city && (
                                  <p className="text-red-500 text-sm mt-1">
                                    {formikIndividual2.errors.city}
                                  </p>
                                )} */}
                              </div>
                            </div>
                            <div className="mb-4">
                              <label
                                className="font-semibold"
                                htmlFor="LoginPassword"
                              >
                                Package Range:
                              </label>
                              <Select
                                className=" bg-gray-50 dark:bg-slate-800 border-0"
                                options={packageArray}
                                placeholder="Select pacakge range"
                              />
                              {/* {formikIndividual2.touched.country &&
                                  formikIndividual2.errors.country && (
                                    <p className="text-red-500 text-sm mt-1">
                                      {formikIndividual2.errors.country}
                                    </p>
                                  )} */}
                            </div>
                            <div>
                              <label className="form-label font-medium">
                                {"Address:"}
                              </label>
                              <textarea
                                name="address"
                                id="message"
                                className="form-input w-full py-2 px-3 h-22 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="Street, height, postal code, province and country"
                              ></textarea>
                            </div>
                            <div>
                              <label className="form-label font-medium">
                                {"Identity Documnet Frontside"}
                              </label>
                              <div className="mt-2 justify-center items-center flex border border-dashed py-5 rounded">
                                {identityDoc1 ? (
                                  <>
                                    <span>{identityDoc1.name}</span>
                                    <button
                                      onClick={handleRemoveIDoc1}
                                      className="ml-2 text-red-500"
                                    >
                                      &times;
                                    </button>
                                  </>
                                ) : (
                                  <input
                                    id="identityD1"
                                    onChange={handleIDoc1Change}
                                    type="file"
                                    name="identityD1"
                                    accept=".pdf, image/jpeg, image/png, image/gif"
                                  />
                                )}
                              </div>
                              {formikIndividual2.errors.identityD1 && (
                                <p className="text-red-500 text-sm mt-1">
                                  {formikIndividual2.errors.identityD1}
                                </p>
                              )}
                            </div>
                            <div>
                              <label className="form-label font-medium">
                                {"Identity Documnet Backside"}
                              </label>
                              <div className="mt-2 justify-center items-center flex border border-dashed py-5 rounded">
                                {identityDoc2 ? (
                                  <>
                                    <span>{identityDoc2.name}</span>
                                    <button
                                      onClick={handleRemoveIDoc2}
                                      className="ml-2 text-red-500"
                                    >
                                      &times;
                                    </button>
                                  </>
                                ) : (
                                  <input
                                    id="identityD2"
                                    onChange={handleIDoc2Change}
                                    type="file"
                                    name="identityD2"
                                    accept=".pdf, image/jpeg, image/png, image/gif"
                                  />
                                )}
                              </div>
                              {formikIndividual2.errors.identityD2 && (
                                <p className="text-red-500 text-sm mt-1">
                                  {formikIndividual2.errors.identityD2}
                                </p>
                              )}
                            </div>

                            <div className="mb-4">
                              <div className="flex items-center w-full mb-0">
                                <input
                                  className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2"
                                  type="checkbox"
                                  value=""
                                  id="AcceptT&C"
                                />
                                <label
                                  className="form-check-label text-slate-400"
                                  htmlFor="AcceptT&C"
                                >
                                  I Accept{" "}
                                  <Link className="text-indigo-600">
                                    Terms And Condition
                                  </Link>
                                </label>
                              </div>
                            </div>
                            <div className="flex w-full mb-4">
                              <div className="flex justify-between w-full items-center mb-0">
                                <div>
                                  <Button
                                    value={"Back"}
                                    onClick={() => setIndividualStep(1)}
                                  />
                                </div>
                                <div>
                                  <Button
                                    value="Finish"
                                    onClick={formikIndividual2.handleSubmit}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="text-center">
                              <span className="text-slate-400 me-2">
                                Already have an account ?{" "}
                              </span>{" "}
                              <Link
                                to="/auth-login"
                                className="text-black dark:text-white font-bold inline-block"
                              >
                                Sign in
                              </Link>
                            </div>
                          </div>
                        ) : null}
                      </form>
                    </>
                  ) : (
                    <>
                      <form className="text-start">
                        {step === 2 ? (
                          <>
                            <h5 className="my-6 text-xl font-semibold">
                              Account Details
                            </h5>
                            <div className="grid grid-cols-1">
                              <div className="mb-4">
                                <label
                                  className="font-semibold"
                                  htmlFor="LoginEmail"
                                >
                                  Work Email :
                                </label>
                                <input
                                  id="email"
                                  type="email"
                                  onChange={formikBusinessStep1.handleChange}
                                  onBlur={formikBusinessStep1.handleBlur}
                                  value={formikBusinessStep1.values.email}
                                  className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                  placeholder="name@example.com"
                                />
                                {formikBusinessStep1.touched.email &&
                                  formikBusinessStep1.errors.email && (
                                    <p className="text-red-500 text-sm mt-1">
                                      {formikBusinessStep1.errors.email}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-4">
                                <label
                                  className="font-semibold"
                                  htmlFor="LoginPassword"
                                >
                                  Work Phone:
                                </label>
                                <input
                                  id="phone"
                                  type="number"
                                  onChange={formikBusinessStep1.handleChange}
                                  onBlur={formikBusinessStep1.handleBlur}
                                  value={formikBusinessStep1.values.phone}
                                  className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                  placeholder="+1 909 892 278:"
                                />
                                {formikBusinessStep1.touched.phone &&
                                  formikBusinessStep1.errors.phone && (
                                    <p className="text-red-500 text-sm mt-1">
                                      {formikBusinessStep1.errors.phone}
                                    </p>
                                  )}
                              </div>

                              <div className="mb-4">
                                <label
                                  className="font-semibold"
                                  htmlFor="LoginPassword"
                                >
                                  Password:
                                </label>
                                <input
                                  id="password"
                                  type="password"
                                  onChange={formikBusinessStep1.handleChange}
                                  onBlur={formikBusinessStep1.handleBlur}
                                  value={formikBusinessStep1.values.password}
                                  className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                  placeholder="Password:"
                                />
                                {formikBusinessStep1.touched.password &&
                                  formikBusinessStep1.errors.password && (
                                    <p className="text-red-500 text-sm mt-1">
                                      {formikBusinessStep1.errors.password}
                                    </p>
                                  )}
                              </div>

                              <div className="flex justify-between mb-4">
                                <div className="flex items-center mb-0">
                                  <input
                                    className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2"
                                    type="checkbox"
                                    value=""
                                    id="RememberMe"
                                  />
                                  <label
                                    className="form-checkbox-label text-slate-400"
                                    htmlFor="RememberMe"
                                  >
                                    I agree to join Movssy mailing list
                                  </label>
                                </div>
                              </div>
                              <div className="flex w-full mb-4">
                                <div className="flex justify-between w-full items-center mb-0">
                                  <div>
                                    <Button
                                      value={"Back"}
                                      onClick={() => setStep(2)}
                                    />
                                  </div>
                                  <div>
                                    <Button
                                      value={"Next Step"}
                                      onClick={formikBusinessStep1.handleSubmit}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="text-center">
                                <span className="text-slate-400 me-2">
                                  Already have an account ?{" "}
                                </span>{" "}
                                <Link
                                  to="/auth-login"
                                  className="text-black dark:text-white font-bold inline-block"
                                >
                                  Sign in
                                </Link>
                              </div>
                            </div>
                          </>
                        ) : step == 3 ? (
                          <>
                            <div className="my-6 ">
                              <h5 className=" text-xl font-semibold">
                                Company details
                              </h5>
                              <p className="mt-1">
                                Enables long-term benefits & VAT deduction
                              </p>
                            </div>
                            <div className="grid grid-cols-1">
                              <div className="grid grid-cols-1 gap-2">
                                <div className="mb-4">
                                  <label
                                    className="font-semibold"
                                    htmlFor="LoginEmail"
                                  >
                                    Company Name :
                                  </label>
                                  <input
                                    id="companyName"
                                    type="text"
                                    className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                    placeholder="Company Name"
                                    onChange={formikBusinessStep2.handleChange}
                                    onBlur={formikBusinessStep2.handleBlur}
                                    value={
                                      formikBusinessStep2.values.companyName
                                    }
                                  />
                                  {formikBusinessStep2.touched.companyName &&
                                    formikBusinessStep2.errors.companyName && (
                                      <p className="text-red-500 text-sm mt-1">
                                        {formikBusinessStep2.errors.companyName}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                                <div className="mb-4">
                                  <label
                                    className="font-semibold"
                                    htmlFor="LoginPassword"
                                  >
                                    Tax Id:
                                  </label>
                                  <input
                                    id="taxId"
                                    type="number"
                                    onChange={formikBusinessStep2.handleChange}
                                    onBlur={formikBusinessStep2.handleBlur}
                                    value={formikBusinessStep2.values.taxId}
                                    className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                    placeholder="Enter Tax Id"
                                  />
                                  {formikBusinessStep2.touched.taxId &&
                                    formikBusinessStep2.errors.taxId && (
                                      <p className="text-red-500 text-sm mt-1">
                                        {formikBusinessStep2.errors.taxId}
                                      </p>
                                    )}
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="font-semibold"
                                    htmlFor="LoginPassword"
                                  >
                                    Vat Id:
                                  </label>
                                  <input
                                    id="vatID"
                                    type="number"
                                    onChange={formikBusinessStep2.handleChange}
                                    onBlur={formikBusinessStep2.handleBlur}
                                    value={formikBusinessStep2.values.vatID}
                                    className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                    placeholder="Enter Vat Id"
                                  />
                                  {formikBusinessStep2.touched.vatID &&
                                    formikBusinessStep2.errors.vatID && (
                                      <p className="text-red-500 text-sm mt-1">
                                        {formikBusinessStep2.errors.vatID}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                                <div className="mb-4">
                                  <label
                                    className="font-semibold"
                                    htmlFor="LoginPassword"
                                  >
                                    Country:
                                  </label>
                                  <Select
                                    className=" bg-gray-50 dark:bg-slate-800 border-0"
                                    options={countryArray}
                                    placeholder="Select Country"
                                    onChange={(option) =>
                                      formikBusinessStep2.setFieldValue(
                                        "country",
                                        option
                                      )
                                    }
                                  />
                                  {formikBusinessStep2.touched.country &&
                                    formikBusinessStep2.errors.country && (
                                      <p className="text-red-500 text-sm mt-1">
                                        {formikBusinessStep2.errors.country}
                                      </p>
                                    )}
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="font-semibold"
                                    htmlFor="LoginPassword"
                                  >
                                    City:
                                  </label>
                                  <input
                                    id="city"
                                    type="text"
                                    onChange={formikBusinessStep2.handleChange}
                                    onBlur={formikBusinessStep2.handleBlur}
                                    value={formikBusinessStep2.values.city}
                                    className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                    placeholder="Enter City"
                                  />
                                  {formikBusinessStep2.touched.city &&
                                    formikBusinessStep2.errors.city && (
                                      <p className="text-red-500 text-sm mt-1">
                                        {formikBusinessStep2.errors.city}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                                <div>
                                  <label className="form-label font-medium">
                                    {"Address:"}
                                  </label>
                                  <textarea
                                    name="address"
                                    id="address"
                                    className="form-input w-full py-2 px-3 h-22 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                    placeholder="Street, height, postal code, province and country"
                                  ></textarea>
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="font-semibold"
                                    htmlFor="LoginPassword"
                                  >
                                    Zip Code:
                                  </label>
                                  <input
                                    id="zipCode"
                                    type="text"
                                    // onChange={formikIndividual2.handleChange}
                                    // onBlur={formikIndividual2.handleBlur}
                                    // value={formikIndividual2.values.city}
                                    className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                    placeholder="Enter zip code"
                                  />
                                  {/* {formikIndividual2.touched.city &&
                                formikIndividual2.errors.city && (
                                  <p className="text-red-500 text-sm mt-1">
                                    {formikIndividual2.errors.city}
                                  </p>
                                )} */}
                                </div>
                              </div>
                              <div className="flex w-full mb-4">
                                <div className="flex justify-between w-full items-center mb-0">
                                  <div>
                                    <Button
                                      value={"Back"}
                                      onClick={() => setStep(2)}
                                    />
                                  </div>
                                  <div>
                                    <Button
                                      value={"Next Step"}
                                      onClick={formikBusinessStep2.handleSubmit}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="text-center">
                                <span className="text-slate-400 me-2">
                                  Already have an account ?{" "}
                                </span>{" "}
                                <Link
                                  to="/auth-login"
                                  className="text-black dark:text-white font-bold inline-block"
                                >
                                  Sign in
                                </Link>
                              </div>
                            </div>
                          </>
                        ) : step === 4 ? (
                          <>
                            <div className="my-6 ">
                              <h5 className=" text-xl font-semibold">
                                Tell us more about your company
                              </h5>
                              <p>
                                We will use this information to tailor your
                                service experience
                              </p>
                            </div>
                            <div className="grid grid-cols-1">
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                                <div className="mb-4">
                                  <div className="flex justify-between">
                                    <label
                                      className="font-semibold"
                                      htmlFor="LoginEmail"
                                    >
                                      Your estimated monthly shipments?
                                    </label>
                                  </div>

                                  <Select
                                    className="h-10 bg-gray-50 dark:bg-slate-800 border-0"
                                    options={EstimatedShippments}
                                    placeholder="Select"
                                    onChange={(option) =>
                                      formikBusinessStep3.setFieldValue(
                                        "monthlyShipments",
                                        option
                                      )
                                    }
                                  />
                                  {formikBusinessStep3.touched
                                    .monthlyShipments &&
                                    formikBusinessStep3.errors
                                      .monthlyShipments && (
                                      <p className="text-red-500 text-sm mt-1">
                                        {
                                          formikBusinessStep3.errors
                                            .monthlyShipments
                                        }
                                      </p>
                                    )}
                                </div>
                                <div className="mb-4">
                                  <div className="flex justify-between">
                                    <label
                                      className="font-semibold"
                                      htmlFor="LoginEmail"
                                    >
                                      Your shipments are mostly?
                                    </label>
                                  </div>
                                  <Select
                                    className=" bg-gray-50 dark:bg-slate-800 border-0"
                                    options={shippmentsOption}
                                    placeholder="Select"
                                    onChange={(option) =>
                                      formikBusinessStep3.setFieldValue(
                                        "shipmentMostly",
                                        option
                                      )
                                    }
                                  />
                                  {formikBusinessStep3.touched.shipmentMostly &&
                                    formikBusinessStep3.errors
                                      .shipmentMostly && (
                                      <p className="text-red-500 text-sm mt-1">
                                        {
                                          formikBusinessStep3.errors
                                            .shipmentMostly
                                        }
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="mb-4">
                                <label
                                  className="font-semibold"
                                  htmlFor="LoginPassword"
                                >
                                  Package Range:
                                </label>
                                <Select
                                  className=" bg-gray-50 dark:bg-slate-800 border-0"
                                  options={packageArray}
                                  placeholder="Select pacakge range"
                                />
                                {/* {formikIndividual2.touched.country &&
                                  formikIndividual2.errors.country && (
                                    <p className="text-red-500 text-sm mt-1">
                                      {formikIndividual2.errors.country}
                                    </p>
                                  )} */}
                              </div>
                              <div>
                                <label className="form-label font-medium">
                                  {"Identity Document Frontside"}
                                </label>
                                <div className="mt-2 justify-center items-center flex border border-dashed py-5 rounded">
                                  {identityBDoc1 ? (
                                    <>
                                      <span>{identityBDoc1.name}</span>
                                      <button
                                        onClick={handleRemoveBIDoc1}
                                        className="ml-2 text-red-500"
                                      >
                                        &times;
                                      </button>
                                    </>
                                  ) : (
                                    <div className="flex items-center">
                                      <input
                                        id="identityD1"
                                        onChange={handleBIDoc1Change}
                                        type="file"
                                        name="identityD1"
                                        accept=".pdf, image/jpeg, image/png, image/gif"
                                        className="hidden"
                                      />
                                      <label
                                        htmlFor="identityD1"
                                        className="flex items-center cursor-pointer bg-gray-200 hover:bg-gray-300 p-2 rounded"
                                      >
                                        <Icon.UploadCloud className="w-7 h-7" />
                                      </label>
                                    </div>
                                  )}
                                </div>
                                {formikBusinessStep3.errors.identityD1 && (
                                  <p className="text-red-500 text-sm mt-1">
                                    {formikBusinessStep3.errors.identityD1}
                                  </p>
                                )}
                              </div>
                              <div>
                                <label className="form-label  font-medium">
                                  {"Identity Document Backside"}
                                </label>
                                <div className="mt-2 justify-center items-center flex border border-dashed py-5 rounded">
                                  {identityBDoc2 ? (
                                    <>
                                      <span>{identityBDoc2.name}</span>
                                      <button
                                        onClick={handleRemoveBIDoc2}
                                        className="ml-2 text-red-500"
                                      >
                                        &times;
                                      </button>
                                    </>
                                  ) : (
                                    <div className="flex items-center">
                                      <input
                                        id="identityD2"
                                        onChange={handleBIDoc2Change}
                                        type="file"
                                        name="identityD2"
                                        accept=".pdf, image/jpeg, image/png, image/gif"
                                        className="hidden"
                                      />
                                      <label
                                        htmlFor="identityD2"
                                        className="flex items-center cursor-pointer bg-gray-200 hover:bg-gray-300 p-2 rounded"
                                      >
                                        <Icon.UploadCloud className="w-7 h-7" />
                                      </label>
                                    </div>
                                  )}
                                </div>
                                {formikBusinessStep3.errors.identityD2 && (
                                  <p className="text-red-500 text-sm mt-1">
                                    {formikBusinessStep3.errors.identityD2}
                                  </p>
                                )}
                              </div>
                              <div className="flex w-full mt-2 mb-4">
                                <div className="flex justify-between w-full items-center mb-0">
                                  <div>
                                    <Button
                                      value={"Back"}
                                      onClick={() => setStep(3)}
                                    />
                                  </div>
                                  <div>
                                    <Button
                                      value={"Finsih"}
                                      onClick={formikBusinessStep3.handleSubmit}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="text-center">
                                <span className="text-slate-400 me-2">
                                  Already have an account ?{" "}
                                </span>{" "}
                                <Link
                                  to="/auth-login"
                                  className="text-black dark:text-white font-bold inline-block"
                                >
                                  Sign in
                                </Link>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="xl:w-[50%] lg:w-[50%] md:w-[50%] flex justify-center  bg-gray-200  rounded-tr-md rounded-br-md shadow-sm">
              <div>
                <div className="text-center">
                  <div>
                    <div className="relative max-w-xl mx-auto text-center py-6">
                      <h1 className=" font-bold text-3xl my-3">
                        Welcome Back To
                      </h1>
                      <h1 className=" font-bold text-5xl mb-3">Movssy</h1>
                    </div>
                    <div className="relative max-w-xl mx-auto text-center py-1">
                      <p className="text-lg ">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                    <img
                      src={logoImg}
                      className="max-w-xl mx-auto w-72 h-72 mt-6"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
