import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import { MdNumbers } from "react-icons/md";
import UserProfileTab from "../../component/dashboard/userProfileTab";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeLanguagei18 } from "../utility/changeLanguagei18N";
import { authorize } from "../../store/slices/authSlice";
import { toast } from "react-toastify";
export default function MyDeatils() {
  const user = useSelector((state) => state.auth.user);
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  const { t } = useTranslation();
  const token = useSelector((state) => state.auth.token);
  const [email, setEmail] = useState(user?.email);
  const [firstName, setFirstName] = useState(user?.first_name);
  const [taxId, setTaxId] = useState(
    user?.tax_id === null ? null : user?.tax_id
  );
  const [vatId, setVatId] = useState(
    user?.vat_id === null ? null : user?.vat_id
  );

  const [options, setOptions] = useState(null);
  const [surName, setSurName] = useState(
    user?.surname === null ? "" : user?.surname
  );
  const [city, setCity] = useState(user?.city === null ? "" : user?.city);
  const [phone, setPhone] = useState(user?.phone === null ? "" : user?.phone);
  const [companyName, setCompanyName] = useState(
    user?.company_name === null ? "" : user?.company_name
  );

  const [document1, setDocument1] = useState(user?.identity_document1);
  const [document2, setDocument2] = useState(user?.identity_document2);
  const [receivedCountry, setReceivedCountry] = useState(user?.country);
  const [country, setCountry] = useState(null);
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [disabledSwitchButton, setDisabledSwitchButton] = useState(false);

  const [selectedDocument1, setSelectedDocument1] = useState(null);
  const [apiselectedDocument1, setApiSelectedDocument1] = useState(null);
  const [apiselectedDocument2, setApiSelectedDocument2] = useState(null);
  const [selectedDocument2, setSelectedDocument2] = useState(null);
  const [certificate, setCertificate] = useState(user?.certificate);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [apiselectedCertificate, setApiSelectedCertificate] = useState(null);

  const handleRemoveDocument1 = () => {
    setDocument1(null);
    setSelectedDocument1(null);
  };
  const handleRemoveDocument2 = () => {
    setDocument2(null);
    setSelectedDocument2(null);
  };
  const handleDocument1Change = (event) => {
    const file = URL.createObjectURL(event.target.files[0]);
    const apiFile = event.target.files[0];
    if (file) {
      setSelectedDocument1(file);
      setApiSelectedDocument1(apiFile);
    }
  };
  const handleRemoveCertificate = () => {
    setCertificate(null);
    setSelectedCertificate(null);
  };
  const handleCertificateChange = (event) => {
    const file = URL.createObjectURL(event.target.files[0]);
    const apiFile = event.target.files[0];
    if (file) {
      setSelectedCertificate(file);
      setApiSelectedCertificate(apiFile);
    }
  };
  const handleDocument2Change = (event) => {
    const file = URL.createObjectURL(event.target.files[0]);
    const apiFile = event.target.files[0];
    if (file) {
      setSelectedDocument2(file);
      setApiSelectedDocument2(apiFile);
    }
  };
  useEffect(() => {
    if (receivedCountry) {
      setCountry({
        value: receivedCountry,
        label: receivedCountry,
      });
    }
  }, [receivedCountry]);
  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption);
  };
  const dispatch = useDispatch();
  const AuthHandler = (token, userInfo) => {
    dispatch(authorize({ token, ...userInfo }));
  };
  const getCountry = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/country", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.countries && result.countries.length > 0) {
          const countries = result?.countries.map((country) => ({
            value: country.country_code,
            label: country.country_name,
            id: country.id,
            city: country.city,
            zipCode: country.zip_code,
          }));
          setOptions(countries);
        }
      })
      .catch((error) => console.error(error));
  };
  const editProfile = (e) => {
    e.preventDefault();
    setDisabledSaveButton(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append("first_name", firstName === null ? "" : firstName);
    formdata.append("surname", surName === null ? "" : surName);
    formdata.append("phone", phone);
    formdata.append("city", city);
    formdata.append("country", country && country.label);
    formdata.append(
      "identity_document_front",
      selectedDocument1 === null ? null : apiselectedDocument1
    );
    formdata.append(
      "identity_document_back",
      selectedDocument2 === null ? null : apiselectedDocument2
    );
    formdata.append("company_name", companyName);
    formdata.append(
      "certificate",
      selectedCertificate === null ? null : apiselectedCertificate
    );
    formdata.append("tax_id", taxId === null ? "" : taxId);
    formdata.append("vat_id", vatId === null ? "" : vatId);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/editProfile", requestOptions)
      .then((response) => response.json())
      .then(async ({ status, message, userData }) => {
        if (status === true) {
          await AuthHandler(token, userData);
          toast.success(message);
          setDisabledSaveButton(false);
        } else {
          toast.error(message);
        }
      })
      .catch((error) => {
        console.error(error);
        setDisabledSaveButton(false);
      });
  };
  const switchPurpose = (e) => {
    e.preventDefault();
    setDisabledSwitchButton(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append("company_name", companyName);
    formdata.append("vat_id", vatId);
    formdata.append("tax_id", taxId);
    formdata.append("certificate", apiselectedCertificate);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/switchPurpose", requestOptions)
      .then((response) => response.json())
      .then(async ({ status, message, userData }) => {
        if (status === true) {
          toast.success(message);
          await AuthHandler(token, userData);
          setDisabledSwitchButton(false);
        } else {
          toast.error(message);
          setDisabledSwitchButton(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setDisabledSwitchButton(false);
      });
  };
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
    changeLanguagei18(languageCode);
  }, []);
  const project = [
    { value: "Austria", label: "Austria" },
    { value: "Belgium", label: "Belgium" },
    { value: "England", label: "England" },
    { value: "SouthAfrica", label: "SouthAfrica" },
  ];
  useEffect(() => {
    getCountry();
  }, []);
  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="grid grid-cols-1">
          <div className="profile-banner relative text-transparent rounded-md shadow dark:shadow-gray-700 overflow-hidden">
            <input
              id="pro-banner"
              name="profile-banner"
              type="file"
              className="hidden"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1">
          <UserProfileTab />
          <div className="xl:col-span-9 lg:col-span-8 md:col-span-8 ">
            <div className="grid grid-cols-1 gap-6">
              <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <h5 className="text-lg font-semibold mb-4 text-btnBackground">
                  {t("Profile_Detail")} :
                </h5>
                <form>
                  {user?.purpose === "personal" ? (
                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                      <div>
                        <label className="form-label font-medium">
                          {t("First_Name")} :{" "}
                        </label>
                        <div className="form-icon relative mt-2">
                          <Icon.User className="w-4 h-4 absolute top-3 start-4" />
                          <input
                            type="text"
                            className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="First Name:"
                            id="firstname"
                            name="name"
                            required=""
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div>
                        <label className="form-label font-medium">
                          {t("Surname")}:
                        </label>
                        <div className="form-icon relative mt-2">
                          <Icon.UserCheck className="w-4 h-4 absolute top-3 start-4" />
                          <input
                            type="text"
                            className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="Last Name:"
                            id="lastname"
                            name="name"
                            required=""
                            value={surName}
                            onChange={(e) => setSurName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                      <div>
                        <label className="form-label text-btnBackground font-medium">
                          {t("Company_Name")} :{" "}
                        </label>
                        <div className="form-icon relative mt-2">
                          <Icon.User className="w-4 h-4 absolute top-3 start-4" />
                          <input
                            type="text"
                            className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="Company Name:"
                            id="companyname"
                            name="companyname"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-4">
                    <div>
                      <label className="form-label font-medium text-btnBackground flex justify-between items-center">
                        {t("Email")} :{" "}
                      </label>
                      <div className="form-icon relative mt-2">
                        <Icon.Mail className="w-4 h-4 absolute top-3 start-4" />
                        <input
                          type="email"
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                          placeholder="Email@gmail.com"
                          name="email"
                          disabled
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        {t("Phone_Number")} :{" "}
                      </label>
                      <div className="form-icon relative mt-2">
                        <Icon.Phone className="w-4 h-4 absolute top-3 start-4" />
                        <input
                          name="phone"
                          id="phone"
                          type="text"
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                          placeholder="+92 672788289 "
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <label className="form-label text-btnBackground font-medium flex justify-between items-center">
                        {t("Country")} :{" "}
                      </label>
                      <div className="form-icon relative mt-2">
                        <Select
                          className="bg-gray-50   border-0"
                          options={options}
                          placeholder="Pick-Up"
                          value={country}
                          onChange={handleCountryChange}
                        />
                      </div>
                    </div>
                    <div>
                      <label className="form-label  text-btnBackground font-medium">
                        {t("City")} :{" "}
                      </label>
                      <div className="form-icon relative mt-2">
                        <Icon.Globe className="w-4 h-4 absolute top-3 start-4" />
                        <input
                          name="name"
                          id="city"
                          type="text"
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                          placeholder="City"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                        {/* <PhoneInput
                          enableSearch={true}
                          country={"us"}
                          value={phoneno}
                          onChange={(phone) => setPhoneNo(phone)}
                        /> */}
                      </div>
                    </div>
                    {user?.purpose === "business" ? (
                      <>
                        <div>
                          <label className="form-label text-btnBackground font-medium flex justify-between items-center">
                            {t("Tax Id")} :{" "}
                          </label>
                          <div className="form-icon relative mt-2">
                            <MdNumbers className="w-4 h-4 absolute top-3 start-4" />
                            <input
                              type="text"
                              className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              placeholder="XXX-XXX-XXX"
                              name="taxId"
                              id="taxId"
                              value={taxId}
                              onChange={(e) => setTaxId(e.target.value)}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label text-btnBackground font-medium">
                            {t("Vat_Id")}:
                          </label>
                          <div className="form-icon relative mt-2">
                            <MdNumbers className="w-4 h-4 absolute top-3 start-4" />
                            <input
                              name="vatId"
                              id="vatId"
                              type="text"
                              className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              placeholder="XXXX-XXXX-XXXX"
                              value={vatId}
                              onChange={(e) => setVatId(e.target.value)}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-4">
                    <div className="">
                      <label className="form-label text-btnBackground font-medium">
                        Identity Document 1
                      </label>

                      <div className=" mt-2 justify-center items-center flex border border-dashed py-10 rounded">
                        {selectedDocument1 !== null || document1 !== null ? (
                          <>
                            {document1 !== null ? (
                              <img
                                src={`https://movssy.com/admin/public/storage/images/identity_documents/${document1}`}
                                alt=""
                                className="w-48 h-24"
                              />
                            ) : (
                              <img
                                src={selectedDocument1}
                                alt=""
                                className=" w-48  h-24"
                              />
                            )}
                            <button
                              onClick={handleRemoveDocument1}
                              className="ml-2 text-red-500  mt-[70px]"
                            >
                              <Icon.Trash className="w-4 h-4" />
                            </button>
                          </>
                        ) : (
                          <input
                            id="document1"
                            type="file"
                            required
                            onChange={handleDocument1Change}
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      <label className="form-label text-btnBackground font-medium">
                        Identity Document 2
                      </label>

                      <div className=" mt-2 justify-center items-center flex border border-dashed py-10 rounded">
                        {selectedDocument2 !== null || document2 !== null ? (
                          <>
                            {document2 !== null ? (
                              <img
                                src={`https://movssy.com/admin/public/storage/images/identity_documents/${document2}`}
                                alt=""
                                className=" w-48  h-24"
                              />
                            ) : (
                              <img
                                src={selectedDocument2}
                                alt=""
                                className=" w-48  h-24"
                              />
                            )}
                            <button
                              onClick={handleRemoveDocument2}
                              className="ml-2 text-red-500  mt-[70px]"
                            >
                              <Icon.Trash className="w-4 h-4" />
                            </button>
                          </>
                        ) : (
                          <input
                            id="document2"
                            type="file"
                            required
                            onChange={handleDocument2Change}
                          />
                        )}
                      </div>
                    </div>
                    <div className="mt-4">
                      <label className="form-label text-btnBackground font-medium">
                        Certificate
                      </label>

                      <div className=" mt-2 justify-center items-center flex border border-dashed py-10 rounded">
                        {selectedCertificate !== null ||
                        certificate !== null ? (
                          <>
                            {certificate !== null ? (
                              <img
                                src={`https://movssy.com/admin/public/storage/images/identity_documents/${certificate}`}
                                alt=""
                                className=" w-48  h-24"
                              />
                            ) : (
                              <img
                                src={selectedCertificate}
                                alt=""
                                className=" w-48  h-24"
                              />
                            )}
                            <button
                              onClick={handleRemoveCertificate}
                              className="ml-2 text-red-500  mt-[70px]"
                            >
                              <Icon.Trash className="w-4 h-4" />
                            </button>
                          </>
                        ) : (
                          <input
                            id="certificate"
                            type="file"
                            required
                            onChange={handleCertificateChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <input
                    type="submit"
                    id="submit"
                    disabled={disabledSaveButton}
                    name="send"
                    onClick={editProfile}
                    className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md mt-5"
                    value={t("Save_Changes")}
                  />
                </form>
              </div>
              {user?.purpose === "personal" ? (
                <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                  <h5 className="text-lg text-btnBackground font-semibold mb-4">
                    {t("Switch_to_business_profile")} :
                  </h5>
                  <form>
                    <div>
                      <label className="form-label text-btnBackground font-medium">
                        {t("Company_Name")}:
                      </label>
                      <div className="form-icon relative mt-2">
                        <Icon.Globe className="w-4 h-4 absolute top-3 start-4" />
                        <input
                          name="companyname"
                          id="companyname"
                          type="text"
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                          placeholder="Company Name "
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-4">
                      <div>
                        <label className="form-label text-btnBackground font-medium">
                          {t("Vat_Id")}:
                        </label>
                        <div className="form-icon relative mt-2">
                          <MdNumbers className="w-4 h-4 absolute top-3 start-4" />
                          <input
                            name="vatId"
                            id="vatId"
                            type="text"
                            className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="XXXX-XXXX-XXXX"
                            value={vatId}
                            onChange={(e) => setVatId(e.target.value)}
                          />
                        </div>
                      </div>
                      <div>
                        <label className="form-label text-btnBackground font-medium">
                          {t("Tax_Id")}:
                        </label>
                        <div className="form-icon relative mt-2">
                          <MdNumbers className="w-4 h-4 absolute top-3 start-4" />
                          <input
                            type="text"
                            className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="XXX-XXX-XXX"
                            name="taxId"
                            id="taxId"
                            value={taxId}
                            onChange={(e) => setTaxId(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <label className="form-label text-btnBackground font-medium">
                        Certificate
                      </label>

                      <div className=" mt-2 justify-center items-center flex border border-dashed py-10 rounded">
                        {selectedCertificate !== null ||
                        certificate !== null ? (
                          <>
                            {certificate !== null ? (
                              <img
                                src={`https://movssy.com/admin/public/storage/images/identity_documents/${document2}`}
                                alt=""
                                className=" w-48  h-24"
                              />
                            ) : (
                              <img
                                src={selectedCertificate}
                                alt=""
                                className=" w-48  h-24"
                              />
                            )}
                            <button
                              onClick={handleRemoveCertificate}
                              className="ml-2 text-red-500  mt-[70px]"
                            >
                              <Icon.Trash className="w-4 h-4" />
                            </button>
                          </>
                        ) : (
                          <input
                            id="document2"
                            type="file"
                            required
                            onChange={handleCertificateChange}
                          />
                        )}
                      </div>
                    </div>

                    <input
                      type="submit"
                      id="submit"
                      name="send"
                      className="py-2 px-5 bg-btnBackground inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center  hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md mt-5"
                      value={t("Send_Request")}
                      disabled={disabledSwitchButton}
                      onClick={switchPurpose}
                    />
                  </form>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
