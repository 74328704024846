import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBuilding } from "react-icons/fa6";
import { FaRegCircle } from "react-icons/fa";
import logo_icon_64 from "../../assets/images/logo-icon-64.png";
import { FaGoogle } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { IoPersonOutline } from "react-icons/io5";
import { FaRegCheckCircle } from "react-icons/fa";
import Button from "../../component/Button";
export default function AuthOption() {
  const [buisnessSelected, setBusinessSelected] = useState(null);
  const [PersonSelected, setPersonSelected] = useState("personal");
  const [index, setIndex] = useState(2);

  const handleBuisnessClick = () => {
    // Add your onClick logic here
    console.log("Button clicked!");
    setPersonSelected(null);
    setBusinessSelected("business");
    setIndex(1);
  };
  const handlePersonClick = () => {
    // Add your onClick logic here
    console.log("Button clicked!");
    setBusinessSelected(null);
    setPersonSelected("personal");
    setIndex(2);
  };
  return (
    <>
      <section className=" py-36 relative flex items-center bg-[url('../../assets/images/cta.jpg')] bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0  bg-gradient-to-b from-transparent to-black"></div>

        <div className="container relative">
          <div className="flex justify-center">
            <div className="max-w-[400px] w-full m-auto p-6 bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
              <Link to="/index">
                <img
                  src={logo_icon_64}
                  className="w-auto h-28 mx-auto "
                  alt=""
                />
              </Link>
              <h5 className="my-6 text-xl font-semibold">
                Get started for free!
              </h5>
              <form className="text-start">
                <div className="grid grid-cols-1">
                  <div
                    className={`mb-4 hover:shadow bg-blue-100 border  ${
                      buisnessSelected !== null ? `border-indigo-700` : ``
                    } cursor-pointer`}
                    onClick={handleBuisnessClick}
                  >
                    <div className="bg-white py-4 px-2 flex items-center justify-between">
                      <FaBuilding
                        className={` w-7 h-7 ${
                          buisnessSelected !== null
                            ? `text-indigo-700`
                            : `text-black`
                        }`}
                      />
                      <div className="">
                        Business <br />
                        Long-term benefits & VAT deduction
                      </div>
                      <div>
                        {buisnessSelected !== null ? (
                          <FaRegCheckCircle className="text-indigo-700" />
                        ) : (
                          <FaRegCircle />
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`mb-4 hover:shadow bg-blue-100 border ${
                      PersonSelected !== null ? `border-indigo-700` : ``
                    } cursor-pointer`}
                    onClick={handlePersonClick}
                  >
                    <div className="bg-white py-4 px-2 flex items-center justify-between">
                      <IoPersonOutline
                        className={`w-7 h-7 ${
                          PersonSelected !== null
                            ? `text-indigo-700`
                            : `text-black`
                        }`}
                      />
                      <div className="">
                        Personal <br />
                        You will be shipping as an individual
                      </div>
                      <div>
                        {PersonSelected != null ? (
                          <FaRegCheckCircle className="text-indigo-700" />
                        ) : (
                          <FaRegCircle />
                        )}
                      </div>
                    </div>
                  </div>
                  {index === 2 ? (
                    <>
                      <div className="mb-4">
                        <Link to={`/register/${"personal"}`}>
                          <Button value={"Continue with Email"} />
                        </Link>
                      </div>
                      <div className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base bg-indigo-600 hover:bg-indigo-700  hover:text-white text-white hover:border-indigo-700  rounded-md w-full mb-4 flex items-center justify-between">
                        <FaFacebook className="w-4 h-4" />
                        <p className="flex-grow text-center">
                          Continue with Facebook
                        </p>
                      </div>
                      <div className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base bg-white hover:bg-indigo-600 border-slate-600 hover:text-white hover:border-indigo-700 text-black rounded-md w-full mb-4 flex items-center justify-between">
                        <FaGoogle className="w-4 h-4" />
                        <p className="flex-grow text-center">
                          Continue with Google
                        </p>
                      </div>
                    </>
                  ) : index === 1 ? (
                    <div className="mb-4">
                      <Link to={`/register/${buisnessSelected}`}>
                        <Button value={"Next Step"} />
                      </Link>
                    </div>
                  ) : null}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
