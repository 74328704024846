import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";

import * as Icon from "react-feather";
import {
  MdKeyboardArrowRight,
  MdOutlineCollectionsBookmark,
  FaArrowRight,
  FaRegQuestionCircle,
  HiOutlineCog6Tooth,
  FiPhone,
} from "../../assets/icons/icons";

export default function Helpcenter() {
  const helpData = [
    {
      icon: FaRegQuestionCircle,
      title: "FAQs",
      desc: "The phrasal sequence of the is now so that many campaign and benefit",
      link: "/helpcenter-faqs",
    },
    {
      icon: MdOutlineCollectionsBookmark,
      title: "Guides / Support",
      desc: "The phrasal sequence of the is now so that many campaign and benefit",
      link: "/helpcenter-guides",
    },
    {
      icon: HiOutlineCog6Tooth,
      title: "Support Request",
      desc: "The phrasal sequence of the is now so that many campaign and benefit",
      link: "/helpcenter-guides",
    },
  ];
  return (
    <>
      <Navbar navClass="nav-light" />

      <section className="relative  w-full py-36 dark:bg-slate-900  bg-center bg-no-repeat bg-cover">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="mb-6 md:text-4xl text-3xl md:leading-normal leading-normal font-medium dark:text-white text-black">
              Hello ! <br /> How can we help you?
            </h3>

            <div className="text-center  mt-4 pt-2">
              <form className="relative  mx-auto max-w-xl">
                <input
                  type="text"
                  id="help"
                  name="name"
                  className="pt-4 shadow-sm pe-40 pb-4 ps-6 w-full h-[50px] outline-none text-black dark:text-white rounded-full bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700"
                  placeholder="Search your questions or topic..."
                />
                <button
                  type="submit"
                  className="py-2 px-5 inline-block font-semibold tracking-wide align-middle transition duration-500 ease-in-out text-base text-center absolute top-[2px] end-[3px] h-[46px] bg-btnBackground hover:bg-indigo-700 border border-btnBackground hover:border-indigo-700 text-white rounded-full"
                >
                  Search
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:py-12 py-8 lg:py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 text-textHighlight md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              Find The Help You Need
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Start working with Movssy provide about shipping.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-6 gap-[30px]">
            {helpData.map((item, index) => {
              let Ions = item.icon;
              return (
                <div key={index} className="text-center px-6 mt-6">
                  <div className="w-20 h-20 bg-indigo-600/5 text-btnBackground rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                    <Ions className="w-7 h-7" />
                  </div>

                  <div className="content mt-7">
                    <Link
                      to={item.link}
                      className="title h5 text-lg font-medium hover:text-indigo-600"
                    >
                      {item.title}
                    </Link>
                    <p className="text-slate-400 mt-3">{item.desc}</p>

                    <div className="mt-5">
                      <Link
                        to={item.link}
                        className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-btnBackground hover:text-indigo-600 after:bg-indigo-600 duration-500"
                      >
                        Read More <FaArrowRight className="ms-2 text-[10px]" />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="container relative md:py-12 py-8 lg:py-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 text-textHighlight md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              Get Started
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Start working with Movssy that can provide everything you about
              shipping.
            </p>
          </div>

          <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            <div className="flex">
              <Icon.HelpCircle className="fea icon-ex-md text-btnBackground me-3"></Icon.HelpCircle>
              <div className="flex-1">
                <h5 className="mb-2 text-xl font-semibold">
                  Lorem Ipsum <span className="text-btnBackground">Text</span>?
                </h5>
                <p className="text-slate-400">
                  If you created a new account after or while ordering you can
                  edit both addresses (for billing and shipping) in your
                  customer account.
                </p>
              </div>
            </div>

            <div className="flex">
              <Icon.HelpCircle className="fea icon-ex-md text-btnBackground me-3"></Icon.HelpCircle>
              <div className="flex-1">
                <h5 className="mb-2 text-xl font-semibold">
                  {" "}
                  Where can I edit my billing and shipping address?
                </h5>
                <p className="text-slate-400">
                  If you created a new account after or while ordering you can
                  edit both addresses (for billing and shipping) in your
                  customer account.
                </p>
              </div>
            </div>

            <div className="flex">
              <Icon.HelpCircle className="fea icon-ex-md text-btnBackground me-3"></Icon.HelpCircle>
              <div className="flex-1">
                <h5 className="mb-2 text-xl font-semibold">
                  {" "}
                  Lorem Ipsum <span className="text-btnBackground">Text</span>?
                </h5>
                <p className="text-slate-400">
                  If you created a new account after or while ordering you can
                  edit both addresses (for billing and shipping) in your
                  customer account.
                </p>
              </div>
            </div>

            <div className="flex">
              <Icon.HelpCircle className="fea icon-ex-md text-btnBackground me-3"></Icon.HelpCircle>
              <div className="flex-1">
                <h5 className="mb-2 text-xl font-semibold">
                  {" "}
                  Where can I edit my billing and shipping address?
                </h5>
                <p className="text-slate-400">
                  If you created a new account after or while ordering you can
                  edit both addresses (for billing and shipping) in your
                  customer account.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container relative md:py-12 py-8 lg:py-16">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-6 md:text-3xl text-textHighlight text-2xl md:leading-normal leading-normal font-semibold">
              Have Question ? Get in touch!
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              sagittis ultrices justo, ac semper felis cursus eu. Quisque
              pellentesque elementum purus, imperdiet gravida magna tempus et.
            </p>

            <div className="mt-6">
              <Link
                to="/contact-us"
                className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md me-2 mt-2"
              >
                <FiPhone className="me-1 text-lg" /> Contact us
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
