import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../component/Footer/footer";

import { MdOutlineNumbers } from "react-icons/md";
import * as Icon from "react-feather";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import RelocationHeaderBar from "../../component/relocation&removalsHeader";
const validationSchema = Yup.object().shape({
  // phoneno: Yup.string().when(["contactmethod", "phoneno"], {
  //   is: (contactmethod, phoneno) =>
  //     contactmethod &&
  //     (contactmethod.value === "Phone only" ||
  //       contactmethod.value === "Phone or email"),
  //   then: Yup.string().required("Phone number is required"),
  //   otherwise: Yup.string().notRequired(),
  // }),
  // email: Yup.string().when(["contactmethod", "email"], {
  //   is: (contactmethod, email) =>
  //     contactmethod &&
  //     (contactmethod.value === "Email only" ||
  //       contactmethod.value === "Phone or email"),
  //   then: Yup.string()
  //     .email("Invalid email address")
  //     .required("Email is required"),
  //   otherwise: Yup.string().notRequired(),
  // }),
  fullName: Yup.string().required("Full name is required"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneno: Yup.string()
    .matches(/^[0-9]+$/, "Must be a valid phone number")
    .required("Phone number is required"),
  contactmethod: Yup.object().required("Select contact method is required"),
});
export default function Step2ContactInfo() {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      phoneno: "",
      email: "",
      fullName: "",
      contactmethod: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("🚀 ~ ContactUs ~ values:", values);
      navigate("/order-confirmation");
    },
  });
  const Options = [
    { value: "Phone or email", label: "Phone or email" },
    { value: "Phone only", label: "Phone only" },
    { value: "Email only", label: "Email only" },
  ];
  return (
    <>
      <RelocationHeaderBar title={"Contact Information"} />

      <section className="container relative mx-auto  md:pt-28 md:pb-16">
        <div className="layout-specing md:col-span-4">
          <div className=" relative mt-4">
            <div
              className=" grid md:col-span-5  grid-cols-1 items-center 
             mx-auto"
            >
              <div className="">
                <div className="">
                  <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="grid grid-cols-1">
                        <div className="">
                          <h2 className="text-2xl font-bold text-btnBackground">
                            Contact details
                          </h2>
                          <div className="text-start mt-4">
                            <label htmlFor="comments" className="font-semibold text-btnBackground">
                              Full name
                            </label>
                            <div className="form-icon relative mt-2">
                              <input
                                name="fullName"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.fullName}
                                id="fullName"
                                type="text"
                                className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="Full name "
                              />
                            </div>
                            {formik.touched.fullName &&
                              formik.errors.fullName && (
                                <p className="text-red-500 text-sm mt-1">
                                  {formik.errors.fullName}
                                </p>
                              )}
                          </div>
                          <div className="text-start mt-4">
                            <label
                              htmlFor="comments"
                              className="font-semibold text-btnBackground"
                            >
                              Contact method
                            </label>
                            <div className="form-icon relative mt-2">
                              <Select
                                className="bg-gray-50 dark:bg-slate-800 border-0"
                                options={Options}
                                placeholder="Select contact method"
                                value={formik.values.contactmethod}
                                onChange={(option) =>
                                  formik.setFieldValue("contactmethod", option)
                                }
                              />
                            </div>
                            {formik.errors.contactmethod && (
                              <p className="text-red-500 text-sm mt-1">
                                {formik.errors.contactmethod}
                              </p>
                            )}
                          </div>
                          {formik.values.contactmethod &&
                          (formik.values.contactmethod.value === "Phone only" ||
                            formik.values.contactmethod.value ===
                              "Phone or email") ? (
                            <div className="text-start mt-4 text-btnBackground">
                              <label
                                htmlFor="comments"
                                className="font-semibold"
                              >
                                Phone number
                              </label>
                              <div className="form-icon relative mt-2">
                                <input
                                  name="phoneno"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.phoneno}
                                  id="phoneno"
                                  type="number"
                                  className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                  placeholder="Phone no "
                                />
                              </div>
                              {formik.touched.phoneno &&
                                formik.errors.phoneno && (
                                  <p className="text-red-500 text-sm mt-1">
                                    {formik.errors.phoneno}
                                  </p>
                                )}
                            </div>
                          ) : null}
                          {formik.values.contactmethod &&
                          (formik.values.contactmethod.value === "Email only" ||
                            formik.values.contactmethod.value ===
                              "Phone or email") ? (
                            <div className="text-start mt-4 text-btnBackground">
                              <label
                                htmlFor="comments"
                                className="font-semibold"
                              >
                                Email
                              </label>
                              <div className="form-icon relative mt-2">
                                <input
                                  name="email"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.email}
                                  id="email"
                                  type="email"
                                  className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                  placeholder="Email "
                                />
                              </div>
                              {formik.touched.email && formik.errors.email && (
                                <p className="text-red-500 text-sm mt-1">
                                  {formik.errors.email}
                                </p>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <button
                        type="submit"
                        id="submit"
                        name="send"
                        className="py-2 mt-4 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md justify-center flex items-center"
                      >
                        Next Step
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
