import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const fetchWallet = createAsyncThunk(
  "walletDetail/fetchwallet",
  async (pageNumber, { getState, rejectWithValue }) => {
    const { token } = getState().auth; // Assuming you have a slice named 'auth' that stores the token
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `https://movssy.com/admin/api/wallet?page=${pageNumber}`,
        requestOptions
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch addresses");
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const walletSlice = createSlice({
  name: "walletDetail",
  initialState: {
    data: 0,
    transactionData: [],
    isLoading: false,
    totalPageNumber: 1,
    error: null,
    message: null,
  },
  reducers: {
    addWallet: (state, action) => {
      state.transactionData.push(action.payload);
    },
    creditWallet: (state, action) => {
      const amount = action.payload;
      state.data = state.data + amount;
    },
    debitWallet: (state, action) => {
      const amount = action.payload;
      state.data = state.data - amount;
    },
    revokeWallet: (state, action) => {
      state.data = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWallet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWallet.fulfilled, (state, action) => {
      if (action.payload.status === true) {
        state.data = action.payload.amount;
        state.transactionData = action.payload.data;
        state.totalPageNumber = action.payload["Page Number"];
      }
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(fetchWallet.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});
export const { creditWallet, debitWallet, addWallet, revokeWallet } =
  walletSlice.actions;
export const walletReducer = walletSlice.reducer;
