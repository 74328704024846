import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import { FaGooglePay } from "react-icons/fa";
import { IoLogoPaypal } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";
import { BsBank } from "react-icons/bs";
import { FaRegCreditCard } from "react-icons/fa";
import { FaWallet } from "react-icons/fa";
import { CiWallet } from "react-icons/ci";
import AccordianComponent from "../../component/AccordianComponent";
import ShippingAccordian from "../../component/ShippingAccordian";
import HeaderBar from "../../component/HeaderBar";
import RightContainer from "../../component/OrdersRightContainer/RightContainer";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchWallet,
  debitWallet,
  addWallet,
} from "../../store/slices/walletSlice";
import Swal from "sweetalert2";
import { TailSpin } from "react-loader-spinner";
import Button from "../../component/Button/button";
import BeardCrumbs from "../../component/BeardCrumbs";
export default function Payment() {
  //   useEffect(() => {
  //     document.documentElement.setAttribute("dir", "ltr");
  //     document.documentElement.classList.add("light");
  //     document.documentElement.classList.remove("dark");
  //   }, []);
  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const { data } = useSelector((state) => state.walletDetail);
  const location = useLocation();
  const orderId = location.state?.orderId;
  const amount = location.state?.amount;
  const pickupContactNo = location.state?.pickupContactNo;
  const pickupCity = location.state?.pickupCity;
  const pickupPostalCode = location.state?.pickupPostalCode;
  const pickupUserName = location.state?.pickupUserName;
  const pickupStreetName = location.state?.pickupStreetName;

  const deliveryContactNo = location.state?.deliveryContactNo;
  const deliveryCity = location.state?.deliveryCity;
  const deliveryPostalCode = location.state?.deliveryPostalCode;
  const deliveryUserName = location.state?.deliveryUserName;
  const deliveryStreetName = location.state?.deliveryStreetName;

  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [paymentGateway, setPaymentGateway] = useState("CreditCard");
  const [isloading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const dispatch = useDispatch();
  const handleNavigate = (event) => {
    event.preventDefault();
    navigate("/user-dashboard/wallet-topup");
  };

  const walletApi = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append("amount", amount);
    formdata.append("order_id", orderId);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/stripe_debit", requestOptions)
      .then((response) => response.json())
      .then(async ({ status, message, data }) => {
        console.log("🚀 ~ .then ~ data:", data);
        if (status === true) {
          await dispatch(debitWallet(amount));
          dispatch(addWallet(data));
          setIsLoading(false);
          navigate("/user-dashboard/order-list");
          toast.success("Amount Successfully Paid...");
        } else {
          toast.error(message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      const cardElement = await elements.getElement(CardElement);
      const { token, error } = await stripe.createToken(cardElement);
      console.log("🚀 ~ handleSubmit ~ token:", token);

      if (error) {
        toast.error(error.message);
      } else {
        handlePayment(token.id);
      }
    } catch (error) {
      console.error("Error accessing Element:", error);
    }
  };
  const StripeCardApi = (stripe_token) => {
    setDisabled(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append("amount", amount && amount);
    formdata.append("token", stripe_token);
    formdata.append("order_id", orderId);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/stripe_card", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res?.status === true) {
          dispatch(addWallet(res?.wallet));
          Swal.fire("Success!", "Paid Successfully!", "success");
          navigate("/user-dashboard/order-list");
          setDisabled(false);
        } else {
          Swal.fire("Error", res?.message, "error");
          setDisabled(false);
        }
      })

      .catch((error) => {
        console.error(error);
        setDisabled(false);
      });
  };
  React.useEffect(() => {
    dispatch(fetchWallet(1));
  }, [navigate]);
  React.useEffect(() => {
    setTimeout(() => {
      setIsStripeLoading(false);
    }, 4000);
  }, []);
  const handlePayment = (tokenId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Pay it!",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        StripeCardApi(tokenId);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Thankyou!");
      }
    });
  };
  return (
    <div>
      <HeaderBar title={"Payment"} />
      <div className="container relative pb-0 mx-auto grid md:grid-cols-12 grid-cols-1 md:py-28">
        <div className="layout-specing md:col-span-8">
          <BeardCrumbs title={"Payment"} />
          <div className=" justify-between items-center">
            <h1 className="text-2xl font-bold ">Payment Methods</h1>
          </div>

          <div className="grid grid-cols-1  mt-6  ">
            <div className="  bg-white dark:bg-slate-800 md:flex lg:flex shadow-sm">
              <div
                className={`w-full cursor-pointer ${
                  paymentGateway === "UserCredits"
                    ? " bg-lightGrey "
                    : " border-lightGrey dark:border-gray-400"
                } cursor-pointer `}
                onClick={() => setPaymentGateway("UserCredits")}
              >
                <div
                  className={`border-t-4 ${
                    paymentGateway === "UserCredits"
                      ? " border-btnBackground "
                      : " border-white dark:border-gray-400"
                  }`}
                ></div>

                <div
                  className={`justify-center items-center flex flex-col my-2 ${
                    paymentGateway === "UserCredits"
                      ? " text-black font-bold"
                      : " "
                  }`}
                >
                  <IoWallet />
                  Wallet
                </div>
              </div>
              <div
                className={`w-full cursor-pointer ${
                  paymentGateway === "CreditCard"
                    ? " bg-lightGrey "
                    : " border-lightGrey dark:border-gray-400"
                } cursor-pointer `}
                onClick={() => setPaymentGateway("CreditCard")}
              >
                <div
                  className={`border-t-4 ${
                    paymentGateway === "CreditCard"
                      ? " border-btnBackground"
                      : " border-white dark:border-gray-400"
                  }`}
                ></div>

                <div
                  className={`justify-center items-center flex flex-col my-2 ${
                    paymentGateway === "CreditCard"
                      ? " text-black font-bold"
                      : " "
                  }`}
                >
                  <FaRegCreditCard />
                  Credit Card
                </div>
              </div>
              <div
                className={`w-full ${
                  paymentGateway === "PayPal"
                    ? " bg-lightGrey"
                    : " border-lightGrey"
                } cursor-pointer `}
                onClick={() => setPaymentGateway("PayPal")}
              >
                <div
                  className={`border-t-4 ${
                    paymentGateway === "PayPal"
                      ? " border-btnBackground"
                      : " border-white dark:border-gray-400"
                  }`}
                ></div>

                <div
                  className={`justify-center items-center flex flex-col my-2 ${
                    paymentGateway === "PayPal" ? " text-black font-bold" : " "
                  }`}
                >
                  <IoLogoPaypal />
                  PayPal
                </div>
              </div>
              <div
                className={`w-full  ${
                  paymentGateway === "GooglePay"
                    ? " bg-lightGrey"
                    : " border-lightGrey dark:border-gray-400"
                } cursor-pointer `}
                onClick={() => setPaymentGateway("GooglePay")}
              >
                <div
                  className={`border-t-4 ${
                    paymentGateway === "GooglePay"
                      ? " border-btnBackground"
                      : " border-white dark:border-gray-400"
                  }`}
                ></div>

                <div
                  className={`justify-center items-center flex flex-col my-2 ${
                    paymentGateway === "GooglePay"
                      ? " text-black font-bold"
                      : " "
                  }`}
                >
                  <FaGooglePay />
                  Google Pay
                </div>
              </div>
              <div
                className={`w-full cursor-pointer ${
                  paymentGateway === "BankTransfer"
                    ? " bg-lightGrey"
                    : " lightGrey"
                }  `}
                onClick={() => setPaymentGateway("BankTransfer")}
              >
                <div
                  className={`border-t-4 ${
                    paymentGateway === "BankTransfer"
                      ? " border-btnBackground"
                      : " border-white dark:border-gray-400"
                  }`}
                ></div>

                <div
                  className={`justify-center items-center flex flex-col my-2 ${
                    paymentGateway === "BankTransfer"
                      ? " text-black font-bold"
                      : " "
                  }`}
                >
                  <BsBank />
                  Bank Transfer
                </div>
              </div>
            </div>
            {paymentGateway === "CreditCard" ? (
              <div className="bg-white dark:bg-slate-800 items-center  py-4 px-4 mt-6 shadow-sm">
                {isStripeLoading ? (
                  <div className=" flex justify-center items-center py-4">
                    <TailSpin
                      visible={true}
                      height="60"
                      width="60"
                      color="rgb(67 56 202)"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                ) : (
                  <React.Fragment>
                    <CardElement />
                    <div className="mt-4">
                      <button
                        onClick={handleSubmit}
                        type="submit"
                        className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
                        disabled={disabled}
                      >
                        Pay Secure
                      </button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            ) : paymentGateway === "PayPal" ? (
              <div className="bg-white dark:bg-slate-800 items-center  py-4 px-4 mt-6 shadow-sm">
                <h2 className="text-lg font-semibold mt-2">
                  You will be redirected to the{" "}
                  <span className="font-bold ">PayPal</span> website.
                </h2>
                <Link to="">
                  <div className="mt-4">
                    <Button title={"Pay Pal"} />
                  </div>
                </Link>{" "}
                {/* Add more payment gateways as needed */}
              </div>
            ) : paymentGateway === "GooglePay" ? (
              <div className="bg-white dark:bg-slate-800 items-center  py-4 px-4 mt-6 shadow-sm">
                <h2 className="text-lg font-semibold mt-2">
                  You will be redirected to the{" "}
                  <span className="font-bold ">Google Pay</span> by clicking on
                  the below button:
                </h2>
                <Link to="">
                  <div className="mt-4">
                    <Button title={"Google Pay"} />
                  </div>
                </Link>{" "}
                {/* Add more payment gateways as needed */}
              </div>
            ) : paymentGateway === "BankTransfer" ? (
              <div className="bg-white dark:bg-slate-800  items-center  py-4 px-4 mt-6 shadow-sm">
                <h2 className="text-lg font-semibold mt-2">
                  Please note that we are only able to confirm your order after
                  the payment is received on our bank account. If you want to
                  ensure the earliest possible pick-up date, we suggest you to
                  consider one of the other possible payment solutions.{" "}
                </h2>
                <Link to="">
                  <div className="mt-4">
                    <Button title={"Finish Order"} />
                  </div>
                </Link>{" "}
                {/* Add more payment gateways as needed */}
              </div>
            ) : paymentGateway === "UserCredits" ? (
              <div className="bg-white dark:bg-slate-800  items-center  py-4 px-4 mt-6 shadow-sm">
                <div>
                  <div className="w-full flex flex-col  py-2 px-3">
                    <FaWallet className="h-7 w-7 text-btnBackground" />
                    <h6 className="text-xm font-semibold mt-1 text-btnBackground">
                      Your balance
                    </h6>
                    <h6 className="text-xm font-semibold text-btnBackground">
                      ${data.toFixed(2)}
                    </h6>

                    <h6 className="text-xm font-semibold  mt-3">
                      You would save:
                    </h6>
                    <h6 className="text-xm font-semibold">$3.30</h6>
                    <div className="mt-4">
                      <Button
                        disabled={isloading}
                        title={data < amount ? "Add Funds" : "Pay Secure"}
                        onClick={data < amount ? handleNavigate : walletApi}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div class="shadow-md block md:hidden mt-4 bg-white rounded p-4">
            <h2 className="text-lg font-bold text-btnBackground">
              Price Breakdown
            </h2>
            <AccordianComponent
              title="Pick-up"
              postcode={pickupPostalCode}
              address={pickupStreetName}
              name={pickupUserName}
              country={pickupCity}
              phno={pickupContactNo}
              city={pickupCity}
            />
            <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>

            <AccordianComponent
              title="Delivery"
              postcode={deliveryPostalCode}
              address={deliveryStreetName}
              name={deliveryUserName}
              country={deliveryCity}
              city={deliveryCity}
              phno={deliveryContactNo}
            />

            <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
            <ShippingAccordian
              shippingoption={"Regular"}
              insuranceprice={"$19"}
              price={"$287"}
            />

            <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
            <div className="  mt-2 flex justify-between items-center ">
              <div>
                <p className="font-bold text-2xl text-btnBackground">Total</p>
                <p className="font-thin text-xs">(incl. VAT)</p>
              </div>
              <h2 className="font-bold text-2xl text-btnBackground ">
                {amount && amount.toFixed(2)}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <RightContainer>
        <div
          className=" overflow-y-auto flex-grow flex-shrink-0"
          style={{ flexBasis: 0 }}
        >
          <div class="styles_content__Ctx_x">
            <h2 className="text-lg font-bold text-btnBackground">
              Price Breakdown
            </h2>
            <AccordianComponent
              title="Pick-up"
              postcode={pickupPostalCode}
              address={pickupStreetName}
              name={pickupUserName}
              country={pickupCity}
              phno={pickupContactNo}
              city={pickupCity}
            />
            <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>

            <AccordianComponent
              title="Delivery"
              postcode={deliveryPostalCode}
              address={deliveryStreetName}
              name={deliveryUserName}
              country={deliveryCity}
              city={deliveryCity}
              phno={deliveryContactNo}
            />

            <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
            <ShippingAccordian
              shippingoption={"Regular"}
              insuranceprice={"$19"}
              price={"$287"}
            />

            <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
            <div className="  mt-2 flex justify-between items-center ">
              <div>
                <p className="font-bold text-2xl text-btnBackground">Total</p>
                <p className="font-thin text-xs">(incl. VAT)</p>
              </div>
              <h2 className="font-bold text-2xl text-btnBackground ">
                {amount && amount.toFixed(2)}
              </h2>
            </div>
            <Link to="/order-address">
              <div className="mt-4">
                <input
                  type="submit"
                  id="search-buy"
                  name="search"
                  className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
                  value="Finish"
                />
              </div>
            </Link>
          </div>
        </div>
        <div
          style={{ flexBasis: "0%", flexShrink: 1, flexGrow: 0 }}
          className="h-auto py-8 "
        >
          <Link to="/order-address">
            <div className="mt-4">
              <input
                type="submit"
                id="search-buy"
                name="search"
                className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
                value="Finish"
              />
            </div>
          </Link>
        </div>
      </RightContainer>
    </div>
  );
}
