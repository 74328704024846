import React, { useState } from "react";
import { Link } from "react-router-dom";

import logoImg from "../../assets/images/Login Illustration.png";
import contactImg from "../../assets/images/contact.svg";
import { useNavigate } from "react-router-dom";

import { FaGoogle } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import Button from "../../component/Button";
import * as Icon from "react-feather";

import { authorize } from "../../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { revokePath } from "../../store/slices/pathSlice";
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});
export default function LoginForm() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const path = useSelector((state) => state.path.path);

  console.log("🚀 ~ LoginForm ~ token:", path);
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  console.log("🚀 ~ LoginForm ~ isAuthorized:", isAuthorized);

  const [errorMsg, setErrorMsg] = useState("");
  const [isPending, setIsPending] = useState(false);
  // const [email, setEmail] = useState("");

  // const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const AuthHandler = (token, userInfo) => {
    dispatch(authorize({ token, ...userInfo }));
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Your login logic here
      setIsPending(true);

      var formdata = new FormData();
      formdata.append("email", values.email);
      formdata.append("password", values.password);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch("https://movssy.com/admin/api/login", requestOptions)
        .then((res) => {
          if (!res.ok) {
            setErrorMsg("Could not co the data for that resource");
          }
          return res.json();
        })
        .then(async (data) => {
          if (data?.status === true) {
            await AuthHandler(data?.token, data?.User);
            const { token, User } = data;
            const userData = { token, User };
            await localStorage.setItem("User", JSON.stringify(userData));
            await sessionStorage.setItem("User", JSON.stringify(userData));
            toast.success("Successfully Login");
            if (path === "/order-details") {
              navigate("/order-details");
              await dispatch(revokePath());
              setIsPending(false);
            } else {
              navigate("/user-dashboard");
              setIsPending(false);
            }
          } else if (
            data?.message ===
            "Your details are getting verified by administrator. Kindly wait for your verification"
          ) {
            toast.error(data?.message);
          } else if (
            data?.message ===
            "Provided credential are invalid. Please provide valid credential"
          ) {
            setErrorMsg(data?.message);
            setIsPending(false);

            toast.error("Invalid email or password");
          } else {
            setErrorMsg(data?.message);
            toast.error(data?.message);
            setIsPending(false);
          }
          setIsPending(false);
        })
        .catch((err) => {
          console.log("🚀 ~ file: authSignup.js:52 ~ HandleSubmit ~ err:", err);
          setErrorMsg(err.message);
          setIsPending(false);
        });
      console.log("Form submitted with values:", values);
    },
  });

  return (
    <>
      <section className="relative ">
        <div className=" relative">
          <div className="md:flex ">
            <div className="xl:w-[50%] lg:w-[50%] md:w-[50%] flex justify-center  bg-gray-200  rounded-tr-md rounded-br-md shadow-sm">
              <div>
                <div className="text-center">
                  <div>
                    <div className="relative max-w-xl mx-auto text-center py-6">
                      <h1 className=" font-bold text-3xl my-3">
                        Welcome Back To
                      </h1>
                      <h1 className=" font-bold text-5xl mb-3">Movssy</h1>
                    </div>
                    <div className="relative max-w-xl mx-auto text-center py-1">
                      <p className="text-lg ">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                    <img
                      src={logoImg}
                      className="max-w-xl mx-auto w-72 h-72 mt-6"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:w-[50%] lg:w-[50%] md:w-[50%]">
              <div className="relative md:flex flex-col md:min-h-screen justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 md:px-20 py-10 px-20  overflow-hidden">
                <div className="text-center">
                  <h1 className="text-3xl font-bold">Login to Movssy</h1>
                </div>
                <div className="title-heading text-center md:my-auto my-20">
                  <form className="text-start">
                    <div className="grid grid-cols-1">
                      <div className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base bg-indigo-600 hover:bg-indigo-700  hover:text-white text-white hover:border-indigo-700  rounded-md w-full mb-4 flex items-center justify-between">
                        <FaFacebook className="w-4 h-4" />
                        <p className="flex-grow text-center">
                          Continue with Facebook
                        </p>
                      </div>
                      <div className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base bg-white hover:bg-indigo-600 border-slate-600 hover:text-white hover:border-indigo-700 text-black rounded-md w-full mb-4 flex items-center justify-between">
                        <FaGoogle className="w-4 h-4" />
                        <p className="flex-grow text-center">
                          Continue with Google
                        </p>
                      </div>

                      <div className="py-3 flex items-center">
                        <div className="border-2 w-full border-black" />
                        <div className="mx-3">OR</div>
                        <div className="border-2 w-full border-black" />
                      </div>
                      <div>
                        <div className="form-icon relative mt-2">
                          <Icon.Mail className="w-4 h-4 absolute top-3 start-4" />
                          <input
                            type="email"
                            className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="Email@gmail.com"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            name="email"
                          />
                        </div>
                        {formik.touched.email && formik.errors.email && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.email}
                          </p>
                        )}
                      </div>

                      <div className="mb-4">
                        <div className="form-icon relative mt-2">
                          <Icon.Lock className="w-4 h-4 absolute top-3 start-4" />
                          <input
                            id="LoginPassword"
                            type="password"
                            className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="Password:"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            name="password"
                          />
                        </div>
                        {formik.touched.password && formik.errors.password && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.password}
                          </p>
                        )}
                      </div>

                      <div className="flex justify-between mb-4">
                        <div className="flex items-center mb-0">
                          <input
                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2"
                            type="checkbox"
                            value=""
                            id="RememberMe"
                          />
                          <label
                            className="form-checkbox-label text-slate-400"
                            htmlFor="RememberMe"
                          >
                            Remember me
                          </label>
                        </div>
                        <p className="text-slate-400 mb-0">
                          <Link
                            to="/auth-re-password"
                            className="text-slate-400"
                          >
                            Forgot password ?
                          </Link>
                        </p>
                      </div>
                      {/* {isPending && <Button value="Loading...." />} */}

                      <div className="mb-4">
                        <input
                          type="submit"
                          onClick={formik.handleSubmit}
                          disabled={isPending}
                          className="py-2 px-5 inline-block tracking-wide  align-middle duration-500 text-base text-center bg-customBlue   text-white rounded-md w-full"
                          value="Login"
                        />
                      </div>

                      <div className="text-center">
                        <span className="text-slate-400 me-2">
                          Don't have an account ?
                        </span>{" "}
                        <Link
                          to="/register"
                          className="text-black dark:text-white font-bold inline-block"
                        >
                          Sign Up
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
