import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateTicket, modifyTicket } from "../../store/slices/ticketSlice";
export default function UpdateTicket() {
  const token = useSelector((state) => state.auth.token);
  console.log("🚀 ~ UpdateTicket ~ token:", token);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const [receivedServiceOption, setReceivedServisOption] = useState("");
  const [receivedEmergencyOption, setReceivedEmergencyOption] = useState("");

  const [apiFile, setApiFile] = useState(null);
  const [serviceOption, setServiceOption] = useState(null);
  const [emergencyOption, setEmergencyOption] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleRemoveFile = () => {
    setSelectedFile(null);
    setApiFile(null);
  };
  const handleServiceTypeChange = (selectedOption) => {
    setServiceOption(selectedOption);
  };
  const handleEmergencyTypeChange = (selectedOption) => {
    setEmergencyOption(selectedOption);
  };
  const [contactNo, setContactNo] = useState("");
  const [description, setDescription] = useState("");
  const emergencyData = [
    { value: "normal", label: "Normal" },
    { value: "medium", label: "Medium" },
    { value: "critical", label: "Critical" },
  ];

  const servicesData = [
    { value: "Document", label: "Document" },
    { value: "Package", label: "Package" },
    { value: "Pallet", label: "Pallet" },
    { value: "Van Delivery", label: "Van Delivery" },
    { value: "FTL & LTL", label: "FTL & LTL" },
    { value: "CustomOffer", label: "CustomOffer" },
  ];
  const handleContactNo = (e) => {
    setContactNo(e.target.value);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("🚀 ~ handleFileChange ~ file:", file);
    if (file) {
      setSelectedFile(file);
    }
  };
  useEffect(() => {
    if (receivedServiceOption) {
      setServiceOption({
        value: receivedServiceOption,
        label: receivedServiceOption,
      });
    }
  }, [receivedServiceOption]);

  useEffect(() => {
    if (receivedEmergencyOption) {
      const capitalizedOption =
        receivedEmergencyOption.charAt(0).toUpperCase() +
        receivedEmergencyOption.slice(1);
      setEmergencyOption({
        value: receivedEmergencyOption,
        label: capitalizedOption,
      });
    }
  }, [receivedEmergencyOption]);
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
  }, []);
  const ShowTicket = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    await fetch(
      `https://movssy.com/admin/api/ticket/show/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.staus === true) {
          if (res["User Tickets"] === null) {
            toast.error("No record found");
          } else {
            setDescription(res["User Tickets"]?.description);
            setReceivedEmergencyOption(res["User Tickets"]?.type_emergency);
            setApiFile(res["User Tickets"]?.file_upload);
            setReceivedServisOption(res["User Tickets"]?.type_service);
          }
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => console.error(error));
  };
  const UpdateTicket = (event) => {
    event.preventDefault();

    const formdata = new FormData();
    formdata.append("type_emergency", emergencyOption.value);
    formdata.append(
      "file_upload",
      selectedFile === null ? apiFile : selectedFile
    );
    console.log("selectedFile", selectedFile);
    console.log("apiFile", apiFile);

    formdata.append("description", description);
    formdata.append("type_service", serviceOption.value);

    dispatch(updateTicket({ formData: formdata, id: id }))
      .unwrap()
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        if (res?.status === true) {
          toast.success("Ticket update successfully");
          navigate("/user-dashboard/ticket");
          dispatch(modifyTicket(res["Updated Ticket"]));
        } else {
          toast.error(res?.message);
        }
      })
      .catch((error) => toast.error(error));
  };

  useEffect(() => {
    ShowTicket();
  }, [navigate]);

  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="grid grid-cols-1">
          <div className="profile-banner relative text-transparent rounded-md shadow dark:shadow-gray-700 overflow-hidden">
            <input
              id="pro-banner"
              name="profile-banner"
              type="file"
              className="hidden"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1">
          <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 ">
            <div className="grid grid-cols-1 gap-6">
              <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <h5 className="text-lg font-semibold mb-4">Ticket</h5>
                <form>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 ">
                    <div>
                      <label className="form-label font-medium">
                        Service Type
                      </label>
                      <div className="form-icon relative mt-2">
                        <Select
                          className="bg-gray-50 dark:bg-slate-800 border-0"
                          options={servicesData}
                          value={serviceOption}
                          onChange={handleServiceTypeChange}
                          placeholder="Select Service"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        Emergency Type
                      </label>
                      <div className="form-icon relative mt-2">
                        <Select
                          className="bg-gray-50 dark:bg-slate-800 border-0"
                          options={emergencyData}
                          value={emergencyOption}
                          onChange={handleEmergencyTypeChange}
                          placeholder="Select Emergency Option"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-4">
                    <div>
                      <label className="form-label font-medium">Message</label>
                      <div className="form-icon relative mt-2">
                        <textarea
                          name="description"
                          onChange={handleDescription}
                          value={description}
                          id="message"
                          className="form-input w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                          placeholder="Message :"
                        ></textarea>
                      </div>
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        Select File
                      </label>

                      <div className=" mt-2 justify-center items-center flex border border-dashed py-10 rounded">
                        {selectedFile !== null || apiFile !== null ? (
                          <>
                            {apiFile !== null ? (
                              <span>{apiFile}</span>
                            ) : (
                              <span>{selectedFile.name}</span>
                            )}
                            <button
                              onClick={handleRemoveFile}
                              className="ml-2 text-red-500"
                            >
                              &times;
                            </button>
                          </>
                        ) : (
                          <input
                            id="file"
                            type="file"
                            required
                            onChange={handleFileChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <input
                    onClick={UpdateTicket}
                    type="submit"
                    value={"Save"}
                    className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
