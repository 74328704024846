import React from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import logo_icon_64 from "../assets/images/logo-icon-64.png";
import { useSelector } from "react-redux";

function HeaderBar({ title }) {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);

  return (
    <>
      <div className="z-999 fixed w-full h-[76px] dark:bg-slate-900 bg-white shadow-sm items-center px-4 sm:px-8 top-0">
        <div className="flex justify-between items-center h-full">
          <div className="flex items-center space-x-2">
            <Link to="#" className="xl:hidden block me-2">
              <img
                src={logo_icon_64}
                className="md:hidden block mx-auto h-8"
                alt=""
              />
            </Link>

            <div className="ps-1.5">
              <div className="relative hidden sm:block font-bold text-2xl">
                <img
                  src={logo_icon_64}
                  className="inline-block mx-auto h-20"
                  alt=""
                />
              </div>
            </div>

            <div className="ps-1.5">
              <div
                className={`relative hidden sm:block ${
                  title === "Shipping Options"
                    ? "font-bold text-black dark:text-white"
                    : "font-semibold  text-gray-400"
                }`}
              >
                Shipping Options
              </div>
            </div>
            <div className="ps-1.5">
              <div className="relative hidden sm:block text-gray-400">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="ps-1.5">
              <div
                className={`relative hidden md:block ${
                  title === "Pickup & Delivery"
                    ? "font-bold text-black dark:text-white"
                    : "font-semibold   text-gray-400"
                }`}
              >
                Pickup & Delivery
              </div>
            </div>
            <div className="ps-1.5">
              <div className="relative hidden md:block text-gray-400">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="ps-1.5">
              <div
                className={`relative hidden md:block ${
                  title === "Insurance"
                    ? "font-bold text-black dark:text-white"
                    : "font-semibold  text-gray-400"
                }`}
              >
                Insurance
              </div>
            </div>
            <div className="ps-1.5">
              <div className="relative hidden md:block text-gray-400">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="ps-1.5">
              <div
                className={`relative  ${
                  title === "Payment"
                    ? "font-bold text-black dark:text-white"
                    : "font-semibold hidden md:block text-gray-400"
                }`}
              >
                Payment
              </div>
            </div>
          </div>

          <ul className="list-none mb-0 space-x-1">
            {!isAuthorized && (
              <li className="inline-block relative">
                <Link to="/login" className="ms-1">
                  <span className="py-[7px] px-6 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white rounded-md hidden sm:inline-block">
                    Login
                  </span>
                  <span className="py-[7px] px-6 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white rounded-md sm:hidden block">
                    Log In
                  </span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default HeaderBar;
