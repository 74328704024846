import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AiOutlineClose, AiOutlineInbox } from "../../assets/icons/icons";
import { GoDiscussionClosed } from "react-icons/go";
import { FaRegEnvelopeOpen } from "react-icons/fa";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchTickets,
  deleteTicket,
  removeTicket,
} from "../../store/slices/ticketSlice";
import { TailSpin } from "react-loader-spinner";
import { changeLanguagei18 } from "../utility/changeLanguagei18N";
import { useTranslation } from "react-i18next";
export default function Tickets() {
  const dispatch = useDispatch();
  const [allticketsData, setAllTicketsData] = useState([]);
  const { data, totalPageNumber } = useSelector((state) => state.Ticket);
  const [currentPage, setCurrentPage] = useState(1);
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  const { t } = useTranslation();
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
    changeLanguagei18(languageCode);
  }, []);
  const isLoading = useSelector((state) => state.Ticket.isLoading);

  React.useEffect(() => {
    dispatch(fetchTickets(currentPage));
  }, [currentPage]);
  let [composeModal, setComposeModal] = useState(false);
  let [activeIndex, setActiveIndex] = useState(0);

  const DeleteTicket = (itemid) => {
    dispatch(deleteTicket(itemid))
      .unwrap()
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        if (res?.status === true) {
          Swal.fire("Deleted!", "Your ticket has been deleted.", "success");
          dispatch(removeTicket({ id: itemid }));
        } else {
          Swal.fire("Error", "Something went wrong", "error");
          toast.error(res?.message);
        }
      })
      .catch((error) => console.error(error));
  };
  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="md:flex justify-between items-center">
          <h5 className="text-lg font-semibold">{t("Tickets")}</h5>

          <Link
            to="/user-dashboard/compose-ticket"
            className="py-2 px-4 inline-block font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md"
          >
            {t("Compose_Ticket")}
          </Link>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1 mt-6 gap-2">
          <div className="xl:col-span-2 lg:col-span-3 md:col-span-4">
            <div className="rounded-md shadow dark:shadow-gray-700 p-6 bg-white dark:bg-slate-900">
              <ul className="flex-column mt-3">
                <li role="presentation">
                  <button
                    onClick={() => setActiveIndex(0)}
                    className={`${
                      activeIndex === 0
                        ? "bg-btnBackground  text-white"
                        : "text-base hover:text-btnBackground"
                    }  px-4 py-2  font-semibold rounded-md w-full  duration-500 text-start flex items-center`}
                  >
                    <AiOutlineInbox className="me-1" /> {t("All")}
                  </button>
                </li>
                <li role="presentation">
                  <button
                    onClick={() => setActiveIndex(1)}
                    className={`${
                      activeIndex === 1
                        ? "bg-btnBackground  text-white"
                        : "text-base hover:text-btnBackground"
                    }  px-4 py-2  font-semibold rounded-md w-full  duration-500 text-start flex items-center`}
                  >
                    <FaRegEnvelopeOpen className="me-1" /> {t("Open")}
                  </button>
                </li>
                <li role="presentation">
                  <button
                    onClick={() => setActiveIndex(2)}
                    className={`${
                      activeIndex === 2
                        ? "bg-btnBackground   text-white"
                        : "text-base hover:text-btnBackground"
                    }  px-4 py-2  font-semibold rounded-md w-full  duration-500 text-start flex items-center`}
                  >
                    <GoDiscussionClosed className="me-1" /> {t("Close")}
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div
            className={`fixed z-50 flex items-center justify-center overflow-hidden inset-0 m-auto bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ${
              composeModal ? "" : "hidden"
            }`}
          >
            <div className="relative w-full h-auto max-w-xl p-4">
              <div className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-700">
                <div className="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-gray-700">
                  <h5 className="text-xl font-semibold">{t("Compose")}</h5>
                  <button
                    type="button"
                    onClick={() => setComposeModal(!composeModal)}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  >
                    <AiOutlineClose />
                  </button>
                </div>
                <div className="p-6 text-center">
                  <form>
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-4">
                      <div className="md:col-span-12">
                        <div className="form-icon relative">
                          <input
                            name="email"
                            id="toemail"
                            type="email"
                            className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="To"
                          />
                        </div>
                      </div>

                      <div className="md:col-span-6">
                        <div className="form-icon relative">
                          <input
                            name="email"
                            id="ccemail"
                            type="email"
                            className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="Cc"
                          />
                        </div>
                      </div>

                      <div className="md:col-span-6">
                        <div className="form-icon relative">
                          <input
                            name="email"
                            id="bccemail"
                            type="email"
                            className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="Bcc"
                          />
                        </div>
                      </div>

                      <div className="md:col-span-12">
                        <CKEditor
                          editor={ClassicEditor}
                          data="<div id=&nbsp;editor&nbsp;><p>Hello,<br/><br/> If the distribution of letters and 'words' is random, the reader will not be distracted from making a neutral judgment on the visual impact and readability of the typefaces (typography), or the distribution of text on the page (layout or type area). <br/><br/>Thank you</p></div>"
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            console.log("Editor is ready to use!", editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                          }}
                          onBlur={(event, editor) => {
                            console.log("Blur.", editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log("Focus.", editor);
                          }}
                        />
                      </div>

                      <div className="md:col-span-12">
                        <button
                          type="submit"
                          id="submit"
                          name="send"
                          className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center"
                        >
                          Send Now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="xl:col-span-10 lg:col-span-9 md:col-span-8">
            <div id="myTabContent" className="">
              {activeIndex === 0 ? (
                <div>
                  <div className="relative overflow-x-auto block w-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                    <table className="w-full text-start overflow-hidden custom-table mx-2">
                      <thead className="text-base">
                        <tr>
                          <th className="text-start p-4 w-auto min-w-[200px]">
                            {t("Subject")}
                          </th>

                          <th className="text-start p-4 w-auto min-w-[150px]">
                            {t("Service_Type")}
                          </th>
                          <th className="text-start p-4 w-48 min-w-[150px]">
                            {t("Emergency_Type")}
                          </th>
                          <th className="text-start p-4 w-auto min-w-[150px]">
                            {t("Date")}
                          </th>
                          <th className="text-start p-4 w-48 min-w-[150px]">
                            {t("Status")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan={5}>
                              <div className=" flex justify-center items-center py-4">
                                <TailSpin
                                  visible={true}
                                  height="60"
                                  width="60"
                                  color="rgb(67 56 202)"
                                  ariaLabel="tail-spin-loading"
                                  radius="1"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                />
                              </div>
                            </td>
                          </tr>
                        ) : data.length === 0 ? (
                          <tr>
                            <td colSpan={5}>
                              <div className=" flex justify-center items-center py-4">
                                {t("No_Record_Found")}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <React.Fragment>
                            {data.map((item, index) => {
                              return (
                                <tr
                                  key={index}
                                  className="bg-gray-200 border border-gray-200 hover:bg-white"
                                >
                                  <td className="text-start custom-left-round   dark:border-gray-800 p-4">
                                    <Link
                                      to={`/user-dashboard/chat/${item?.id}`}
                                      className="font-semibold hover:text-indigo-600"
                                    >
                                      {item?.subject === null
                                        ? "N/A"
                                        : item?.subject}
                                    </Link>
                                  </td>
                                  <td className="text-start  dark:border-gray-800 p-4">
                                    <span className="text-slate-400">
                                      {item?.type_service && item?.type_service}
                                    </span>
                                  </td>

                                  <td className="text-start dark:border-gray-800 p-4">
                                    <span className="text-slate-400">
                                      {item.type_emergency &&
                                        item.type_emergency}
                                    </span>
                                  </td>

                                  <td className="text-start  dark:border-gray-800 p-4">
                                    <span className="text-slate-400">
                                      {item.created_at.split("T")[0]}
                                    </span>
                                  </td>
                                  <td className="text-start custom-right-round  dark:border-gray-800 p-4">
                                    <span
                                      className={`${
                                        item.status === "open"
                                          ? "bg-emerald-600/10 dark:bg-emerald-600/20 text-emerald-600"
                                          : "bg-red-600/10 dark:bg-red-600/20 text-red-600 "
                                      }  text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1`}
                                    >
                                      {item?.status}
                                    </span>
                                  </td>
                                  {/* <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                    <div className="flex items-center">
                                      <Link
                                        to={`/user-dashboard/update-ticket/${item?.id}`}
                                        className="cursor-pointer py-1 px-4 inline-block font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                                      >
                                        Edit
                                      </Link>
                                      <span
                                        onClick={() => {
                                          handleDelete(item?.id);
                                        }}
                                        className="cursor-pointer py-1 px-4 inline-block font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-red-600/5 hover:bg-red-600 border-red-600/10 hover:border-red-600 text-red-600 hover:text-white rounded-md ms-2"
                                      >
                                        Delete
                                      </span>
                                    </div>
                                  </td> */}
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-4 flex items-center justify-between">
                    <div>
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="h-8 w-8 inline-flex items-center justify-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800 border-gray-100 dark:border-gray-700 text-slate-400 hover:text-slate-900 dark:hover:text-white rounded-full"
                      >
                        <i className="mdi mdi-arrow-left"></i>
                      </button>
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPageNumber}
                        className="h-8 w-8 inline-flex items-center justify-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800 border-gray-100 dark:border-gray-700 text-slate-400 hover:text-slate-900 dark:hover:text-white rounded-full"
                      >
                        <i className="mdi mdi-arrow-right"></i>
                      </button>
                    </div>

                    <span className="text-slate-400">
                      Showing {currentPage} out of {totalPageNumber}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {activeIndex === 1 ? (
                <div>
                  <div className="relative overflow-x-auto block w-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                    <table class="w-full text-start overflow-hidden custom-table mx-2">
                      <thead className="text-base">
                        <tr>
                          <th className="text-start p-4 w-auto min-w-[200px]">
                            {t("Subject")}
                          </th>

                          <th className="text-start p-4 w-auto min-w-[150px]">
                            {t("Service_Type")}
                          </th>
                          <th className="text-start p-4 w-48 min-w-[150px]">
                            {t("Emergency_Type")}
                          </th>
                          <th className="text-start p-4 w-auto min-w-[150px]">
                            {t("Date")}
                          </th>
                          <th className="text-start p-4 w-48 min-w-[150px]">
                            {t("Status")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? ( // If loading is true, render the loader
                          <tr>
                            <td colSpan={5}>
                              <div className=" flex justify-center items-center py-4">
                                <TailSpin
                                  visible={true}
                                  height="60"
                                  width="60"
                                  color="rgb(67 56 202)"
                                  ariaLabel="tail-spin-loading"
                                  radius="1"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                />
                              </div>
                            </td>
                          </tr>
                        ) : data.filter((ticket) => {
                            return ticket.status === "open";
                          }).length === 0 ? (
                          <tr>
                            <td colSpan={5}>
                              <div className=" flex justify-center items-center py-4">
                                No record found
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <React.Fragment>
                            {data
                              .filter((ticket) => {
                                return ticket.status === "open";
                              })
                              .map((item, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className="bg-gray-200 border border-gray-200 hover:bg-white "
                                  >
                                    <td className="text-start custom-left-round border-gray-100 dark:border-gray-800 p-4">
                                      <Link
                                        to={`/user-dashboard/chat/${item?.id}`}
                                        className="font-semibold hover:text-indigo-600"
                                      >
                                        {item?.subject === null
                                          ? "N/A"
                                          : item?.subject}
                                      </Link>
                                    </td>
                                    <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                      <span className="text-slate-400">
                                        {item?.type_service &&
                                          item?.type_service}
                                      </span>
                                    </td>

                                    <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                      <span className="text-slate-400">
                                        {item.type_emergency &&
                                          item.type_emergency}
                                      </span>
                                    </td>

                                    <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                      <span className="text-slate-400">
                                        {item.created_at.split("T")[0]}
                                      </span>
                                    </td>
                                    <td className="text-start custom-right-round border-t border-gray-100 dark:border-gray-800 p-4">
                                      <span
                                        className={`${
                                          item.status === "open"
                                            ? "bg-emerald-600/10 dark:bg-emerald-600/20 text-emerald-600"
                                            : "bg-red-600/10 dark:bg-red-600/20 text-red-600 "
                                        }  text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1`}
                                      >
                                        {item?.status}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                          </React.Fragment>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-4 flex items-center justify-between">
                    <div>
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="h-8 w-8 inline-flex items-center justify-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800 border-gray-100 dark:border-gray-700 text-slate-400 hover:text-slate-900 dark:hover:text-white rounded-full"
                      >
                        <i className="mdi mdi-arrow-left"></i>
                      </button>
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPageNumber}
                        className="h-8 w-8 inline-flex items-center justify-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800 border-gray-100 dark:border-gray-700 text-slate-400 hover:text-slate-900 dark:hover:text-white rounded-full"
                      >
                        <i className="mdi mdi-arrow-right"></i>
                      </button>
                    </div>

                    <span className="text-slate-400">
                      Showing {currentPage} out of {totalPageNumber}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}

              {activeIndex === 2 ? (
                <div>
                  <div className="relative overflow-x-auto block w-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                    <table className="w-full text-start overflow-hidden custom-table mx-2">
                      <thead className="text-base">
                        <tr>
                          <th className="text-start p-4 w-auto min-w-[200px]">
                            {t("Subject")}
                          </th>

                          <th className="text-start p-4 w-auto min-w-[150px]">
                            {t("Service_Type")}
                          </th>
                          <th className="text-start p-4 w-48 min-w-[150px]">
                            {t("Emergency_Type")}
                          </th>
                          <th className="text-start p-4 w-auto min-w-[150px]">
                            {t("Date")}
                          </th>
                          <th className="text-start p-4 w-48 min-w-[150px]">
                            {t("Status")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? ( // If loading is true, render the loader
                          <tr>
                            <td colSpan={5}>
                              <div className=" flex justify-center items-center py-4">
                                <TailSpin
                                  visible={true}
                                  height="60"
                                  width="60"
                                  color="rgb(67 56 202)"
                                  ariaLabel="tail-spin-loading"
                                  radius="1"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                />
                              </div>
                            </td>
                          </tr>
                        ) : data.filter((ticket) => {
                            return ticket.status === "closed";
                          }).length === 0 ? (
                          <tr>
                            <td colSpan={5}>
                              <div className=" flex justify-center items-center py-4">
                                No record found
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <React.Fragment>
                            {data
                              .filter((ticket) => {
                                return ticket.status === "closed";
                              })
                              .map((item, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className="bg-gray-200 border border-gray-200 hover:bg-white "
                                  >
                                    <td className="text-start custom-left-round dark:border-gray-800 p-4">
                                      <Link
                                        to={`/user-dashboard/chat/${item?.id}`}
                                        className="font-semibold hover:text-indigo-600"
                                      >
                                        {item?.subject === null
                                          ? "N/A"
                                          : item?.subject}
                                      </Link>
                                    </td>
                                    <td className="text-start dark:border-gray-800 p-4">
                                      <span className="text-slate-400">
                                        {item?.type_service &&
                                          item?.type_service}
                                      </span>
                                    </td>

                                    <td className="text-start  dark:border-gray-800 p-4">
                                      <span className="text-slate-400">
                                        {item.type_emergency &&
                                          item.type_emergency}
                                      </span>
                                    </td>

                                    <td className="text-start  dark:border-gray-800 p-4">
                                      <span className="text-slate-400">
                                        {item.created_at.split("T")[0]}
                                      </span>
                                    </td>
                                    <td className="text-start custom-right-round dark:border-gray-800 p-4">
                                      <span
                                        className={`${
                                          item.status === "open"
                                            ? "bg-emerald-600/10 dark:bg-emerald-600/20 text-emerald-600"
                                            : "bg-red-600/10 dark:bg-red-600/20 text-red-600 "
                                        }  text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1`}
                                      >
                                        {item?.status}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                          </React.Fragment>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-4 flex items-center justify-between">
                    <div>
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="h-8 w-8 inline-flex items-center justify-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800 border-gray-100 dark:border-gray-700 text-slate-400 hover:text-slate-900 dark:hover:text-white rounded-full"
                      >
                        <i className="mdi mdi-arrow-left"></i>
                      </button>
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPageNumber}
                        className="h-8 w-8 inline-flex items-center justify-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800 border-gray-100 dark:border-gray-700 text-slate-400 hover:text-slate-900 dark:hover:text-white rounded-full"
                      >
                        <i className="mdi mdi-arrow-right"></i>
                      </button>
                    </div>

                    <span className="text-slate-400">
                      Showing {currentPage} out of {totalPageNumber}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
