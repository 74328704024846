import React, { useState } from "react";
import * as Icon from "react-feather";
import Count from "./count";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { changeLanguagei18 } from "../../pages/utility/changeLanguagei18N";
import { useTranslation } from "react-i18next";

export default function DataStates() {
  const token = useSelector((state) => state.auth.token);
  const [totalOrders, setTotalOrders] = useState(0);
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  const { data } = useSelector((state) => state.walletDetail);

  const { t, i18n } = useTranslation();
  const getCountOrders = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/orderCount", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        if (res.status === true) {
          setTotalOrders(res["Order Count"]);
        } else {
          if (res.message !== "No Order Found !") {
            toast.error(res.message);
          }
        }
      })
      .catch((error) => console.error(error));
  };
  React.useEffect(() => {
    getCountOrders();
    changeLanguagei18(languageCode);
  }, []);
  return (
    <div className="grid xl:grid-cols-1 md:grid-cols-1 grid-cols-1  gap-6">
      <Count
        Icon={<Icon.ShoppingBag className="h-7 w-7" />}
        title={t("Current Balance")}
        amount={data.toFixed(2)}
        dollar={"$"}
        seeAll={t("Top up")}
      />
      <Count
        Icon={<Icon.ShoppingCart className="h-7 w-7" />}
        title={t("Total_Orders")}
        amount={totalOrders}
        seeAll={t("See_All")}
      />
    </div>
  );
}
