import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getUser } from "../utility/getUser";

function ProtectiveLoginRoute({ children }) {
  const token = useSelector((state) => state.auth.token);
  const [user, setUser] = React.useState(getUser());
  console.log("🚀 ~ ProtectiveLoginRoute ~ user:", user?.token);
  if (user?.token !== undefined) {
    return <Navigate to={"/"} />;
  }
  return children;
}

export default ProtectiveLoginRoute;
