import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
export default function ShippingAccordian({
  shippingoption,
  insuranceprice,
  price,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="mt-2">
      <div
        className="flex justify-between cursor-pointer"
        onClick={toggleAccordion}
      >
        <p className=" font-thin">{"Shipping"}</p>
        {isOpen === true ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </div>
      {isOpen === false ? (
        <div>
          <p className=" font-bold">{shippingoption}</p>
        </div>
      ) : null}
      {isOpen && (
        <div className="">
          <div className="flex justify-between items-center">
            <p className=" font-bold">{shippingoption}</p>

            <p className=" font-bold">{price}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className=" font-thin">{"Insurance"}</p>
            <p className=" font-bold">{insuranceprice}</p>
          </div>
        </div>
      )}
    </div>
  );
}
