import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoImg from "../../assets/images/Login Illustration.png";

import contactImg from "../../assets/images/contact.svg";
import { useNavigate } from "react-router-dom";

import { FaGoogle } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import Button from "../../component/Button";
import { authorize } from "../../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { revokePath } from "../../store/slices/pathSlice";
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});
export default function AuthRePassword() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const path = useSelector((state) => state.path.path);

  console.log("🚀 ~ LoginForm ~ token:", path);
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  console.log("🚀 ~ LoginForm ~ isAuthorized:", isAuthorized);

  const [errorMsg, setErrorMsg] = useState("");
  const [isPending, setIsPending] = useState(false);
  // const [email, setEmail] = useState("");

  // const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const AuthHandler = (token, userInfo) => {
    dispatch(authorize({ token, ...userInfo }));
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Your login logic here
      const formdata = new FormData();
      formdata.append("email", values.email);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(
        "https://movssy.com/admin/api/send_reset_password_email",
        requestOptions
      )
        .then((res) => {
          console.log("🚀 ~ file: authSignup.js:40 ~ .then ~ res:", res);
          if (!res.ok) {
            setErrorMsg("Could not co the data for that resource");
          }
          return res.json();
        })
        .then(({ status, message, token }) => {
          if (status === true) {
            if (message === "Send reset password email successfully") {
              toast.success(message);
              navigate(`/reset/${token}`);
            }
          } else {
            toast.error(message);
          }
        })
        .catch((error) => console.error(error));
    },
  });

  return (
    <>
      <section className="relative ">
        <div className=" relative">
          <div className="md:flex ">
            <div className="xl:w-[50%] lg:w-[50%] md:w-[50%]">
              <div className="relative md:flex flex-col md:min-h-screen justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 md:px-10 py-10  z-1 px-20">
                <div className="text-center">
                  <Link to="/index">
                    <img src={logoImg} className="mx-auto w-auto h-28" alt="" />
                  </Link>
                </div>
                <div className="title-heading text-center md:my-auto my-20">
                  <form className="text-start" onSubmit={formik.handleSubmit}>
                    <div className="grid grid-cols-1">
                      <h5 className="my-6 text-xl font-semibold text-btnBackground">
                        Reset Your Password
                      </h5>

                      <p className="text-slate-400 mb-6">
                        Please enter your email address. You will receive a link
                        to create a new password via email.
                      </p>
                      <div className="mb-4">
                        <label
                          className="font-semibold text-btnBackground"
                          htmlFor="LoginEmail"
                        >
                          Email Address:
                        </label>
                        <input
                          id="LoginEmail"
                          type="email"
                          className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                          placeholder="name@example.com"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          name="email"
                        />
                        {formik.touched.email && formik.errors.email && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.email}
                          </p>
                        )}
                      </div>

                      <div className="mb-4">
                        <input
                          type="submit"
                          className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md w-full"
                          value="Send"
                        />
                      </div>

                      <div className="text-center">
                        <span className="text-slate-400 me-2">
                          Remember your password ?{" "}
                        </span>{" "}
                        <Link
                          to="/auth-login"
                          className="text-black dark:text-white font-bold inline-block"
                        >
                          Sign in
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="xl:w-[50%] lg:w-[50%] md:w-[50%] flex justify-center  bg-gray-200  rounded-tr-md rounded-br-md shadow-sm">
              <div>
                <div className="text-center">
                  <div>
                    <div className="relative max-w-xl mx-auto text-center py-6">
                      <h1 className=" font-bold text-3xl my-3">
                        Welcome Back To
                      </h1>
                      <h1 className=" font-bold text-5xl mb-3">Movssy</h1>
                    </div>
                    <div className="relative max-w-xl mx-auto text-center py-1">
                      <p className="text-lg ">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                    <img
                      src={logoImg}
                      className="max-w-xl mx-auto w-72 h-72 mt-6"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
