import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { MdOutlineSupportAgent } from "react-icons/md";

import Order from "../../component/dashboard/order";
import DataStates from "../../component/dashboard/dataStates";
import ShippmentsAndTopUp from "../../component/dashboard/shipmentAndTopup";
import Dimensions from "../../component/dashboard/dimensions";
import { useSelector, useDispatch } from "react-redux";
import { fetchAddresses } from "../../store/slices/addressSlice";
import { fetchWallet } from "../../store/slices/walletSlice";
import { useTranslation } from "react-i18next";
import { changeLanguagei18 } from "../utility/changeLanguagei18N";
import CanvasJSReact from "@canvasjs/react-charts";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
export default function DashBoard() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAddresses());
    dispatch(fetchWallet(1));
  }, [navigate]);
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
    changeLanguagei18(languageCode);
  }, []);
  const options = {
    animationEnabled: true,
    title: {
      text: "Orders",
    },
    axisY: {
      title: "$4450",
    },
    data: [
      {
        type: "splineArea",
        xValueFormatString: "YYYY",
        yValueFormatString: "#,##0.## bn kW⋅h",
        showInLegend: true,
        markerBorderColor: "#008000",
        dataPoints: [
          { x: new Date(2008, 0), y: 10 },
          { x: new Date(2009, 0), y: 20 },
          { x: new Date(2010, 0), y: 30 },
          { x: new Date(2011, 0), y: 40 },
          { x: new Date(2012, 0), y: 50 },
          { x: new Date(2013, 0), y: 60 },
          { x: new Date(2014, 0), y: 70 },
          { x: new Date(2015, 0), y: 80 },
          { x: new Date(2016, 0), y: 90 },
          { x: new Date(2017, 0), y: 100 },
        ],
      },
    ],
  };
  return (
    <>
      <div className="container-fluid relative px-3">
        <div className="layout-specing">
          <div className="grid lg:grid-cols-12 grid-cols-1 mt-6 gap-6">
            <div className="lg:col-span-8">
              <Order />
            </div>
            <div className="lg:col-span-4">
              <DataStates />
              <Dimensions />
            </div>
          </div>
          {/* <div className="grid lg:grid-cols-12 grid-cols-1 mt-6 gap-6 "> */}

          {/* </div> */}

          <div className="grid lg:grid-cols-12 grid-cols-1 mt-6 gap-6">
            <div className="lg:col-span-12"></div>
            {/* <div className="lg:col-span-4">
              <Chat />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
