import React from "react";

function RightContainer({ children }) {
  return (
    <div className="bg-white hidden  dark:bg-slate-900 border-l-2 dark:border-l-slate-400 h-full mr-1 w-80 fixed top-0 px-4 right-0 md:flex flex-col">
      <div className=" h-[76px]"></div>
      {children}
    </div>
  );
}

export default RightContainer;
