import React from "react";
import QRIMAGE from "../../assets/images/qrimage.jpg";
import BARCODEIMG from "../../assets/images/barcode.jpg";
import GIFT from "../../assets/images/Capture.PNG";
import "./label.css";
function Label() {
  return (
    <div className=" container">
      <div className="border-black border-2">
        <div className="px-4  border-b-2 border-black">
          <div className="flex justify-between items-center ">
            <p className=" fontMedium">EASYSHIP+D</p>
            <p className="fontBold">2KG</p>
            <p className="fontBold">1 OF 1</p>
          </div>
          <div className="flex justify-between items-center ">
            <div className=" fontMedium flex flex-col">
              <p className=" fontMedium">852 3008 3991</p>
              <p className=" fontMedium">EASYSHIP+D</p>
              <p className=" fontMedium">2/F HUA FU COMMECIAL BUILDING</p>
              <p className=" fontMedium">HONG KONG 0000</p>
              <p className=" fontMedium">HONG KONG SAR, CHINA</p>
            </div>
            <div className=" fontMedium flex flex-col mr-4">
              <p className="font-semi-bold ">SHP#: A668 99TX KTH</p>
              <p className="font-semi-bold ">SHP WT: 2KG</p>
              <p className="font-semi-bold ">SHP DWT: 0.5 KG</p>
              <p className="font-semi-bold ">DATE: 20 FEB 2021</p>
              <p className="font-semi-bold ">DWT: 5,5,5</p>
            </div>
          </div>
          <p className="fontBold text-2xl">SHIP TO:</p>
          <div className="flex  flex-col mb-6  ml-11">
            <p className="fontBold text-xl">0123456789</p>
            <p className="fontBold text-xl">JHON SMITH</p>
            <p className="fontBold text-xl">905 LOMA VISTA DR</p>
            <p className="fontBold text-2xl">BEVERLY HILLS CA 90210</p>
            <p className="fontBold text-2xl">UNITED STATES</p>
          </div>
        </div>

        <div className="flex  px-4 ">
          <div className="flex  items-center border-r-2 p-1 border-black">
            <img src={QRIMAGE} className=" w-40  h-36" alt="" />
          </div>
          <div className="flex  flex-col px-2 py-2">
            <p className="fontBold text-3xl">CA 900-50</p>
            <img src={BARCODEIMG} className=" w-auto h-28" alt="" />
          </div>
        </div>
        <div className="h-2 w-full bg-black  "></div>
        <div className="flex   px-4  justify-between">
          <div className="flex flex-col border-black">
            <p className="fontBold text-3xl">UPS SAVER</p>
            <p className="font-semi-bold text-xl">
              TRACKING #: 1Z A66 899 04 95552 4102
            </p>
          </div>
          <div className="flex flex-col px-2 py-2">
            <p className="fontBold text-4xl ">1P</p>
          </div>
        </div>
        <div className="h-1 w-full bg-black  "></div>
        <div className="flex  ml-12 py-1">
          <img src={BARCODEIMG} className=" w-40  h-28" alt="" />
        </div>
        <div className="h-2 w-full bg-black  "></div>
        <div>
          <div className="flex   px-4  justify-between">
            <div className="flex flex-col border-black">
              <p className="fontBold text-xl">BILLING: P/P</p>
              <p className="fontBold text-xl">DESC: 1 x test</p>
            </div>
            <div className="flex  flex-col px-2 py-2">
              <p className="fontBold text-4xl mr-20">EDI</p>
            </div>
          </div>
          <div className="flex   px-4  justify-between mt-12">
            <div className="flex flex-col border-black justify-end">
              <p className="fontBold text-xl mb-2">
                REFERENCE NO.1: ESHK354319829
              </p>
            </div>
            <div className="flex  flex-col px-2 py-2">
              <img src={GIFT} className=" w-40  h-16" alt="" />
            </div>
          </div>
          <div className="flex  justify-evenly mx-auto">
            <p className="fontBold  text-sm ">XOL 21.02.07</p>
            <p className="fontBold text-sm">NV45 42.0A 01/2021*</p>
          </div>
        </div>
        <div className="h-1 w-full bg-black  "></div>

        <div className="flex mx-4">
          <p className="fontBold  text-sm py-1">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Label;
