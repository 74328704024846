import React from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import logo_icon_64 from "../assets/images/logo-icon-64.png";
import { useSelector } from "react-redux";

function BeardCrumbs({ title }) {
  return (
    <>
      <div className="flex flex-1 mt-20 items-center mb-4 md:hidden">
        <div className="flex flex-1 items-center ">
          <div className="ps-1.5">
            <div
              className={`relative block md:hidden ${
                title === "Shipping Options"
                  ? "font-semibold text-black text-sm dark:text-white"
                  : " text-sm text-gray-400"
              }`}
            >
              Shipping Options
            </div>
          </div>
          <div className="ps-1.5">
            <div className="relative block md:hidden text-gray-400">
              <IoIosArrowForward />
            </div>
          </div>
          <div className="ps-1.5">
            <div
              className={`relative block md:hidden ${
                title === "Pickup & Delivery"
                  ? "font-semibold text-black text-sm dark:text-white"
                  : " text-sm   text-gray-400"
              }`}
            >
              Pickup & Delivery
            </div>
          </div>
          <div className="ps-1.5">
            <div className="relative block md:hidden  text-gray-400">
              <IoIosArrowForward />
            </div>
          </div>
          <div className="ps-1.5">
            <div
              className={`relative block md:hidden ${
                title === "Insurance"
                  ? "font-semibold text-black text-sm  dark:text-white"
                  : "text-sm  text-gray-400"
              }`}
            >
              Insurance
            </div>
          </div>
          <div className="ps-1.5">
            <div className="relative block md:hidden  text-gray-400">
              <IoIosArrowForward />
            </div>
          </div>
          <div className="ps-1.5">
            <div
              className={`relative block md:hidden ${
                title === "Payment"
                  ? "font-semibold text-black text-sm dark:text-white"
                  : "text-sm   text-gray-400"
              }`}
            >
              Payment
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BeardCrumbs;
