import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

import * as Icon from "react-feather";
import { FiArrowRight } from "../../assets/icons/icons";
import { IoAnalyticsSharp } from "react-icons/io5";

function Count(props) {
  return (
    <div className="relative  overflow-hidden rounded-md  shadow-lg dark:shadow-gray-700 bg-white dark:bg-slate-900">
      <div className="p-5 flex items-center justify-between">
        <span>
          <span className="text-btnBackground font-semibold block">
            {props.title}
          </span>
          <span className="flex items-center justify-between mt-1">
            <span className="text-xl font-semibold">
              <span className="counter-value">
                {props.dollar && props.dollar}
                <CountUp start={0} end={props.amount} />
              </span>
            </span>
            {/* {item?.loss ? (
            <span className="text-red-600 text-sm ms-1 font-semibold flex items-center">
              <HiArrowTrendingDown className="me-1" />
              {item?.loss}
            </span>
          ) : (
            ""
          )} */}
            {/* {item?.profit ? (
            <span className="text-emerald-600 text-sm ms-1 font-semibold flex items-center">
              <HiArrowTrendingUp className="me-1" />
              {item?.profit}
            </span>
          ) : (
            ""
          )} */}
            {props?.null ? (
              <span className="text-slate-400 text-sm ms-1 font-semibold flex items-center">
                <IoAnalyticsSharp className="me-1" />
                {props?.null}
              </span>
            ) : (
              ""
            )}
          </span>
        </span>
        <span className="flex justify-center border border-btnBackground items-center rounded-md p-2    shadow shadow-indigo-600/5 dark:shadow-indigo-600/10 text-btnBackground">
          {props.Icon}
        </span>
      </div>

      <div className="px-5 py-4   dark:bg-slate-800">
        <Link
          to="/user-dashboard/order-list"
          className="relative inline-flex text-btnBackground items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500  hover:text-indigo-600 dark:hover:text-white after:bg-indigo-600  duration-500"
        >
          {props.seeAll} <FiArrowRight className="ms-1" />
        </Link>
      </div>
    </div>
  );
}

export default Count;
