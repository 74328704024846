import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import {
  deleteAddresses,
  fetchAddresses,
  removeAddress,
} from "../../store/slices/addressSlice";
import { TailSpin } from "react-loader-spinner";
import { changeLanguagei18 } from "../utility/changeLanguagei18N";
import { useTranslation } from "react-i18next";
import * as Icon from "react-feather";

export default function ShowAddress() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
  }, []);

  const token = useSelector((state) => state.auth.token);
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  const { t } = useTranslation();
  const { data, isLoading, totalPageNumer } = useSelector(
    (state) => state.addressDetail
  );
  const [currentPage, setCurrentPage] = React.useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleDelete = (itemId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteAddress(itemId);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Thankyou!");
      }
    });
  };

  const DeleteAddress = (id) => {
    dispatch(deleteAddresses(id))
      .unwrap()
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        if (res?.status === true) {
          Swal.fire("Deleted!", "Your address has been deleted.", "success");
          dispatch(removeAddress(res.data));
        } else {
          Swal.fire("Error", "Something went wrong", "error");
          toast.error(res?.message);
        }
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    dispatch(fetchAddresses(currentPage));
    changeLanguagei18(languageCode);
  }, [currentPage, navigate]);
  return (
    <React.Fragment>
      <div className="container-fluid relative px-3">
        <div className="layout-specing">
          <div className="md:flex justify-between items-center">
            <h5 className="text-lg font-semibold">Address List</h5>
            <Link
              to="/user-dashboard/address"
              className="py-2 px-4 inline-block font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md"
            >
              {t("Create_New_Address")}
            </Link>
          </div>

          <div className="grid grid-cols-1 mt-6">
            <div className="relative overflow-x-auto block w-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
              <table className="w-full text-start overflow-hidden custom-table mx-2">
                <thead className="text-base">
                  <tr>
                    <th className="text-start p-4 min-w-[128px]">{t("Id#")}</th>
                    <th className="text-start p-4 min-w-[192px]">
                      {t("Name")}
                    </th>
                    <th className="text-center p-4 min-w-[200px]">
                      {t("Street_No")}
                    </th>
                    <th className="text-center p-4">City</th>
                    <th className="text-center p-4 min-w-[150px]">
                      {t("Post_Code")}
                    </th>
                    <th className="text-center p-4 min-w-[150px]">
                      {t("Contact_Number")}
                    </th>
                    <th className="text-center p-4">{t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? ( // If loading is true, render the loader
                    <tr>
                      <td colSpan={7}>
                        <div className=" flex justify-center items-center py-4">
                          <TailSpin
                            visible={true}
                            height="60"
                            width="60"
                            color="rgb(67 56 202)"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                      </td>
                    </tr>
                  ) : data.length === 0 ? (
                    <tr>
                      <th colSpan={7}>{"No_Record_Found"}</th>
                    </tr>
                  ) : (
                    data.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className="bg-gray-200 border border-gray-200 hover:bg-white "
                        >
                          <th className="text-start custom-left-round cursor-pointer border-t border-gray-100 dark:border-gray-800 p-4">
                            {item?.id}
                          </th>

                          <td className="text-start  dark:border-gray-800 p-4">
                            <div className="flex items-center">
                              <span className="ms-2 font-medium">
                                {item.full_name === null
                                  ? "N/A"
                                  : item.full_name}
                              </span>
                            </div>
                          </td>
                          <td className="text-center  dark:border-gray-800 p-4">
                            <span className="text-slate-400">
                              {item.street === null ? "N/A" : item.street}
                            </span>
                          </td>
                          <td className="text-center  dark:border-gray-800 p-4">
                            <span className="text-slate-400">
                              {item.city === null ? "N/A" : item?.city}
                            </span>
                          </td>
                          <td className="text-center dark:border-gray-800 p-4">
                            <span className="text-slate-400">
                              {item.postal_code === null
                                ? "N/A"
                                : item.postal_code}
                            </span>
                          </td>
                          <td className="text-center  dark:border-gray-800 p-4">
                            <span className="text-slate-400">
                              {item.contact === null ? "N/A" : item?.contact}
                            </span>
                          </td>

                          <td className="text-end custom-right-round dark:border-gray-800 p-4">
                            <div className="flex items-center">
                              <Link
                                to={`/user-dashboard/update-address/${item?.id}`}
                                className="cursor-pointer py-1 px-4 inline-block font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md"
                              >
                                <Icon.Edit className="h-4 w-4" />
                              </Link>
                              <span
                                onClick={() => {
                                  handleDelete(item?.id);
                                }}
                                className="cursor-pointer py-1 px-4 inline-block font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-red-600/5 hover:bg-red-600 border-red-600/10 hover:border-red-600 text-red-600 hover:text-white rounded-md ms-2"
                              >
                                <Icon.Trash2 className="h-4 w-4" />
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>

            <div className="mt-5 flex items-center justify-between">
              <div>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="h-8 w-8 inline-flex items-center justify-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800 border-gray-100 dark:border-gray-700 text-slate-400 hover:text-slate-900 dark:hover:text-white rounded-full"
                >
                  <i className="mdi mdi-arrow-left"></i>
                </button>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPageNumer}
                  className="h-8 w-8 inline-flex items-center justify-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800 border-gray-100 dark:border-gray-700 text-slate-400 hover:text-slate-900 dark:hover:text-white rounded-full"
                >
                  <i className="mdi mdi-arrow-right"></i>
                </button>
              </div>

              <span className="text-slate-400">
                Showing {currentPage} out of {totalPageNumer}
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
