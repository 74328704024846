import React from "react";
import { Link } from "react-router-dom";
import logo_icon_64 from "../../assets/images/logo-icon-64.png";

export default function ContinueWithEmail() {
  return (
    <>
      <section className="md:h-screen py-36 flex items-center bg-[url('../../assets/images/cta.jpg')] bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
        <div className="container relative">
          <div className="flex justify-center">
            <div className="max-w-[400px] w-full m-auto p-6 bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
              <Link to="/index">
                <img
                  src={logo_icon_64}
                  className="w-auto h-20 mx-auto "
                  alt=""
                />
              </Link>
              <h5 className="my-6 text-xl font-semibold">Enter Your Email</h5>
              <div className="grid grid-cols-1">
                <p className="text-slate-400 mb-6">
                  Please enter your email address. You will receive a otp code
                  via email.
                </p>
                <form className="text-start">
                  <div className="grid grid-cols-1">
                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="LoginEmail">
                        Email Address:
                      </label>
                      <input
                        id="LoginEmail"
                        type="email"
                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                        placeholder="name@example.com"
                      />
                    </div>
                    <Link to={"/Otp-Verification"}>
                      <div className="mb-4">
                        <input
                          type="submit"
                          className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full"
                          value="Send"
                        />
                      </div>
                    </Link>

                    <div className="text-center">
                      <span className="text-slate-400 me-2">
                        Remember your password ?{" "}
                      </span>
                      <Link
                        to="/auth-login"
                        className="text-black dark:text-white font-bold inline-block"
                      >
                        Sign in
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
