import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import client1 from "../../assets/images/client/01.jpg";
import client2 from "../../assets/images/client/02.jpg";
import client3 from "../../assets/images/client/03.jpg";
import client4 from "../../assets/images/client/04.jpg";
import client5 from "../../assets/images/client/05.jpg";
import client6 from "../../assets/images/client/06.jpg";
import client7 from "../../assets/images/client/07.jpg";
import client8 from "../../assets/images/client/08.jpg";

import { MdKeyboardArrowRight } from "../../assets/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchWallet } from "../../store/slices/walletSlice";
import { TailSpin } from "react-loader-spinner";
import { changeLanguagei18 } from "../utility/changeLanguagei18N";
import { useTranslation } from "react-i18next";
export default function PaymentHistoryDashboard() {
  const { transactionData, isLoading, totalPageNumber } = useSelector(
    (state) => state.walletDetail
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = React.useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
    changeLanguagei18(languageCode);
  }, []);

  React.useEffect(() => {
    dispatch(fetchWallet(currentPage));
  }, [navigate, currentPage]);
  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="md:flex justify-between items-center">
          <h5 className="text-lg font-semibold">{t("Payment_History")}</h5>

          {/* <ul className="tracking-[0.5px] inline-flex items-center sm:mt-0 mt-3">
            <li className="inline-block capitalize text-[14px] font-bold duration-500 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white">
              <Link to="/index">Techwind</Link>
            </li>
            <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <MdKeyboardArrowRight />
            </li>
            <li
              className="inline-block capitalize text-[14px] font-bold text-indigo-600 dark:text-white"
              aria-current="page"
            >
              Order List
            </li>
          </ul> */}
        </div>

        <div className="grid grid-cols-1 mt-6">
          <div className="relative overflow-x-auto block w-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
            <table className="w-full text-start overflow-hidden custom-table mx-2">
              <thead className="text-base">
                <tr>
                  <th className="text-start p-4 min-w-[100px]">{t("Id#")}</th>
                  <th className="text-start  p-4 min-w-[150px]">{t("Date")}</th>
                  <th className="text-center p-4 min-w-[150px]">{t("Type")}</th>
                  <th className="text-center p-4">{t("Payment_Method")}</th>
                  <th className="text-center p-4 ">{t("Amount")}</th>
                  <th className="text-center p-4 min-w-[200px]">
                    {t("Type_of_Payment")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? ( // If loading is true, render the loader
                  <tr>
                    <td colSpan={6}>
                      <div className=" flex justify-center items-center py-4">
                        <TailSpin
                          visible={true}
                          height="60"
                          width="60"
                          color="rgb(67 56 202)"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    </td>
                  </tr>
                ) : transactionData === undefined ||
                  transactionData.length === 0 ? (
                  <tr>
                    <td colSpan={6}>
                      <div className=" flex justify-center items-center py-4">
                        {t("No_Record_Found")}
                      </div>
                    </td>
                  </tr>
                ) : (
                  transactionData.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className="bg-gray-200 border border-gray-200 hover:bg-white "
                      >
                        <td className="text-start border-t custom-left-round border-gray-100 dark:border-gray-800 p-4">
                          <Link to="#" className="hover:text-indigo-600">
                            <div className="flex items-center">
                              <span className="ms-2 font-medium">
                                {item.id}
                              </span>
                            </div>
                          </Link>
                        </td>
                        <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                          {item?.created_at.split("T")[0]}
                        </td>
                        <td className="text-center border-t border-gray-100 dark:border-gray-800 p-4">
                          <span className="text-slate-400">
                            {item?.transaction_type === null
                              ? "N/A"
                              : item?.transaction_type}
                          </span>
                        </td>
                        <td className="text-center border-t border-gray-100 dark:border-gray-800 p-4">
                          <span className="text-slate-400">
                            {item.payment_method}
                          </span>
                        </td>
                        <td className="text-center border-t border-gray-100 dark:border-gray-800 p-4">
                          <span className="text-slate-400">{item.amount}</span>
                        </td>
                        <td className="text-center border-t custom-right-round border-gray-100 dark:border-gray-800 p-4">
                          <span className="text-slate-400">{item.type}</span>
                        </td>

                        {/* <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                        <Link
                          to="/invoice"
                          className="py-1 px-4 inline-block font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                        >
                          Preview
                        </Link>
                        <Link
                          to="#"
                          className="py-1 px-4 inline-block font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white rounded-md ms-2"
                        >
                          Print
                        </Link>
                      </td> */}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>

          <div className="mt-5 flex items-center justify-between">
            <div>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="h-8 w-8 inline-flex items-center justify-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800 border-gray-100 dark:border-gray-700 text-slate-400 hover:text-slate-900 dark:hover:text-white rounded-full"
              >
                <i className="mdi mdi-arrow-left"></i>
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPageNumber}
                className="h-8 w-8 inline-flex items-center justify-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800 border-gray-100 dark:border-gray-700 text-slate-400 hover:text-slate-900 dark:hover:text-white rounded-full"
              >
                <i className="mdi mdi-arrow-right"></i>
              </button>
            </div>

            <span className="text-slate-400">
              Showing {currentPage} out of {totalPageNumber}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
