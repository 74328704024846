import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { FiPrinter } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";
import { IoIosRadioButtonOff } from "react-icons/io";
import { AiOutlineSafety } from "react-icons/ai";
import { IoSearch } from "react-icons/io5";
import { IoMdRadioButtonOn } from "react-icons/io";
import "react-calendar/dist/Calendar.css";
import { ArrowRight } from "react-feather";
import HeaderBar from "../../component/HeaderBar";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import RightContainer from "../../component/OrdersRightContainer/RightContainer";
import { useMemo } from "react";
import { checkPath } from "../../store/slices/pathSlice";
import "flatpickr/dist/themes/material_blue.css";
import Tooltip from "../../component/ToolTip/Tooltip";
import Flatpickr from "react-flatpickr";
import { TailSpin } from "react-loader-spinner";
import * as Yup from "yup";
import { useFormik } from "formik";
import BeardCrumbs from "../../component/BeardCrumbs";
const validationSchema = Yup.object().shape({
  data: Yup.array().of(
    Yup.object().shape({
      weight: Yup.number().required("Weight is required"),
      length: Yup.number().required("Length is required"),
      width: Yup.number().required("Width is required"),
      height: Yup.number().required("Height is required"),
      content: Yup.string().required("Content is required"),
      value: Yup.number().required("Value is required"),
    })
  ),
  shippingOption: Yup.string().required("Select shipping option"),
  deliveryOption: Yup.object().required("Select delivery option"),
  pickupOption: Yup.object().required("Select pick-up option"),
  pickupDate: Yup.string().required("Select date"),
});
export default function OrderDetails() {
  const goBack = () => {
    window.history.back();
  };
  const user = useSelector((state) => state.auth.user);
  const [data, setData] = useState([
    {
      weight: "",
      length: "",
      width: "",
      height: "",
      content: "",
      value: "",
    },
  ]);
  const location = useLocation();
  const pickupValue = location.state?.pickupValue;
  const deliveryValue = location.state?.deliveryValue;
  const [pickupOption, setPickupOption] = useState(null);
  const [isloading, setIsLoading] = useState(false);

  const [deliveryOption, setDeliveryOption] = useState(null);
  const [getParams, setGetParams] = useState(null);
  const [serviceName, setServiceName] = useState("");
  const [options, setOptions] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  useEffect(() => {
    const url = new URL(window.location.href);
    const orderDetailsParam = url.href.includes("user-dashboard");
    setGetParams(orderDetailsParam);
  }, []);

  const token = useSelector((state) => state.auth.token);
  console.log("🚀 ~ OrderDetails ~ token:", token);

  const addAnother = () => {
    const newData = [
      ...formik.values.data,
      { weight: "", length: "", width: "", height: "", value: "", content: "" },
    ];
    formik.setValues({ ...formik.values, data: newData });
    setData([
      ...data,
      { weight: "", length: "", width: "", height: "", value: "", content: "" },
    ]);
  };
  const getCountry = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/country", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.countries && result.countries.length > 0) {
          const countries = result?.countries.map((country) => ({
            value: country.country_code,
            label: country.country_name,
            id: country.id,
            city: country.city,
            zipCode: country.zip_code,
          }));
          setOptions(countries);
        }
      })
      .catch((error) => console.error(error));
  };
  const handleDelete = (indexToDelete) => {
    const updateddata = formik.values.data.filter(
      (role, index) => index !== indexToDelete
    );
    formik.setValues({ ...formik.values, data: updateddata });
    const updatedData = data.filter((item, index) => index !== indexToDelete);
    setData(updatedData);
  };
  const [markupamount, setMarkupamount] = useState(0);
  const [service, setServices] = useState([]);
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [netPrice, setNetPrice] = useState("");

  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const [show, toggle] = useState(false);

  const eventHandlers = useMemo(
    () => ({
      onKeyUp: () => handleSave(),
    }),
    []
  );

  const handleStoreData = () => {
    data.forEach((rowData) => {
      StoreOrderApi(rowData);
    });
  };
  React.useEffect(() => {
    if (pickupValue) {
      formik.setFieldValue("pickupOption", pickupValue);
      setPickupOption(pickupValue);
    }
  }, [pickupValue]);
  React.useEffect(() => {
    if (deliveryValue) {
      formik.setFieldValue("deliveryOption", deliveryValue);
      setDeliveryOption(deliveryValue);
    }
  }, [deliveryValue]);
  const AuthHandler = (pathValue) => {
    dispatch(
      checkPath({
        path: pathValue,
        orderingAs: "",
      })
    );
  };
  const formik = useFormik({
    initialValues: {
      data: [
        {
          weight: "",
          length: "",
          width: "",
          height: "",
          content: "",
          value: "",
        },
      ],
      shippingOption: "",
      pickupOption: null,
      deliveryOption: null,
      pickupDate: date,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleStoreData();
    },
  });

  React.useEffect(() => {
    if (isAuthorized === false) {
      AuthHandler("/order-details");

      navigate("/login");
    }
  }, [isAuthorized, navigate]);
  const StoreOrderApi = (rowData) => {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const formattedDate = `${formik.values.pickupDate.getFullYear()}-${(
      formik.values.pickupDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${formik.values.pickupDate
      .getDate()
      .toString()
      .padStart(2, "0")}`; // Format date as YYYY-MM-DD
    var formdata = new FormData();
    formdata.append("ordering_as", user?.purpose);
    formdata.append("package_name", formik.values.shippingOption);
    formdata.append("package_type", "DOCUMENTS");
    formdata.append("weight", rowData?.weight);
    formdata.append("height", rowData.height);
    formdata.append("length", rowData.length);
    formdata.append("width", rowData.width);
    formdata.append("from", pickupOption && pickupOption.value);
    formdata.append("to", deliveryOption && deliveryOption.value);
    formdata.append("pickup_date", formattedDate);
    formdata.append("service", serviceName);
    formdata.append("amount", amount);
    formdata.append("markup_amount", markupamount);
    formdata.append("dangerous_goods", isChecked);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/store_order", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        if (res.status === true) {
          toast.success("Order Detail Successfully Saved");
          navigate(`/order-address`, {
            state: {
              orderId: res?.Order.id,
              amount: amount,
              pickupCode: pickupOption && pickupOption.value,
              deliveryCode: deliveryOption && deliveryOption.value,
            },
          });
          setIsLoading(false);
        }
        if (res.message) {
          toast.error(res.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  const handleSave = () => {
    data.forEach((rowData) => {
      ShowMainQoutes(rowData);
    });
  };

  const handleWeightChange = (value, index) => {
    const newformikData = [...formik.values.data];
    newformikData[index].weight = value;
    formik.setValues({ ...formik.values, data: newformikData });
    const newData = [...data];
    newData[index].weight = value;
    setData(newData);
  };
  const handleHeigthChange = (value, index) => {
    const newformikData = [...formik.values.data];
    newformikData[index].height = value;
    formik.setValues({ ...formik.values, data: newformikData });
    const newData = [...data];
    newData[index].height = value;
    setData(newData);
  };
  const handleWidthChange = (value, index) => {
    const newformikData = [...formik.values.data];
    newformikData[index].width = value;
    formik.setValues({ ...formik.values, data: newformikData });
    const newData = [...data];
    newData[index].width = value;
    setData(newData);
  };
  const handleLengthChange = (value, index) => {
    const newformikData = [...formik.values.data];
    newformikData[index].length = value;
    formik.setValues({ ...formik.values, data: newformikData });
    const newData = [...data];
    newData[index].length = value;
    setData(newData);
  };
  const handleContentChange = (value, index) => {
    const newformikData = [...formik.values.data];
    newformikData[index].content = value;
    formik.setValues({ ...formik.values, data: newformikData });
    const newData = [...data];
    newData[index].content = value;
    setData(newData);
  };
  const handleValueChange = (value, index) => {
    const newformikData = [...formik.values.data];
    newformikData[index].value = value;
    formik.setValues({ ...formik.values, data: newformikData });
    const newData = [...data];
    newData[index].value = value;
    setData(newData);
  };
  const ShowMainQoutes = (rowData) => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append("length", rowData.length);
    formdata.append("width", rowData.width);
    formdata.append("height", rowData.height);
    formdata.append("content", rowData.content);
    formdata.append("declare_value", rowData.value);
    formdata.append("weight", rowData?.weight);
    // formdata.append("ship_country_code", "AR");
    // formdata.append("recipient_country_code", "US");

    formdata.append("pickup_country_code", pickupOption && pickupOption.value);
    formdata.append("pickup_city", pickupOption && pickupOption.city);
    formdata.append("pickup_postal_code", pickupOption && pickupOption.zipCode);

    formdata.append("delivery_city", deliveryOption && deliveryOption.city);
    formdata.append(
      "delivery_postal_code",
      deliveryOption && deliveryOption.zipCode
    );
    formdata.append(
      "delivery_country_code",
      deliveryOption && deliveryOption.value
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch("https://movssy.com/admin/api/main/get_qoute_api", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        if (res.status === true) {
          let markup = 0;
          let discount = 0;

          if (res.response["Markup Percentage"][0] !== undefined) {
            markup = res.response["Markup Percentage"][0].markup_percentage;
          }
          if (res.response["User Info"].discount !== null) {
            discount = res.response["User Info"].discount;
            const parsedNumber = parseFloat(discount);
            discount = Math.floor(parsedNumber);
            setDiscountAmount(discount);
          }
          console.log("🚀 ~ .then ~ discount:", discount);
          const combinedServices = [];
          if (res?.response["Fedex Api"]) {
            if (res?.response["Fedex Api"].errors) {
              console.log("Errors");
            } else {
              const fedexServiceName =
                res.response["Fedex Api"].output.rateReplyDetails;
              combinedServices.push(
                ...fedexServiceName.map((service) => {
                  const netPrice =
                    service?.ratedShipmentDetails[0]?.totalNetCharge +
                    (service?.ratedShipmentDetails[0]?.totalNetCharge *
                      markup) /
                      100;
                  const discountAmount = (netPrice * discount) / 100;
                  const finalNetPrice = netPrice - discountAmount;

                  return {
                    serviceName: service?.serviceType,
                    amount: service?.ratedShipmentDetails[0]?.totalNetCharge,
                    dateDetail: service?.commit?.dateDetail?.dayOfWeek,
                    minimumTransitTime:
                      service?.commit?.transitDays?.minimumTransitTime,
                    description: service?.commit?.transitDays?.description,
                    markupAmount:
                      (service?.ratedShipmentDetails[0]?.totalNetCharge *
                        markup) /
                      100,
                    netPrice: netPrice,
                    courierType: "Fedex",
                    totalAmount: finalNetPrice,
                  };
                })
              );
              // combinedServices.push(
              //   ...fedexServiceName.map((service) => ({
              //     serviceName: service?.serviceType,
              //     amount: service?.ratedShipmentDetails[0]?.totalNetCharge,
              //     dateDetail: service?.commit?.dateDetail?.dayOfWeek,
              //     minimumTransitTime:
              //       service?.commit?.transitDays?.minimumTransitTime,
              //     description: service?.commit?.transitDays?.description,
              //     markupAmount:
              //       (service?.ratedShipmentDetails[0]?.totalNetCharge *
              //         markup) /
              //       100,
              //     netPrice:
              //       service?.ratedShipmentDetails[0]?.totalNetCharge +
              //       (service?.ratedShipmentDetails[0]?.totalNetCharge *
              //         markup) /
              //         100,
              //     courierType: "Fedex",
              //     totalAmount:
              //       service?.ratedShipmentDetails[0]?.totalNetCharge +
              //       (service?.ratedShipmentDetails[0]?.totalNetCharge *
              //         markup) /
              //         100 -
              //       (service?.ratedShipmentDetails[0]?.totalNetCharge *
              //         discount) /
              //         100,
              //   }))
              // );
            }
          }
          if (res?.response["DHL Api"]) {
            if (
              res.response["DHL Api"].RateResponse.Provider[0].Notification[0]
                .Message
            ) {
              console.log("error DHL");
            } else {
              const dhlServices =
                res.response["DHL Api"].RateResponse.Provider[0].Service;
              combinedServices.push(
                ...dhlServices.map((service) => {
                  const netPrice =
                    service?.TotalNet?.Amount +
                    (service?.TotalNet?.Amount * markup) / 100;
                  const discountAmount = (netPrice * discount) / 100;
                  const finalNetPrice = netPrice - discountAmount;

                  return {
                    serviceName: service?.Charges?.Charge[0]?.ChargeType,
                    dateDetail: "Tue",
                    amount: service?.TotalNet?.Amount,
                    minimumTransitTime: "TWO DAYS",
                    description: "2 business days",
                    markupAmount: (service?.TotalNet?.Amount * markup) / 100,
                    netPrice: netPrice,
                    courierType: "DHL",
                    totalAmount: finalNetPrice,
                  };
                })
              );
              // combinedServices.push(
              //   ...dhlServices.map((service) => ({

              //     serviceName: service?.Charges?.Charge[0]?.ChargeType,
              //     dateDetail: "Tue",
              //     amount: service?.TotalNet?.Amount,
              //     minimumTransitTime: "TWO DAYS",
              //     description: "2 buisness days",
              //     markupAmount: (service?.TotalNet?.Amount * markup) / 100,
              //     netPrice:
              //       service?.TotalNet?.Amount +
              //       (service?.TotalNet?.Amount * markup) / 100,
              //     courierType: "DHL",
              //     totalAmount:
              //       service?.TotalNet?.Amount +
              //       (service?.TotalNet?.Amount * markup) / 100 -
              //       (service?.TotalNet?.Amount * discount) / 100,
              //   }))
              // );
            }
          }
          if (res?.response["UPS Api"]) {
            if (res?.response["UPS Api"].response.errors[0].message) {
              console.log("Error");
            } else {
              const upsServices =
                res.response["UPS Api"].RateResponse.RatedShipment;
              const netPrice =
                upsServices?.TotalCharges?.MonetaryValue +
                (upsServices?.TotalCharges?.MonetaryValue * markup) / 100;
              const discountAmount = (netPrice * discount) / 100;
              const finalNetPrice = netPrice - discountAmount;
              combinedServices.push({
                serviceName: "UPS SERVICE",
                dateDetail: "Tue",
                amount: upsServices?.TotalCharges?.MonetaryValue,
                minimumTransitTime: "TWO DAYS",
                description: "2 buisness days",
                markupAmount:
                  (upsServices?.TotalCharges?.MonetaryValue * markup) / 100,
                netPrice:
                  upsServices?.TotalCharges?.MonetaryValue +
                  (upsServices?.TotalCharges?.MonetaryValue * markup) / 100,
                courierType: "UPS",
                totalAmount: finalNetPrice,
              });
            }

            setServices(combinedServices);
            setLoading(false);
            toggle(false);
          }
        }
      })
      .catch((error) => console.log("error", error));
  };
  React.useEffect(() => {
    getCountry();
  }, []);

  return (
    <div>
      <HeaderBar title={"Shipping Options"} />
      <div className="container relative pb-0 mx-auto grid md:grid-cols-12 grid-cols-1 md:py-28">
        <div className="layout-specing md:col-span-8">
          <BeardCrumbs title={"Shipping Options"} />

          <div className=" justify-between items-center text-textHighlight">
            <h1 className="text-2xl font-bold ">Shipping options</h1>
          </div>

          <div className="grid grid-cols-1 mt-6  ">
            <div className="bg-bgGrey dark:bg-slate-900 dark:shadow-slate-300 shadow items-center  py-4 px-4">
              <h2 className="text-lg font-semibold">Pick-Up</h2>
              <Select
                className="bg-gray-50   border-0"
                options={options}
                placeholder="Pick-Up"
                value={formik.values.pickupOption}
                onChange={(option) => {
                  formik.setFieldValue("pickupOption", option);
                  setPickupOption(option);
                }}
              />
              {formik.touched.pickupOption && formik.errors.pickupOption && (
                <p className="text-red-500 text-sm mt-1">
                  {formik.errors.pickupOption}
                </p>
              )}
              <h2 className="text-lg font-semibold mt-2">Delivery</h2>
              <Select
                className="bg-gray-50   border-0"
                options={options}
                placeholder="Delivery"
                value={formik.values.deliveryOption}
                onChange={(option) => {
                  formik.setFieldValue("deliveryOption", option);
                  setDeliveryOption(option);
                }}
              />
              {formik.touched.deliveryOption &&
                formik.errors.deliveryOption && (
                  <p className="text-red-500 text-sm mt-1">
                    {formik.errors.deliveryOption}
                  </p>
                )}
            </div>
            {formik.values.data.map((item, index) => (
              <div
                key={index}
                className="bg-white dark:bg-slate-900 dark:shadow-slate-300 items-center mt-4 shadow "
              >
                <div className="md:flex justify-between bg-lightGrey dark:bg-slate-300 px-4 py-2 ">
                  <div className=" flex items-center ">
                    <h2 className="text-lg font-semibold text-btnBackground">
                      Package{" "}
                    </h2>
                    <h2 className="text-lg font-semibold ml-3 text-btnBackground">
                      - 1 +{" "}
                    </h2>
                  </div>
                  <div className=" flex items-center ">
                    <h2 className="text-lg text-textHighlight">
                      Allowed Dimensions
                    </h2>
                    <Tooltip text={"Dimensions"}>
                      <FaInfoCircle className="ml-1 w-4 h-4 " />
                    </Tooltip>

                    {index !== 0 && (
                      <MdDeleteOutline
                        className="ml-2 w-5 h-5"
                        onClick={() => handleDelete(index)}
                      />
                    )}
                  </div>
                </div>
                <div className="px-4 py-2 md:flex justify-between  ">
                  <div className="">
                    <label className="form-label text-btnBackground font-medium flex justify-between items-center">
                      Weight (kg):{" "}
                    </label>
                    <input
                      {...eventHandlers}
                      type="number"
                      className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                      name="weight"
                      value={item?.weight}
                      onChange={(e) =>
                        handleWeightChange(e.target.value, index)
                      }
                    />
                    {formik.touched.data &&
                      formik.errors.data &&
                      formik.errors.data[index] && (
                        <p className="text-red-500 text-sm mt-1">
                          {formik.errors.data[index].weight}
                        </p>
                      )}
                  </div>
                  <div className="mx-1">
                    <label className="form-label  text-btnBackground font-medium flex justify-between items-center">
                      Height (cm):{" "}
                    </label>
                    <input
                      {...eventHandlers}
                      type="number"
                      className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                      name="height"
                      value={item.height}
                      onChange={(e) =>
                        handleHeigthChange(e.target.value, index)
                      }
                    />
                    {formik.touched.data &&
                      formik.errors.data &&
                      formik.errors.data[index] && (
                        <p className="text-red-500 text-sm mt-1">
                          {formik.errors.data[index].height}
                        </p>
                      )}
                  </div>
                  <div className="mx-1">
                    <label className="form-label text-btnBackground font-medium flex justify-between items-center">
                      Length (cm) :{" "}
                    </label>
                    <input
                      {...eventHandlers}
                      type="number"
                      className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                      name="length"
                      value={item.length}
                      onChange={(e) =>
                        handleLengthChange(e.target.value, index)
                      }
                    />
                    {formik.touched.data &&
                      formik.errors.data &&
                      formik.errors.data[index] && (
                        <p className="text-red-500 text-sm mt-1">
                          {formik.errors.data[index].length}
                        </p>
                      )}
                  </div>
                  <div className="">
                    <label className="form-label text-btnBackground font-medium flex justify-between items-center">
                      Width (cm) :{" "}
                    </label>

                    <input
                      {...eventHandlers}
                      type="number"
                      className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                      name="width"
                      value={item.width}
                      onChange={(e) => handleWidthChange(e.target.value, index)}
                    />
                    {formik.touched.data &&
                      formik.errors.data &&
                      formik.errors.data[index] && (
                        <p className="text-red-500 text-sm mt-1">
                          {formik.errors.data[index].width}
                        </p>
                      )}
                  </div>
                </div>
                <div className="px-4 py-2">
                  Please make sure weight and dimensions are accurate to avoid
                  any inconvenience.
                  <div className="py-2 border-b-2 text-btnBackground w-full"></div>
                  <div className=" mt-3 font-bold">PACKAGE 1</div>
                  <div className="flex  space-x-4">
                    <div className="flex-grow">
                      <label className="form-label text-btnBackground font-medium flex justify-between items-center">
                        Content:{" "}
                      </label>
                      <input
                        {...eventHandlers}
                        onChange={(e) =>
                          handleContentChange(e.target.value, index)
                        }
                        value={item.content}
                        className="px-2 w-full h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 "
                        type="text"
                        placeholder="What inside in this text"
                      />
                      {formik.touched.data &&
                        formik.errors.data &&
                        formik.errors.data[index] && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.data[index].content}
                          </p>
                        )}
                    </div>
                    {/* Second input with width 20% */}
                    <div className="flex-shrink">
                      <label className="form-label text-btnBackground font-medium flex justify-between items-center">
                        Value:{" "}
                      </label>
                      <input
                        value={item.value}
                        {...eventHandlers}
                        onChange={(e) =>
                          handleValueChange(e.target.value, index)
                        }
                        className=" px-2 w-full h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 "
                        type="text"
                        placeholder="200"
                      />
                      {formik.touched.data &&
                        formik.errors.data &&
                        formik.errors.data[index] && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.data[index].value}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="mt-4">
              <input
                type="submit"
                id="search-buy"
                name="search"
                onClick={addAnother}
                className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
                value="Add Package"
              />
            </div>
            <React.Fragment>
              <div className=" justify-between items-center mt-6">
                <h1 className="text-2xl font-bold text-btnBackground">
                  Select Shipping options
                </h1>
              </div>
              {formik.errors.shippingOption && (
                <p className="text-red-500 text-sm mt-1">
                  {formik.errors.shippingOption}
                </p>
              )}
            </React.Fragment>
            {loading === false ? (
              service.map((item, index) => (
                <div
                  key={index}
                  className={`bg-white dark:bg-slate-800 dark:border-gray-400 cursor-pointer border hover:shadow-lg hover:border-indigo-700 dark:hover:border-white items-center mt-4 shadow px-4 py-4 ${
                    formik.values.shippingOption === "Express"
                      ? "border-btnBackground dark:border-white"
                      : ""
                  }`}
                  onClick={() => {
                    formik.setFieldValue("shippingOption", item?.serviceName);
                    setAmount(item?.totalAmount);
                    setServiceName(item?.courierType);
                    setMarkupamount(item?.markupAmount);
                    setNetPrice(item?.netPrice);
                    console.log("Item", item);
                  }}
                >
                  <div className="flex flex-col md:flex-row items-center text-ellipsis overflow-hidden p3">
                    <div className="flex items-center flex-shrink-0 space-x-3 mb-2 md:mb-0">
                      {formik.values.shippingOption === item?.serviceName ? (
                        <IoMdRadioButtonOn className="ml-2 w-5 h-5 text-btnBackground dark:text-white" />
                      ) : (
                        <IoIosRadioButtonOff className="ml-2 w-5 h-5" />
                      )}
                    </div>
                    <div className="flex-grow ml-1 mb-2 md:mb-0">
                      <h2 className="text-lg font-bold truncate ">
                        {item?.serviceName}
                      </h2>
                      <div className="flex flex-col md:flex-row items-center justify-between">
                        <div>
                          <p className="font-medium">Earliest pick-up</p>
                          <h3 className="text-lg font-semibold">
                            {item?.dateDetail}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-end space-y-2 md:space-y-0 md:flex-row md:space-x-2">
                      <p className="text-lg font-medium">
                        ${item?.netPrice.toFixed(2)}
                      </p>
                      <p className="font-thin">incl. VAT</p>
                      <div className="flex space-x-2">
                        <FiPrinter className="text-btnBackground" />
                        <IoSearch className="text-btnBackground" />
                        <AiOutlineSafety className="text-btnBackground" />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center items-center px-4 py-4">
                <TailSpin
                  visible={true}
                  height="60"
                  width="60"
                  color="rgb(67 56 202)"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            )}

            <div className="border dark:border-gray-400 p-4 cursor-pointer mt-4">
              <div className="relative mt-2">
                <label className="font-semibold text-btnBackground">
                  Pick up Date:
                </label>
                {/* <input
                  name="date1"
                  className="form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 end"
                  placeholder="Select date :"
                  onClick={handleCalendarToggle}
                  value={date.toDateString()}
                  readOnly
                /> */}
                <div className="form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 end">
                  <Flatpickr
                    options={{
                      minDate: "today",
                      maxDate: new Date().fp_incr(10),
                      altInput: true,
                      enableTime: false,
                      altFormat: "d M Y",
                      dateFormat: "Y-m-d",
                    }}
                    style={{
                      width: "30%",
                      borderColor: "green",
                    }}
                    value={formik.values.pickupDate}
                    onChange={(date) => {
                      formik.setFieldValue("pickupDate", date[0]);
                    }}
                  />
                  {formik.touched.pickupDate && formik.errors.pickupDate && (
                    <p className="text-red-500 text-sm mt-1">
                      {formik.errors.pickupDate}
                    </p>
                  )}
                </div>

                {/* {showCalendar && (
                  <div className="">
                    <Flatpickr
                      options={{
                        minDate: "today",
                        altInput: true,
                        enableTime: false,
                        altFormat: "d M Y",
                        dateFormat: "Y-m-d",
                        noTime: true,
                      }}
                      value={date}
                      onChange={(date) => {
                        console.log(date);
                      }}
                    />
                  </div>
                )} */}
              </div>
            </div>
            {loading ? (
              <div class="styles_content__Ctx_x flex  mt-12 justify-center items-center">
                <TailSpin
                  visible={true}
                  height="60"
                  width="60"
                  color="rgb(67 56 202)"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <div class="shadow-md block md:hidden mt-4 bg-white rounded p-4">
                <h2 className="text-lg font-bold mt-4 text-textHighlight">
                  Order summary
                </h2>
                <div>
                  <div className=" flex mt-2  items-center ">
                    <p className="font-thin text-btnBackground">Pick-up</p>
                    <ArrowRight className="ml-2 h-7 w-5" />
                    <p className="font-thin ml-2 text-btnBackground">
                      Delivery
                    </p>
                  </div>
                  <div className=" flex mt-2  items-center ">
                    <p className="font-bold ">
                      {formik.values.pickupOption &&
                        formik.values.pickupOption.value}
                    </p>

                    <p className="font-bold    ml-[68px]">
                      {formik.values.deliveryOption &&
                        formik.values.deliveryOption.value}
                    </p>
                  </div>
                  <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
                </div>
                <div className=" flex mt-2  items-center ">
                  <p className="font-thin text-btnBackground">
                    Shipping Options
                  </p>
                </div>
                <div className=" flex mt-1 justify-between items-center ">
                  <p className="font-bold ">
                    {formik.values.shippingOption &&
                      formik.values.shippingOption}
                  </p>

                  <p className="font-bold ml-7">
                    {amount && amount.toFixed(2)}
                  </p>
                </div>
                <div className=" flex mt-1 justify-between items-center ">
                  <p className="font-thin ">1 x Package</p>

                  <p className="font-thin ml-7">{"1Kg"}</p>
                </div>
                <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
                <div className="  mt-2  items-center ">
                  <p className="font-thin text-btnBackground">Pick-up Date</p>
                  <h2 className="font-bold text-xl ">
                    {formik.values.pickupDate &&
                      formik.values.pickupDate.toDateString()}
                  </h2>
                </div>

                <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
                <div className="  mt-2 flex justify-between items-center ">
                  <div>
                    <p className="font-bold text-xl text-btnBackground">
                      Net Price
                    </p>
                  </div>
                  <h2 className="font-bold text-xl text-text-btnBackground ">
                    {netPrice && `${netPrice.toFixed(2)}`}
                    {}
                  </h2>
                </div>
                <div className="  mt-2 flex justify-between items-center ">
                  <div>
                    <p className="font-bold text-xl text-btnBackground">
                      Discount
                    </p>
                  </div>
                  <h2 className="font-bold text-xl text-btnBackground ">
                    {amount && `${discountAmount}%`}
                    {}
                  </h2>
                </div>
                <div className="  mt-2 flex justify-between items-center ">
                  <div>
                    <p className="font-bold text-2xl text-btnBackground">
                      Total
                    </p>
                    <p className="font-thin text-xs ">(incl. VAT)</p>
                  </div>
                  <h2 className="font-bold text-2xl text-btnBackground ">
                    {amount && amount.toFixed(2)}
                  </h2>
                </div>
                <div className="mt-4">
                  <input
                    onClick={formik.handleSubmit}
                    type="submit"
                    id="search-buy"
                    disabled={isloading}
                    name="search"
                    className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
                    value="Next Step"
                  />
                </div>

                <div className="mt-4">
                  <div className="flex items-center ">
                    <input
                      className="form-checkbox self-start rounded border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2"
                      type="checkbox"
                      value=""
                      id="AcceptDangerous"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className="text-sm text-btnBackground"
                      htmlFor="AcceptDangerous"
                    >
                      Select this if you conatain batteries or something
                      dangerous
                    </label>
                  </div>
                  <div className="flex items-center ">
                    <input
                      className="form-checkbox self-start rounded border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2"
                      type="checkbox"
                      value=""
                      id="AcceptT&C"
                    />
                    <label
                      className="text-sm text-slate-400"
                      htmlFor="AcceptT&C"
                    >
                      By clicking Next step, you agree that you are not shipping
                      any{" "}
                      <Link to="" className="text-btnBackground">
                        restricted or prohibited items
                      </Link>
                    </label>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-4">
              <input
                type="submit"
                id="search-buy"
                onClick={goBack}
                name="search"
                className="btn px-3 bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn !h-12 rounded"
                value="Back"
              />
            </div>
          </div>
        </div>
      </div>

      <RightContainer>
        <div
          className={`${"overflow-y-auto flex-grow flex-shrink-0"}`}
          style={{ flexBasis: 0 }}
        >
          {loading ? (
            <div class="styles_content__Ctx_x flex  mt-12 justify-center items-center">
              <TailSpin
                visible={true}
                height="60"
                width="60"
                color="rgb(67 56 202)"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <div class="styles_content__Ctx_x">
              <h2 className="text-lg font-bold mt-4 text-textHighlight">
                Order summary
              </h2>
              <div>
                <div className=" flex mt-2  items-center ">
                  <p className="font-thin text-btnBackground">Pick-up</p>
                  <ArrowRight className="ml-2 h-7 w-5" />
                  <p className="font-thin ml-2 text-btnBackground">Delivery</p>
                </div>
                <div className=" flex mt-2  items-center ">
                  <p className="font-bold ">
                    {formik.values.pickupOption &&
                      formik.values.pickupOption.value}
                  </p>

                  <p className="font-bold    ml-[68px]">
                    {formik.values.deliveryOption &&
                      formik.values.deliveryOption.value}
                  </p>
                </div>
                <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
              </div>
              <div className=" flex mt-2  items-center ">
                <p className="font-thin text-btnBackground">Shipping Options</p>
              </div>
              <div className=" flex mt-1 justify-between items-center ">
                <p className="font-bold ">
                  {formik.values.shippingOption && formik.values.shippingOption}
                </p>

                <p className="font-bold ml-7">{amount && amount.toFixed(2)}</p>
              </div>
              <div className=" flex mt-1 justify-between items-center ">
                <p className="font-thin ">1 x Package</p>

                <p className="font-thin ml-7">{"1Kg"}</p>
              </div>
              <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
              <div className="  mt-2  items-center ">
                <p className="font-thin text-btnBackground">Pick-up Date</p>
                <h2 className="font-bold text-xl ">
                  {formik.values.pickupDate &&
                    formik.values.pickupDate.toDateString()}
                </h2>
              </div>

              <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
              <div className="  mt-2 flex justify-between items-center ">
                <div>
                  <p className="font-bold text-xl text-btnBackground">
                    Net Price
                  </p>
                </div>
                <h2 className="font-bold text-xl text-text-btnBackground ">
                  {netPrice && `${netPrice.toFixed(2)}`}
                  {}
                </h2>
              </div>
              <div className="  mt-2 flex justify-between items-center ">
                <div>
                  <p className="font-bold text-xl text-btnBackground">
                    Discount
                  </p>
                </div>
                <h2 className="font-bold text-xl text-btnBackground ">
                  {amount && `${discountAmount}%`}
                  {}
                </h2>
              </div>
              <div className="  mt-2 flex justify-between items-center ">
                <div>
                  <p className="font-bold text-2xl text-btnBackground">Total</p>
                  <p className="font-thin text-xs ">(incl. VAT)</p>
                </div>
                <h2 className="font-bold text-2xl text-btnBackground ">
                  {amount && amount.toFixed(2)}
                </h2>
              </div>
            </div>
          )}
        </div>
        <div
          style={{ flexBasis: "0%", flexShrink: 1, flexGrow: 0 }}
          className="h-auto py-8 "
        >
          <div className="mt-4">
            <input
              onClick={formik.handleSubmit}
              type="submit"
              id="search-buy"
              disabled={isloading}
              name="search"
              className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
              value="Next Step"
            />
          </div>

          <div className="mt-4">
            <div className="flex items-center ">
              <input
                className="form-checkbox self-start rounded border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2"
                type="checkbox"
                value=""
                id="AcceptDangerous"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label
                className="text-sm text-btnBackground"
                htmlFor="AcceptDangerous"
              >
                Select this if you conatain batteries or something dangerous
              </label>
            </div>
            <div className="flex items-center ">
              <input
                className="form-checkbox self-start rounded border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2"
                type="checkbox"
                value=""
                id="AcceptT&C"
              />
              <label className="text-sm text-slate-400" htmlFor="AcceptT&C">
                By clicking Next step, you agree that you are not shipping any{" "}
                <Link to="" className="text-btnBackground">
                  restricted or prohibited items
                </Link>
              </label>
            </div>
          </div>
        </div>
      </RightContainer>
    </div>
  );
}
