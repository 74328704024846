import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import smallLogo from "../assets/images/logo-icon-32.png";
import client1 from "../assets/images/client/01.jpg";
import client2 from "../assets/images/client/02.jpg";
import { FaInfo } from "react-icons/fa6";
import * as Icon from "react-feather";
import { IoHelp } from "react-icons/io5";
import { AiOutlineUser, IoMdLogOut } from "../assets/icons/icons";
import { FaShippingFast } from "react-icons/fa";
import SimpleBarReact from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { useDispatch, useSelector } from "react-redux";
import { revoke } from "../store/slices/authSlice";
import { revokePath } from "../store/slices/pathSlice";
import Select from "react-select";
import { toast } from "react-toastify";
import { changeLanguage } from "../store/slices/languageSlice";
import { changeLanguagei18 } from "../pages/utility/changeLanguagei18N";
import { revokeWallet } from "../store/slices/walletSlice";
import { useTranslation } from "react-i18next";
export default function Topnav({ setToggle, toggle }) {
  let [country, setCountry] = useState(false);
  let [cart, setCart] = useState(false);
  const [modal, setModal] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [originServiceArea, setOriginServiceArea] = useState("");
  const [destinationAreaa, setDestinationArea] = useState("");
  const [service, setService] = useState("");
  const [code, setCode] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [date, setdate] = useState("");
  const [showtime, setTime] = useState("");
  let [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  let [notification, setNotification] = useState(false);
  let [user, setUser] = useState(false);
  let [setting, setSetting] = useState(false);
  let [help, setHelp] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const eventHandlers = React.useMemo(
    () => ({
      onFocus: () => {
        setErrorMsg("");
        setService("");
      },
      onBlur: () => console.log("Event Handler"),
    }),
    []
  );
  const formatTime = (timeString) => {
    const hours = timeString.substring(0, 2);
    const minutes = timeString.substring(2, 4);

    // Format time into "HH:mm" format
    return `${hours}:${minutes}`;
  };
  const formatDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = parseInt(dateString.substring(4, 6), 10);
    const day = dateString.substring(6, 8);

    // Array of month names
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Format date into "Month Day, Year" format
    return `${months[month - 1]} ${parseInt(day, 10)}, ${year}`;
  };
  const TrackingAPi = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append("tracking_number", trackingNumber);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    await fetch("https://movssy.com/admin/api/main/track", requestOptions)
      .then((res) => {
        if (!res.ok) {
          toast.error(res.status);
        }
        return res.json();
      })
      .then((result) => {
        console.log("🚀 ~ .then ~ result:", result);
        if (
          result.response?.["DHL Api"]?.trackShipmentRequestResponse
            ?.trackingResponse?.TrackingResponse?.AWBInfo?.ArrayOfAWBInfoItem
            .Status.ActionStatus === "Success"
        ) {
          const shipmentInfo =
            result.response?.["DHL Api"]?.trackShipmentRequestResponse
              ?.trackingResponse?.TrackingResponse?.AWBInfo?.ArrayOfAWBInfoItem
              .ShipmentInfo;
          console.log("🚀 ~ .then ~ shipmentInfo:", shipmentInfo);
          setOriginServiceArea(shipmentInfo?.OriginServiceArea?.Description);
          setDestinationArea(shipmentInfo?.DestinationServiceArea?.Description);
          const date = new Date(shipmentInfo?.ShipmentDate.split("T")[0]);
          const options = { year: "numeric", month: "long", day: "2-digit" };
          const formattedDate = date.toLocaleDateString("en-US", options);
          setdate(formattedDate);
          setTime(shipmentInfo?.ShipmentDate.split("T")[1]);
          setService("DHL EXPRESS");
          setCode(trackingNumber);
          setModal(true);
        } else if (result.response["Fedex Api"]) {
          if (
            result.response["Fedex Api"] === 403 ||
            result.response["Fedex Api"] === 422
          ) {
            setErrorMsg("No shipment found");
            setModal(true);
          } else {
            const trackingResult =
              result.response["Fedex Api"].output.completeTrackResults[0]
                .trackResults[0];
            setService("Fedex EXPRESS");

            setOriginServiceArea(
              trackingResult?.originLocation?.locationContactAndAddress?.address
                ?.countryName
            );
            setDestinationArea(
              trackingResult?.destinationLocation?.locationContactAndAddress
                ?.address?.countryName
            );
            setCode(trackingNumber);
            setModal(true);
          }
        } else if (result.response["UPS Api"]) {
          if (result.response["UPS Api"].response.errors[0]) {
            setErrorMsg("No shipment found");
            setModal(true);
          } else {
            const shipment =
              result.response["UPS Api"].trackResponse?.shipment?.[0];
            const packageInfo = shipment?.package?.[0];
            const activity = packageInfo?.activity?.[0];
            const deliveryDate = packageInfo?.deliveryDate?.[0]?.date;
            const deliveryTime = packageInfo?.deliveryTime?.endTime;
            let time = formatTime(deliveryTime);
            setTime(time);
            let date = formatDate(deliveryDate);
            setdate(date);
            const address = activity?.location?.address;
            console.log("🚀 ~ .then ~ address:", address);
            setOriginServiceArea(address?.country);
            setDestinationArea(address?.country);
            const description = activity?.status?.description;
            setStatus(description);
            setService("UPS EXPRESS");
            setCode(trackingNumber);
            setModal(true);
          }
        } else {
          setErrorMsg("No shipment found");
          setModal(true);
        }
      })
      .catch((error) => console.error(error));
  };
  const Logout = () => {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch("https://movssy.com/admin/api/logout", requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          if (response.status === 401) {
            await sessionStorage.removeItem("User");
            await localStorage.removeItem("User");
            await dispatch(revoke());
            await dispatch(revokePath());
            await dispatch(revokeWallet());

            setIsLoading(false);
            navigate("/login");
          } else {
            toast.success(`Http error ${response.status}`);
          }
        }
        return response.json();
      })
      .then(async (result) => {
        if (result?.message === "User Logout Successfully") {
          console.log(result);
          await dispatch(revoke());
          await dispatch(revokePath());
          await dispatch(revokeWallet());
          await sessionStorage.removeItem("User");
          await localStorage.removeItem("User");
          navigate("/login");
          toast.success("Logout Successfully");
          setIsLoading(false);
        } else {
          toast.error(result.message);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    let countries = () => {
      setCountry(false);
    };
    let shopingCart = () => {
      setCart(false);
    };
    let notificationToggle = () => {
      setNotification(false);
    };
    let helpToggle = () => {
      setHelp(false);
    };
    // let userData = () => {
    //   setUser(false);
    // };
    let settingData = () => {
      setSetting(false);
    };
    document.addEventListener("mousedown", countries);
    document.addEventListener("mousedown", shopingCart);
    document.addEventListener("mousedown", notificationToggle);
    // document.addEventListener("mousedown", userData);
    document.addEventListener("mousedown", settingData);
    document.addEventListener("mousedown", helpToggle);
  }, []);
  const toggleHandler = () => {
    setToggle(!toggle);
  };
  const handlechangeLanguage = (option) => {
    setLanguage(option);
  };
  const saveChange = (event) => {
    event.preventDefault();
    setDisabled(true);
    dispatch(changeLanguage(language.value));
    changeLanguagei18(language.value);
    setDisabled(false);
    setModal(!modal);
  };
  return (
    <React.Fragment>
      <div className="p-5 border-t border-gray-100 dark:border-slate-800">
        <div
          className={`bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40 flex items-center justify-center ${
            modal ? "" : "hidden"
          }`}
        >
          <div className="relative w-full h-auto max-w-lg p-4">
            <div className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
              <button
                type="button"
                onClick={() => setModal(!modal)}
                className="absolute -top-4 -end-4 text-gray-400 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 hover:text-gray-900 rounded-full text-sm p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <div className="p-6 py-10 text-center">
                {errorMsg != "" ? (
                  <p className="text-xl lg:text-2xl font-bold dark:text-white">
                    {errorMsg}
                  </p>
                ) : (
                  <>
                    <div className="px-4 pt-8">
                      <span className="text-xl lg:text-2xl font-bold dark:text-white">
                        Shipment Details
                      </span>
                      <div>
                        <div className="mt-2 rounded-xl text-black  items-center max-w-xl flex    dark:text-white">
                          <span>Tracking Code: </span>
                          <p className="text-indigo-700 ml-1 ">
                            {code && code}
                          </p>
                        </div>
                        <div className="mt-2 rounded-xl text-black  items-center max-w-xl flex dark:text-white   ">
                          <span>This shipment is handle by: </span>
                          <p className="text-black font-bold ml-1 dark:text-gray-400">
                            {service && service}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" border-b-2 border-b-stone-500 py-2" />
                    <div className="px-4 py-4">
                      <span className="text-xl lg:text-2xl font-bold text-green-600">
                        {status && status}
                      </span>
                      <div className="mt-2 rounded-xl text-black  items-center max-w-xl flex  dark:text-white">
                        <span>
                          {date && date} {showtime && showtime} Local time,{" "}
                          <br />
                          Service Area: {originServiceArea &&
                            originServiceArea}{" "}
                        </span>
                      </div>
                      <div className="mt-2 rounded-xl text-black  items-center max-w-xl flex    ">
                        <span className="text-sm font-bold text-black dark:text-white">
                          Origin Service Area:{" "}
                          {originServiceArea && originServiceArea}
                        </span>
                      </div>
                      <div className="mt-2 rounded-xl text-black dark:text-white items-center max-w-xl flex    ">
                        <span className="text-sm font-bold text-black">
                          Destination Service Area:{" "}
                          {destinationAreaa && destinationAreaa}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="top-header ">
        <div className="header-bar flex justify-between">
          <div className="flex items-center space-x-3">
            {/* <span className=" hidden md:block ">
                <img
                  src={logoLight}
                  className="inline-block dark:hidden mx-auto h-20"
                  alt=""
                />
                <img
                  src={LogoDark}
                  className="hidden dark:inline-block mx-auto h-20"
                  alt=""
                />
              </span> */}

            <Link
              id="close-sidebar"
              className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-[20px] text-center bg-gray-50 dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700 border border-gray-100 dark:border-gray-800 text-slate-900 dark:text-white rounded-full"
              href="#"
            >
              <Icon.Menu className="h-4 w-4" onClick={toggleHandler} />
            </Link>

            <div className="flex flex-col rounded-xl shadow-md">
              <div className="flex   py-1 px-2 rounded-xl  items-center space-x-2">
                <input
                  type="text"
                  {...eventHandlers}
                  placeholder="Enter your tracking id"
                  onChange={(e) => setTrackingNumber(e.target.value)}
                  className="w-full rounded-sm bg-white px-3 bg-transparent dark:bg-slate-900 dark:text-slate-200  outline-none focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                />
                <input
                  onClick={TrackingAPi}
                  value={t("Find")}
                  className="cursor-pointer rounded-[8px]  w-14 py-[5px] justify-center items-center px-[8px] font-semibold tracking-wide  align-middle duration-500 text-base text-center bg-btnBackground hover:bg-btnBackground/20 text-white hover:text-white sm:inline-block"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: "0 0 auto", // Ensures the span doesn't grow or shrink
                  }}
                />
              </div>
            </div>
          </div>

          <ul className="list-none mb-0 space-x-3">
            {/* <li className="dropdown inline-block relative">
              <div
                className={`dropdown-menu absolute end-0 m-0 mt-4 z-10 w-60 rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-700  ${
                  cart ? "" : "hidden"
                }`}
              >
                <ul className="py-3 text-start">
                  <li>
                    <Link
                      to="#"
                      className="flex items-center justify-between py-1.5 px-4"
                    >
                      <span className="flex items-center">
                        <img
                          src={shopItem1}
                          className="rounded shadow dark:shadow-gray-700 w-9"
                          alt=""
                        />
                        <span className="ms-3">
                          <span className="block font-semibold">
                            T-shirt (M)
                          </span>
                          <span className="block text-sm text-slate-400">
                            $320 X 2
                          </span>
                        </span>
                      </span>

                      <span className="font-semibold">$640</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="flex items-center justify-between py-1.5 px-4"
                    >
                      <span className="flex items-center">
                        <img
                          src={shopItem2}
                          className="rounded shadow dark:shadow-gray-700 w-9"
                          alt=""
                        />
                        <span className="ms-3">
                          <span className="block font-semibold">Bag</span>
                          <span className="block text-sm text-slate-400">
                            $50 X 5
                          </span>
                        </span>
                      </span>

                      <span className="font-semibold">$250</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="flex items-center justify-between py-1.5 px-4"
                    >
                      <span className="flex items-center">
                        <img
                          src={shopItem3}
                          className="rounded shadow dark:shadow-gray-700 w-9"
                          alt=""
                        />
                        <span className="ms-3">
                          <span className="block font-semibold">
                            Watch (Men)
                          </span>
                          <span className="block text-sm text-slate-400">
                            $800 X 1
                          </span>
                        </span>
                      </span>

                      <span className="font-semibold">$800</span>
                    </Link>
                  </li>

                  <li className="border-t border-gray-100 dark:border-gray-800 my-2"></li>

                  <li className="flex items-center justify-between py-1.5 px-4">
                    <h6 className="font-semibold mb-0">Total($):</h6>
                    <h6 className="font-semibold mb-0">$1690</h6>
                  </li>

                  <li className="py-1.5 px-4 space-x-1">
                    <Link
                      to="#"
                      className="py-[5px] px-4 inline-block font-semibold tracking-wide align-middle duration-500 text-sm text-center rounded-md bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white"
                    >
                      View Cart
                    </Link>
                    <Link
                      to="#"
                      className="py-[5px] px-4 inline-block font-semibold tracking-wide align-middle duration-500 text-sm text-center rounded-md bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white"
                    >
                      Checkout
                    </Link>
                    <p className="text-sm text-slate-400 mt-1">*T&C Apply</p>
                  </li>
                </ul>
              </div>
            </li> */}

            <li className="dropdown inline-block relative">
              <button
                onClick={() => setNotification(!notification)}
                className="dropdown-toggle h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-[20px] text-center bg-gray-50 dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700 border border-gray-100 dark:border-gray-800 text-slate-900 dark:text-white rounded-full"
                type="button"
              >
                <Icon.Bell className="h-4 w-4" />
                <span className="absolute top-0 end-0 flex items-center justify-center bg-red-600 text-white text-[10px] font-bold rounded-full w-2 h-2 after:content-[''] after:absolute after:h-2 after:w-2 after:bg-red-600 after:top-0 after:end-0 after:rounded-full after:animate-ping"></span>
              </button>

              <div
                className={`dropdown-menu absolute end-0 m-0 mt-4 z-10 w-64 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 ${
                  notification ? "" : "hidden"
                }`}
              >
                <span className="px-4 py-4 flex justify-between">
                  <span className="font-semibold">{t("Notifications")}</span>
                  <span className="flex items-center justify-center bg-red-600/20 text-red-600 text-[10px] font-bold rounded-full w-5 max-h-5 ms-1">
                    3
                  </span>
                </span>
                <SimpleBarReact className="h-64">
                  <ul className="py-2 text-start h-64 border-t border-gray-100 dark:border-gray-800">
                    <li>
                      <Link to="#!" className="block font-medium py-1.5 px-4">
                        <div className="flex items-center">
                          <div className="h-10 w-10 rounded-md shadow shadow-indigo-600/10 dark:shadow-gray-700 bg-indigo-600/10 dark:bg-slate-800 text-indigo-600 dark:text-white flex items-center justify-center">
                            <Icon.ShoppingCart className="h-4 w-4" />
                          </div>
                          <div className="ms-2">
                            <span className="text-[15px] font-semibold block">
                              {t("Order_Complete")}
                            </span>
                            <small className="text-slate-400">15 min ago</small>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="#!" className="block font-medium py-1.5 px-4">
                        <div className="flex items-center">
                          <img
                            src={client1}
                            className="h-10 w-10 rounded-md shadow dark:shadow-gray-700"
                            alt=""
                          />
                          <div className="ms-2">
                            <span className="text-[15px] font-semibold block">
                              <span className="font-bold">Message</span> from
                              Luis
                            </span>
                            <small className="text-slate-400">1 hour ago</small>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="#!" className="block font-medium py-1.5 px-4">
                        <div className="flex items-center">
                          <div className="h-10 w-10 rounded-md shadow shadow-indigo-600/10 dark:shadow-gray-700 bg-indigo-600/10 dark:bg-slate-800 text-indigo-600 dark:text-white flex items-center justify-center">
                            <Icon.DollarSign className="h-4 w-4" />
                          </div>
                          <div className="ms-2">
                            <span className="text-[15px] font-semibold block">
                              <span className="font-bold">
                                One Refund Request
                              </span>
                            </span>
                            <small className="text-slate-400">2 hour ago</small>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="#!" className="block font-medium py-1.5 px-4">
                        <div className="flex items-center">
                          <div className="h-10 w-10 rounded-md shadow shadow-indigo-600/10 dark:shadow-gray-700 bg-indigo-600/10 dark:bg-slate-800 text-indigo-600 dark:text-white flex items-center justify-center">
                            <Icon.Truck className="h-4 w-4" />
                          </div>
                          <div className="ms-2">
                            <span className="text-[15px] font-semibold block">
                              Deliverd your Order
                            </span>
                            <small className="text-slate-400">Yesterday</small>
                          </div>
                        </div>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="#!" className="block font-medium py-1.5 px-4">
                        <div className="flex items-center">
                          <img
                            src={client2}
                            className="h-10 w-10 rounded-md shadow dark:shadow-gray-700"
                            alt=""
                          />
                          <div className="ms-2">
                            <span className="text-[15px] font-semibold block">
                              <span className="font-bold">Cally</span> started
                              following you
                            </span>
                            <small className="text-slate-400">2 days ago</small>
                          </div>
                        </div>
                      </Link>
                    </li> */}
                  </ul>
                </SimpleBarReact>
              </div>
            </li>
            <li className="dropdown inline-block relative">
              <button
                onClick={() => setHelp(!help)}
                className="dropdown-toggle items-center"
                type="button"
              >
                <span className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-[20px] text-center bg-gray-50 dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700 border border-gray-100 dark:border-gray-800 text-slate-900 dark:text-white rounded-full">
                  <IoHelp />
                </span>
              </button>
              <div
                className={`dropdown-menu absolute end-0 m-0 mt-4 z-10 w-44 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 ${
                  help ? "" : "hidden"
                }`}
              >
                <ul className="py-2 text-start">
                  <li>
                    <Link
                      to="/profile"
                      className="flex items-center font-medium py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"
                    >
                      <AiOutlineUser className="me-2" />
                      {t("Contact_Support")}{" "}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/profile-setting"
                      className="flex items-center font-medium py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"
                    >
                      <FaShippingFast className="me-2" />
                      {t("Shipping_Tools")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/profile-setting"
                      className="flex items-center font-medium py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"
                    >
                      <FaInfo className="me-2" />
                      {t("Contact_Support")}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            <li className="dropdown inline-block relative"></li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}
