import React from "react";
import "./Modal.css";
import { IoIosClose } from "react-icons/io";
function Modal({ closeModal, children }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="closeTitleButton">
          <button onClick={() => closeModal(false)}>
            <IoIosClose size={40} />
          </button>
        </div>
        <div className="title">Address Book</div>
        <div className="body">{children}</div>
      </div>
    </div>
  );
}

export default Modal;
