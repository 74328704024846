import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const createTicket = createAsyncThunk(
  "Ticket/createTicket",
  async (formData, { getState, rejectWithValue }) => {
    const { token } = getState().auth; // Assuming you have a slice named 'auth' that stores the token
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        "https://movssy.com/admin/api/ticket/store",
        requestOptions
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to add address");
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchTickets = createAsyncThunk(
  "Ticket/fetchTickets",
  async (pageNumber, { getState, rejectWithValue }) => {
    const { token } = getState().auth; // Assuming you have a slice named 'auth' that stores the token
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `https://movssy.com/admin/api/ticket?page=${pageNumber}`,
        requestOptions
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch addresses");
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleteTicket = createAsyncThunk(
  "Ticket/deleteTicket",
  async (id, { getState, rejectWithValue }) => {
    const { token } = getState().auth; // Assuming you have a slice named 'auth' that stores the token
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `https://movssy.com/admin/api/ticket/${id}`,
        requestOptions
      );

      const data = await response.json();

      if (!response.ok) {
        toast(response.status || "Failed to fetch addresses");
      }

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateTicket = createAsyncThunk(
  "Ticket/updateTicket",
  async ({ formData, id }, { getState, rejectWithValue }) => {
    const { token } = getState().auth; // Assuming you have a slice named 'auth' that stores the token
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `https://movssy.com/admin/api/ticket/update/${id}`,
        requestOptions
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to add address");
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const ticketSlice = createSlice({
  name: "Ticket",
  initialState: {
    data: [],
    totalPageNumber: 1,
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    addTicket: (state, action) => {
      state.data.push(action.payload);
    },
    modifyTicket: (state, action) => {
      const index = state.data.findIndex(
        (address) => address.id === action.payload.id
      );
      state.data[index] = action.payload;
    },
    removeTicket: (state, action) => {
      const index = state.data.findIndex(
        (address) => address.id === action.payload.id
      );
      state.data.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTickets.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTickets.fulfilled, (state, action) => {
      if (action.payload.status === true) {
        console.log("🚀 ~ builder.addCase ~ action.payload:", action.payload);
        state.data = action.payload["User Tickets"].data;
        state.totalPageNumber = action.payload["Page Number"];
      }
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(fetchTickets.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});
export const { addTicket, modifyTicket, removeTicket } = ticketSlice.actions;
export const ticketReducer = ticketSlice.reducer;
