import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
export default function AccordianComponent({
  title,
  name,
  address,
  postcode,
  phno,
  city,
  country,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="mt-2">
      <div
        className="flex justify-between cursor-pointer"
        onClick={toggleAccordion}
      >
        <p className=" font-thin text-btnBackground">{title}</p>
        {isOpen === true ? (
          <IoIosArrowUp className="text-btnBackground" />
        ) : (
          <IoIosArrowDown className="text-btnBackground" />
        )}
      </div>
      {isOpen === false ? (
        <div>
          <p className=" font-bold">{address}</p>
          <p className=" font-thin">
            {postcode} {address}
          </p>
        </div>
      ) : null}
      {isOpen && (
        <div className="">
          <p className=" font-thin">{name && name}</p>

          <p className=" font-thin">{address && address}</p>
          <p className=" font-thin">
            {postcode && postcode} {city && city}
          </p>
          <p className=" font-thin">{city && city}</p>
          <p className=" font-thin">{phno}</p>
        </div>
      )}
    </div>
  );
}
