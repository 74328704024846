import React from "react";

import image6 from "../assets/images/blog/01.jpg";
import image7 from "../assets/images/blog/02.jpg";
import image8 from "../assets/images/blog/03.jpg";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

export default function IndustrySpecificationSoltuions(props) {
  const data = [
    {
      Title: "Automotive industry",
      Description:
        "The phrasal sequence of the is now so that many campaign and benefit",

      DelayTime: ".3s",
    },
    {
      Title: "Manufacturing",
      Description:
        "The phrasal sequence of the is now so that many campaign and benefit",

      DelayTime: ".5s",
    },
    {
      Title: "Retail & e-commerce",
      Description:
        "The phrasal sequence of the is now so that many campaign and benefit",

      DelayTime: ".7s",
    },
    {
      Title: "Medical supplies",
      Description:
        "The phrasal sequence of the is now so that many campaign and benefit",

      DelayTime: ".5s",
    },
  ];

  return (
    <div className={props.className}>
      <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-4 mt-8 gap-[30px]">
        {data.map((data, index) => {
          return (
            <div
              key={index}
              className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden wow animate__animated animate__fadeInUp"
              data-wow-delay={data.DelayTime}
            >
              <img src={"https://placehold.co/300x200/EEE/31343C"} alt="" />

              <div className="content p-4">{data?.Title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
