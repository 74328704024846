import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import client1 from "../../assets/images/client/01.jpg";
import client2 from "../../assets/images/client/02.jpg";
import client3 from "../../assets/images/client/03.jpg";
import client4 from "../../assets/images/client/04.jpg";
import client5 from "../../assets/images/client/05.jpg";
import client6 from "../../assets/images/client/06.jpg";
import client7 from "../../assets/images/client/07.jpg";
import client8 from "../../assets/images/client/08.jpg";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../store/slices/languageSlice";
import { changeLanguagei18 } from "../utility/changeLanguagei18N";

export default function OrderList() {
  const token = useSelector((state) => state.auth.token);
  const isLoading = useSelector((state) => state.addressDetail.isLoading);
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPage] = React.useState(1);
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);

  const { t } = useTranslation();
  const OrdersList = (page) => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://movssy.com/admin/api/orders?page=${page}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          toast.error(response.status);
        }
        return response.json();
      })
      .then((res) => {
        console.log("🚀 ~ .then ~ orders:", res);
        if (res?.status === true) {
          if (res?.orders.data.length === 0) {
            setData(null);
            setLoading(false);
          } else {
            setData(res?.orders?.data);
            setTotalPage(res["Page Number"]);
            setLoading(false);
          }
        } else {
          toast.error(res?.message);
          setData(null);
          setLoading(false);
        }
      })
      .catch((error) => console.error(error));
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    OrdersList(currentPage);
  }, [navigate, currentPage]);
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
    changeLanguagei18(languageCode);
  }, []);
  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="md:flex justify-between items-center">
          <h5 className="text-lg font-semibold">{t("Order List")}</h5>

          {/* <ul className="tracking-[0.5px] inline-flex items-center sm:mt-0 mt-3">
            <li className="inline-block capitalize text-[14px] font-bold duration-500 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white">
              <Link to="/index">Techwind</Link>
            </li>
            <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <MdKeyboardArrowRight />
            </li>
            <li
              className="inline-block capitalize text-[14px] font-bold text-indigo-600 dark:text-white"
              aria-current="page"
            >
              Order List
            </li>
          </ul> */}
        </div>

        <div className="grid grid-cols-1 mt-6">
          <div className="relative overflow-x-auto block w-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
            <table className="w-full text-start overflow-hidden custom-table mx-2">
              <thead className="text-base">
                <tr>
                  <th className="text-start p-4 min-w-[128px]">
                    {t("Order_No.")}
                  </th>
                  <th className="text-start p-4 min-w-[192px]">{t("Name")}</th>
                  <th className="text-start p-4 min-w-[200px]">{t("Date")}</th>
                  <th className="text-start p-4">{t("Amount")}</th>
                  <th className="text-start p-4 min-w-[150px]">
                    {t("Pick-Up_Date")}
                  </th>
                  <th className="text-start p-4 min-w-[150px]">
                    {t("Total_Price")}
                  </th>
                  <th className="text-end p-4">{t("Status")}</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={7}>
                      <div className="flex justify-center items-center py-4">
                        <TailSpin
                          visible={true}
                          height="60"
                          width="60"
                          color="rgb(67 56 202)"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    </td>
                  </tr>
                ) : data === null ? (
                  <tr>
                    <td colSpan={7}>
                      <div className="flex justify-center items-center py-4">
                        {t("No_Record_Found")}
                      </div>
                    </td>
                  </tr>
                ) : (
                  data.map((item, index) => (
                    <tr
                      key={index}
                      className="bg-gray-200 border border-gray-200 hover:bg-white"
                    >
                      <td className="text-start custom-left-round border-gray-100 dark:border-gray-800 p-4">
                        <Link
                          to={`/user-dashboard/Order-details/${item?.id}`}
                          className="font-semibold hover:text-indigo-600"
                        >
                          {item?.id}
                        </Link>
                      </td>
                      <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                        <div className="flex items-center">
                          <span className="ms-2 font-medium">
                            {item.package_name === null
                              ? "N/A"
                              : item.package_name}
                          </span>
                        </div>
                      </td>
                      <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                        <span className="text-slate-400">
                          {item.created_at.split("T")[0]}
                        </span>
                      </td>
                      <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                        <span className="text-slate-400">
                          {item.orignal_amount === null
                            ? "N/A"
                            : item.orignal_amount}
                        </span>
                      </td>
                      <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                        <span className="text-slate-400">
                          {item.pickup_date === null
                            ? "N/A"
                            : item.pickup_date.split(" ")[0]}
                        </span>
                      </td>
                      <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                        <span className="text-slate-400">
                          {item.orignal_amount === null
                            ? "N/A"
                            : item.orignal_amount}
                        </span>
                      </td>
                      <td className="text-end custom-right-round border-t border-gray-100 dark:border-gray-800 p-4">
                        <span
                          className={`${
                            item.status === "Pending"
                              ? "bg-yellow-600/10 dark:bg-yellow-600/20 text-yellow-600"
                              : "bg-red-600/10 dark:bg-red-600/20 text-red-600"
                          } text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1`}
                        >
                          {item.status}
                        </span>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          <div className="mt-5 flex items-center justify-between">
            <div>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="h-8 w-8 inline-flex items-center justify-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800 border-gray-100 dark:border-gray-700 text-slate-400 hover:text-slate-900 dark:hover:text-white rounded-full"
              >
                <i className="mdi mdi-arrow-left"></i>
              </button>

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="h-8 w-8 inline-flex items-center justify-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800 border-gray-100 dark:border-gray-700 text-slate-400 hover:text-slate-900 dark:hover:text-white rounded-full"
              >
                <i className="mdi mdi-arrow-right"></i>
              </button>
            </div>

            <span className="text-slate-400">
              Showing {currentPage} out of {totalPages}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
