import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";
import { FiPhone, MdKeyboardArrowRight } from "../../assets/icons/icons";

export default function HelpcenterGuides() {
  return (
    <>
      <Navbar navClass="nav-light" />

      <section className="relative table w-full py-36 dark:bg-slate-900  bg-center bg-no-repeat bg-cover">
        <div className="absolute inset-0  opacity-80"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="md:text-4xl dark:text-white text-3xl md:leading-normal tracking-wide leading-normal font-medium text-black">
              Guides & Support
            </h3>
          </div>
        </div>

        {/* <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex items-center space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Techwind</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/helpcenter-overview">Helpcenter</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Guides</li>
                    </ul>
                </div> */}
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
            <div className="">
              <h5 className="font-semibold text-xl text-textHighlight">
                Getting started
              </h5>
              <ul className="list-none mt-4">
                <li className="mt-2">
                  <Link className="text-slate-400">
                    <i className="mdi mdi-arrow-right text-btnBackground me-2"></i>
                    Deciding to purchase
                  </Link>
                </li>
                <li className="mt-2">
                  <Link className="text-slate-400">
                    <i className="mdi mdi-arrow-right text-btnBackground me-2"></i>
                    List your space
                  </Link>
                </li>
                <li className="mt-2">
                  <Link className="text-slate-400">
                    <i className="mdi mdi-arrow-right text-btnBackground me-2"></i>
                    Landing an experience or adventure
                  </Link>
                </li>
                <li className="mt-2">
                  <Link className="text-slate-400">
                    <i className="mdi mdi-arrow-right text-btnBackground me-2"></i>
                    Top uses questions
                  </Link>
                </li>
              </ul>
            </div>

            <div className="">
              <h5 className="font-semibold text-xl">How payouts work</h5>
              <ul className="list-none mt-4">
                <li className="mt-2">
                  <Link className="text-slate-400">
                    <i className="mdi mdi-arrow-right text-btnBackground me-2"></i>
                    Getting paid
                  </Link>
                </li>
                <li className="mt-2">
                  <Link className="text-slate-400">
                    <i className="mdi mdi-arrow-right text-btnBackground me-2"></i>
                    Adding payout info
                  </Link>
                </li>
                <li className="mt-2">
                  <Link className="text-slate-400">
                    <i className="mdi mdi-arrow-right text-btnBackground me-2"></i>
                    Your payout status
                  </Link>
                </li>
                <li className="mt-2">
                  <Link className="text-slate-400">
                    <i className="mdi mdi-arrow-right text-btnBackground me-2"></i>
                    Donations
                  </Link>
                </li>
                <li className="mt-2">
                  <Link className="text-slate-400">
                    <i className="mdi mdi-arrow-right text-btnBackground me-2"></i>
                    Taxes
                  </Link>
                </li>
              </ul>
            </div>

            <div className="">
              <h5 className="font-semibold text-xl">Your account</h5>
              <ul className="list-none mt-4">
                <li className="mt-2">
                  <Link className="text-slate-400">
                    <i className="mdi mdi-arrow-right text-btnBackground me-2"></i>
                    Your profile
                  </Link>
                </li>
                <li className="mt-2">
                  <Link className="text-slate-400">
                    <i className="mdi mdi-arrow-right text-btnBackground me-2"></i>
                    Account security
                  </Link>
                </li>
                <li className="mt-2">
                  <Link className="text-slate-400">
                    <i className="mdi mdi-arrow-right text-btnBackground me-2"></i>
                    Identification & verifications
                  </Link>
                </li>
                <li className="mt-2">
                  <Link className="text-slate-400">
                    <i className="mdi mdi-arrow-right text-btnBackground me-2"></i>
                    Reviews
                  </Link>
                </li>
                <li className="mt-2">
                  <Link className="text-slate-400">
                    <i className="mdi mdi-arrow-right text-btnBackground me-2"></i>
                    Superhost status
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-6 md:text-3xl text-textHighlight text-2xl md:leading-normal leading-normal font-semibold">
              Have Question ? Get in touch!
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              sagittis ultrices justo, ac semper felis cursus eu. Quisque
              pellentesque elementum purus, imperdiet gravida magna tempus et.
            </p>

            <div className="mt-6">
              <Link
                to="/contact-us"
                className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md me-2 mt-2"
              >
                <FiPhone className="me-1 text-lg" /> Contact us
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
