import React, { useState } from "react";
import { Link } from "react-router-dom";

import imageP from "../../assets/images/consulting/1.jpg";
import image1 from "../../assets/images/consulting/2.jpg";
import image2 from "../../assets/images/consulting/3.jpg";
import ab03 from "../../assets/images/about/ab03.jpg";
import ab02 from "../../assets/images/about/ab02.jpg";
import ab01 from "../../assets/images/about/ab01.jpg";
import pro1 from "../../assets/images/portfolio/pro1.jpg";
import pro2 from "../../assets/images/portfolio/pro2.jpg";
import pro3 from "../../assets/images/portfolio/pro3.jpg";
import pro4 from "../../assets/images/portfolio/pro4.jpg";
import pro5 from "../../assets/images/portfolio/pro5.jpg";
import pro6 from "../../assets/images/portfolio/pro6.jpg";
import image3 from "../../assets/images/client/01.jpg";
import image4 from "../../assets/images/client/02.jpg";
import image5 from "../../assets/images/client/03.jpg";
import image6 from "../../assets/images/client/04.jpg";
import image7 from "../../assets/images/client/05.jpg";
import image8 from "../../assets/images/client/06.jpg";
import Select from "react-select";

import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";
import CookieModal from "../../component/cookieModal";
import ManagementTeam from "../../component/managementTeam";
import CompanyLogo from "../../component/companyLogo";

import * as Icon from "react-feather";
import { FaArrowRight, FiAirplay } from "../../assets/icons/icons";

import TinySlider from "tiny-slider-react";
import CountUp from "react-countup";
import { FaStar } from "react-icons/fa6";
import DimensionAndWidthCard from "../../component/DimensionAndWidthCard";
const settings = {
  container: ".tiny-single-item",
  items: 1,
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  speed: 400,
  gutter: 16,
};
export default function Package() {
  const [activeIndex, setActiveIndex] = useState(1);
  const AboutData = [
    {
      image: imageP,
      title: "About the pickup",
      desc: "All our services offer door-to-door delivery, with parcels delivered to the address of your choice.",
      number: 1,
    },
    {
      image: image1,
      title: "About the delivery",
      desc: "Parcels are picked up between 9 am and 6 pm, with some services offering same-day collection.",
      number: 2,
    },
  ];
  const project = [
    { value: "Austria", label: "Austria" },
    { value: "Belgium", label: "Belgium" },
    { value: "England", label: "England" },
    { value: "SouthAfrica", label: "SouthAfrica" },
  ];

  const OrderingAs = [
    { value: "Individual", label: "Individual" },
    { value: "Business", label: "Business" },
  ];
  const servicesData = [
    { value: "Document", label: "Document" },
    { value: "Package", label: "Package" },
    { value: "Pallet", label: "Pallet" },
    { value: "Van Delivery", label: "Van Delivery" },
    { value: "FTL & LTL", label: "FTL & LTL" },
    { value: "CustomOffer", label: "CustomOffer" },
  ];
  const accordionData = [
    {
      id: 1,
      title: "Correlation Analysis",
      content:
        "Receive heartfelt contributions instantly & straight to your bank. Guests can choose any amount of money to give",
    },
    {
      id: 2,
      title: "Drag-and-Drop Dashboard",
      content:
        "Receive heartfelt contributions instantly & straight to your bank. Guests can choose any amount of money to give",
    },
    {
      id: 3,
      title: "What do I need to do to start selling",
      title: "Smart Trend Detection ?",
      content:
        "Receive heartfelt contributions instantly & straight to your bank. Guests can choose any amount of money to give",
    },
    {
      id: 4,
      title: "What happens when I receive an order ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 5,
      title: "How does it work ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 6,
      title: "Do I need a designer to use Techwind ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 7,
      title: "What do I need to do to start selling ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 8,
      title: "What happens when I receive an order ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
  ];
  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };

  const accordionData1 = accordionData.filter((x) => x.id < 4);

  const services = [
    {
      feedback:
        '" Techwind made the processes so easy. Techwind instantly increased the amount of interest and ultimately saved us over $10,000. " ',
      name: "Christa Smith",
      role: "Manager",
      image: image3,
    },
    {
      feedback:
        '" I highly recommend Techwind as the new way to sell your home "by owner". My home sold in 24 hours for the asking price. Best $400 you could spend to sell your home. "',
      name: "Christa Smith",
      role: "Manager",
      image: image4,
    },
    {
      feedback:
        '" My favorite part about selling my home myself was that we got to meet and get to know the people personally. This made it so much more enjoyable! "',
      name: "Christa Smith",
      role: "Manager",
      image: image5,
    },
    {
      feedback: '" Great experience all around! Easy to use and efficient. "',
      name: "Christa Smith",
      role: "Manager",
      image: image6,
    },
    {
      feedback:
        '" Techwind made selling my home easy and stress free. They went above and beyond what is expected. "',
      name: "Christa Smith",
      role: "Manager",
      image: image7,
    },
    {
      feedback:
        '" Techwind is fair priced, quick to respond, and easy to use. I highly recommend their services! "',
      name: "Christa Smith",
      role: "Manager",
      image: image8,
    },
  ];
  const aboutData = [
    {
      icon: Icon.Codesandbox,
      title: "User Friendly",
      desc: "Parcels are picked up between 9 am and 6 pm, with some services offering same-day collection.",
    },
    {
      icon: Icon.Send,
      title: "Super Fast",
      desc: "All our services offer door-to-door delivery, with parcels delivered to the address of your choice.",
    },
    {
      icon: Icon.Star,
      title: "Insightful Analytics",
      desc: "The phrasal sequence of the is now so that many campaign and benefit",
    },
    {
      icon: Icon.Bookmark,
      title: "Highly Rated",
      desc: "The phrasal sequence of the is now so that many campaign and benefit",
    },
  ];
  const servecies1 = [
    {
      image: imageP,
      title: "Profitable Marketing",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 1,
    },
    {
      image: image1,
      title: "SEO Specialists",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 2,
    },
    {
      image: image2,
      title: "Audience Analysis",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 3,
    },
  ];
  const productData = [
    {
      image: pro1,
      title: "Techwind Personal Portfolio",
      name: "Creative",
    },
    {
      image: pro2,
      title: "Techwind Minimal Portfolio",
      name: "Minimal",
    },
    {
      image: pro3,
      title: "Techwind NFT Market",
      name: "Crypto",
    },
    {
      image: pro4,
      title: "Techwind Portfolio",
      name: "Portfolio",
    },
    {
      image: pro5,
      title: "Techwind Constuction",
      name: "Corporate",
    },
    {
      image: pro6,
      title: "Techwind SEO",
      name: "SEO",
    },
  ];
  return (
    <>
      <Navbar navClass="nav-light" />
      <section className="relative md:py-50 py-44  bg-right bg-no-repeat bg-cover">
        <div className="absolute inset-0  dark:bg-slate-900"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 items-center">
            {/* <span className="text-white/80 font-semibold mb-4 text-lg">
              Save on worldwideshipping with Movssy
            </span> */}
            <h4 className="font-bold lg:leading-normal  leading-normal text-4xl lg:text-5xl mb-5 text-btnBackground dark:text-white">
              Ship parcels fast and easy!
            </h4>
            <p className="text-black text-lg max-w-xl dark:text-gray-400">
              Save time and stress with our hassle-free parcel <br></br>{" "}
              shipping options
              <div className="flex text-xl font-medium text-amber-400">
                <p className="text-green-600">Trustpilot</p>
                <ul className="text-xl font-medium text-amber-400 list-none mb-2">
                  <li className="inline">
                    <i className="mdi mdi-star"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star"></i>
                  </li>
                </ul>
              </div>
            </p>

            {/* <div className="mt-8">
              <Link
                to="#"
                className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
              >
                Learn More <FaArrowRight className="ms-2 text-[10px]" />
              </Link>
            </div> */}
            <div className=" relative">
              <div className="grid grid-cols-1 justify-center">
                <div className="relative mt-10">
                  <div className="grid grid-cols-1">
                    <div>
                      <form className="p-6 bg-bgGrey dark:bg-slate-900 rounded-xl shadow-md dark:shadow-gray-700">
                        <div className="registration-form text-dark text-start">
                          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 space-x-3 lg:gap-0 gap-6">
                            <div>
                              <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                {/* <LuSearch className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                                <Select
                                  className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                  options={project}
                                  placeholder="Pick up"
                                />
                              </div>
                            </div>

                            <div>
                              <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                {/* <AiOutlineHome className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                                <Select
                                  className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                  options={project}
                                  placeholder="Delivery"
                                />
                              </div>
                            </div>

                            <div>
                              <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                {/* <AiOutlineDollar className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                                <Select
                                  className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                  options={servicesData}
                                  placeholder={"Services"}
                                />
                              </div>
                            </div>
                            <Link to="/order-details">
                              <div className="">
                                <input
                                  type="submit"
                                  id="search-buy"
                                  name="search"
                                  className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full h-14 rounded"
                                  value="Get a qoute"
                                />
                              </div>
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" border-t border-b border-btnBackground dark:border-gray-700">
        <div className="container relative">
          <CompanyLogo />
        </div>
      </section>

      <section className="relative md:py-12 py-8 lg:py-16 hidden md:block">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <p className="text-btnBackground max-w-xl mx-auto ">LIMITATIONS</p>
            <h3 className="mb-4 md:text-3xl text-Highlight md:leading-normal text-2xl leading-normal font-semibold">
              Dimensions and weight limits
            </h3>
          </div>

          <div className="gap-6  mt-8 ">
            <div className="flex justify-between items-center">
              <div className="">
                <h3 className="mb-2 md:text-2xl text-2xl md:leading-normal leading-normal font-semibold text-black dark:text-white">
                  {"Regular & Flexi"}
                </h3>
                <DimensionAndWidthCard
                  dimensionName={"Weight"}
                  paragraph={"Up to 30 kg"}
                />
              </div>
              <div className="">
                <h3 className="mb-2 md:text-2xl text-2xl md:leading-normal leading-normal font-semibold text-black dark:text-white">
                  {"Regular Plus"}
                </h3>
                <DimensionAndWidthCard
                  dimensionName={"Weight"}
                  paragraph={"Up to 30 kg"}
                />
              </div>
              <div className="">
                <h3 className="mb-2 md:text-2xl text-2xl md:leading-normal leading-normal font-semibold text-black dark:text-white">
                  {"Regular Plus"}
                </h3>
                <DimensionAndWidthCard
                  dimensionName={"Weight"}
                  paragraph={"Up to 70 kg"}
                />
              </div>

              <div className="">
                <h3 className="mb-2 md:text-2xl text-2xl md:leading-normal leading-normal font-semibold text-black dark:text-white">
                  {"Express"}
                </h3>
                <DimensionAndWidthCard
                  dimensionName={"Weight"}
                  paragraph={"Up to 70 kg"}
                />
              </div>
            </div>
            <div className="flex justify-between items-center w-full">
              <div className="">
                <DimensionAndWidthCard
                  dimensionName={"Length"}
                  paragraph={"175 cm (biggest side of the package)"}
                />
              </div>
              <div className="">
                <DimensionAndWidthCard
                  dimensionName={"Length"}
                  paragraph={"120 cm (biggest side of the package)"}
                />
              </div>
              <div className="">
                <DimensionAndWidthCard
                  dimensionName={"Length"}
                  paragraph={"120 cm (biggest side of the package)"}
                />
              </div>
              <div className="">
                <DimensionAndWidthCard
                  dimensionName={"Length"}
                  paragraph={"120 cm (biggest side of the package)"}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="">
                <DimensionAndWidthCard
                  dimensionName={"Measurements"}
                  paragraph={"Length + 2x height + 2x width < 300 cm"}
                />
              </div>
              <div className="">
                <DimensionAndWidthCard
                  dimensionName={"Measurements"}
                  paragraph={"Up to 210 cm in total"}
                />
              </div>
              <div className="">
                <DimensionAndWidthCard
                  dimensionName={"Measurements"}
                  paragraph={"Up to 230 cm in total"}
                />
              </div>
              <div className="">
                <DimensionAndWidthCard
                  dimensionName={"Measurements"}
                  paragraph={"Up to 210 cm in total"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative md:py-12 py-8 lg:py-16">
        <div className="container relative ">
          <div className="md:flex  mt-8 gap-10  px-9 justify-center items-center">
            {AboutData.map((item, index) => {
              return (
                <div className="group relative" key={index}>
                  <div className=" text-blue-700  w-9 h-9 flex justify-center items-center rounded-full  bg-blue-200">
                    {/* <img src={item.image} className="" alt="" />
                    <div className="absolute inset-0 bg-slate-900/30 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div> */}
                    <p>{item?.number}</p>
                  </div>

                  <div className="mt-6">
                    <Link
                      to="#"
                      className="text-xl font-semibold hover:text-indigo-600 transition-all duration-500 ease-in-out"
                    >
                      {item.title}
                    </Link>

                    <p className="text-slate-400 mt-4">{item.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="relative dark:bg-slate-800 md:py-12 py-8 lg:py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center ">
            <p className="text-btnBackground  max-w-xl mx-auto">
              DELIVERY TIME
            </p>

            <h3 className="mb-2 md:text-3xl md:leading-normal text-2xl text-textHighlight leading-normal font-semibold">
              How long does it take to deliver a package?
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Delivery times vary depending on the specific route and service
              selected. To help give you a general idea, here is an estimate of
              the delivery times:
            </p>
            <table class="w-9/12  md:w-6/12 text-left mx-auto mt-4 leading-normal">
              <thead>
                <tr class="border-b-2 text-xl font-semibold ">
                  <th>Services</th>
                  <th>Estimated Delivery Time</th>
                </tr>
              </thead>
              <tbody>
                <tr class="border-b-2 ">
                  <th>Standard Service</th>
                  <td>1-7 working days</td>
                </tr>
                <tr class="border-b-2 ">
                  <th>Regular Plus</th>
                  <td>1-3 working days</td>
                </tr>
                <tr class="border-b-2 ">
                  <th>Flexi</th>
                  <td>2-5 working days</td>
                </tr>
                <tr class="border-b-2 ">
                  <th>Express</th>
                  <td>24-72 hours</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section className="relative  dark:bg-slate-800 md:py-12 py-8 lg:py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <div className="lg:ms-5">
                <p className="text-btnBackground max-w-xl text-2xl mb-2">
                  TRACK & TRACE
                </p>

                <h3 className="mb-6 md:text-3xl text-textHighlight text-2xl md:leading-normal leading-normal font-semibold">
                  Get real-time shipment updates
                </h3>

                <p className="text-slate-400 max-w-xl">
                  Tracking is included for free on all our package services, so
                  you will always know where your package is.
                </p>

                <div className="mt-6">
                  <Link
                    to="/contact-one"
                    className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md mt-2"
                  >
                    <FiAirplay className="me-1" /> Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="lg:col-span-5 md:col-span-6">
              <div className="grid grid-cols-2 gap-6 items-center">
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img
                      src={"https://placehold.co/600x400/EEE/31343C"}
                      className="shadow rounded-md"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative dark:bg-slate-800 md:py-12 py-8 lg:py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px] ">
            <div className="lg:col-span-5 md:col-span-6">
              <div className="grid grid-cols-2 gap-6 items-center">
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img
                      src={"https://placehold.co/600x400/EEE/31343C"}
                      className="shadow rounded-md"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-span-7 md:col-span-6">
              <div className="lg:ms-5">
                {/* <div className="flex mb-4">
                  <span className="text-indigo-600 text-2xl font-bold mb-0">
                    <CountUp
                      className="counter-value text-6xl font-bold"
                      start={1}
                      end={15}
                    ></CountUp>
                    +
                  </span>
                  <span className="self-end font-semibold ms-2">
                    Years <br /> Experience
                  </span>
                </div> */}
                <p className="text-btnBackground max-w-xl text-2xl mb-2">
                  INSURANCE
                </p>

                <h3 className="mb-6 md:text-3xl text-textHighlight text-2xl md:leading-normal leading-normal font-semibold">
                  We make safety a priority
                </h3>

                <p className="text-slate-400 max-w-xl">
                  Basic insurance is included in the price of most shipping
                  services, but you can always add extra coverage for greater
                  peace of mind.
                </p>

                <div className="mt-6">
                  <Link
                    to="/contact-one"
                    className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md mt-2"
                  >
                    <FiAirplay className="me-1" /> More about insurance
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative md:py-12 py-8 lg:py-16">
        <section className="relative  dark:bg-slate-800 md:py-24 py-16">
          <div className="container relative">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
              <div className="lg:col-span-7 md:col-span-6">
                <div className="lg:ms-5">
                  <p className="text-btnBackground max-w-xl text-2xl mb-2">
                    WE ARE GLOBAL
                  </p>

                  <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-textHighlight">
                    Send parcels anywhere you wish
                  </h3>

                  <p className="text-slate-400 max-w-xl">
                    Whether you need to send a parcel down the road or across
                    the world, we’ve got you covered!
                  </p>

                  <div className="mt-6">
                    <Link
                      to="/contact-one"
                      className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md mt-2"
                    >
                      <FiAirplay className="me-1" /> More about parcel delivery
                    </Link>
                  </div>
                </div>
              </div>
              <div className="lg:col-span-5 md:col-span-6">
                <div className="grid grid-cols-2 gap-6 items-center">
                  <div className="col-span-6">
                    <div className="grid grid-cols-1 gap-6">
                      <img
                        src={"https://placehold.co/600x400/EEE/31343C"}
                        className="shadow rounded-md"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container relative md:mt-16 mt-12 lg:pt-24 pt-16">
          <div className="relative grid grid-cols-1 pb-2 text-center z-1">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-black dark:text-white">
              Ship with the best <br /> courier companies
            </h3>
          </div>

          <div className="relative  flex  items-center mt-2 gap-[30px] z-1 justify-center">
            <div className=" text-center">
              <img
                src="https://static.eurosender.com/wp-content/uploads/2023/03/01093823/dpdbutton-logo-no-bg.png"
                alt
                className="w-20 h20"
              ></img>
            </div>
            <div className=" text-center">
              <img
                src="https://static.eurosender.com/wp-content/uploads/2023/03/01093656/dhlbutton-logo_-no-bg.png"
                alt
                className="w-20 h20"
              ></img>
            </div>

            <div className=" text-center">
              <img
                src="https://static.eurosender.com/wp-content/uploads/2023/03/01093824/upsbutton-logo-no-bg.png"
                alt
                className="w-20 h20"
              ></img>
            </div>

            <div className=" text-center">
              <img
                src="https://static.eurosender.com/wp-content/uploads/2023/03/14142029/Fedexbutton-logo-no-bg.png"
                alt
                className="w-20 h20"
              ></img>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-black dark:text-white">
              What Our Client Say ?
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Start working with Tailwind CSS that can provide everything you
              need to generate awareness, drive traffic, connect.
            </p>
          </div>

          <div className="flex justify-center relative mt-16">
            <div className="relative md:w-1/3 w-full">
              {/* <div className="absolute -top-20 md:-start-24 -start-0">
                <i className="mdi mdi-format-quote-open text-9xl opacity-5"></i>
              </div>

              <div className="absolute bottom-28 md:-end-24 -end-0">
                <i className="mdi mdi-format-quote-close text-9xl opacity-5"></i>
              </div> */}

              <div className="">
                <TinySlider settings={settings}>
                  {services.map((item, index) => {
                    return (
                      <div className="tiny-slide" key={index}>
                        <div className="">
                          <div class="relative">
                            <img
                              src={"https://placehold.co/300x300/EEE/31343C"}
                              alt=""
                              class="object-cover w-full h-60 mx-auto rounded-t-2xl"
                            />
                            <div class="rounded-t-2xl absolute bg-gradient-to-t from-gray-800 opacity-75 inset-0 z-0"></div>
                          </div>
                          <h6 className="mt-2 font-semibold">{item.name}</h6>
                          <span className="text-slate-400 text-sm">
                            {item.role}
                          </span>
                          <div className=" mt-5">
                            {/* <ul className="text-xl font-medium text-amber-400 list-none mb-2">
                              <li className="inline">
                                <i className="mdi mdi-star"></i>
                              </li>
                              <li className="inline">
                                <i className="mdi mdi-star"></i>
                              </li>
                              <li className="inline">
                                <i className="mdi mdi-star"></i>
                              </li>
                              <li className="inline">
                                <i className="mdi mdi-star"></i>
                              </li>
                              <li className="inline">
                                <i className="mdi mdi-star"></i>
                              </li>
                            </ul> */}
                            <p className="text-lg text-slate-400 italic">
                              {" "}
                              {item.feedback}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </TinySlider>
              </div>
            </div>
          </div>
        </div>

        {/* <ManagementTeam className="relative md:mt-24 mt-16" id={""} /> */}
      </section>
      <Footer />
      <CookieModal />
    </>
  );
}
