import React, { useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";
import { FiPhone } from "../../assets/icons/icons";
import { toast } from "react-toastify";

export default function HelpcenterFaqs() {
  const [activeIndex, setActiveIndex] = React.useState(1);
  const [faqData, setFaqData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const accordionData = [
    {
      title: "What is Lorem Ipsum mean?",
      content:
        "‘Lorem ipsum dolor sit amet, consectetur adipisici elit…’ (complete text) is dummy text that is not meant to mean anything. It is used as a placeholder in magazine layouts, for example, in order to give an impression of the finished document. The text is intentionally unintelligible so that the viewer is not distracted by the content. The language is not real Latin and even the first word ‘Lorem’ does not exist. It is said that the lorem ipsum text has been common among typesetters since the 16th century. (Source: Wikipedia.com).",
    },
    {
      title: "Do I have to pay customs or import fees?",
      content:
        "‘Lorem ipsum dolor sit amet, consectetur adipisici elit…’ (complete text) is dummy text that is not meant to mean anything. It is used as a placeholder in magazine layouts, for example, in order to give an impression of the finished document. The text is intentionally unintelligible so that the viewer is not distracted by the content. The language is not real Latin and even the first word ‘Lorem’ does not exist. It is said that the lorem ipsum text has been common among typesetters since the 16th century. (Source: Wikipedia.com).",
    },
    {
      title: "Can I track my order?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      title: "I’ve received the wrong item",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
  ];

  const initialState = {
    activeIndex: 0,
    activeGeneral: 0,
    activePayment: 0,
    activeSupport: 0,
  };

  // const toggleAccordion = (category, index) => {
  //   dispatch({
  //     type: "SELECT_CATEGORY",
  //     payload: { category: category, index: index },
  //   });
  // };

  const FaqApi = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/faq", requestOptions)
      .then((response) => response.json())
      .then(({ status, faqs, message }) => {
        console.log("🚀 ~ .then ~ faqs:", faqs);
        if (status === true) {
          setFaqData(faqs);
          setLoading(false);
        } else {
          toast.error(message);
        }
      })

      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };

  const reducerMethod = (state, action) => {
    switch (action.payload.category) {
      case "active":
        return {
          ...state,
          activeIndex: action.payload.index,
        };
      case "general":
        return {
          ...state,
          activeGeneral: action.payload.index,
        };
      case "payments":
        return {
          ...state,
          activePayment: action.payload.index,
        };
      case "support":
        return {
          ...state,
          activeSupport: action.payload.index,
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducerMethod, initialState);
  React.useEffect(() => {
    FaqApi();
  }, [navigate]);
  return (
    <>
      <Navbar navClass="nav-light" />

      <section className="relative table w-full py-36 dark:bg-slate-900  bg-center bg-no-repeat bg-cover">
        <div className="absolute inset-0  opacity-80"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="md:text-4xl text-3xl  dark:text-white md:leading-normal tracking-wide leading-normal font-medium text-black">
              Frequently Asked Questions
            </h3>
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
            {loading ? (
              <div className="lg:col-span-12 md:col-span-7 flex justify-center items-center">
                <TailSpin
                  visible={true}
                  height="60"
                  width="60"
                  color="rgb(67 56 202)"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <React.Fragment>
                {faqData.length === 0 ? (
                  <div className="lg:col-span-12 md:col-span-7">
                    <h5 className="text-2xl font-semibold">No Record Found</h5>
                  </div>
                ) : (
                  <div className="lg:col-span-12 md:col-span-7">
                    <div id="tech">
                      <div
                        id="accordion-collapseone"
                        data-accordion="collapse"
                        className="mt-6"
                      >
                        {faqData.map((item, index) => (
                          <div
                            key={index}
                            className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4"
                          >
                            <h2
                              className="text-base font-semibold"
                              id="accordion-collapse-heading-1"
                            >
                              <button
                                type="button"
                                onClick={() => toggleAccordion(item.id)}
                                className={`flex justify-between items-center p-5 w-full font-medium text-start ${
                                  activeIndex === item.id
                                    ? "bg-lightGrey dark:bg-slate-800 text-btnBackground"
                                    : ""
                                }`}
                                data-accordion-target="#accordion-collapse-body-1"
                                aria-expanded="true"
                                aria-controls="accordion-collapse-body-1"
                              >
                                <span className="text-btnBackground">
                                  {item.questions}
                                </span>
                                <svg
                                  data-accordion-icon
                                  className={`${
                                    activeIndex === item.id
                                      ? "rotate-180"
                                      : "rotate-270"
                                  } w-4 h-4 shrink-01`}
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </h2>
                            {activeIndex === item.id && (
                              <div>
                                <div className="p-5 bg-lightGrey">
                                  <p className=" text-btnBackground dark:text-gray-400">
                                    {item?.answers}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* <div id="general" className="mt-8">
                <h5 className="text-2xl font-semibold">General Questions</h5>

                <div
                  id="accordion-collapsetwo"
                  data-accordion="collapse"
                  className="mt-6"
                >
                  {accordionData.map((item, index) => (
                    <div
                      key={index}
                      className={`relative shadow dark:shadow-gray-700 rounded-md overflow-hidden mt-4`}
                    >
                      <h2 className="text-base font-medium">
                        <button
                          onClick={() => toggleAccordion("general", index)}
                          type="button"
                          className={`flex justify-between items-center p-5 w-full font-semibold text-start ${
                            state.activeGeneral === index
                              ? "bg-gray-50 dark:bg-slate-800 text-violet-600"
                              : ""
                          } `}
                          data-accordion-target="#accordion-collapse-body-1"
                          aria-expanded="true"
                          aria-controls="accordion-collapse-body-1"
                        >
                          <span>{item.title}</span>
                          <svg
                            data-accordion-icon
                            className={`${
                              state.activeIndex === index
                                ? "rotate-180"
                                : "rotate-270"
                            } w-4 h-4 shrink-01`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </h2>
                      {state.activeGeneral === index && (
                        <div aria-labelledby="accordion-collapse-heading-1">
                          <div className="p-5">
                            <p className="text-slate-400 dark:text-gray-400">
                              {item.content}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div id="payment" className="mt-8">
                <h5 className="text-2xl font-semibold">Payments Questions</h5>

                <div
                  id="accordion-collapsethree"
                  data-accordion="collapse"
                  className="mt-6"
                >
                  {accordionData.map((item, index) => (
                    <div
                      key={index}
                      className={`relative shadow dark:shadow-gray-700 rounded-md overflow-hidden mt-4`}
                    >
                      <h2 className="text-base font-medium">
                        <button
                          onClick={() => toggleAccordion("payments", index)}
                          type="button"
                          className={`flex justify-between items-center p-5 w-full font-semibold text-start ${
                            state.activePayment === index
                              ? "bg-gray-50 dark:bg-slate-800 text-violet-600"
                              : ""
                          } `}
                          data-accordion-target="#accordion-collapse-body-1"
                          aria-expanded="true"
                          aria-controls="accordion-collapse-body-1"
                        >
                          <span>{item.title}</span>
                          <svg
                            data-accordion-icon
                            className={`${
                              state.activeIndex === index
                                ? "rotate-180"
                                : "rotate-270"
                            } w-4 h-4 shrink-01`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </h2>
                      {state.activePayment === index && (
                        <div aria-labelledby="accordion-collapse-heading-1">
                          <div className="p-5">
                            <p className="text-slate-400 dark:text-gray-400">
                              {item.content}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div id="support" className="mt-8">
                <h5 className="text-2xl font-semibold">Support Questions</h5>

                <div
                  id="accordion-collapsefour"
                  data-accordion="collapse"
                  className="mt-6"
                >
                  {accordionData.map((item, index) => (
                    <div
                      key={index}
                      className={`relative shadow dark:shadow-gray-700 rounded-md overflow-hidden mt-4`}
                    >
                      <h2 className="text-base font-medium">
                        <button
                          onClick={() => toggleAccordion("support", index)}
                          type="button"
                          className={`flex justify-between items-center p-5 w-full font-semibold text-start ${
                            state.activeSupport === index
                              ? "bg-gray-50 dark:bg-slate-800 text-violet-600"
                              : ""
                          } `}
                          data-accordion-target="#accordion-collapse-body-1"
                          aria-expanded="true"
                          aria-controls="accordion-collapse-body-1"
                        >
                          <span>{item.title}</span>
                          <svg
                            data-accordion-icon
                            className={`${
                              state.activeIndex === index
                                ? "rotate-180"
                                : "rotate-270"
                            } w-4 h-4 shrink-01`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </h2>
                      {state.activeSupport === index && (
                        <div aria-labelledby="accordion-collapse-heading-1">
                          <div className="p-5">
                            <p className="text-slate-400 dark:text-gray-400">
                              {item.content}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div> */}
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-6 md:text-3xl text-textHighlight text-2xl md:leading-normal leading-normal font-semibold">
              Have Question ? Get in touch!
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              sagittis ultrices justo, ac semper felis cursus eu. Quisque
              pellentesque elementum purus, imperdiet gravida magna tempus et.
            </p>

            <div className="mt-6">
              <Link
                to="/contact-us"
                className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md me-2 mt-2"
              >
                <FiPhone className="me-1 text-lg" /> Contact us
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
