import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ArrowRight } from "react-feather";
import HeaderBar from "../../component/HeaderBar";
import RightContainer from "../../component/OrdersRightContainer/RightContainer";
import BeardCrumbs from "../../component/BeardCrumbs";
export default function Safety() {
  //   useEffect(() => {
  //     document.documentElement.setAttribute("dir", "ltr");
  //     document.documentElement.classList.add("light");
  //     document.documentElement.classList.remove("dark");
  //   }, []);
  const [data, setData] = useState([
    {
      weight: "",
      length: "",
      width: "",
      height: "",
    },
  ]);
  console.log("🚀 ~ file: OrderDetails.js:30 ~ OrderDetails ~ data:", data);
  const addAnother = () => {
    setData([...data, { weight: "", length: "", width: "", height: "" }]);
  };
  const [shippingOption, setshippingOption] = useState("Regular");
  const [date, setDate] = useState(new Date());
  const [pickup, setPickUp] = useState("");
  const [price, setPrice] = useState(287);

  const [delivery, setDelivery] = useState("");
  const [isToggled, setToggled] = useState(false);
  const [value, setValue] = useState(0);

  const handleClick = () => {
    if (isToggled === true) {
      setToggled(false);
      setPrice(287);
    } else if (isToggled === false) {
      setToggled(true);
      setPrice(287 + 19);
    }
  };
  return (
    <div>
      <HeaderBar title={"Insurance"} />
      <div className="container relative pb-0 mx-auto grid md:grid-cols-12 grid-cols-1 md:py-28">
        <div className="layout-specing md:col-span-8">
          <BeardCrumbs title={"Insurance"} />
          <div className=" justify-between items-center">
            <h1 className="text-2xl font-bold text-btnBackground ">
              Upgrade your order
            </h1>
            <h2 className="text-lg font-semibold mt-1">{"Insurance"}</h2>
            <p className=" text-btnBackground cursor-pointer">
              <span className="underline">Click here to login</span>
              <span className="text-gray-400 ">
                {" "}
                and access your saved information
              </span>
            </p>
          </div>

          <div className="grid grid-cols-1 mt-6  ">
            <div className="bg-white dark:bg-slate-800 items-center  py-4 px-4 shadow-sm">
              <div className="flex justify-around items-center">
                <span>
                  Insurance coverage up to the given value of the shipment
                </span>
                <button
                  className={`py-2 px-4 rounded-md ${
                    isToggled ? "bg-btnBackground" : "bg-bgGrey"
                  } text-white`}
                  onClick={handleClick}
                >
                  {isToggled ? "Added 19" : "Add 19"}
                </button>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex items-center ">
                <input
                  className="form-checkbox self-start rounded border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2"
                  type="checkbox"
                  value=""
                  id="AcceptT&C"
                />
                <label className="text-sm text-slate-400" htmlFor="AcceptT&C">
                  Click to next button you agree Lorem Ipsum........{" "}
                </label>
              </div>
            </div>
            <div class="shadow-md block md:hidden mt-4 bg-white rounded p-4">
              <h2 className="text-lg font-bold mt-4 text-btnBackground">
                Order summary
              </h2>
              <div>
                <div className=" flex mt-2  items-center ">
                  <p className="font-thin text-btnBackground">Pick-up</p>
                  <ArrowRight className="ml-2 h-7 w-5" />
                  <p className="font-thin ml-2 text-btnBackground">Delivery</p>
                </div>
                <div className=" flex mt-2  items-center ">
                  <p className="font-bold ">{pickup && pickup.value}</p>

                  <p className="font-bold ml-7">{delivery && delivery.value}</p>
                </div>
                <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
              </div>
              <div className=" flex mt-2  items-center ">
                <p className="font-thin text-btnBackground">Shipping Options</p>
              </div>
              <div className=" flex mt-1 justify-between items-center ">
                <p className="font-bold text-btnBackground">
                  {shippingOption && shippingOption}
                </p>

                <p className="font-bold ml-7">
                  {shippingOption === "Regular" ? "$287" : "387"}
                </p>
              </div>
              <div className=" flex mt-1 justify-between items-center ">
                <p className="font-thin text-btnBackground">1 x Pacakge</p>

                <p className="font-thin ml-7">{"1Kg"}</p>
              </div>
              <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
              <div className="  mt-2  items-center ">
                <p className="font-thin text-btnBackground">Pick-up Date</p>
                <h2 className="font-bold text-xl btnBackground">
                  {date.toDateString()}
                </h2>
              </div>
              <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
              <div className="  mt-2  items-center ">
                <p className="font-thin btnBackground">Insurance</p>
                <div className="  mt-2 flex justify-between items-center ">
                  <p className="font-bold text-2xl text-btnBackground">
                    Insurance
                  </p>
                  <p className="font-bold text-2xs">$19</p>
                </div>
              </div>

              <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
              <div className="  mt-2 flex justify-between items-center ">
                <div>
                  <p className="font-bold text-2xl text-btnBackground">Total</p>
                  <p className="font-thin text-xs">(incl. VAT)</p>
                </div>
                <h2 className="font-bold text-2xl text-btnBackground ">
                  {price && "$" + price}
                </h2>
              </div>
            </div>

            <Link to="/order-details">
              <div className="mt-4">
                <input
                  type="submit"
                  id="search-buy"
                  name="search"
                  className="btn px-3 bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn  !h-12 rounded"
                  value="Back"
                />
              </div>
            </Link>
          </div>
        </div>
      </div>

      <RightContainer>
        <div
          className=" overflow-y-auto flex-grow flex-shrink-0"
          style={{ flexBasis: 0 }}
        >
          <div class="styles_content__Ctx_x">
            <h2 className="text-lg font-bold mt-4 text-btnBackground">
              Order summary
            </h2>
            <div>
              <div className=" flex mt-2  items-center ">
                <p className="font-thin text-btnBackground">Pick-up</p>
                <ArrowRight className="ml-2 h-7 w-5" />
                <p className="font-thin ml-2 text-btnBackground">Delivery</p>
              </div>
              <div className=" flex mt-2  items-center ">
                <p className="font-bold ">{pickup && pickup.value}</p>

                <p className="font-bold ml-7">{delivery && delivery.value}</p>
              </div>
              <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
            </div>
            <div className=" flex mt-2  items-center ">
              <p className="font-thin text-btnBackground">Shipping Options</p>
            </div>
            <div className=" flex mt-1 justify-between items-center ">
              <p className="font-bold text-btnBackground">
                {shippingOption && shippingOption}
              </p>

              <p className="font-bold ml-7">
                {shippingOption === "Regular" ? "$287" : "387"}
              </p>
            </div>
            <div className=" flex mt-1 justify-between items-center ">
              <p className="font-thin text-btnBackground">1 x Pacakge</p>

              <p className="font-thin ml-7">{"1Kg"}</p>
            </div>
            <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
            <div className="  mt-2  items-center ">
              <p className="font-thin text-btnBackground">Pick-up Date</p>
              <h2 className="font-bold text-xl btnBackground">
                {date.toDateString()}
              </h2>
            </div>
            <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
            <div className="  mt-2  items-center ">
              <p className="font-thin btnBackground">Insurance</p>
              <div className="  mt-2 flex justify-between items-center ">
                <p className="font-bold text-2xl text-btnBackground">
                  Insurance
                </p>
                <p className="font-bold text-2xs">$19</p>
              </div>
            </div>

            <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
            <div className="  mt-2 flex justify-between items-center ">
              <div>
                <p className="font-bold text-2xl text-btnBackground">Total</p>
                <p className="font-thin text-xs">(incl. VAT)</p>
              </div>
              <h2 className="font-bold text-2xl text-btnBackground ">
                {price && "$" + price}
              </h2>
            </div>
          </div>
        </div>
        <div
          style={{ flexBasis: "0%", flexShrink: 1, flexGrow: 0 }}
          className="h-auto py-8 "
        >
          <Link to="/order-payment">
            <div className="mt-4">
              <input
                type="submit"
                id="search-buy"
                name="search"
                className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
                value="Next Step"
              />
            </div>
          </Link>
        </div>
      </RightContainer>
    </div>
  );
}
