import React from "react";
import { Link } from "react-router-dom";
import contact from "../../assets/images/contact.svg";
import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";
import { IoIosHelpCircle } from "react-icons/io";
import {
  MdKeyboardArrowRight,
  FiPhone,
  FaRegEnvelope,
  RiMapPinLine,
} from "../../assets/icons/icons";
import { MdOutlineNumbers } from "react-icons/md";
import * as Icon from "react-feather";
import Select from "react-select";
import { useState } from "react";
import { useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  message: Yup.string().required("Message is required"),
  selectedOption: Yup.object().required("Help option is required"),
  orderNo: Yup.string().required("Order no is required"),
  firstName: Yup.string().required("First name is required"),
  surName: Yup.string().required("Surname is required"),
  myFile: Yup.mixed()
    .required("Required")
    .test(
      "fileFormat",
      "Only PDF, JPEG, PNG, and GIF files are allowed",
      (value) => {
        if (value) {
          const supportedFormats = [
            "application/pdf",
            "image/jpeg",
            "image/png",
            "image/gif",
          ]; // Add supported formats here
          return supportedFormats.includes(value.type);
        }
        return true;
      }
    )
    .test("fileSize", "File size must not be more than 3MB", (value) => {
      if (value) {
        return value.size <= 3145728;
      }
      return true;
    }),
});
export default function ContactUs() {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [articleData, setArticleData] = useState([]);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("🚀 ~ handleFileChange ~ file:", file);
    if (file) {
      formik.setFieldValue("myFile", file);
      setSelectedFile(file);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    formik.setFieldValue("myFile", "");
  };

  const [articleOption, setArtcileOption] = useState("");
  const ShowArticleApi = (value) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://movssy.com/admin/api/articles/${value}`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        console.log("🚀 ~ .then ~ articles:", res);
        if (res?.status === true) {
          if (res?.articles.length === 0) {
            setArticleData([]);
          } else {
            setArticleData(res?.articles);

            const articlesData = res?.articles.map((article) => ({
              value: article.group,
              label: article.article_name,
            }));
            setOptions(articlesData);
          }
        } else {
          toast.error(res?.message);
        }
      })
      .catch((error) => console.error(error));
  };
  const project = [
    { value: "Collection", label: "Collection" },
    { value: "Tracking", label: "Tracking" },
    { value: "Delivery", label: "Delivery" },
    { value: "Payments", label: "Payments" },
    { value: "Claims", label: "Claims" },
    { value: "Api Access", label: "Api Access" },
  ];

  const handleSelectChange = (selectedOption) => {
    console.log("🚀 ~ handleSelectChange ~ selectedOption:", selectedOption);
    setArtcileOption(selectedOption);
  };
  const handleArticleChange = (selectedOption) => {
    console.log("🚀 ~ handleSelectChange ~ selectedOption:", selectedOption);
    ShowArticleApi(selectedOption?.value);
    setSelectedOption(selectedOption);
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      surName: "",
      orderNo: "",
      email: "",
      message: "",
      selectedOption: null,
      myFile: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Your login logic here
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");

      const formdata = new FormData();
      formdata.append("first_name", values.firstName);
      formdata.append("surname", values.surName);
      formdata.append("email", values.email);
      formdata.append("order_no", values.orderNo);
      formdata.append("message", values.message);
      formdata.append("upload_file", values.myFile);
      formdata.append("group", values.selectedOption?.value);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      fetch("https://movssy.com/admin/api/contact/store", requestOptions)
        .then((response) => {
          if (!response.ok) {
            toast.error(response?.status);
          }
          return response.json();
        })
        .then(({ status }) => {
          if (status === true) {
            toast.success("We've Received Your Message!");
            setLoading(false);

            formik.resetForm();

            navigate("/");
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(true);
        });
    },
  });
  return (
    <>
      <Navbar navClass="nav-light" />

      <section className="relative md:py-40 py-30  bg-right bg-no-repeat bg-cover">
        <div className="absolute inset-0  dark:bg-slate-900"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 items-center">
            <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">
              Contact us
            </h4>
            <p className="text-black text-lg max-w-xl dark:text-gray-400">
              We aim to respond as quickly as possible. In most cases you will
              receive a response within 3 hours between 8am and 5:30pm (CET),
              Monday to Friday. You can also reach us by phone on +44 203 129
              2884.
            </p>
            <div className="conatiner relative md:py-12 py-8 lg:py-16">
              <div className="bg-bgGrey dark:bg-slate-800 p-4 shadow-sm">
                <p className="text-black dark:text-white">Select Topic</p>
                <div className="filter-search-form relative  lg:before:rounded-md ">
                  {/* <LuSearch className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                  <Select
                    className="bg-gray-50 dark:bg-slate-800 border-0"
                    options={project}
                    value={selectedOption}
                    onChange={handleArticleChange}
                    placeholder="Select Topic"
                  />
                </div>
              </div>
            </div>

            {/* <div className="mt-8">
              <Link
                to="#"
                className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
              >
                Learn More <FaArrowRight className="ms-2 text-[10px]" />
              </Link>
            </div> */}
          </div>
        </div>
      </section>
      <section className="relative md:py-18 py-12">
        {selectedOption && (
          <div className="container relative md:my-8 my-6 duration-500 ">
            <div className=" grid md:col-span-5  grid-cols-1 items-center gap-[30px] mx-auto">
              {articleData.length === 0 ? null : (
                <div className="">
                  {/* <img src={contact} alt="" /> */}

                  <div className="bg-lightGrey dark:bg-slate-900 shadow-sm py-28 px-4 lg:leading-normal leading-normal  mb-5 text-black">
                    <span className="text-xl lg:text-2xl font-bold dark:text-white">
                      Related Articles
                    </span>
                    <div>
                      {articleData.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="  mt-2  rounded-xl text-black  items-center max-w-xl flex    "
                          >
                            <IoIosHelpCircle className="w-5 h-5 text-btnBackground " />
                            <p className="text-btnBackground ml-2">
                              {item.article_name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}

              <div className="">
                <div className="">
                  <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="mb-5">
                        <div className="text-start">
                          <label htmlFor="subject" className="font-semibold">
                            What do you need help with?
                          </label>
                          <div className="form-icon relative mt-2">
                            <Icon.Book className="w-4 h-4 absolute top-3 start-4"></Icon.Book>
                            <Select
                              className="bg-gray-50 dark:bg-slate-800 border-0"
                              options={options}
                              placeholder="Option"
                              value={formik.values.selectedOption}
                              onChange={(option) =>
                                formik.setFieldValue("selectedOption", option)
                              }
                            />
                          </div>
                          {formik.touched.selectedOption &&
                            formik.errors.selectedOption && (
                              <p className="text-red-500 text-sm mt-1">
                                {formik.errors.selectedOption}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-12 lg:gap-6">
                        <div className="lg:col-span-6 mb-5">
                          <div className="text-start">
                            <label
                              htmlFor="name"
                              className="font-semibold text-btnBackground"
                            >
                              Your Name:
                            </label>
                            <div className="form-icon relative mt-2">
                              <Icon.User className="w-4 h-4 absolute top-3 text-btnBackground start-4"></Icon.User>
                              <input
                                name="firstName"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstName}
                                id="firstName"
                                type="text"
                                className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="Name :"
                              />
                            </div>
                            {formik.touched.firstName &&
                              formik.errors.firstName && (
                                <p className="text-red-500 text-sm mt-1">
                                  {formik.errors.firstName}
                                </p>
                              )}
                          </div>
                        </div>

                        <div className="lg:col-span-6 mb-5">
                          <div className="text-start text-btnBackground">
                            <label htmlFor="email" className="font-semibold">
                              Surname:
                            </label>
                            <div className="form-icon relative mt-2">
                              <Icon.User className="w-4 h-4 absolute top-3 start-4"></Icon.User>
                              <input
                                name="surName"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.surName}
                                id="surName"
                                type="text"
                                className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="SurName :"
                              />
                            </div>
                            {formik.touched.surName &&
                              formik.errors.surName && (
                                <p className="text-red-500 text-sm mt-1">
                                  {formik.errors.surName}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-1">
                        <div className="mb-5">
                          <div className="text-start">
                            <label htmlFor="comments" className="font-semibold">
                              Your Email:
                            </label>
                            <div className="form-icon relative mt-2">
                              {/* <Icon.MessageCircle className="w-4 h-4 absolute top-3 start-4"></Icon.MessageCircle> */}
                              <Icon.Mail className="w-4 h-4 absolute top-3 start-4"></Icon.Mail>
                              <input
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                id="email"
                                type="email"
                                className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="Email :"
                              />
                            </div>
                            {formik.touched.email && formik.errors.email && (
                              <p className="text-red-500 text-sm mt-1">
                                {formik.errors.email}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-1">
                        <div className="mb-5">
                          <div className="text-start">
                            <label htmlFor="comments" className="font-semibold">
                              Your Order Number:
                            </label>
                            <div className="form-icon relative mt-2">
                              {/* <Icon.MessageCircle className="w-4 h-4 absolute top-3 start-4"></Icon.MessageCircle> */}
                              <MdOutlineNumbers className="w-4 h-4 absolute top-3 start-4" />
                              <input
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.orderNo}
                                name="orderNo"
                                id="orderNo"
                                type="number"
                                className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="Order Number :"
                              />
                            </div>
                            {formik.touched.orderNo &&
                              formik.errors.orderNo && (
                                <p className="text-red-500 text-sm mt-1">
                                  {formik.errors.orderNo}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-1">
                        <div className="">
                          <div className="text-start">
                            <label htmlFor="comments" className="font-semibold">
                              Your Message:
                            </label>
                            <div className="form-icon relative mt-2">
                              <Icon.MessageCircle className="w-4 h-4 absolute top-3 start-4"></Icon.MessageCircle>
                              <textarea
                                name="message"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.message}
                                id="message"
                                className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="Message :"
                              ></textarea>
                            </div>
                            {formik.touched.message &&
                              formik.errors.message && (
                                <p className="text-red-500 text-sm mt-1">
                                  {formik.errors.message}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-1">
                        <div className="mt-4">
                          <div className="justify-center items-center flex border border-dashed py-11">
                            {selectedFile ? (
                              <>
                                <span>{selectedFile.name}</span>
                                <button
                                  onClick={handleRemoveFile}
                                  className="ml-2 text-red-500"
                                >
                                  &times;
                                </button>
                              </>
                            ) : (
                              <input
                                id="myFile"
                                type="file"
                                name="myFile"
                                accept=".pdf, image/jpeg, image/png, image/gif"
                                onChange={handleFileChange}
                              />
                            )}
                          </div>
                          {formik.errors.myFile && (
                            <p className="text-red-500 text-sm mt-1">
                              {formik.errors.myFile}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="mb-4 mt-2">
                        <div className="flex  w-full mb-0">
                          <input
                            className="mt-1 form-checkbox rounded border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2"
                            type="checkbox"
                            value=""
                            id="AcceptT&C"
                          />
                          <label
                            className="form-check-label text-slate-400"
                            htmlFor="AcceptT&C"
                          >
                            I agree to the processing of the personal data that
                            I provide to Movssy in accordance with{" "}
                            <Link className="text-btnBackground">
                              Movssy Privacy policy
                            </Link>
                          </label>
                        </div>
                      </div>

                      <button
                        type="submit"
                        id="submit"
                        name="send"
                        disabled={loading}
                        className="py-2 px-5 bg-btnBackground font-semibold tracking-wide border align-middle duration-500 text-base text-center hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md justify-center flex items-center"
                      >
                        Send Message
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      <Footer />
    </>
  );
}
