import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const addAddress = createAsyncThunk(
  "addressDetail/addAddress",
  async (formData, { getState, rejectWithValue }) => {
    const { token } = getState().auth; // Assuming you have a slice named 'auth' that stores the token
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        "https://movssy.com/admin/api/address_book/store",
        requestOptions
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to add address");
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchAddresses = createAsyncThunk(
  "addressDetail/fetchAddresses",
  async (pageNumber, { getState, rejectWithValue }) => {
    const { token } = getState().auth; // Assuming you have a slice named 'auth' that stores the token
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `https://movssy.com/admin/api/address_book?page=${pageNumber}`,
        requestOptions
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch addresses");
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleteAddresses = createAsyncThunk(
  "addressDetail/deleteAddresses",
  async (id, { getState, rejectWithValue }) => {
    const { token } = getState().auth; // Assuming you have a slice named 'auth' that stores the token
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `https://movssy.com/admin/api/address_book/${id}`,
        requestOptions
      );

      const data = await response.json();

      if (!response.ok) {
        toast(response.status || "Failed to fetch addresses");
      }

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateAddress = createAsyncThunk(
  "addressDetail/updateAddress",
  async ({ formData, id }, { getState, rejectWithValue }) => {
    const { token } = getState().auth; // Assuming you have a slice named 'auth' that stores the token
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `https://movssy.com/admin/api/address_book/update/${id}`,
        requestOptions
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to add address");
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const addressSlice = createSlice({
  name: "addressDetail",
  initialState: {
    data: [],
    totalPageNumer: 1,
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    addAddresss: (state, action) => {
      state.data.push(action.payload);
    },
    modifyAddress: (state, action) => {
      const index = state.data.findIndex(
        (address) => address.id === action.payload.id
      );
      state.data[index] = action.payload;
    },
    removeAddress: (state, action) => {
      const index = state.data.findIndex(
        (address) => address.id === action.payload.id
      );
      state.data.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAddresses.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAddresses.fulfilled, (state, action) => {
      if (action.payload.status === true) {
        state.data = action.payload.address_book.data;
        state.totalPageNumer = action.payload["Page Number"];
      }
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(fetchAddresses.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});
export const { addAddresss, modifyAddress, removeAddress } =
  addressSlice.actions;
export const addressReducer = addressSlice.reducer;
