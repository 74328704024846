import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import { ArrowRight } from "react-feather";
import Select from "react-select";
import HeaderBar from "../../component/HeaderBar";
import RightContainer from "../../component/OrdersRightContainer/RightContainer";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Modal from "../../component/Modal/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import BeardCrumbs from "../../component/BeardCrumbs";

const validationSchema = Yup.object().shape({
  pickupUserName: Yup.string().required("This field is required"),
  pickupStreetName: Yup.string().required("This field is required"),
  pickupPostalCode: Yup.string().required("This field is required"),
  pickupCity: Yup.string().required("This field is required"),
  pickupContactNo: Yup.string().required("This field is required"),
  deliveryUserName: Yup.string().required("This field is required"),
  deliveryStreetName: Yup.string().required("This field is required"),
  deliveryPostalCode: Yup.string().required("This field is required"),
  deliveryCity: Yup.string().required("This field is required"),
  deliveryContactNo: Yup.string().required("This field is required"),
  email: Yup.string().required("This field is required"),
});
export default function Addresses() {
  const location = useLocation();
  const orderId = location.state?.orderId;
  const amount = location.state?.amount;
  const deliveryCode = location.state?.deliveryCode;
  const pickupCode = location.state?.pickupCode;
  const [openModal, setOpenModal] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [shippingOption, setshippingOption] = useState("Regular");
  const [date, setDate] = useState(new Date());
  const [pickup, setPickUp] = useState("");
  const [isloading, setIsLoading] = useState(false);

  const [delivery, setDelivery] = useState("");
  const [pickupUserName, setPickupUserName] = useState("");
  const [deliveryUserName, setDeliveryUserName] = useState("");
  const [pickupStreetName, setPickupStreetName] = useState("");
  const [deliveryStreetName, setDeliveryStreetName] = useState("");
  const [deliveryPostalCode, setDeliveryPostalCode] = useState("");
  const [pickupPostalCode, setPickupPostalCode] = useState("");
  const [pickupCity, setPickupCity] = useState("");
  const [deliveryCity, setDeliveryCity] = useState("");
  const [deliveryContactNo, setDeliveryContactNo] = useState("");
  const [pickupContactNo, setPickupContactNo] = useState("");
  const [email, setEmail] = useState("");
  const [specialNote, setSpecialNote] = useState("");
  const goBack = () => {
    window.history.back();
  };
  const handleSpecialNote = (e) => {
    setSpecialNote(e.target.value);
  };
  const OrdersList = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/address_book", requestOptions)
      .then((response) => response.json())
      .then(({ status, message, all_user_address_book }) => {
        if (status === true) {
          if (all_user_address_book.length === 0) {
            setData(null);
            setLoading(false);
          } else {
            const mappedData = all_user_address_book.map((item) => ({
              value: item.id,
              label: item.full_name + " - " + item?.city,
              name: item.full_name,
              city: item?.city,
              post_code: item?.postal_code,
              street: item?.street,
              contactno: item?.contact,
            }));
            setData(mappedData);
            setLoading(false);
          }
        } else {
          toast.error(message);
          setData(null);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const handlePickUpChange = async (selectedOption) => {
    formik.setFieldValue("deliveryUserName", selectedOption?.name);
    formik.setFieldValue("deliveryStreetName", selectedOption?.street);
    formik.setFieldValue("deliveryPostalCode", selectedOption?.post_code);
    formik.setFieldValue("deliveryCity", selectedOption?.city);
    formik.setFieldValue("deliveryContactNo", selectedOption?.contactno);
    await setOpenModal(false);
  };
  const formik = useFormik({
    initialValues: {
      pickupUserName: "",
      pickupStreetName: "",
      pickupPostalCode: "",
      pickupCity: "",
      pickupContactNo: "",
      deliveryUserName: "",
      deliveryStreetName: "",
      deliveryPostalCode: "",
      deliveryCity: "",
      deliveryContactNo: "",
      email: "",
      specialNote: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("🚀 ~ Addresses ~ values:", values);
      setIsLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      var formdata = new FormData();
      formdata.append("pickup_user_full_name", values.pickupUserName);
      formdata.append("pickup_user_street", values.pickupStreetName);
      formdata.append("pickup_user_postal_code", values.pickupPostalCode);
      formdata.append("pickup_user_city", values.pickupCity);
      formdata.append("pickup_user_contact", values.pickupContactNo);
      formdata.append("delivery_user_full_name", values.deliveryUserName);
      formdata.append("delivery_user_street", values.deliveryStreetName);
      formdata.append("delivery_user_postal_code", values.deliveryPostalCode);
      formdata.append("delivery_user_city", values.deliveryCity);
      formdata.append("delivery_user_contact", values.deliveryContactNo);
      formdata.append("email", values.email);
      formdata.append("special_note", values.specialNote);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(
        `https://movssy.com/admin/api/pickup_delivery_order/${orderId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then(({ status, message, Order_Update }) => {
          if (status === true) {
            navigate(`/order-payment`, {
              state: {
                orderId: Order_Update?.id,
                amount: amount,
                pickupUserName: values.pickupUserName,
                pickupStreetName: values.pickupStreetName,
                pickupPostalCode: values.pickupPostalCode,
                pickupCity: values.pickupCity,
                pickupContactNo: values.pickupContactNo,
                deliveryUserName: values.deliveryUserName,
                deliveryStreetName: values.deliveryStreetName,
                deliveryPostalCode: values.deliveryPostalCode,
                deliveryCity: values.deliveryCity,
                deliveryContactNo: values.deliveryContactNo,
              },
            });
            toast.success("Order Successfully Created");
            setIsLoading(false);
          } else {
            toast.error(message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log("error", error);
          setIsLoading(false);
        });
      console.log("Form submitted with values:", values);
    },
  });

  useEffect(() => {
    OrdersList();
  }, [navigate]);
  return (
    <div>
      <HeaderBar title={"Pickup & Delivery"} />
      {openModal && (
        <Modal closeModal={setOpenModal}>
          {data === null ? (
            <div className="flex justify-center items-center my-auto">
              <h2 className="text-lg text-btnBackground font-semibold">
                No Data Found
              </h2>
            </div>
          ) : (
            <div
              className=" overflow-y-auto flex-grow flex-shrink-0"
              style={{ flexBasis: 0 }}
            >
              <div class="styles_content__Ctx_x">
                {data.map((item, index) => (
                  <div
                    key={index} // Add key prop for each element in map
                    onClick={() => handlePickUpChange(item)}
                    className={`bg-white dark:bg-slate-800 dark:border-gray-400 cursor-pointer border hover:shadow-lg hover:border-indigo-700 dark:hover:border-white  items-center mb-4 shadow px-4 py-4    `}
                  >
                    {item?.name && (
                      <p className="font-semibold text-xs">
                        Full Name: {item?.name}
                      </p>
                    )}
                    {item?.city && (
                      <p className="font-semibold text-xs">
                        City: {item?.city}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </Modal>
      )}
      <div className="container relative pb-0 mx-auto grid md:grid-cols-12 grid-cols-1 md:py-28">
        <div className="layout-specing md:col-span-8">
          <BeardCrumbs title={"Pickup & Delivery"} />
          <div className=" justify-between items-center ">
            <h1 className="text-2xl font-bold text-btnBackground">
              Pick-up & Delivery
            </h1>
          </div>
          <div className="grid grid-cols-1 mt-6 ">
            <div className="bg-white dark:bg-slate-800 shadow  items-center  py-4 px-4">
              <h2 className="text-xl font-bold text-btnBackground">
                {"Pick-up Address"}
              </h2>
              <div className="flex flex-col">
                <h2 className="text-lg text-btnBackground font-semibold mt2">
                  Full Name:
                </h2>
                <input
                  type="text"
                  className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                  name="pickupUserName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pickupUserName}
                  placeholder="Person or company"
                />
                {formik.touched.pickupUserName &&
                  formik.errors.pickupUserName && (
                    <p className="text-red-500 text-sm mt-1">
                      {formik.errors.pickupUserName}
                    </p>
                  )}
              </div>
              <div className="flex flex-col ">
                <h2 className="text-lg text-btnBackground font-semibold mt-2">
                  Street Name:
                </h2>
                <input
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pickupStreetName}
                  className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                  name="pickupStreetName"
                  placeholder="Street name and house number"
                />
                {formik.touched.pickupStreetName &&
                  formik.errors.pickupStreetName && (
                    <p className="text-red-500 text-sm mt-1">
                      {formik.errors.pickupStreetName}
                    </p>
                  )}
              </div>
              <div className=" md:flex justify-between mt-2 ">
                <div className="flex flex-col">
                  <label className="form-label text-btnBackground font-medium flex justify-between items-center">
                    Postcode:{" "}
                  </label>
                  <input
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.pickupPostalCode}
                    className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                    name="pickupPostalCode"
                    placeholder="1203"
                  />
                  {formik.touched.pickupPostalCode &&
                    formik.errors.pickupPostalCode && (
                      <p className="text-red-500 text-sm mt-1">
                        {formik.errors.pickupPostalCode}
                      </p>
                    )}
                </div>
                <div className="flex flex-col">
                  <label className="form-label text-btnBackground font-medium flex justify-between items-center">
                    Town / City:{" "}
                  </label>
                  <input
                    type="text"
                    className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                    name="pickupCity"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.pickupCity}
                    placeholder="Vienna"
                  />
                  {formik.touched.pickupCity && formik.errors.pickupCity && (
                    <p className="text-red-500 text-sm mt-1">
                      {formik.errors.pickupCity}
                    </p>
                  )}
                </div>
              </div>
              <h2 className="text-lg text-btnBackground font-semibold mt2">
                Phone Number:
              </h2>
              <input
                type="text"
                className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                name="pickupContactNo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pickupContactNo}
                placeholder="+92 315 57 69223 "
              />
              {formik.touched.pickupContactNo &&
                formik.errors.pickupContactNo && (
                  <p className="text-red-500 text-sm mt-1">
                    {formik.errors.pickupContactNo}
                  </p>
                )}
              <p className="text-xs mt-2">
                Courier service providers are not obliged to contact you by
                phone. In case they decide to do so, only local phone numbers
                will be contacted.
              </p>
            </div>

            <div className="bg-white dark:bg-slate-800 mt-6  items-center shadow py-4 px-4">
              <div className="md:flex justify-between">
                <h2 className="text-xl text-btnBackground font-bold">
                  {"Delivery Address"}
                </h2>
                <span
                  className="underline cursor-pointer"
                  onClick={() => setOpenModal(true)}
                >
                  Select Address From Book
                </span>
              </div>
              <div className="flex flex-col">
                <h2 className="text-lg font-semibold text-btnBackground mt2">
                  Full Name:
                </h2>
                <input
                  type="text"
                  className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                  name="deliveryUserName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.deliveryUserName}
                  placeholder="Person or company"
                />
                {formik.touched.deliveryUserName &&
                  formik.errors.deliveryUserName && (
                    <p className="text-red-500 text-sm mt-1">
                      {formik.errors.deliveryUserName}
                    </p>
                  )}
              </div>
              <div className="flex flex-col">
                <h2 className="text-lg text-btnBackground font-semibold mt-2">
                  Street Name:
                </h2>
                <input
                  type="text"
                  className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                  name="deliveryStreetName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.deliveryStreetName}
                  placeholder="Street name and house number"
                />
                {formik.touched.deliveryStreetName &&
                  formik.errors.deliveryStreetName && (
                    <p className="text-red-500 text-sm mt-1">
                      {formik.errors.deliveryStreetName}
                    </p>
                  )}
              </div>
              <div className=" md:flex justify-between mt-2 ">
                <div className="flex flex-col">
                  <label className="form-label text-btnBackground font-medium flex justify-between items-center">
                    Postcode:{" "}
                  </label>
                  <input
                    type="number"
                    className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                    name="deliveryPostalCode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.deliveryPostalCode}
                    placeholder="1028"
                  />
                  {formik.touched.deliveryPostalCode &&
                    formik.errors.deliveryPostalCode && (
                      <p className="text-red-500 text-sm mt-1">
                        {formik.errors.deliveryPostalCode}
                      </p>
                    )}
                </div>
                <div className="flex flex-col">
                  <label className="form-label text-btnBackground font-medium flex justify-between items-center">
                    Town / City:{" "}
                  </label>
                  <input
                    type="text"
                    className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                    name="deliveryCity"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.deliveryCity}
                    placeholder="Vienna"
                  />
                  {formik.touched.deliveryCity &&
                    formik.errors.deliveryCity && (
                      <p className="text-red-500 text-sm mt-1">
                        {formik.errors.deliveryCity}
                      </p>
                    )}
                </div>
              </div>
              <h2 className="text-lg text-btnBackground font-semibold mt2">
                Phone Number:
              </h2>
              <input
                type="text"
                className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                name="deliveryContactNo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.deliveryContactNo}
                placeholder="+92 315 57 69223 "
              />
              {formik.touched.deliveryContactNo &&
                formik.errors.deliveryContactNo && (
                  <p className="text-red-500 text-sm mt-1">
                    {formik.errors.deliveryContactNo}
                  </p>
                )}
              <p className="text-xs mt-2">
                Courier service providers are not obliged to contact you by
                phone. In case they decide to do so, only local phone numbers
                will be contacted.
              </p>
            </div>

            <div className="bg-white dark:bg-slate-800 mt-6 shadow-sm items-center  py-4 px-4">
              <h2 className="text-xl font-bold ">{"Delivery Address"}</h2>
              <div className="flex flex-col">
                <h2 className="text-lg font-semibold mt-2">
                  Special notes - optional:
                </h2>
                <textarea
                  id="myTextArea"
                  rows="4"
                  onChange={handleSpecialNote}
                  value={specialNote}
                  placeholder="Use the alley, call before you arrive, fragile shipment"
                  className="resize-none border rounded-md w-full py-2 px-3 text-gray-700 dark:border-gray-400 dark:bg-slate-900 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
            <div className="bg-white dark:bg-slate-800 mt-6 shadow  items-center  py-4 px-4">
              <h2 className="text-xl text-btnBackground font-bold ">
                {"Contact details"}
              </h2>
              <div className="flex flex-col">
                <h2 className="text-lg font-semibold mt-4 text-btnBackground">
                  Email:
                </h2>
                <input
                  type="email"
                  className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeholder="Email@gmail.com"
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="text-red-500 text-sm mt-1">
                    {formik.errors.email}
                  </p>
                )}
              </div>
            </div>
            <div class="shadow-md block md:hidden mt-4 bg-white rounded p-4">
              <h2 className="text-lg font-bold mt-4 text-btnBackground">
                Order summary
              </h2>
              <div>
                <div className=" flex mt-2  items-center ">
                  <p className="font-thin text-btnBackground">Pick-up</p>
                  <ArrowRight className="ml-2 h-7 w-5" />
                  <p className="font-thin ml-2 text-btnBackground">Delivery</p>
                </div>
                <div className=" flex mt-2  items-center ">
                  <p className="font-bold ">{pickupCode && pickupCode}</p>

                  <p className="font-bold  ml-[68px]">
                    {deliveryCode && deliveryCode}
                  </p>
                </div>
                <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
              </div>
              <div className=" flex mt-2  items-center ">
                <p className="font-thin text-btnBackground">Shipping Options</p>
              </div>
              <div className=" flex mt-1 justify-between items-center ">
                <p className="font-bold text-btnBackground">
                  {shippingOption && shippingOption}
                </p>

                <p className="font-bold ml-7 text-btnBackground">
                  {amount && amount.toFixed(2)}
                </p>
              </div>
              <div className=" flex mt-1 justify-between items-center ">
                <p className="font-thin text-btnBackground">1 x Package</p>

                <p className="font-thin ml-7">{"1Kg"}</p>
              </div>
              <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
              <div className="  mt-2  items-center ">
                <p className="font-thin text-btnBackground">Pick-up Date</p>
                <h2 className="font-bold text-xl text-btnBackground">
                  {date.toDateString()}
                </h2>
              </div>

              <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
              <div className="  mt-2 flex justify-between items-center ">
                <div>
                  <p className="font-bold text-2xl text-btnBackground">Total</p>
                  <p className="font-thin text-xs">(incl. VAT)</p>
                </div>
                <h2 className="font-bold text-2xl text-btnBackground ">
                  {amount && amount.toFixed(2)}
                </h2>
              </div>
              <div className="mt-4">
                <input
                  onClick={formik.handleSubmit}
                  disabled={isloading}
                  type="submit"
                  id="search-buy"
                  name="search"
                  className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
                  value="Next Step"
                />
              </div>
            </div>
            <Link to="/order-details">
              <div className="mt-4">
                <input
                  type="submit"
                  onClick={goBack}
                  id="search-buy"
                  name="search"
                  className="btn px-3 bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn !h-12 rounded"
                  value="Back"
                />
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* <div className="bg-white  shadow-sm overflow-y-auto w-72 fixed top-0  px-4 right-0 justify-start">
          <div className="mt-14">
            <h2 className="text-lg font-bold ">Order summary</h2>
            <div>
              <div className=" flex mt-2  items-center ">
                <p className="font-thin ">Pick-up</p>
                <ArrowRight className="ml-2 h-7 w-5" />
                <p className="font-thin ml-2">Delivery</p>
              </div>
              <div className=" flex mt-2  items-center ">
                <p className="font-bold ">{pickup && pickup.value}</p>

                <p className="font-bold ml-7">{delivery && delivery.value}</p>
              </div>
              <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
            </div>
            <div className=" flex mt-2  items-center ">
              <p className="font-thin ">Shipping Options</p>
            </div>
            <div className=" flex mt-1 justify-between items-center ">
              <p className="font-bold ">{shippingOption && shippingOption}</p>

              <p className="font-bold ml-7">
                {shippingOption === "Regular" ? "$287" : "387"}
              </p>
            </div>
            <div className=" flex mt-1 justify-between items-center ">
              <p className="font-thin ">1 x Pacakge</p>

              <p className="font-thin ml-7">{"1Kg"}</p>
            </div>
            <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
            <div className="  mt-2  items-center ">
              <p className="font-thin ">Pick-up Date</p>
              <h2 className="font-bold text-xl ">{date.toDateString()}</h2>
            </div>

            <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
            <div className="  mt-2 flex justify-between items-center ">
              <div>
                <p className="font-bold text-2xl ">Total</p>
                <p className="font-thin text-xs">(incl. VAT)</p>
              </div>
              <h2 className="font-bold text-2xl text-indigo-700 ">
                {shippingOption === "Regular" ? "$287" : "387"}
              </h2>
            </div>
            <Link to="/order-safety">
              <div className="mt-4">
                <input
                  type="submit"
                  id="search-buy"
                  name="search"
                  className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
                  value="Next Step"
                />
              </div>
            </Link>
          </div>
        </div> */}
      <RightContainer>
        <div
          className=" overflow-y-auto flex-grow flex-shrink-0"
          style={{ flexBasis: 0 }}
        >
          <div class="styles_content__Ctx_x">
            <h2 className="text-lg font-bold mt-4 text-btnBackground">
              Order summary
            </h2>
            <div>
              <div className=" flex mt-2  items-center ">
                <p className="font-thin text-btnBackground">Pick-up</p>
                <ArrowRight className="ml-2 h-7 w-5" />
                <p className="font-thin ml-2 text-btnBackground">Delivery</p>
              </div>
              <div className=" flex mt-2  items-center ">
                <p className="font-bold ">{pickupCode && pickupCode}</p>

                <p className="font-bold  ml-[68px]">
                  {deliveryCode && deliveryCode}
                </p>
              </div>
              <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
            </div>
            <div className=" flex mt-2  items-center ">
              <p className="font-thin text-btnBackground">Shipping Options</p>
            </div>
            <div className=" flex mt-1 justify-between items-center ">
              <p className="font-bold text-btnBackground">
                {shippingOption && shippingOption}
              </p>

              <p className="font-bold ml-7 text-btnBackground">
                {amount && amount.toFixed(2)}
              </p>
            </div>
            <div className=" flex mt-1 justify-between items-center ">
              <p className="font-thin text-btnBackground">1 x Package</p>

              <p className="font-thin ml-7">{"1Kg"}</p>
            </div>
            <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
            <div className="  mt-2  items-center ">
              <p className="font-thin text-btnBackground">Pick-up Date</p>
              <h2 className="font-bold text-xl text-btnBackground">
                {date.toDateString()}
              </h2>
            </div>

            <div className="py-2  border-b-[1px]  border-gray-300 w-full"></div>
            <div className="  mt-2 flex justify-between items-center ">
              <div>
                <p className="font-bold text-2xl text-btnBackground">Total</p>
                <p className="font-thin text-xs">(incl. VAT)</p>
              </div>
              <h2 className="font-bold text-2xl text-btnBackground ">
                {amount && amount.toFixed(2)}
              </h2>
            </div>
          </div>
        </div>
        <div
          style={{ flexBasis: "0%", flexShrink: 1, flexGrow: 0 }}
          className="h-auto py-8 "
        >
          {/* <Link to="/order-safety"> */}
          <div className="mt-4">
            <input
              onClick={formik.handleSubmit}
              disabled={isloading}
              type="submit"
              id="search-buy"
              name="search"
              className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
              value="Next Step"
            />
          </div>
        </div>
      </RightContainer>
    </div>
  );
}
