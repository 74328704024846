import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function ProtectiveRoute({ children }) {
  const getUser = () => {
    const userData = sessionStorage.getItem("User");
    try {
      const parsedUser = JSON.parse(userData);
      if (parsedUser && parsedUser.token) {
        return parsedUser;
      }
    } catch (error) {
      console.error("Error parsing user data:", error);
    }
    return null;
  };
  const [user, setUser] = useState(getUser());
  console.log("🚀 ~ ProtectiveRoute ~ user:", user);
  if (user?.token === undefined || user?.token === null ) {
    return <Navigate to={"/login"} />;
  }
  return children;
}

export default ProtectiveRoute;
