import React from "react";
function Button(props) {
  return (
    <button
      type="submit"
      className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.title}
    </button>
  );
}

export default Button;
