import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useState } from "react";
import { toast } from "react-toastify";
import { changeLanguagei18 } from "../utility/changeLanguagei18N";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function NewOrder() {
  const [options, setOptions] = useState([]);
  const [pickupValue, setPickupValue] = useState([]);
  const [deliveryValue, setDeliveryValue] = useState([]);
  const [serviceValue, setServiceValue] = useState(null);
  const navigate = useNavigate();
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  const { t } = useTranslation();

  const servicesData = [
    { value: "Document", label: "Document" },
    { value: "Package", label: "Package" },
    { value: "Pallet", label: "Pallet" },
    { value: "Van Delivery", label: "Van Delivery" },
    { value: "FTL & LTL", label: "FTL & LTL" },
    { value: "CustomOffer", label: "CustomOffer" },
    { value: "Sea Frieght", label: "Sea Frieght (on request)" },
    { value: "Air Frieght", label: "Air Frieght (on request)" },
    {
      value: "Relocation & removals",
      label: "Relocation & removals (on request)",
    },
  ];
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
    changeLanguagei18(languageCode);
  }, []);
  const getCountry = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/country", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.countries && result.countries.length > 0) {
          const countries = result?.countries.map((country) => ({
            value: country.country_code,
            label: country.country_name,
            id: country.id,
            city: country.city,
            zipCode: country.zip_code,
          }));
          setOptions(countries);
        }
      })
      .catch((error) => console.error(error));
  };
  const handleNavigate = (event) => {
    event.preventDefault();
    if (serviceValue !== null) {
      if (
        serviceValue.value === "Sea Frieght" ||
        serviceValue.value === "Air Frieght" ||
        serviceValue.value === "Removal & relocation"
      ) {
        navigate("/relocation&removal", {
          state: {
            title: serviceValue.value,
            pickupValue: pickupValue,
            deliveryValue: deliveryValue,
          },
        });
      } else {
        navigate("/order-details", {
          state: {
            pickupValue: pickupValue,
            deliveryValue: deliveryValue,
          },
        });
      }
    } else {
      toast.error("Select Service..");
    }
  };
  React.useEffect(() => {
    getCountry();
  }, [navigate]);
  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="md:flex justify-between items-center">
          <h5 className="text-lg font-semibold text-btnBackground">
            {t("New_Order")}
          </h5>
        </div>

        <form
          onSubmit={handleNavigate}
          className="p-6 bg-white dark:bg-slate-900 rounded-xl shadow-md dark:shadow-gray-700 mt-3"
        >
          <div className="registration-form text-dark text-start">
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
              <div>
                <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                  {/* <LuSearch className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                  <Select
                    className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                    options={options}
                    placeholder={t("From")}
                    value={pickupValue}
                    required
                    onChange={(option) => setPickupValue(option)}
                  />
                </div>
              </div>

              <div>
                <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                  {/* <AiOutlineHome className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                  <Select
                    className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                    options={options}
                    required
                    placeholder={t("To")}
                    value={deliveryValue}
                    onChange={(option) => setDeliveryValue(option)}
                  />
                </div>
              </div>

              <div>
                <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                  {/* <AiOutlineDollar className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                  <Select
                    className="form-input  bg-gray-50 dark:bg-slate-800 border-0"
                    options={servicesData}
                    placeholder={t("Service")}
                    value={serviceValue}
                    required
                    onChange={(option) => setServiceValue(option)}
                  />
                </div>
              </div>

              <div className="">
                <input
                  type="submit"
                  id="search-buy"
                  name="search"
                  className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
                  value={t("Start_Shipping")}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
