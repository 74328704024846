import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import client from "../../assets/images/client/05.jpg";
import {
  AiOutlineDashboard,
  TbNotebook,
  AiOutlineCreditCard,
  FaRecycle,
  LuBellRing,
  BiCog,
  CgLogOff,
} from "../../assets/icons/icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeLanguagei18 } from "../../pages/utility/changeLanguagei18N";

export default function UserProfileTab() {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  let [uploadFile, setUpoadFile] = useState(client);
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  const { t } = useTranslation();
  function handleChange(event) {
    if (event.target.files && event.target.files.length !== 0) {
      setUpoadFile(URL.createObjectURL(event.target.files[0]));
    }
  }
  React.useEffect(() => {
    changeLanguagei18(languageCode);
  }, []);
  return (
    <>
      <div className="xl:col-span-3 lg:col-span-4 md:col-span-4 mx-6">
        <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 ">
          <div className="profile-pic text-center mb-5">
            <input
              id="pro-img"
              name="profile-image"
              type="file"
              className="hidden"
              onChange={(e) => handleChange(e)}
            />
            <div>
              <div className="mt-4">
                <h5 className="text-lg font-semibold text-btnBackground">
                  {user && user?.purpose === "personal"
                    ? user?.first_name
                    : user?.company_name}
                </h5>
                <p className="text-slate-400">{user && user?.email}</p>
              </div>
            </div>
          </div>

          <div className="border-t border-gray-100 dark:border-gray-700">
            <ul className="list-none sidebar-nav mb-0 mt-3" id="navmenu-nav">
              <li className="navbar-item account-menu">
                <Link
                  to="/user-dashboard/my-details"
                  className={`${
                    location.pathname === "/user-dashboard/my-details"
                      ? "text-btnBackground dark:text-white"
                      : "text-slate-400"
                  } navbar-link  flex items-center py-2 rounded hover:text-btnBackground`}
                >
                  <span className="me-2 text-[18px] mb-0">
                    <AiOutlineDashboard />
                  </span>
                  <h6 className="mb-0 font-semibold">{t("My_Details")}</h6>
                </Link>
              </li>

              <li className="navbar-item account-menu">
                <Link
                  to="/user-dashboard/change-password"
                  className={`${
                    location.pathname === "/user-dashboard/change-password"
                      ? "text-btnBackground dark:text-white"
                      : "text-slate-400"
                  } navbar-link  flex items-center py-2 rounded hover:text-btnBackground`}
                >
                  <span className="me-2 text-[18px] mb-0">
                    <FaRecycle />
                  </span>
                  <h6 className="mb-0 font-semibold">{t("Change_Password")}</h6>
                </Link>
              </li>

              {/* <li className="navbar-item account-menu">
                <Link
                  to="/profile-notification"
                  className={`${
                    location.pathname === "/profile-notification"
                      ? "text-indigo-600 dark:text-white"
                      : "text-slate-400"
                  } navbar-link  flex items-center py-2 rounded hover:text-indigo-600`}
                >
                  <span className="me-2 text-[18px] mb-0">
                    <LuBellRing />
                  </span>
                  <h6 className="mb-0 font-semibold">Notifications</h6>
                </Link>
              </li> */}

              <li className="navbar-item account-menu">
                <Link
                  to="/user-dashboard/profile-setting"
                  className={`${
                    location.pathname === "/user-dashboard/profile-setting"
                      ? "text-btnBackground dark:text-white"
                      : "text-slate-400"
                  } navbar-link  flex items-center py-2 rounded hover:text-btnBackground`}
                >
                  <span className="me-2 text-[18px] mb-0">
                    <BiCog />
                  </span>
                  <h6 className="mb-0 font-semibold">{t("Settings")}</h6>
                </Link>
              </li>

              {/* <li className="navbar-item account-menu">
                <Link
                  to="/auth-lock-screen"
                  className={`${
                    location.pathname === "/auth-lock-screen"
                      ? "text-indigo-600 dark:text-white"
                      : "text-slate-400"
                  } navbar-link  flex items-center py-2 rounded hover:text-indigo-600`}
                >
                  <span className="me-2 text-[18px] mb-0">
                    <CgLogOff />
                  </span>
                  <h6 className="mb-0 font-semibold">Sign Out</h6>
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
