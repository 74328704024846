import React from "react";
import logo_dark from "../../assets/images/logo-dark.png";
import logo_light from "../../assets/images/logo-light.png";
import { AiOutlineLineChart, IoMdLogOut } from "../../assets/icons/icons";
import "../../assets/libs/@mdi/font/css/materialdesignicons.min.css";
import "../../assets/css/tailwind.css";
import { useState, useEffect } from "react";
import { Settings, ShoppingCart } from "feather-icons-react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { revoke } from "../../store/slices/authSlice";
import { revokePath } from "../../store/slices/pathSlice";
import { toast } from "react-toastify";
import { revokeWallet } from "../../store/slices/walletSlice";
import { getUser } from "../../pages/utility/getUser";

export default function Navbar(props) {
  const { navClass, navJustify } = props;
  window.addEventListener("scroll", windowScroll);
  let [userModal, setUser] = useState(false);
  // const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const token = useSelector((state) => state.auth.token);
  const [isMenu, setisMenu] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  // const getUser = () => {
  //   let user = localStorage.getItem("User");
  //   if (user) {
  //     user = JSON.parse(user);
  //   } else {
  //     user = null;
  //   }
  //   return user;
  // };

  const [user, setUserData] = useState(getUser());
  const dispatch = useDispatch();
  const toggleMenu = () => {
    setisMenu(!isMenu);
    if (document.getElementById("navigation")) {
      const anchorArray = Array.from(
        document.getElementById("navigation").getElementsByTagName("a")
      );
      anchorArray.forEach((element) => {
        element.addEventListener("click", (elem) => {
          const target = elem.target.getAttribute("href");
          if (target !== "") {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle("open");
            }
          }
        });
      });
    }
  };

  useEffect(() => {
    activateMenu();
    window.scrollTo(0, 0);
  }, []);
  const Logout = () => {
    setIsloading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch("https://movssy.com/admin/api/logout", requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          if (response.status === 401) {
            await dispatch(revoke());
            await dispatch(revokePath());
            await dispatch(revokeWallet());
            await sessionStorage.removeItem("User");
            await localStorage.removeItem("User");
            navigate("/login");
          } else {
            toast.success(`Http error ${response.status}`);
          }
        }
        return response.json();
      })
      .then(async (result) => {
        if (result?.message === "User Logout Successfully") {
          await sessionStorage.removeItem("User");
          await localStorage.removeItem("User");

          navigate("/login");
          toast.success("Logout Successfully");
          await dispatch(revoke());
          await dispatch(revokePath());
          setIsloading(false);
        } else {
          toast.error(result.message);
          setIsloading(false);
        }
      })
      .catch((error) => console.log("error", error));
  };
  function windowScroll() {
    const navbar = document.getElementById("topnav");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      if (navbar !== null) {
        navbar?.classList.add("nav-sticky");
      }
    } else {
      if (navbar !== null) {
        navbar?.classList.remove("nav-sticky");
      }
    }

    const mybutton = document.getElementById("back-to-top");
    if (mybutton != null) {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        mybutton.classList.add("flex");
        mybutton.classList.remove("hidden");
      } else {
        mybutton.classList.add("hidden");
        mybutton.classList.remove("flex");
      }
    }
  }

  const activateMenu = () => {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }
      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");

        var immediateParent = getClosest(matchingMenuItem, "li");

        if (immediateParent) {
          immediateParent.classList.add("active");
        }

        var parent = getClosest(immediateParent, ".child-menu-item");
        if (parent) {
          parent.classList.add("active");
        }

        var parent = getClosest(parent || immediateParent, ".parent-menu-item");

        if (parent) {
          parent.classList.add("active");

          var parentMenuitem = parent.querySelector(".menu-item");
          if (parentMenuitem) {
            parentMenuitem.classList.add("active");
          }

          var parentOfParent = getClosest(parent, ".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        } else {
          var parentOfParent = getClosest(
            matchingMenuItem,
            ".parent-parent-menu-item"
          );
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        }
      }
    }
  };

  const getClosest = (elem, selector) => {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(
              s
            ),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  };

  var navbarHtml = (
    <>
      <nav
        id="topnav"
        className={`defaultscroll ${
          navClass === "nav-light"
            ? ""
            : navClass === "nav-sticky"
            ? "bg-white dark:bg-slate-900"
            : ""
        }`}
      >
        <div className="container relative">
          {navClass === "nav-light" ? (
            <Link className="logo" to="/index">
              <span className="inline-block  text-black dark:text-white">
                Movssy
              </span>
            </Link>
          ) : (
            <Link className="logo" to="/index">
              <span>Movssy</span>
            </Link>
          )}

          <div className="menu-extras">
            <div className="menu-item">
              <Link
                to="#"
                className={`navbar-toggle ${isMenu ? "open" : ""}`}
                id="isToggle"
                onClick={() => toggleMenu()}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>

          {navClass !== "nav-light" ? (
            <ul className={`buy-button list-none space-x-1 mb-0`}>
              {!user && user?.token === undefined ? (
                <>
                  <li className="inline mb-0">
                    <Link
                      to="/login"
                      className="p-2 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded bg-btnBackground/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white"
                    >
                      Sign In
                    </Link>
                  </li>

                  <li className="inline ps-1 mb-0">
                    <Link
                      to="/auth-signup"
                      target="_blank"
                      className="p-2 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded bg-btnBackground hover:bg-btnBackground border border-indigo-600 hover:border-indigo-700 text-white"
                    >
                      Sign Up
                    </Link>
                  </li>
                </>
              ) : (
                <li className="inline mb-0">
                  <span className="p-2 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white">
                    {user?.User?.purpose === "business"
                      ? user?.User?.company_name && user?.User?.company_name
                      : user?.User?.first_name && user?.User?.first_name}
                  </span>
                </li>
              )}
            </ul>
          ) : (
            <ul className="buy-button list-none space-x-1 mb-0">
              {!user && user?.token === undefined ? (
                <>
                  <li className="inline mb-0">
                    <Link to="/login">
                      <span className="login-btn-primary">
                        <span className="p-2 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded bg-btnBackground/5 hover:bg-indigo-600 border border-btnBackground/10 hover:border-indigo-600 text-btnBackground hover:text-white">
                          Sign In
                        </span>
                      </span>
                      <span className="login-btn-light">
                        <span className="p-2 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 border hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                          Sign In
                        </span>
                      </span>
                    </Link>
                  </li>

                  <li className="inline ps-1 mb-0">
                    <Link to="/register" target="_blank">
                      <div className="login-btn-primary">
                        <span className="p-2 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded bg-btnBackground hover:bg-indigo-700 border border-btnBackground hover:border-indigo-700 text-white">
                          {/* <ShoppingCart className="h-4 w-4" /> */}
                          Sign Up
                        </span>
                      </div>
                      <div className="login-btn-light">
                        <span className="p-2 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 border hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                          {/* <ShoppingCart className="h-4 w-4" />
                           */}
                          Sign Up
                        </span>
                      </div>
                    </Link>
                  </li>
                </>
              ) : (
                <li className="inline mb-0">
                  <span
                    onClick={() => setUser(!userModal)}
                    className="dropdown-toggle items-center cursor-pointer"
                    type="button"
                  >
                    <span className="p-2 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 border hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                      {user?.User?.purpose === "business"
                        ? user?.User?.company_name && user?.User?.company_name
                        : user?.User?.first_name && user?.User?.first_name}
                    </span>
                  </span>
                  <div
                    className={`dropdown-menu absolute end-0 m-0 mr-11  z-10 w-44 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 ${
                      userModal ? "" : "hidden"
                    }`}
                  >
                    <ul className="py-2 text-start">
                      <li>
                        <Link
                          to="/user-dashboard"
                          className="flex items-center font-medium py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"
                        >
                          <AiOutlineLineChart className="me-2" />
                          Dashboard
                        </Link>
                      </li>

                      <li>
                        <button
                          disabled={isLoading}
                          onClick={() => Logout()}
                          className="flex cursor-pointer items-center font-medium py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"
                        >
                          <IoMdLogOut className="me-2" />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              )}
            </ul>
          )}

          <div id="navigation" style={{ display: isMenu ? "block" : "none" }}>
            <ul className={`navigation-menu ${navClass} ${navJustify}`}>
              <li>
                <Link to="/index" className="sub-menu-item">
                  Home
                </Link>
              </li>

              <li className="has-submenu parent-parent-menu-item">
                <Link to="#!">Services</Link>
                <span className="menu-arrow"></span>
                <ul className="submenu">
                  <li className="has-submenu parent-menu-item">
                    <Link to="/document"> Document Shipping </Link>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Link to="/pacakge"> Package Shipping</Link>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Link to="/van-delivery"> Van Delivery</Link>
                  </li>

                  <li className="has-submenu parent-menu-item">
                    <Link to="/frieght-transport"> Frieght Transport </Link>
                  </li>

                  <li>
                    <Link to="/pallete" className="sub-menu-item">
                      Pallet Delivery{" "}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/aboutus" className="sub-menu-item">
                  About
                </Link>
              </li>
              <li>
                <Link to="/helpcenter-overview" className="sub-menu-item">
                  Help
                </Link>
              </li>

              <li>
                <Link to="/contact-us" className="sub-menu-item">
                  Contact Us{" "}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
  return <div>{navbarHtml}</div>;
}
