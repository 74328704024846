import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import logo_icon_64 from "../../assets/images/logo-icon-64.png";

export default function OtpVerificationScreen() {
  const [otp, setOtp] = useState("");

  const handleVerify = () => {
    // Send 'otp' to your server for validation
    // If validation is successful, proceed; otherwise, show an error message
    console.log("OTP is ", otp);
  };
  return (
    <>
      <section className="md:h-screen py-36 flex items-center bg-[url('../../assets/images/cta.jpg')] bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
        <div className="container relative">
          <div className="flex justify-center">
            <div className="max-w-[400px] w-full m-auto p-6 bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
              <Link to="/index">
                <img
                  src={logo_icon_64}
                  className="w-auto h-20 mx-auto "
                  alt=""
                />
              </Link>
              <h5 className="my-6 text-xl font-semibold">Otp Verification</h5>
              <div className="grid grid-cols-1">
                <p className="text-slate-400 ">
                  Lorem Ipsum Text Like enter your 4 digit code here.
                </p>
                <form className="text-start">
                  <div className="grid grid-cols-1">
                    <div className="">
                      <label className="font-semibold" htmlFor="LoginEmail">
                        Email Address:
                      </label>
                    </div>
                    <div class="flex py-2 items-center justify-center">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span> </span>}
                        inputType="tel"
                        containerStyle={{ display: "unset" }}
                        inputStyle={{ width: "2rem", height: "3rem" }}
                        renderInput={(props, i) => (
                          <input
                            {...props}
                            className={`text-lg border-2 border-gray-300    gap-x-1 px-2 py-1`}
                          />
                        )}
                      />
                    </div>
                    <Link to={"/index"}>
                      <div className="mb-4">
                        <input
                          type="submit"
                          className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full"
                          value="Submit"
                        />
                      </div>
                    </Link>
                    <div className="text-center">
                      <span className="text-slate-400 me-2">
                        Remember your password ?{" "}
                      </span>
                      <Link
                        to="/auth-login"
                        className="text-black dark:text-white font-bold inline-block"
                      >
                        Sign in
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
