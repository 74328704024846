import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Icon from "react-feather";
import { changeLanguagei18 } from "../utility/changeLanguagei18N";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export default function Chats() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [message, setMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [messages, setMessages] = React.useState([]);
  const [isloading, setLoading] = React.useState(false);
  const [ticketData, setTicketData] = React.useState("");
  const token = useSelector((state) => state.auth.token);
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  const handleRemoveFile = () => {
    setSelectedFile(null);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
    changeLanguagei18(languageCode);
  }, []);
  const showMessages = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,

      redirect: "follow",
    };

    fetch(`https://movssy.com/admin/api/ticket/show/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        if (response.staus === true) {
          if (response["User Tickets"]?.ticket_message) {
            setMessages(response["User Tickets"]?.ticket_message);
          } else {
            setMessages([]);
          }
          setTicketData(response["User Tickets"]);
          setLoading(false);
        }
      })
      .catch((error) => console.error(error));
  };
  const SendMessage = (event) => {
    event.preventDefault();

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append("file_upload", selectedFile);
    formdata.append("message", message);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `https://movssy.com/admin/api/ticket/send_message/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then(({ status, message }) => {
        if (status === true) {
          toast.success("Message Sent Successfully");
          setMessage("");
          setSelectedFile(null);
          showMessages();
          message("");
        } else {
          toast.error(message);
        }
      })
      .catch((error) => console.error(error));
  };
  const TicketClose = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://movssy.com/admin/api/ticket/${id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        if (res.status === true) {
          toast.success(res.message);

          navigate("/user-dashboard/ticket");
        }
      })
      .catch((error) => console.error(error));
  };
  const handleClose = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Close it!",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        TicketClose();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Thankyou!");
      }
    });
  };
  React.useEffect(() => {
    showMessages();
  }, [navigate]);
  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="md:flex justify-between items-center">
          <h5 className="text-lg font-semibold  underline">
            {ticketData?.subject === null ? "N/A" : ticketData?.subject}
          </h5>
          {/* <ul className="tracking-[0.5px] inline-flex items-center sm:mt-0 mt-3">
            <li className="inline-block capitalize text-[14px] font-bold duration-500 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white">
              <Link to="/index">Techwind</Link>
            </li>
            <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <MdKeyboardArrowRight />
            </li>
            <li
              className="inline-block capitalize text-[14px] font-bold text-indigo-600 dark:text-white"
              aria-current="page"
            >
              Order List
            </li>
          </ul> */}
        </div>

        {/* <div className="grid grid-cols-1 mt-6">
          <Chat />
        </div> */}
        <div className="grid md:grid-cols-12 grid-cols-1 mt-2 gap-6">
          <div className="lg:col-span-12 md:col-span-12">
            <div className="p-6 rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 mt-6">
              {isloading === true ? (
                <div>Loading....</div>
              ) : (
                <React.Fragment>
                  {messages.length === 0 ? (
                    <div>No Record Found</div>
                  ) : (
                    <React.Fragment>
                      <h5 className="text-lg font-semibold">Messages:</h5>
                      {messages.map((item, index) => {
                        return (
                          <div className="mt-6" key={index}>
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <div className="ms-3 flex-1">
                                  <Link
                                    to=""
                                    className="text-lg font-semibold hover:text-indigo-600 duration-500"
                                  >
                                    {item.sender?.first_name === null
                                      ? "N/A"
                                      : item.sender?.first_name}
                                  </Link>
                                  <p className="text-sm text-slate-400">
                                    {item?.created_at === null
                                      ? "N/A"
                                      : item?.created_at.split("T")[0]}
                                  </p>
                                </div>
                              </div>

                              {/* <Link
                                to=""
                                className="text-slate-400 hover:text-indigo-600 duration-500 ms-5"
                              >
                                <i className="mdi mdi-reply"></i> Reply
                              </Link> */}
                            </div>
                            <div className="p-4 bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-700 mt-6">
                              <p className="text-slate-400 italic">
                                {item?.message === null ? "N/A" : item?.message}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>

            {ticketData?.status && ticketData?.status === "open" ? (
              <div className="p-6 rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 mt-6">
                <div className="flex  justify-between items-center">
                  <h5 className="text-lg font-semibold">Leave A Reply:</h5>
                  <button
                    onClick={handleClose}
                    className="py-2 px-4 inline-block font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md"
                  >
                    Close Ticket
                  </button>
                </div>

                <form className="mt-6">
                  <div className="grid grid-cols-1">
                    <div className="mb-5">
                      <div className="text-start">
                        <div className="form-icon relative mt-2">
                          <Icon.MessageCircle className="w-4 h-4 absolute top-3 start-4" />
                          <textarea
                            name="message"
                            onChange={handleMessage}
                            value={message}
                            id="message"
                            className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="Message :"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div>
                      <label className="form-label font-medium">
                        Select File
                      </label>
                      <div className="mt-2 justify-center items-center flex border border-dashed py-10 rounded">
                        {selectedFile ? (
                          <>
                            <span>{selectedFile.name}</span>
                            <button
                              onClick={handleRemoveFile}
                              className="ml-2 text-red-500"
                            >
                              &times;
                            </button>
                          </>
                        ) : (
                          <input
                            id="file"
                            type="file"
                            required
                            onChange={handleFileChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <input
                    type="submit"
                    value={"Send"}
                    onClick={SendMessage}
                    className="py-2 px-5   inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5"
                  />
                </form>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
