import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  path: "",
  orderingAs: "",
};

const pathSlice = createSlice({
  name: "path",
  initialState,
  reducers: {
    checkPath: (state, action) => {
      state.path = action.payload.path;
      state.orderingAs = action.payload.orderingAs;
      // state.pickup = action.payload.pickup;
      // state.delivery = action.payload.delivery;
      // state.service = action.payload.service;
    },
    revokePath: (state) => {
      state.path = "";
      state.orderingAs = "";
    },
  },
});

export { pathSlice };
export const { checkPath, revokePath } = pathSlice.actions;
export const pathReducer = pathSlice.reducer;
