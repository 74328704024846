import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import UserProfileTab from "../../component/dashboard/userProfileTab";
import { useTranslation } from "react-i18next";
import { changeLanguagei18 } from "../utility/changeLanguagei18N";
import { useSelector } from "react-redux";
export default function ProfileSetting() {
  const [isToggled, setToggled] = useState(false);
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  const { t } = useTranslation();
  const handleToggle = () => {
    setToggled(!isToggled);
  };
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
  }, []);
  React.useEffect(() => {
    changeLanguagei18(languageCode);
  }, []);
  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="grid grid-cols-1">
          <div className="profile-banner relative text-transparent rounded-md shadow dark:shadow-gray-700 overflow-hidden">
            <input
              id="pro-banner"
              name="profile-banner"
              type="file"
              className="hidden"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1">
          <UserProfileTab />
          <div className="xl:col-span-9 lg:col-span-8 md:col-span-8 ">
            <div className="grid grid-cols-1 gap-6">
              <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <h5 className="text-lg font-semibold mb-4 text-btnBackground">
                  {t("Newsletter")}
                </h5>

                <p className="text-slate-400 mb-4">{t("Newsletter_desc")}</p>
              </div>
              <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <h5 className="text-lg font-semibold mb-4 text-btnBackground">
                  {t("Courier_exclusions")}
                </h5>
                <p className="text-slate-400 mb-4">
                  {t("Courier_exclusions_desc")}
                </p>
              </div>
              <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <h5 className="text-lg font-semibold mb-4 text-btnBackground">
                  {t("Download_all_data")}
                </h5>

                <Link
                  to="#"
                  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md"
                >
                  {t("Download")}
                </Link>
              </div>
              <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <h5 className="text-lg font-semibold mb-4 text-btnBackground">
                  {t("Suspend_account")}
                </h5>

                <p className="text-slate-400 mb-4">{t("Suspending_account")}</p>

                <Link
                  to="#"
                  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md"
                >
                  {t("Suspend_account")}
                </Link>
              </div>
              <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <h5 className="text-lg font-semibold mb-4 text-red-600">
                  {t("Delete_account_permanently")}
                </h5>

                <p className="text-slate-400 mb-4">
                  {t("Deleting_an_account_desc")}
                </p>

                <Link
                  to="#"
                  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md"
                >
                  {t("Delete_Account")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
