import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import QRIMAGE from "../../assets/images/qrimage.jpg";
import BARCODEIMG from "../../assets/images/barcode.jpg";
import GIFT from "../../assets/images/Capture.PNG";
import { FaStar } from "react-icons/fa";
import { hover } from "@testing-library/user-event/dist/hover";

export default function ViewSingleUserOrder() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [modal, setModal] = useState(false);
  const [disableReview, setDisableReview] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const [selectedValue, setSelectedValue] = useState("");
  const [message, setMessage] = useState("");
  const handleGeneratePDF = async () => {
    const date = new Date();

    const html = getProdReportHTML();
    const element = document.createElement("div");
    element.innerHTML = html;
    var opt = {
      margin: 0.2,
      filename: `Order-Label-${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}.pdf`,

      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "A4", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).toPdf().save();
  };
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };
  const ReviewApi = (event) => {
    event.preventDefault();
    setDisableReview(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append("message", message);
    formdata.append("rating", rating);
    formdata.append("order_id", id);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `https://movssy.com/admin/api/order/review/store/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        if (res?.status === true) {
          toast.success("Review submit successfully!");
          setModal(false);
          setDisableReview(false);
        } else {
          toast.error(res?.message);
          setDisableReview(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setDisableReview(false);
      });
  };
  const getProdReportHTML = () => {
    let html = `
    <html>
    <head>
        <style>
        body{
          font-size:14px;
        }
            .container {
                margin-right: auto;
                margin-left: auto;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
    
            .border-black {
                border-color: #000;
            }
    
            .px-4 {
                padding-left: 1rem;
                padding-right: 1rem;
            }
    
            .border-b-2 {
                border-bottom-width: 2px;
            }
    
            .flex {
                display: flex;
            }
    
            .justify-between {
                justify-content: space-between;
            }
    
            .items-center {
                align-items: center;
            }
    
            .fontsemibold {
                font-weight: 600;
            }
    
            .fontMedium {
                font-weight: 500;
            }
    
            .fontBold {
                font-weight: 700;
            }
    
            .flex-col {
                flex-direction: column;
            }
    
            .mr-4 {
                margin-right: 1rem;
            }
    
            .text-2xl {
                font-size: 1.5rem;
            }
            .margin-bottom {
              margin-bottom: 0.5rem
            }

            .text-xl {
                font-size: 1.25rem;
            }
    
            .mb-6 {
                margin-bottom: 1.5rem;
            }
    
            .ml-11 {
                margin-left: 2.75rem;
            }
    
            .h-36 {
                height: 9rem;
            }
    
            .w-auto {
                width: auto;
            }
    
            .h-28 {
                height: 7rem;
            }
    
            .h-2 {
                height: 0.5rem;
            }
    
            .w-full {
                width: 100%;
            }
    
            .bg-black {
                background-color: #000;
            }
        </style>
    </head>
    <body>
    <div class="container" id="div">
        <div class="border-black border-2">
            <div class="px-4  border-b-2 border-black">
                <div class="flex justify-between items-center ">
                    <p class=" fontMedium">EASYSHIP+D</p>
                    <p class="fontBold">2KG</p>
                    <p class="fontBold">1 OF 1</p>
                </div>
                <div class="flex justify-between items-center ">
                    <div class=" fontMedium flex flex-col">
                        <p class=" fontMedium">852 3008 3991</p>
                        <p class=" fontMedium">EASYSHIP+D</p>
                        <p class=" fontMedium">2/F HUA FU COMMECIAL BUILDING</p>
                        <p class=" fontMedium">HONG KONG 0000</p>
                        <p class=" fontMedium">HONG KONG SAR, CHINA</p>
                    </div>
                    <div class=" fontMedium flex flex-col mr-4">
                        <p class="font-semi-bold ">SHP#: A668 99TX KTH</p>
                        <p class="font-semi-bold ">SHP WT: 2KG</p>
                        <p class="font-semi-bold ">SHP DWT: 0.5 KG</p>
                        <p class="font-semi-bold ">DATE: 20 FEB 2021</p>
                        <p class="font-semi-bold ">DWT: 5,5,5</p>
                    </div>
                </div>
                <p class="fontBold text-2xl">SHIP TO:</p>
                <div class="flex  flex-col mb-6  ml-11">
                    <p class="fontBold text-xl">0123456789</p>
                    <p class="fontBold text-xl">JHON SMITH</p>
                    <p class="fontBold text-xl">905 LOMA VISTA DR</p>
                    <p class="fontBold text-2xl">BEVERLY HILLS CA 90210</p>
                    <p class="fontBold text-2xl">UNITED STATES</p>
                </div>
            </div>
            <div class="flex  px-4 ">
                <div class="flex  items-center border-r-2 p-1 border-black">
                    <img src=${QRIMAGE} class=" w-40  h-36" alt="" />
                </div>
                <div class="flex  flex-col px-2 py-2">
                    <p class="fontBold text-3xl">CA 900-50</p>
                    <img src=${BARCODEIMG} class=" w-auto h-28" alt="" />
                </div>
            </div>
            <div class="h-2 w-full bg-black  "></div>
            <div class="flex   px-4  justify-between border-b-2 border-black">
                <div class="flex flex-col border-black margin-bottom">
                    <p class="fontBold text-3xl">UPS SAVER</p>
                    <p class="font-semi-bold text-xl">
                        TRACKING #: 1Z A66 899 04 95552 4102
                    </p>
                </div>
                <div class="flex flex-col px-2 py-2">
                    <p class="fontBold text-4xl ">1P</p>
                </div>
            </div>
            <div class="flex  ml-12 py-1">
                <img src=${BARCODEIMG} class=" w-40  h-28" alt="" />
            </div>
            <div class="h-2 w-full bg-black  "></div>
            <div class="border-b-2 border-black">
                <div class="flex   px-4  justify-between">
                    <div class="flex flex-col border-black">
                        <p class="fontBold text-xl">BILLING: P/P</p>
                        <p class="fontBold text-xl">DESC: 1 x test</p>
                    </div>
                    <div class="flex  flex-col px-2 py-2">
                        <p class="fontBold text-4xl mr-20">EDI</p>
                    </div>
                </div>
                <div class="flex   px-4  justify-between mt-12">
                    <div class="flex flex-col border-black justify-end mb-6 ">
                        <p class="fontBold text-xl  ">
                            REFERENCE NO.1: ESHK354319829
                        </p>
                    </div>
                    <div class="flex  flex-col px-2 py-2">
                        <img src=${GIFT} class=" w-40  h-16" alt="" />
                    </div>
                </div>
                <div class="flex  justify-evenly mx-auto margin-bottom">
                    <p class="fontBold  text-sm ">XOL 21.02.07</p>
                    <p class="fontBold text-sm">NV45 42.0A 01/2021*</p>
                </div>
            </div>
         
    
            <div class="flex mx-4 margin-bottom">
                <p class="fontBold  text-sm ">
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book. It has survived not
                    only five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. 
                </p>
            </div>
        </div>
    </div>
    </body>
    </html>
      `;
    return html;
  };
  const ShowOrderDetail = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://movssy.com/admin/api/order_detail/${id}`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === true) {
          if (result["Order Details"] !== undefined) {
            console.log("🚀 ~ .then ~ (result]:", result["Order Details"]);
            setData(result["Order Details"]);
            setSelectedValue(result["Order Details"].ordering_as);
          }
        } else {
          setData(null);
        }
        console.log("🚀 ~ .then ~ result['Order Details']:", result);
      })
      .catch((error) => console.error("Error fetching order details:", error));
  };
  const handleStarClick = (index) => {
    setRating(index + 1);
  };
  const Rating = () => {
    return (
      <div className="flex my-2">
        {[...Array(5)].map((star, index) => {
          const currentIndex = index + 1;
          return (
            <label>
              <input
                type="radio"
                name="radio"
                style={{ display: "none" }}
                value={currentIndex}
                onClick={() => handleStarClick(index)}
              />
              <FaStar
                key={index}
                size={40}
                className="cursor-pointer"
                color={
                  currentIndex <= (hover || rating) ? "#ffc107" : "#e4e5e9"
                }
                onMouseEnter={() => setHover(currentIndex)}
                onMouseLeave={() => setHover(null)}
              />
            </label>
          );
        })}
      </div>
    );
  };
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
  }, []);
  const [phoneno, setPhoneNo] = useState("");
  useEffect(() => {
    ShowOrderDetail();
  }, [navigate]);
  return (
    <React.Fragment>
      {data === null ? (
        <div className="container-fluid relative px-3">
          <div className="layout-specing">
            <div className="grid md:grid-cols-12 grid-cols-1 mt-2 gap-6">
              <div className="lg:col-span-12 md:col-span-12">
                <div className="p-6 rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 mt-6">
                  <h5 className="text-lg font-bold my-4">No record found</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid relative px-3">
          <div className="layout-specing">
            <div className="flex justify-between items-center">
              <h5 className="text-lg font-bold my-4">
                Order # {id.padStart(6, 0)}
              </h5>
              <div>
                <button
                  onClick={() => setModal(true)}
                  className="py-[7px]  px-6 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white rounded-md sm:inline-block "
                >
                  Review
                </button>
                <button
                  onClick={handleGeneratePDF}
                  className="ml-2 py-[7px]  px-6 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white rounded-md sm:inline-block "
                >
                  Download
                </button>
              </div>
            </div>
            <div className="grid md:grid-cols-12 grid-cols-1">
              <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 ">
                <div className="grid grid-cols-1   gap-6">
                  <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                    <h5 className="text-lg font-semibold mb-4">
                      Order Details
                    </h5>
                    <label className="form-label font-medium">
                      Ordering As
                    </label>
                    <div>
                      <input
                        type="radio"
                        id="html"
                        name="fav_language"
                        value="personal"
                        checked={selectedValue === "personal"}
                        onChange={() => setSelectedValue("personal")}
                        disabled={selectedValue !== "Personal"}
                      />
                      <label htmlFor="html">Personal</label>
                      <input
                        type="radio"
                        id="css"
                        name="fav_language"
                        value="business"
                        checked={selectedValue === "business"}
                        onChange={() => setSelectedValue("business")}
                        disabled={selectedValue !== "business"}
                        className="ml-4 text-gray-300 bg-slate-400 border-gray-400"
                      />
                      <label htmlFor="css">Business</label>
                      <br />
                    </div>
                    <form>
                      <div className="grid lg:grid-cols-4 grid-cols-1 gap-5">
                        <div>
                          <label className="form-label font-medium">
                            Package Name
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="text"
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              disabled
                              value={
                                data?.package_name === null
                                  ? ""
                                  : data?.package_name
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium">
                            Package Type
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="text"
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              disabled
                              value={
                                data?.package_type === null
                                  ? ""
                                  : data?.package_type
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium flex justify-between items-center">
                            Amount
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="number"
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              name="email"
                              disabled
                              value={
                                data?.orignal_amount === null
                                  ? ""
                                  : data?.orignal_amount
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium">
                            Pickup Date
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="text"
                              value={
                                data?.pickup_date === null
                                  ? ""
                                  : data?.pickup_date
                              }
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            />
                            {/* <PhoneInput
                          enableSearch={true}
                          country={"us"}
                          value={phoneno}
                          onChange={(phone) => setPhoneNo(phone)}
                        /> */}
                          </div>
                        </div>

                        <div>
                          <label className="form-label font-medium">
                            Delievery Date
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="text"
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              disabled
                              value={
                                data?.delivery_date === null
                                  ? ""
                                  : data?.delivery_date
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium">
                            Order Email
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="email"
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              id="lastname"
                              name="name"
                              value={data?.email === null ? "" : data?.email}
                              disabled
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium flex justify-between items-center">
                            From
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="text"
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              name="email"
                              value={data?.from === null ? "" : data?.from}
                              disabled
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium">To</label>
                          <div className="form-icon relative mt-2">
                            <input
                              name="name"
                              id="occupation"
                              type="text"
                              value={data?.to === null ? "" : data?.to}
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            />
                            {/* <PhoneInput
                          enableSearch={true}
                          country={"us"}
                          value={phoneno}
                          onChange={(phone) => setPhoneNo(phone)}
                        /> */}
                          </div>
                        </div>

                        <div>
                          <label className="form-label font-medium">
                            Weight
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="number"
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              id="firstname"
                              name="name"
                              disabled
                              value={data?.weight === null ? "" : data?.weight}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium">
                            Length
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="number"
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              id="lastname"
                              name="name"
                              disabled
                              value={data?.length === null ? "" : data?.length}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium flex justify-between items-center">
                            Width
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="number"
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              disabled
                              value={data?.width === null ? "" : data?.width}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium">
                            Height
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              name="name"
                              id="occupation"
                              type="number"
                              value={data?.height === null ? "" : data?.heigth}
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            />
                            {/* <PhoneInput
                          enableSearch={true}
                          country={"us"}
                          value={phoneno}
                          onChange={(phone) => setPhoneNo(phone)}
                        /> */}
                          </div>
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-4">
                        <div>
                          <label className="form-label font-medium">
                            Tracking Number
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              value={
                                data?.tracking_number === null
                                  ? ""
                                  : data?.tracking_number
                              }
                              type="text"
                              disabled
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium">
                            Order Action
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              value={data?.status === null ? "" : data?.status}
                              type="text"
                              disabled
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 mt-4">
                <div className="grid grid-cols-1 gap-6">
                  <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                    <h5 className="text-lg font-semibold mb-4">
                      Pickup Details{" "}
                    </h5>
                    <form>
                      <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                        <div>
                          <label className="form-label font-medium">Name</label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="text"
                              className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              value={
                                data?.pickup_user_full_name === null
                                  ? ""
                                  : data?.pickup_user_full_name
                              }
                              disabled
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium">
                            Contact
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="text"
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              disabled
                              value={
                                data?.pickup_user_contact === null
                                  ? ""
                                  : data?.pickup_user_contact
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-3 grid-cols-1 gap-5 mt-4">
                        <div>
                          <label className="form-label font-medium flex justify-between items-center">
                            Postal Code
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="email"
                              className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              disabled
                              value={
                                data?.pickup_user_postal_code === null
                                  ? ""
                                  : data?.pickup_user_postal_code
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium flex justify-between items-center">
                            Street
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="text"
                              className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              disabled
                              value={
                                data?.pickup_user_street === null
                                  ? ""
                                  : data?.pickup_user_street
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium">City</label>
                          <div className="form-icon relative mt-2">
                            <input
                              value={
                                data?.pickup_user_city === null
                                  ? ""
                                  : data?.pickup_user_full_name
                              }
                              type="text"
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 mt-4">
                <div className="grid grid-cols-1 gap-6">
                  <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                    <h5 className="text-lg font-semibold mb-4">
                      Delievery Details
                    </h5>
                    <form>
                      <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                        <div>
                          <label className="form-label font-medium">Name</label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="text"
                              className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              disabled
                              value={
                                data?.delivery_user_full_name === null
                                  ? ""
                                  : data?.delivery_user_full_name
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium">
                            Contact
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="text"
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              disabled
                              value={
                                data?.delivery_user_contact === null
                                  ? ""
                                  : data?.delivery_user_contact
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-3 grid-cols-1 gap-5 mt-4">
                        <div>
                          <label className="form-label font-medium flex justify-between items-center">
                            Postal Code
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="number"
                              className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              name="email"
                              disabled
                              value={
                                data?.delivery_user_postal_code === null
                                  ? ""
                                  : data?.delivery_user_postal_code
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium flex justify-between items-center">
                            Street
                          </label>
                          <div className="form-icon relative mt-2">
                            <input
                              type="email"
                              className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              value={
                                data?.delivery_user_street === null
                                  ? ""
                                  : data?.delivery_user_street
                              }
                              disabled
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label font-medium">City</label>
                          <div className="form-icon relative mt-2">
                            <input
                              value={
                                data?.delivery_user_city === null
                                  ? ""
                                  : data?.delivery_user_city
                              }
                              type="text"
                              className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            />
                            {/* <PhoneInput
                          enableSearch={true}
                          country={"us"}
                          value={phoneno}
                          onChange={(phone) => setPhoneNo(phone)}
                        /> */}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40 flex items-center justify-center ${
              modal ? "" : "hidden"
            }`}
          >
            <div className="relative w-full h-auto max-w-lg p-4">
              <div className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                <button
                  type="button"
                  onClick={() => setModal(!modal)}
                  className="absolute -top-4 -end-4 text-gray-400 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 hover:text-gray-900 rounded-full text-sm p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
                <div className="p-6 py-10 text-center">
                  <div className="relative overflow-hidden text-transparent -m-3">
                    <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-red-600 rounded-xl duration-500 text-4xl flex align-middle justify-center items-center">
                      <FaStar />
                    </div>
                  </div>
                  <div className="text-start">
                    <h4 className="text-xl font-semibold mt-6">Review</h4>
                    <div>
                      <Rating />
                    </div>

                    <label htmlFor="comments" className="font-semibold">
                      Your Message:
                    </label>
                    <div className="form-icon relative mt-2">
                      <textarea
                        name="message"
                        onChange={handleMessage}
                        value={message}
                        id="message"
                        className="form-input w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                        placeholder="Message :"
                      ></textarea>
                    </div>
                  </div>
                  <div className=" text-end">
                    <button
                      disabled={disableReview}
                      onClick={ReviewApi}
                      className="py-[5px] px-4 inline-block font-semibold tracking-wide duration-500 text-sm text-center  bg-transparent hover:bg-indigo-600 border border-indigo-600 text-indigo-600 hover:text-white rounded-md mt-2"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
