import React from "react";

import image6 from "../assets/images/blog/01.jpg";
import image7 from "../assets/images/blog/02.jpg";
import image8 from "../assets/images/blog/03.jpg";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

export default function RoadFrieght(props) {
  const data = [
    {
      Title: "Pallete Shipping",
      Description:
        "The phrasal sequence of the is now so that many campaign and benefit",
      Image: image6,
      DelayTime: ".3s",
      route: "/pallete",
    },
    {
      Title: "Van Delivery",
      Description:
        "The phrasal sequence of the is now so that many campaign and benefit",
      Image: image7,
      DelayTime: ".5s",
      route: "/van-delivery",
    },
    {
      Title: "Shared truck (LTL)",
      Description:
        "The phrasal sequence of the is now so that many campaign and benefit",
      Image: image8,
      DelayTime: ".7s",
      route: "/ltl-transport",
    },
    {
      Title: "Full truck (FTL)",
      Description:
        "The phrasal sequence of the is now so that many campaign and benefit",
      Image: image7,
      DelayTime: ".5s",
      route: "/ftl-transport",
    },
  ];
  return (
    <div className={props.className}>
      <div
        className="grid md:grid-cols-12 grid-cols-1 items-center wow animate__animated animate__fadeInUp"
        data-wow-delay=".1s"
      >
        <div className="md:col-span-12">
          <h6 className="text-btnBackground text-sm font-bold uppercase mb-2">
            OPTIONS AVAILABLE
          </h6>
          <h3 className="mb-4 text-textHighlight md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            Road freight shipping solutions for different loads Pallet shipping
            <br /> for different loads Pallet shipping
          </h3>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-4 mt-8 gap-[30px]">
        {data.map((data, index) => {
          return (
            <div
              key={index}
              className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden wow animate__animated animate__fadeInUp"
              data-wow-delay={data.DelayTime}
            >
              <img
                src={"https://placehold.co/300x200/EEE/31343C"}
                className=" w-full"
                alt=""
              />

              <div className="content p-6">
                <div className="mt-2">
                  <Link
                    to={data.route}
                    className="relative font-bold inline-flex items-center  tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                  >
                    {data?.Title} <FaArrowRight className="ms-2 text-[10px]" />
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
