import React from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../component/Footer/footer";

import { MdOutlineNumbers } from "react-icons/md";
import * as Icon from "react-feather";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import RelocationHeaderBar from "../../component/relocation&removalsHeader";
const validationSchema = Yup.object().shape({
  shipmentDescription: Yup.string().required(
    "Shipment description is required"
  ),
  selectdelivery: Yup.object().required("Delivery option is required"),
  selectpickup: Yup.object().required("Pickup option is required"),

  myFile: Yup.mixed()
    .required("Required")
    .test(
      "fileFormat",
      "Only PDF, JPEG, PNG, and GIF files are allowed",
      (value) => {
        if (value) {
          const supportedFormats = [
            "application/pdf",
            "image/jpeg",
            "image/png",
            "image/gif",
          ]; // Add supported formats here
          return supportedFormats.includes(value.type);
        }
        return true;
      }
    )
    .test("fileSize", "File size must not be more than 3MB", (value) => {
      if (value) {
        return value.size <= 3145728;
      }
      return true;
    }),
});
export default function RemovalandRecolation() {
  const navigate = useNavigate();
  const location = useLocation();
  const [options, setOptions] = React.useState([]);

  const pickupValue = location.state?.pickupValue;
  const deliveryValue = location.state?.deliveryValue;

  const title = location.state?.title;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("🚀 ~ handleFileChange ~ file:", file);
    if (file) {
      formik.setFieldValue("myFile", file);
    }
  };

  const handleRemoveFile = () => {
    formik.setFieldValue("myFile", "");
  };
  React.useEffect(() => {
    if (pickupValue) {
      formik.setFieldValue("selectpickup", pickupValue);
    }
  }, [pickupValue]);
  React.useEffect(() => {
    if (deliveryValue) {
      formik.setFieldValue("selectdelivery", deliveryValue);
    }
  }, [deliveryValue]);
  const formik = useFormik({
    initialValues: {
      shipmentDescription: "",
      selectpickup: null,
      selectdelivery: null,
      myFile: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("🚀 ~ ContactUs ~ values:", values);
      navigate("/contact-info");
    },
  });
  const project = [
    { value: "Austria", label: "Austria" },
    { value: "Belgium", label: "Belgium" },
    { value: "England", label: "England" },
    { value: "SouthAfrica", label: "SouthAfrica" },
  ];
  const getCountry = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/country", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.countries && result.countries.length > 0) {
          const countries = result?.countries.map((country) => ({
            value: country.country_code,
            label: country.country_name,
            id: country.id,
            city: country.city,
            zipCode: country.zip_code,
          }));
          setOptions(countries);
        }
      })
      .catch((error) => console.error(error));
  };
  React.useEffect(() => {
    getCountry();
  }, [navigate]);
  return (
    <>
      <RelocationHeaderBar title={"Shipment details"} />

      <section className="container relative mx-auto  md:pt-28 md:pb-16">
        <div className="layout-specing md:col-span-6">
          <div
            className=" grid md:col-span-4  grid-cols-1 items-center 
          mx-auto"
          >
            <h1 className="text-2xl font-bold text-btnBackground">
              Individual offer {title && "-"} {title && title}
            </h1>
          </div>
          <div className="bg-bgGrey mt-4 dark:bg-slate-900 dark:shadow-slate-300 shadow items-center  py-4 px-4">
            <h2 className="text-lg font-semibold">Pick-Up</h2>
            <Select
              className="bg-gray-50   border-0"
              options={options}
              placeholder="Pick-Up"
              value={formik.values.selectpickup}
              onChange={(option) =>
                formik.setFieldValue("selectpickup", option)
              }
            />
            {formik.touched.selectpickup && formik.errors.selectpickup && (
              <p className="text-red-500 text-sm mt-1">
                {formik.errors.selectpickup}
              </p>
            )}
            <h2 className="text-lg font-semibold mt-2">Delivery</h2>
            <Select
              className="bg-gray-50   border-0"
              options={options}
              placeholder="Delivery"
              value={formik.values.selectdelivery}
              onChange={(option) =>
                formik.setFieldValue("selectdelivery", option)
              }
            />
            {formik.touched.selectdelivery && formik.errors.selectdelivery && (
              <p className="text-red-500 text-sm mt-1">
                {formik.errors.selectdelivery}
              </p>
            )}
          </div>
          <div className=" relative mt-4">
            <div
              className=" grid md:col-span-5  grid-cols-1 items-center 
             mx-auto"
            >
              <div className="">
                <div className="">
                  <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="grid grid-cols-1">
                        <div className="">
                          <h2 className="text-2xl font-bold text-btnBackground">
                            What are you shipping?
                          </h2>
                          <div className="text-start mt-4 ">
                            <label
                              htmlFor="comments"
                              className="font-semibold text-btnBackground"
                            >
                              Shipment description
                            </label>
                            <div className="form-icon relative mt-2">
                              <Icon.MessageCircle className="w-4 h-4 absolute top-3 start-4 text-btnBackground"></Icon.MessageCircle>
                              <textarea
                                name="shipmentDescription"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.shipmentDescription}
                                id="shipmentDescription"
                                className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="Shipment description :"
                              ></textarea>
                            </div>
                            {formik.touched.shipmentDescription &&
                              formik.errors.shipmentDescription && (
                                <p className="text-red-500 text-sm mt-1">
                                  {formik.errors.shipmentDescription}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-1">
                        <div className="mt-4">
                          <label
                            htmlFor="comments"
                            className="font-semibold text-btnBackground"
                          >
                            Upload photos of your item(s) (optional)
                          </label>
                          <div className="justify-center items-center flex border border-dashed py-11">
                            {formik.values.myFile ? (
                              <>
                                <span>{formik.values.myFile.name}</span>
                                <button
                                  onClick={handleRemoveFile}
                                  className="ml-2 text-red-500"
                                >
                                  &times;
                                </button>
                              </>
                            ) : (
                              <input
                                id="myFile"
                                type="file"
                                name="myFile"
                                accept=".pdf, image/jpeg, image/png, image/gif"
                                onChange={handleFileChange}
                              />
                            )}
                          </div>
                          {formik.errors.myFile && (
                            <p className="text-red-500 text-sm mt-1">
                              {formik.errors.myFile}
                            </p>
                          )}
                        </div>
                      </div>

                      <button
                        type="submit"
                        id="submit"
                        name="send"
                        className="mt-4 py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md justify-center flex items-center"
                      >
                        Next Step
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
