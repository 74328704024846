import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import { MdNumbers } from "react-icons/md";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createTicket, addTicket } from "../../store/slices/ticketSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { changeLanguagei18 } from "../utility/changeLanguagei18N";
const validationSchema = Yup.object().shape({
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
  serviceOption: Yup.object().required("Service type is required"),
  emergencyOption: Yup.object().required("Emergency type is required"),
  myFile: Yup.mixed()
    .required("Required")
    .test(
      "fileFormat",
      "Only PDF, JPEG, PNG, and GIF files are allowed",
      (value) => {
        if (value) {
          const supportedFormats = [
            "application/pdf",
            "image/jpeg",
            "image/png",
            "image/gif",
          ]; // Add supported formats here
          return supportedFormats.includes(value.type);
        }
        return true;
      }
    )
    .test("fileSize", "File size must not be more than 3MB", (value) => {
      if (value) {
        return value.size <= 3145728;
      }
      return true;
    }),
});
export default function ComposeTicket() {
  const token = useSelector((state) => state.auth.token);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serviceOption, setServiceOption] = useState(null);
  const [emergencyOption, setEmergencyOption] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleRemoveFile = () => {
    setSelectedFile(null);
    formik.setFieldValue("myFile", "");
  };

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const emergencyData = [
    { value: "normal", label: "Normal" },
    { value: "medium", label: "Medium" },
    { value: "critical", label: "Critical" },
  ];

  const servicesData = [
    { value: "Document", label: "Document" },
    { value: "Package", label: "Package" },
    { value: "Pallet", label: "Pallet" },
    { value: "Van Delivery", label: "Van Delivery" },
    { value: "FTL & LTL", label: "FTL & LTL" },
    { value: "CustomOffer", label: "CustomOffer" },
  ];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("🚀 ~ handleFileChange ~ file:", file);
    if (file) {
      setSelectedFile(file);
      formik.setFieldValue("myFile", file);
    }
  };
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
    changeLanguagei18(languageCode);
  }, []);

  const StoreTicket = (event) => {
    event.preventDefault();

    const formdata = new FormData();
    formdata.append("type_emergency", emergencyOption.value);
    formdata.append("file_upload", selectedFile);
    formdata.append("subject", subject);
    formdata.append("message", message);
    formdata.append("type_service", serviceOption.value);

    dispatch(createTicket(formdata))
      .unwrap()
      .then((res) => {
        if (res?.status === true) {
          toast.success("Request sent successfully");
          dispatch(addTicket(res?.ticket));
          navigate("/user-dashboard/ticket");
        } else {
          toast.error(res?.message);
        }
      });
  };
  const formik = useFormik({
    initialValues: {
      subject: "",
      message: "",
      serviceOption: null,
      emergencyOption: null,
      selectedFile: null,
      myFile: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Your login logic here
      setIsSubmitting(true);

      const formdata = new FormData();
      formdata.append("type_emergency", values.emergencyOption.value);
      formdata.append("file_upload", values.myFile);
      formdata.append("subject", values.subject);
      formdata.append("message", values.message);
      formdata.append("type_service", values.serviceOption.value);

      dispatch(createTicket(formdata))
        .unwrap()
        .then((res) => {
          if (res?.status === true) {
            toast.success("Request sent successfully");
            dispatch(addTicket(res?.ticket));
            navigate("/user-dashboard/ticket");
            setIsSubmitting(false);
          } else {
            toast.error(res?.message);
            setIsSubmitting(false);
          }
        });
    },
  });
  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="grid grid-cols-1">
          <div className="profile-banner relative text-transparent rounded-md shadow dark:shadow-gray-700 overflow-hidden">
            <input
              id="pro-banner"
              name="profile-banner"
              type="file"
              className="hidden"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1">
          <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 ">
            <div className="grid grid-cols-1 gap-6">
              <div className="p-6 relative rounded-md  shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <h5 className="text-lg font-semibold text-btnBackground mb-4">
                  {t("Ticket")}
                </h5>
                <form onSubmit={formik.handleSubmit}>
                  <div className="grid lg:grid-cols-1 grid-cols-1">
                    <label className="form-label text-btnBackground font-medium">
                      {t("Subject")}
                    </label>
                    <input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subject}
                      name="subject"
                      id="subject"
                      type="text"
                      className="form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                      placeholder="Subject"
                    />
                    {formik.touched.subject && formik.errors.subject && (
                      <p className="text-red-500 text-sm mt-1">
                        {formik.errors.subject}
                      </p>
                    )}
                  </div>
                  <div className="grid lg:grid-cols-2 mt-4 grid-cols-1 gap-5 ">
                    <div>
                      <label className="form-label text-btnBackground font-medium">
                        {t("Service_Type")}
                      </label>
                      <div className="form-icon relative mt-2">
                        <Select
                          className="bg-gray-50 dark:bg-slate-800 border-0"
                          options={servicesData}
                          value={formik.values.serviceOption}
                          onChange={(option) =>
                            formik.setFieldValue("serviceOption", option)
                          }
                          placeholder={t("Select_Service")}
                        />
                        {formik.touched.serviceOption &&
                          formik.errors.serviceOption && (
                            <p className="text-red-500 text-sm mt-1">
                              {formik.errors.serviceOption}
                            </p>
                          )}
                      </div>
                    </div>
                    <div>
                      <label className="form-label text-btnBackground font-medium">
                        {t("Emergency_Type")}
                      </label>
                      <div className="form-icon relative mt-2">
                        <Select
                          className="bg-gray-50 dark:bg-slate-800 border-0"
                          options={emergencyData}
                          value={formik.values.emergencyOption}
                          onChange={(option) =>
                            formik.setFieldValue("emergencyOption", option)
                          }
                          placeholder={t("Select_Emergency_Option")}
                        />
                        {formik.touched.emergencyOption &&
                          formik.errors.emergencyOption && (
                            <p className="text-red-500 text-sm mt-1">
                              {formik.errors.emergencyOption}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-4">
                    <div>
                      <label className="form-label text-btnBackground font-medium">
                        {t("Message")}
                      </label>
                      <div className="form-icon relative mt-2">
                        <textarea
                          name="message"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.message}
                          id="message"
                          className="form-input w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                          placeholder="Message :"
                        ></textarea>
                        {formik.touched.message && formik.errors.message && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div>
                      <label className="form-label text-btnBackground font-medium">
                        {t("Select_File")}
                      </label>
                      <div className="mt-2 justify-center items-center flex border border-dashed py-10 rounded">
                        {selectedFile ? (
                          <>
                            <span>{selectedFile.name}</span>
                            <button
                              onClick={handleRemoveFile}
                              className="ml-2 text-red-500"
                            >
                              &times;
                            </button>
                          </>
                        ) : (
                          <input
                            id="myFile"
                            onChange={handleFileChange}
                            type="file"
                            name="myFile"
                            accept=".pdf, image/jpeg, image/png, image/gif"
                          />
                        )}
                      </div>
                      {formik.errors.myFile && (
                        <p className="text-red-500 text-sm mt-1">
                          {formik.errors.myFile}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid  grid-cols-2 text-btnBackground gap-5 mt-4"></div>
                  <input
                    type="submit"
                    value={t("Save")}
                    disabled={isSubmitting}
                    className="py-2 px-5 bg-btnBackground cursor-pointer inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center  hover:bg-indigo-700 border-btnBackground0 hover:border-indigo-700 text-white rounded-md mt-5"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
