import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import imageP from "../../assets/images/Services/Airplane.png";
import image1 from "../../assets/images/Services/Cargo.png";
import image2 from "../../assets/images/Services/Scooter.png";
import imageF from "../../assets/images/Services/Truck.png";

import hitw1 from "../../assets/images/How it works/human-dolly.png";
import hitw2 from "../../assets/images/How it works/Vector.png";
import hitw3 from "../../assets/images/How it works/Maskgroup.png";
import hitw4 from "../../assets/images/How it works/Group 211.png";
import hitw5 from "../../assets/images/How it works/Airplane.png";

import group9 from "../../assets/images/Hero Section/Group 209.png";
import airplane from "../../assets/images/Hero Section/Airplane.png";

import group8 from "../../assets/images/Hero Section/Group 208.png";
import review1 from "../../assets/images/REVIEWS/Rectangle 22332.png";
import review2 from "../../assets/images/REVIEWS/Rectangle 22334.png";
import review3 from "../../assets/images/REVIEWS/Rectangle 22336.png";

import image3 from "../../assets/images/client/01.jpg";
import image4 from "../../assets/images/client/02.jpg";
import image5 from "../../assets/images/client/03.jpg";
import image6 from "../../assets/images/client/04.jpg";
import image7 from "../../assets/images/client/05.jpg";
import image8 from "../../assets/images/client/06.jpg";
import Select from "react-select";

import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";
import CookieModal from "../../component/cookieModal";
import ManagementTeam from "../../component/managementTeam";
import CompanyLogo from "../../component/companyLogo";

import * as Icon from "react-feather";
import { FaArrowRight, FiAirplay } from "../../assets/icons/icons";
import { checkPath } from "../../store/slices/pathSlice";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

import CountUp from "react-countup";
import { FaStar } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import UserFeedBack2 from "../../component/userFeedBack2";
const settings = {
  container: ".tiny-single-item",
  items: 1,
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  speed: 400,
  gutter: 16,
};

export default function IndexConsulting() {
  const [activeIndex, setActiveIndex] = useState(1);
  const [faqData, setFaqData] = useState([]);
  const [options, setOptions] = useState([]);
  const [pickupValue, setPickupValue] = useState([]);
  const [deliveryValue, setDeliveryValue] = useState([]);
  const [serviceValue, setServiceValue] = useState(null);
  const steps = [
    {
      id: 1,
      icon: hitw1, // Replace with actual path or import statement
      title: "Lorem Ipsum",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 2,
      icon: hitw2, // Replace with actual path or import statement
      title: "Lorem Ipsum",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 3,
      icon: hitw3, // Replace with actual path or import statement
      title: "Lorem Ipsum",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AuthHandler = (pathValue) => {
    dispatch(
      checkPath({
        path: pathValue,
        orderingAs: orderingAs,
      })
    );
  };
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);

  const [orderingAs, setOrderingAs] = useState("personal");
  const handleNavigate = (event) => {
    event.preventDefault();
    if (isAuthorized) {
      if (serviceValue !== null) {
        if (
          serviceValue.value === "Sea Frieght" ||
          serviceValue.value === "Air Frieght" ||
          serviceValue.value === "Removal & relocation"
        ) {
          navigate("/relocation&removal", {
            state: {
              title: serviceValue.value,
              pickupValue: pickupValue,
              deliveryValue: deliveryValue,
            },
          });
        } else {
          navigate("/order-details", {
            state: {
              pickupValue: pickupValue,
              deliveryValue: deliveryValue,
            },
          });
        }
      } else {
        toast.error("Select Service..");
      }
    } else {
      AuthHandler("/order-details");
      navigate("/login");
    }
  };
  const FaqApi = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/faq", requestOptions)
      .then((response) => response.json())
      .then(({ status, faqs, message }) => {
        console.log("🚀 ~ .then ~ faqs:", faqs);
        if (status === true) {
          setFaqData(faqs);
        } else {
          toast.error(message);
        }
      })
      .catch((error) => console.error(error));
  };

  const getCountry = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/country", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.countries && result.countries.length > 0) {
          const countries = result?.countries.map((country) => ({
            value: country.country_code,
            label: country.country_name,
            id: country.id,
            city: country.city,
            zipCode: country.zip_code,
          }));
          setOptions(countries);
        }
      })
      .catch((error) => console.error(error));
  };
  const servicesData = [
    { value: "Document", label: "Document" },
    { value: "Package", label: "Package" },
    { value: "Pallet", label: "Pallet" },
    { value: "Van Delivery", label: "Van Delivery" },
    { value: "FTL & LTL", label: "FTL & LTL" },
    { value: "CustomOffer", label: "CustomOffer" },
    { value: "Sea Frieght", label: "Sea Frieght (on request)" },
    { value: "Air Frieght", label: "Air Frieght (on request)" },
    {
      value: "Relocation & removals",
      label: "Relocation & removals (on request)",
    },
  ];

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };

  const faqData1 = faqData.length > 3 ? faqData.slice(0, 3) : faqData;
  const services = [
    {
      feedback:
        '" Techwind made the processes so easy. Techwind instantly increased the amount of interest and ultimately saved us over $10,000. " ',
      name: "Christa Smith",
      role: "Manager",
      image: image3,
    },
    {
      feedback:
        '" I highly recommend Techwind as the new way to sell your home "by owner". My home sold in 24 hours for the asking price. Best $400 you could spend to sell your home. "',
      name: "Christa Smith",
      role: "Manager",
      image: image4,
    },
    {
      feedback:
        '" My favorite part about selling my home myself was that we got to meet and get to know the people personally. This made it so much more enjoyable! "',
      name: "Christa Smith",
      role: "Manager",
      image: image5,
    },
    {
      feedback: '" Great experience all around! Easy to use and efficient. "',
      name: "Christa Smith",
      role: "Manager",
      image: image6,
    },
    {
      feedback:
        '" Techwind made selling my home easy and stress free. They went above and beyond what is expected. "',
      name: "Christa Smith",
      role: "Manager",
      image: image7,
    },
    {
      feedback:
        '" Techwind is fair priced, quick to respond, and easy to use. I highly recommend their services! "',
      name: "Christa Smith",
      role: "Manager",
      image: image8,
    },
  ];
  const TestimonialData = [
    {
      feedback:
        '" Techwind made the processes so easy. Techwind instantly increased the amount of interest and ultimately saved us over $10,000. " ',
      name: "Christa Smith",
      role: "Manager",
      image: review1,
    },
    {
      feedback:
        '" I highly recommend Techwind as the new way to sell your home "by owner". My home sold in 24 hours for the asking price. Best $400 you could spend to sell your home. "',
      name: "Christa Smith",
      role: "Manager",
      image: review2,
    },
    {
      feedback:
        '" My favorite part about selling my home myself was that we got to meet and get to know the people personally. This made it so much more enjoyable! "',
      name: "Christa Smith",
      role: "Manager",
      image: review3,
    },
  ];

  const servecies1 = [
    {
      image: imageP,
      title: "Profitable Marketing",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 1,
    },
    {
      image: image1,
      title: "SEO Specialists",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 2,
    },
    {
      image: image2,
      title: "Audience Analysis",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 3,
    },
    {
      image: imageF,
      title: "SEO Specialists",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 2,
    },
  ];
  React.useEffect(() => {
    FaqApi();
    getCountry();
  }, [navigate]);
  return (
    <>
      <Navbar navClass="nav-light" />
      <section className="relative md:py-50  py-44  bg-right bg-no-repeat bg-cover">
        <div className="absolute inset-0 flex justify-center">
          <div className="w-[200px]  rounded-bl-full bg-opacity-20  rounded-tl-lg rounded-tr-lg  rounded-br-full bg-gradient-to-r from-transparent via-gray-200 to-transparent h-32   "></div>
        </div>
        {/* <div className="absolute inset-0 bg-white dark:bg-slate-900"></div> */}
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-8 md:col-span-8">
              <div className="lg:ms-5">
                <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">
                  Save on Worldwide Shipping <br /> with Movssy.
                </h4>

                <div className=" relative">
                  <div className="grid grid-cols-1 justify-center">
                    <div className="relative mt-4">
                      <div className="grid grid-cols-1">
                        <div>
                          <form
                            onSubmit={handleNavigate}
                            className="p-6 bg-bgGrey dark:bg-slate-900 rounded-xl shadow-md dark:shadow-gray-700"
                          >
                            <div className="registration-form text-dark text-start">
                              <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 space-x-3 lg:gap-0 gap-6">
                                <div>
                                  <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                    {/* <LuSearch className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                                    <Select
                                      className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                      options={options}
                                      placeholder="Pick up"
                                      value={pickupValue}
                                      required
                                      onChange={(option) =>
                                        setPickupValue(option)
                                      }
                                    />
                                  </div>
                                </div>

                                <div>
                                  <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                    {/* <AiOutlineHome className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                                    <Select
                                      className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                      options={options}
                                      required
                                      placeholder="Delivery"
                                      value={deliveryValue}
                                      onChange={(option) =>
                                        setDeliveryValue(option)
                                      }
                                    />
                                  </div>
                                </div>

                                <div>
                                  <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                    {/* <AiOutlineDollar className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                                    <Select
                                      className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                      options={servicesData}
                                      placeholder={"Services"}
                                      value={serviceValue}
                                      required
                                      onChange={(option) =>
                                        setServiceValue(option)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="">
                                  <input
                                    type="submit"
                                    id="search-buy"
                                    name="search"
                                    className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full h-14 rounded"
                                    value="Get a qoute"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="text-black text-lg max-w-xl dark:text-gray-400 mt-11">
                  Trusted by businesses across the globe
                  <div className="flex text-xl font-medium text-amber-400">
                    <i className="mdi mdi-star"></i>
                    <p className="text-green-400">Trustpilot</p>
                    <ul className="text-xl font-medium text-amber-400 list-none mb-2">
                      <li className="inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                      <li className="inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                      <li className="inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                      <li className="inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                      <li className="inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                    </ul>
                  </div>
                </p>
              </div>
            </div>
            <div className="lg:col-span-4 md:col-span-4">
              <div className="flex flex-col items-center justify-center">
                <div className="absolute top-8 right-24 hidden md:block">
                  <img
                    src={airplane}
                    alt="Aeroplane"
                    className="w-auto h-auto "
                  />
                </div>
                <div>
                  <img src={group9} alt="Group" className="w-auto h-auto" />
                </div>
                <div className="absolute bottom-16 right-16 hidden md:block">
                  <img src={group8} alt="Airplane" className="w-16 h-16" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" border-t border-b border-gray-100 dark:border-gray-700">
        <div className="container relative">
          <CompanyLogo />
        </div>
      </section>

      <section className="relative md:py-12 py-8 lg:py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-4 text-center">
            <h3 className="mb-2 md:text-3xl text-textHighlight md:leading-normal text-2xl leading-normal font-semibold">
              Services
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit..
            </p>
          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 mt-4 gap-[30px]">
            {servecies1.map((item, index) => {
              return (
                <div
                  className="group relative border border-btnBackground p-4 rounded-lg flex flex-col"
                  key={index}
                >
                  <div className="mt-6 flex-grow">
                    <Link
                      to="#"
                      className="text-xl font-semibold hover:text-indigo-600 transition-all duration-500 ease-in-out"
                    >
                      {item.title}
                    </Link>

                    <p className="text-slate-400 mt-4">{item.desc}</p>
                  </div>
                  <div className="py-4 px-2 mt-auto">
                    <img src={item?.image} className="" alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <div className="container relative md:py-12 py-8 lg:py-16">
        <div className="absolute inset-0 opacity-25 dark:opacity-50 bg-[url('../../assets/images/Worldmap1.png')] bg-no-repeat bg-center bg-cover"></div>
        <div className="relative grid grid-cols-1 pb-8 text-center z-1">
          <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-textHighlight dark:text-white">
            Trusted by more than 10K users
          </h3>

          <p className="text-black max-w-xl mx-auto">
            Start working with Tailwind CSS that can provide everything you need
            to generate awareness, drive traffic, connect.
          </p>
        </div>

        <div className="relative grid md:grid-cols-3 grid-cols-1 items-center mt-8 gap-[30px] z-1">
          <div className="counter-box text-center">
            <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-textHighlight dark:text-white">
              <CountUp className="counter-value" start={1010} end={1548} />+
            </h1>
            <h5 className="counter-head text-lg text-textHighlight font-medium">
              Couriers Delivered
            </h5>
          </div>

          <div className="counter-box text-center">
            <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-textHighlight dark:text-white">
              <CountUp className="counter-value" start={2} end={25} />+
            </h1>
            <h5 className="counter-head text-lg text-textHighlight font-medium">
              Award Gained
            </h5>
          </div>

          <div className="counter-box text-center">
            <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-textHighlight dark:text-white">
              <CountUp className="counter-value" start={0} end={9} />+
            </h1>
            <h5 className="counter-head text-lg font-medium text-textHighlight">
              Years Experience
            </h5>
          </div>
        </div>
      </div>
      <div className="container gap-3 relative grid grid-cols-1 rounded md:py-12 py-8 lg:py-16 text-center dark:bg-slate-800">
        <div className="mx-auto mt-2">
          <div className="px-4 py-1 border border-black rounded-full bg-bgGrey">
            <p>Are you ready?</p>
          </div>
        </div>
        <h1 className=" text-textHighlight font-bold text-2xl leading-medium max-w-xl mx-auto">
          Lorem Ipsum
        </h1>
        <p className=" md:text-xl leading-medium  text-justify">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        <div className="mx-auto mt-2">
          <div className="px-4 py-1 border text-xl font-bold border-btnBackground text-white rounded-full bg-btnBackground">
            <p>Explore</p>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto md:py-12 py-8 lg:py-16"></div>
      <section className="relative dark:bg-slate-800 md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <div className="lg:ms-5">
                <h2 className="text-3xl font-semibold text-textHighlight mb-8">
                  How it works?
                </h2>
                <div className="flex flex-col space-y-8">
                  {steps.map((step) => (
                    <div key={step.id} className="flex items-start space-x-4 ">
                      <div className="flex-shrink-0 justify-center items-center border-black border p-3 rounded-full">
                        <img
                          src={step.icon}
                          alt={step.title}
                          className="h-9 w-9 "
                        />
                      </div>
                      <div>
                        <h3 className="text-xl font-semibold text-gray-800">
                          {step.title}
                        </h3>
                        <p className="text-gray-600">{step.description}</p>
                        <a href="#" className="text-blue-500 mt-2 inline-block">
                          Read More
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="lg:col-span-5 md:col-span-6">
              <div className="flex flex-col items-center justify-center">
                <div className="absolute top-6 hidden md:block">
                  <img src={hitw5} alt="Aeroplane" className="w-20 h-20 " />
                </div>
                <div>
                  <img src={hitw4} alt="Group" className="w-full h-auto" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:py-12 py-8 lg:py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 md:hidden mt-8">
            <div className="md:col-span-12 text-center">
              <Link
                to="#"
                className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500"
              >
                See More <FaArrowRight className="ms-2 text-[10px]" />
              </Link>
            </div>
          </div>
        </div>

        <div className="container relative md:py-12 py-8 lg:py-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-textHighlight dark:text-white">
              Testimonials
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              incididunt ut labore et dolore magna aliqua.
            </p>
          </div>

          <div className="flex  justify-center items-center mt-16">
            <div className="relative md:w-1/3 w-full">
              <TinySlider settings={settings}>
                {TestimonialData.map((item, index) => {
                  return (
                    <div className="tiny-slide" key={index}>
                      <div className="relative">
                        <img
                          src={item?.image}
                          alt=""
                          className="object-cover w-full h-60 mx-auto rounded-t-2xl"
                        />
                        <div className="rounded-t-2xl absolute bg-gradient-to-t from-gray-800 opacity-75 inset-0 z-0"></div>
                      </div>
                      <h6 className="mt-2 font-semibold">{item.name}</h6>
                      <span className="text-slate-400 text-sm">
                        {item.role}
                      </span>
                      <div className="mt-5">
                        <p className="text-lg text-slate-400 italic">
                          {item.feedback}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </TinySlider>
            </div>
          </div>
        </div>

        <section className="relative md:py-12 py-8 lg:py-16">
          <div className="container relative  ">
            <div className="absolute inset-0  opacity-25 dark:opacity-50  bg-no-repeat bg-center bg-cover"></div>

            <div className="relative grid grid-cols-1 pb-8 text-center">
              <p className=" text-textHighlight leading-medium max-w-xl mx-auto">
                Frequently Asked Questions
              </p>
              <h6 className=" md:text-xl leading-medium text-2xl font-semibold">
                Got questions? Weve got answers! Explore our FAQ section for
                quick solutions
              </h6>
            </div>
            {faqData.length === 0 ? null : (
              <div className="relative rounded-sm grid grid-cols-1 mt-2 gap-[30px]">
                <div>
                  {faqData1.map((item, index) => (
                    <div
                      key={index}
                      className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4"
                    >
                      <h2
                        className="text-base font-semibold"
                        id="accordion-collapse-heading-1"
                      >
                        <button
                          type="button"
                          onClick={() => toggleAccordion(item.id)}
                          className={`flex justify-between items-center p-5 w-full font-medium text-start ${
                            activeIndex === item.id
                              ? "bg-lightGrey dark:bg-slate-800 text-btnBackground"
                              : ""
                          }`}
                          data-accordion-target="#accordion-collapse-body-1"
                          aria-expanded="true"
                          aria-controls="accordion-collapse-body-1"
                        >
                          <span>{item.questions}</span>
                          <svg
                            data-accordion-icon
                            className={`${
                              activeIndex === item.id
                                ? "rotate-180"
                                : "rotate-270"
                            } w-4 h-4 shrink-01`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </h2>
                      {activeIndex === item.id && (
                        <div>
                          <div className="p-5 bg-lightGrey">
                            <p className="text-btnBackground dark:text-gray-400">
                              {item?.answers}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>

        {/* <ManagementTeam className="relative md:mt-24 mt-16" id={""} /> */}
      </section>

      <Footer />
      <CookieModal />
    </>
  );
}
