import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaHandHoldingDollar } from "react-icons/fa6";
import imageP from "../../assets/images/consulting/1.jpg";
import image1 from "../../assets/images/consulting/2.jpg";
import image2 from "../../assets/images/consulting/3.jpg";
import ab03 from "../../assets/images/about/ab03.jpg";
import ab02 from "../../assets/images/about/ab02.jpg";
import ab01 from "../../assets/images/about/ab01.jpg";
import pro1 from "../../assets/images/portfolio/pro1.jpg";
import pro2 from "../../assets/images/portfolio/pro2.jpg";
import pro3 from "../../assets/images/portfolio/pro3.jpg";
import pro4 from "../../assets/images/portfolio/pro4.jpg";
import pro5 from "../../assets/images/portfolio/pro5.jpg";
import pro6 from "../../assets/images/portfolio/pro6.jpg";
import image3 from "../../assets/images/client/01.jpg";
import image4 from "../../assets/images/client/02.jpg";
import image5 from "../../assets/images/client/03.jpg";
import image6 from "../../assets/images/client/04.jpg";
import image7 from "../../assets/images/client/05.jpg";
import image8 from "../../assets/images/client/06.jpg";
import Select from "react-select";
import { CiDeliveryTruck } from "react-icons/ci";
import { FaMap } from "react-icons/fa";
import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";
import CookieModal from "../../component/cookieModal";
import ManagementTeam from "../../component/managementTeam";
import CompanyLogo from "../../component/companyLogo";
import { IoInformationCircleOutline } from "react-icons/io5";
import * as Icon from "react-feather";
import { FiAirplay } from "../../assets/icons/icons";

import TinySlider from "tiny-slider-react";
import CountUp from "react-countup";
import { FaStar } from "react-icons/fa6";
import {
  FaRegBuilding,
  LiaUniversitySolid,
  LuPizza,
  AiOutlineCheckCircle,
  BsCheckCircle,
  LiaTimesCircleSolid,
  LiaCrosshairsSolid,
  MdKeyboardArrowRight,
  FaArrowRight,
  BiLineChart,
  FiFileText,
  GoRocket,
  GoClock,
  LuUsers,
  LuSearch,
} from "../../assets/icons/icons";
import classic02 from "../../assets/images/saas/classic02.png";
import classic03 from "../../assets/images/saas/classic03.png";
import DimensionAndWidthCard from "../../component/DimensionAndWidthCard";
import Button from "../../component/Button";
import CourierCompaneis from "../../component/CourierCompaneis";
const settings = {
  container: ".tiny-single-item",
  items: 1,
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  speed: 400,
  gutter: 16,
};
export default function AboutUs() {
  const [activeIndex, setActiveIndex] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("company");
  const matchCategory = (category) => {
    setSelectedCategory(category);
  };

  const AboutData = [
    {
      image: imageP,
      title: "About the pickup",
      desc: "All our services offer door-to-door delivery, with parcels delivered to the address of your choice.",
      number: 1,
    },
    {
      image: image1,
      title: "About the delivery",
      desc: "Parcels are picked up between 9 am and 6 pm, with some services offering same-day collection.",
      number: 2,
    },
  ];
  const advantagesData = [
    {
      title: "Instant quotes ",
      desc: " for pallet shipping",
    },
    {
      title: "Real-time tracking",
      desc: "via our online tool",
    },
  ];
  const project = [
    { value: "Austria", label: "Austria" },
    { value: "Belgium", label: "Belgium" },
    { value: "England", label: "England" },
    { value: "SouthAfrica", label: "SouthAfrica" },
  ];

  const OrderingAs = [
    { value: "Individual", label: "Individual" },
    { value: "Business", label: "Business" },
  ];
  const servicesData = [
    { value: "Document", label: "Document" },
    { value: "Package", label: "Package" },
    { value: "Pallet", label: "Pallet" },
    { value: "Van Delivery", label: "Van Delivery" },
    { value: "FTL & LTL", label: "FTL & LTL" },
    { value: "CustomOffer", label: "CustomOffer" },
  ];
  const accordionData = [
    {
      id: 1,
      title: "Correlation Analysis",
      content:
        "Receive heartfelt contributions instantly & straight to your bank. Guests can choose any amount of money to give",
    },
    {
      id: 2,
      title: "Drag-and-Drop Dashboard",
      content:
        "Receive heartfelt contributions instantly & straight to your bank. Guests can choose any amount of money to give",
    },
    {
      id: 3,
      title: "What do I need to do to start selling",
      title: "Smart Trend Detection ?",
      content:
        "Receive heartfelt contributions instantly & straight to your bank. Guests can choose any amount of money to give",
    },
    {
      id: 4,
      title: "What happens when I receive an order ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 5,
      title: "How does it work ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 6,
      title: "Do I need a designer to use Techwind ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 7,
      title: "What do I need to do to start selling ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 8,
      title: "What happens when I receive an order ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
  ];
  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };

  const accordionData1 = accordionData.filter((x) => x.id < 4);

  const services = [
    {
      feedback:
        '" Techwind made the processes so easy. Techwind instantly increased the amount of interest and ultimately saved us over $10,000. " ',
      name: "Christa Smith",
      role: "Manager",
      image: image3,
    },
    {
      feedback:
        '" I highly recommend Techwind as the new way to sell your home "by owner". My home sold in 24 hours for the asking price. Best $400 you could spend to sell your home. "',
      name: "Christa Smith",
      role: "Manager",
      image: image4,
    },
    {
      feedback:
        '" My favorite part about selling my home myself was that we got to meet and get to know the people personally. This made it so much more enjoyable! "',
      name: "Christa Smith",
      role: "Manager",
      image: image5,
    },
    {
      feedback: '" Great experience all around! Easy to use and efficient. "',
      name: "Christa Smith",
      role: "Manager",
      image: image6,
    },
    {
      feedback:
        '" Techwind made selling my home easy and stress free. They went above and beyond what is expected. "',
      name: "Christa Smith",
      role: "Manager",
      image: image7,
    },
    {
      feedback:
        '" Techwind is fair priced, quick to respond, and easy to use. I highly recommend their services! "',
      name: "Christa Smith",
      role: "Manager",
      image: image8,
    },
  ];
  const services2 = [
    {
      icon: LiaUniversitySolid,
      desc: "To send a large number of packages at once",
    },
    {
      icon: LuPizza,
      title: "Food & Commodities",
      desc: "When items are too bulky to be sent in packages",
    },
    {
      icon: FaRegBuilding,
      title: "Real estate",
      desc: "For moving furniture and large household appliances",
    },
  ];
  const ratingData = [
    {
      title: "Collaborative",
      desc: "We believe in the power of shared knowledge.",
    },
    {
      title: "Bold",
      desc: "We are courageous enough to dare our team to take on new challenges.",
    },
    {
      title: "Progressive",
      desc: "We work in a fast-paced environment, constantly improving our processes.",
    },
  ];
  const StepsData = [
    {
      stepNo: "Step 1",
      title: "Select route",
    },
    {
      stepNo: "Step 2",
      title: "Select service",
    },
    {
      stepNo: "Behind the scenes",
      title: "Get your price",
    },
    {
      stepNo: "Step 3",
      title: "Get your price",
    },
  ];
  const infoData = [
    {
      icon: "https://placehold.co/200x200/EEE/31343C",
      title: "Box van",
      desc: "465 x 165 x 180 cm or 13 cubic metres and 1000 kg capacity",
    },
    {
      icon: "https://placehold.co/200x200/EEE/31343C",
      title: "Curtain-side van",
      desc: "410 x 210 x 230 cm or 19 cubic metres and 1000 kg capacity (with tail-lift only 700 kg)",
    },
  ];
  const LocationData = [
    {
      icon: Icon.Codesandbox,
      title: "Location 1 ",
      desc: "Lorem ipsum",
    },
    {
      icon: Icon.Send,
      title: "Location 2 ",
      desc: "Lorem Ipsum",
    },
  ];
  const landingOne = [
    {
      icon: BiLineChart,
      feature: "Hign Performance",
    },
    {
      icon: LiaCrosshairsSolid,
      feature: "Best Securities",
    },
    {
      icon: FiAirplay,
      feature: "Trusted Service",
    },
    {
      icon: GoRocket,
      feature: "Info Technology",
    },
    {
      icon: GoClock,
      feature: "24/7 Support",
    },
    {
      icon: LuUsers,
      feature: "IT Management",
    },
    {
      icon: FiFileText,
      feature: "Certified Company",
    },
    {
      icon: LuSearch,
      feature: "Data Analytics",
    },
  ];
  const ChecklistData = [
    {
      icon: Icon.Codesandbox,
      title: "About the pick-up",
    },
    {
      icon: Icon.Send,
      title: "About the delivery",
      desc: "I shipped a pallet from Italy to Czech Republic. It arrived in good conditions and within the time…",
    },
  ];
  const shippingData = [
    {
      icon: Icon.BarChart,
      title: "Pallet Transport ",
      desc: "Ideal for 1-5 pallete, optional tail-lift and manual handler",
    },
    {
      icon: Icon.Send,
      title: "Shared Truck (LTL)",
      desc: "I shipped a pallet from Italy to Czech Republic. It arrived in good conditions and within the time…",
    },
    {
      icon: Icon.Star,
      title: "Full Truck FTL",
      desc: "Booking system on Movssy is very easy and straight forward. You are given an idea of the cost…",
    },
    {
      icon: Icon.Star,
      title: "Van Delivery",
      desc: "Booking system on Movssy is very easy and straight forward. You are given an idea of the cost…",
    },
  ];
  const servecies1 = [
    {
      image: imageP,
      title: "Profitable Marketing",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 1,
    },
    {
      image: image1,
      title: "SEO Specialists",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 2,
    },
    {
      image: image2,
      title: "Audience Analysis",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 3,
    },
  ];
  const productData = [
    {
      image: pro1,
      title: "Techwind Personal Portfolio",
      name: "Creative",
    },
    {
      image: pro2,
      title: "Techwind Minimal Portfolio",
      name: "Minimal",
    },
    {
      image: pro3,
      title: "Techwind NFT Market",
      name: "Crypto",
    },
    {
      image: pro4,
      title: "Techwind Portfolio",
      name: "Portfolio",
    },
    {
      image: pro5,
      title: "Techwind Constuction",
      name: "Corporate",
    },
    {
      image: pro6,
      title: "Techwind SEO",
      name: "SEO",
    },
  ];
  return (
    <>
      <Navbar navClass="nav-light" />
      <section className="relative md:py-50 py-44  bg-right bg-no-repeat bg-cover">
        <div className="absolute inset-0 "></div>
        <div className="container relative">
          <div className="grid grid-cols-1 items-center">
            <h4 className="font-bold lg:leading-normal leading-normal text-2xl lg:text-4xl mb-2 text-black">
              About Us <br /> Your company’s logistics <br /> control tower
            </h4>
          </div>
        </div>
      </section>
      <div className="grid w-full grid-cols-1  text-center">
        <div className="filters-group-wrap mt-4">
          <div className="filters-group">
            <ul className="mb-0 list-none container-filter-border-bottom filter-options  space-x-8">
              <li
                className={`${
                  selectedCategory === "company" ? "active" : ""
                } inline-block  text-lg font-semibold mb-3 cursor-pointer relative border-b border-transparent text-slate-400 duration-500`}
                onClick={() => matchCategory("company")}
              >
                Company
              </li>
              <li
                className={`${
                  selectedCategory === "mission" ? "active" : ""
                } inline-block me-6 text-lg font-semibold mb-3 cursor-pointer relative border-b border-transparent text-slate-400 duration-500`}
                onClick={() => matchCategory("mission")}
              >
                Mission
              </li>
              <li
                className={`${
                  selectedCategory === "howitworks" ? "active" : ""
                } inline-block me-6 text-lg font-semibold mb-3 cursor-pointer relative border-b border-transparent text-slate-400 duration-500`}
                onClick={() => matchCategory("howitworks")}
              >
                How it works
              </li>

              <li
                className={`${
                  selectedCategory === "trust" ? "active" : ""
                } inline-block me-6 text-lg font-semibold mb-3 cursor-pointer relative border-b border-transparent text-slate-400 duration-500`}
                onClick={() => matchCategory("trust")}
              >
                Trust
              </li>
            </ul>
          </div>
        </div>
      </div>
      {selectedCategory === "company" ? (
        <>
          <section className="relative   dark:bg-slate-800 md:py-12 py-8 lg:py-16">
            <div className="container relative">
              <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                <div className="lg:col-span-7 md:col-span-6">
                  <div className="lg:ms-5">
                    <h3 className="mb-6 md:text-3xl text-textHighlight text-2xl md:leading-normal leading-normal font-semibold">
                      What is Movssy?
                    </h3>

                    <p className="text-slate-400 max-w-xl">
                      Movssy is a freight forwarder that connects shippers and
                      logistics companies worldwide. Our centralised solution
                      caters to every logistics requirement, providing access to
                      the best transporters at discounted rates. We empower
                      businesses to expand their reach and take full control of
                      their logistics processes.
                    </p>
                  </div>
                </div>
                <div className="lg:col-span-5 md:col-span-6">
                  <div className="grid grid-cols-2 gap-6 items-center">
                    <div className="col-span-6">
                      <div className="grid grid-cols-1 gap-6">
                        <img
                          src={"https://placehold.co/600x400/EEE/31343C"}
                          className="shadow rounded-md"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="relative  dark:bg-slate-800 md:py-12 py-8 lg:py-16">
            <div className="container relative ">
              <div className="absolute inset-0 opacity-25 dark:opacity-50  bg-no-repeat bg-center bg-cover"></div>

              <div className="relative grid grid-cols-1 pb-8 text-center">
                <h3 className=" md:text-3xl leading-medium text-2xl font-semibold text-textHighlight">
                  Our Locations
                </h3>
              </div>
              <div className="sm:flex mt-4">
                <div className="sm:w-1/2 picture-item p-4 rounded-md">
                  <div className="">
                    <div className="relative">
                      <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                        <img
                          src={"https://placehold.co/600x400/EEE/31343C"}
                          className="rounded-t-md shadow"
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="pt-4 px-3">
                      <h5 className="mb-1 font-semibold text-xl">
                        <Link
                          to="#"
                          className="hover:text-indigo-600 transition-all duration-500 ease-in-out"
                        >
                          Location 1
                        </Link>
                      </h5>
                      <span className="text-slate-400">Lorem Ipsum</span>
                    </div>
                  </div>
                </div>

                <div className="sm:w-1/2 picture-item p-4 rounded-md">
                  <div className="">
                    <div className="relative">
                      <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                        <img
                          src={"https://placehold.co/600x400/EEE/31343C"}
                          className="rounded-t-md shadow"
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="pt-4 px-3">
                      <h5 className="mb-1 font-semibold text-xl">
                        <Link
                          to="#"
                          className="hover:text-emerald-600 transition-all duration-500 ease-in-out"
                        >
                          Location 2
                        </Link>
                      </h5>
                      <span className="text-slate-400">Lorem Ipsum</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="relative md:py-12 py-8 lg:py-16">
            <div className="container relative">
              <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                  <span className="text-textHighlight text-bold md:text-4xl text-2xl">
                    Time Line
                  </span>
                  <br /> Our Story
                </h3>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 mt-8 gap-x-[30px] gap-y-[50px]">
                {landingOne.map((data, index) => {
                  let Icons = data.icon;
                  return (
                    <div className="text-center md:px-3" key={index}>
                      <div className="w-24 h-24 bg-indigo-600/5 text-btnBackground rounded-3xl text-4xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                        <Icons className="h-7 w-7" />
                      </div>

                      <div className="content mt-7">
                        <Link
                          to="/page-services"
                          className="title h5 text-lg font-medium hover:text-indigo-600"
                        >
                          {"Lorem Ipsum"}
                        </Link>
                        <p className="text-slate-400 mt-3">
                          The phrasal sequence of the Lorem Ipsum text is now so
                          that many
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          <section className="relative md:py-12 py-8 lg:py-16 justify-center  dark:bg-slate-800">
            <div className="container relative text-center  py-8 ">
              <div className="relative grid grid-cols-1 pb-8 text-center z-1">
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-black dark:text-white">
                  Movssy in numbers
                </h3>
              </div>

              <div className="relative  grid md:grid-cols-3 grid-cols-1 items-center  gap-[30px] z-1">
                <div className="counter-box text-center justify-center flex flex-col">
                  <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-textHighlight dark:text-white">
                    <CountUp
                      className="counter-value"
                      start={1010}
                      end={3000}
                    />
                    +
                  </h1>
                  <h5 className="counter-head text-lg font-bold">
                    Registered Users
                  </h5>
                </div>

                <div className="counter-box text-center items-center justify-center flex flex-col">
                  <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-slate-400 dark:text-white">
                    <CountUp className="counter-value" start={0} end={1} />
                    M+
                  </h1>
                  <h5 className="counter-head text-lg font-bold">
                    Monthly Websites visit
                  </h5>
                </div>

                <div className="counter-box text-center  justify-center flex flex-col">
                  <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-slate-400 dark:text-white">
                    <CountUp className="counter-value" start={0} end={95} />+
                  </h1>
                  <h5 className="counter-head text-lg font-bold">
                    Team Members
                  </h5>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : selectedCategory === "mission" ? (
        <>
          <section className="relative  dark:bg-slate-800 md:py-12 py-8 lg:py-16">
            <div className="container relative">
              <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px] ">
                <div className="lg:col-span-5 md:col-span-6">
                  <div className="grid grid-cols-2 gap-6 items-center">
                    <div className="col-span-6">
                      <div className="grid grid-cols-1 gap-6">
                        <img
                          src={"https://placehold.co/600x400/EEE/31343C"}
                          className="shadow rounded-md"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-7 md:col-span-6">
                  <div className="lg:ms-5">
                    <h3 className="mb-6 md:text-3xl text-textHighlight text-2xl md:leading-normal leading-normal font-semibold">
                      Our mission
                    </h3>
                    <p className="text-btnBackground max-w-xl text-2xl mb-2">
                      Logistics. Digitalised.
                    </p>
                    <p className="text-slate-400 max-w-xl">
                      To digitalise the traditional paper-based logistics
                      industry, to empower companies to automatise their
                      logistics operations, to help freight forwarders and
                      transporters fill up their trucks, and to provide
                      pre-negotiated quotes for anyone.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="relative justify-center md:py-12 py-8 lg:py-16 ">
            <div className="container relative   ">
              <div className="relative grid grid-cols-1  text-left ">
                <h6 className=" md:text-2xl text-textHighlight leading-medium text-xl font-bold mx-auto">
                  What we stand for
                </h6>
                <div className="py-4 text-textHighlight md:flex md:justify-between md:items-center gap-[15px] ">
                  <div className="flex flex-col  w-full items-center my-1 ">
                    <Icon.Tablet className="text-btnBackground w-7 h-7" />
                    <span className="ml-4 font-bold">
                      Tech solutions to achieve the impossible
                    </span>
                  </div>
                  <div className="flex flex-col  w-full items-center my-1">
                    <Icon.User className="text-btnBackground w-7 h-7" />
                    <span className="ml-4 font-bold">
                      Outstanding customer service
                    </span>
                  </div>
                  <div className="flex flex-col w-full items-center my-1">
                    <Icon.Star className="text-btnBackground w-7 h-7" />
                    <span className="ml-4 font-bold">
                      The right answer for complex logistics issues
                    </span>
                  </div>
                  <div className="flex flex-col w-full items-center my-1">
                    <Icon.Users className="text-btnBackground w-7 h-7" />
                    <span className="ml-4 font-bold">
                      A strong multicultural team
                    </span>
                  </div>
                </div>
                <div className="flex text-btnBackground hover:text-blue-600 cursor-pointer items-center mx-auto">
                  <Icon.ArrowRight className=" me-2" />
                  <p className="">More about Movssy</p>
                </div>
              </div>
            </div>
          </section>
          <section className="relative justify-center md:py-12 py-8 lg:py-16 ">
            <div className="container relative   ">
              <div className="relative grid grid-cols-1   ">
                <h6 className=" md:text-2xl leading-medium text-xl font-bold mx-auto">
                  Our Values
                </h6>

                <div className="relative grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-2 gap-[30px]">
                  {ratingData.map((item, index) => {
                    let Icons = item.icon;
                    return (
                      <div
                        key={index}
                        className="group px-6 py-8 bg-bgGrey dark:bg-slate-900  dark:hover:shadow-gray-800   "
                      >
                        <div className="content text-xl text-btnBackground font-semibold">
                          {item?.title}
                        </div>
                        <div className="border-b-2 my-1"></div>
                        <div className="content text-xl  font-thin">
                          {item.desc}

                          {/* <p className="text-slate-400 mt-4">{item.desc}</p> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </>
      ) : selectedCategory === "howitworks" ? (
        <>
          <section className="relative  dark:bg-slate-800 md:py-24 py-16">
            <div className="container relative">
              <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                <div className="lg:col-span-7 md:col-span-6">
                  <div className="lg:ms-5">
                    <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                      Understanding the process
                    </h3>

                    <p className="text-slate-400 max-w-xl">
                      At Movssy, we developed a proprietary algorithm capable of
                      matching specific requests with the perfect shipping
                      solution at the lowest price. Our automatised platform
                      compares thousands of quotes and services from the best
                      international logistics companies in a matter of seconds,
                      always showing our clients the best performing option for
                      their route and preferred type of service. All the quotes
                      are pre-negotiated, which means we have already done the
                      hard work, and all the booking options are now digital,
                      meaning that with only a few clicks, you can book a simple
                      parcel delivery or an entire truck.
                    </p>
                  </div>
                </div>
                <div className="lg:col-span-5 md:col-span-6">
                  <div className="grid grid-cols-2 gap-6 items-center">
                    <div className="col-span-6">
                      <div className="grid grid-cols-1 gap-6">
                        <img
                          src={"https://placehold.co/600x400/EEE/31343C"}
                          className="shadow rounded-md"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="relative  dark:bg-slate-800 md:py-24 py-16">
            <div className="container relative">
              <h3 className="mb-1 text-btnBackgroun text-2xl md:leading-normal leading-normal font-semibold">
                Our Solutions
              </h3>
              <p className=" text-textHighlight max-w-xl text-3xl mb-2">
                Behind the scenes
              </p>
              <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] sm:items-center  ">
                <div className="lg:col-span-8 md:col-span-8">
                  <div className="grid grid-cols-1 gap-6 ">
                    <div className="col-span-6">
                      <div className="grid grid-cols-1 gap-6 items-center">
                        <div className="flex justify-end">
                          <p className="text-textHighlight max-w-xl text-3xl mb-2">
                            Order Process
                          </p>
                        </div>

                        <div className="py-4 md:flex md:justify-between md:items-center gap-[15px] ">
                          {StepsData.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="flex flex-col w-full   "
                              >
                                <Icon.Tablet className="text-btnBackgroun w-7 h-7" />
                                <span className="mt-1 text-slate-400 text-xs">
                                  {item?.stepNo}
                                </span>

                                <span className="mt-1 text-textHighlight font-bold">
                                  {item?.title}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-4 md:col-span-4">
                  <div className="grid grid-cols-1 gap-6 items-center">
                    <div className="flex justify-start">
                      <p className="text-textHighlight max-w-xl text-3xl mb-2">
                        After Booking
                      </p>
                    </div>
                    <div className="py-4 md:flex md:justify-between md:items-center gap-[15px] ">
                      <div className="flex flex-col items-center justify-center w-full">
                        <Icon.Tablet className="text-btnBackground w-7 h-7" />
                        <span className="mt-1 text-slate-400 text-xs">
                          {"Step 4"}
                        </span>
                        <span className="mt-1 font-bold text-textHighlight">
                          {"Pickup & Delivery"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="py-4 mb-6 border-t border-b border-gray-100 dark:border-gray-700">
            <div className="container relative">
              <CourierCompaneis />
            </div>
          </section>
        </>
      ) : selectedCategory === "trust" ? (
        <>
          <section className="relative md:py-12 py-8 lg:py-16">
            <div className="container relative  ">
              <div className="absolute inset-0  opacity-25 dark:opacity-50  bg-no-repeat bg-center bg-cover"></div>

              <div className="relative grid grid-cols-1  text-center">
                <p className=" text-btnBackground font-semibold leading-medium max-w-xl mx-auto">
                  WE HEAR YOU
                </p>
                <h3 className=" md:text-3xl text-2xl md:leading-normal leading-normal font-bold">
                  Your opinion matters
                </h3>
                <p className="text-slate-400 max-w-xl mx-auto mb-2">
                  When we say that we care, we mean it! We are demanding when it
                  comes to how we talk and support our customers, and we
                  definitely want to know what they think. Only by doing so, we
                  can keep improving our service and satisfying even more
                  clients.
                </p>
              </div>
            </div>
          </section>
          <section className="relative  dark:bg-slate-800 md:py-12 py-8 lg:py-16">
            <div className="container relative">
              <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                <div className="lg:col-span-5 md:col-span-6">
                  <div className="grid grid-cols-2 gap-6 items-center">
                    <div className="col-span-6">
                      <div className="grid grid-cols-1 gap-6">
                        <img
                          src={"https://placehold.co/600x400/EEE/31343C"}
                          className="shadow rounded-md"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-7 md:col-span-6">
                  <div className="lg:ms-5">
                    <h3 className=" md:text-3xl text-textHighlight text-2xl md:leading-normal leading-normal font-semibold">
                      <span>"</span>Since we use Movssy services, all the
                      operations go really smoothly.
                      <span>"</span>
                    </h3>

                    <p className="text-slate-400 max-w-xl text-xl">
                      Iron X Wood.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="relative  dark:bg-slate-800 md:py-12 py-8 lg:py-16">
            <div className="container relative">
              <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                <div className="lg:col-span-7 md:col-span-6">
                  <div className="lg:ms-5">
                    <h3 className=" md:text-3xl text-textHighlight text-2xl md:leading-normal leading-normal font-semibold">
                      <span>"</span>They meet our last minute needs and provide
                      excellent service
                      <span>"</span>
                    </h3>

                    <p className="text-slate-400 max-w-xl text-xl">
                      Post Luxembourg
                    </p>
                  </div>
                </div>
                <div className="lg:col-span-5 md:col-span-6">
                  <div className="grid grid-cols-2 gap-6 items-center">
                    <div className="col-span-6">
                      <div className="grid grid-cols-1 gap-6">
                        <img
                          src={"https://placehold.co/600x400/EEE/31343C"}
                          className="shadow rounded-md"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : null}

      <Footer />
      <CookieModal />
    </>
  );
}
