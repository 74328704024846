export const getUser = () => {
  const userData = sessionStorage.getItem("User");
  try {
    const parsedUser = JSON.parse(userData);
    console.log("🚀 ~ getUser ~ parsedUser:", parsedUser);
    if (parsedUser && parsedUser.token) {
      return parsedUser;
    }
  } catch (error) {
    console.error("Error parsing user data:", error);
  }
  return null;
};
