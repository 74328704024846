import React from "react";
import { Link } from "react-router-dom";
import contact from "../../assets/images/contact.svg";
import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";
import { Stepper } from "@mantine/core";
import { IoIosHelpCircle } from "react-icons/io";
import {
  MdKeyboardArrowRight,
  FiPhone,
  FaRegEnvelope,
  RiMapPinLine,
} from "../../assets/icons/icons";
import { MdOutlineNumbers } from "react-icons/md";
import * as Icon from "react-feather";
import Select from "react-select";
import { useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function IndexTracking() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [originServiceArea, setOriginServiceArea] = useState("");
  const [destinationAreaa, setDestinationArea] = useState("");
  const [service, setService] = useState("");
  const [code, setCode] = useState("");

  const [status, setStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [date, setdate] = useState("");
  const [showtime, setTime] = useState("");

  const token = useSelector((state) => state.auth.token);
  console.log("🚀 ~ IndexTracking ~ token:", token);
  const eventHandlers = React.useMemo(
    () => ({
      onFocus: () => {
        setErrorMsg("");
        setService("");
      },
      onBlur: () => console.log("Event Handler"),
    }),
    []
  );

  const formatDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = parseInt(dateString.substring(4, 6), 10);
    const day = dateString.substring(6, 8);

    // Array of month names
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Format date into "Month Day, Year" format
    return `${months[month - 1]} ${parseInt(day, 10)}, ${year}`;
  };
  const formatTime = (timeString) => {
    const hours = timeString.substring(0, 2);
    const minutes = timeString.substring(2, 4);

    // Format time into "HH:mm" format
    return `${hours}:${minutes}`;
  };
  const TrackingAPi = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append("tracking_number", trackingNumber);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    await fetch("https://movssy.com/admin/api/main/track", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("🚀 ~ .then ~ result:", result);
        if (
          result.response?.["DHL Api"]?.trackShipmentRequestResponse
            ?.trackingResponse?.TrackingResponse?.AWBInfo?.ArrayOfAWBInfoItem
            .Status.ActionStatus === "Success"
        ) {
          const shipmentInfo =
            result.response?.["DHL Api"]?.trackShipmentRequestResponse
              ?.trackingResponse?.TrackingResponse?.AWBInfo?.ArrayOfAWBInfoItem
              .ShipmentInfo;
          console.log("🚀 ~ .then ~ shipmentInfo:", shipmentInfo);
          setOriginServiceArea(shipmentInfo?.OriginServiceArea?.Description);
          setDestinationArea(shipmentInfo?.DestinationServiceArea?.Description);
          const date = new Date(shipmentInfo?.ShipmentDate.split("T")[0]);
          const options = { year: "numeric", month: "long", day: "2-digit" };
          const formattedDate = date.toLocaleDateString("en-US", options);
          setdate(formattedDate);
          setTime(shipmentInfo?.ShipmentDate.split("T")[1]);
          setService("DHL EXPRESS");
          setCode(trackingNumber);
        } else if (result.response["Fedex Api"]) {
          if (result.response["Fedex Api"] === 403) {
            setErrorMsg("No shipment found");
          } else {
            const trackingResult =
              result.response["Fedex Api"].output.completeTrackResults[0]
                .trackResults[0];
            setService("Fedex EXPRESS");

            setOriginServiceArea(
              trackingResult?.originLocation?.locationContactAndAddress?.address
                ?.countryName
            );
            setDestinationArea(
              trackingResult?.destinationLocation?.locationContactAndAddress
                ?.address?.countryName
            );
            setCode(trackingNumber);
          }
        } else if (result.response["UPS Api"]) {
          if (result.response["UPS Api"].response.errors[0]) {
            setErrorMsg("No shipment found");
          } else {
            const shipment =
              result.response["UPS Api"].trackResponse?.shipment?.[0];
            const packageInfo = shipment?.package?.[0];
            const activity = packageInfo?.activity?.[0];
            const deliveryDate = packageInfo?.deliveryDate?.[0]?.date;
            const deliveryTime = packageInfo?.deliveryTime?.endTime;
            let time = formatTime(deliveryTime);
            setTime(time);
            let date = formatDate(deliveryDate);
            setdate(date);
            const address = activity?.location?.address;
            console.log("🚀 ~ .then ~ address:", address);
            setOriginServiceArea(address?.country);
            setDestinationArea(address?.country);
            const description = activity?.status?.description;
            setStatus(description);
            setService("UPS EXPRESS");
            setCode(trackingNumber);
          }
        } else {
          setErrorMsg("No shipment found");
          toast.error(result.message);
        }
      })
      .catch((error) => console.error(error));
  };
  return (
    <>
      <Navbar navClass="nav-light" />

      <section className="relative md:py-40 py-30  bg-right bg-no-repeat bg-cover">
        <div className="absolute inset-0  dark:bg-slate-900"></div>

        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <div className="lg:ms-5">
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold dark:text-white">
                  International Parcel Tracking | How does it work?
                </h3>
                <div className="flex flex-col  ">
                  <ul
                    className="dark:text-gray-400"
                    style={{
                      listStyleType: "disc",
                      marginLeft: 20,
                      marginBottom: 10,
                    }}
                  >
                    <li>
                      <span className="font-bold">
                        Copy the tracking number
                      </span>{" "}
                      sent to you by e-mail
                    </li>
                    <li>
                      Insert the parcel tracking number in the tool below and
                      click <span className="font-bold">Track</span>
                    </li>
                  </ul>

                  <div className="flex  border border-black border-spacing-6 rounded-sm">
                    <input
                      type="text"
                      {...eventHandlers}
                      placeholder="Input tracking or order number"
                      onChange={(e) => setTrackingNumber(e.target.value)}
                      className="w-full bg-white px-3 h-14 bg-transparent dark:bg-slate-900 dark:text-slate-200 border outline-none focus:border-btnBackground dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                    />
                    <input
                      onClick={TrackingAPi}
                      value={"Track"}
                      className="cursor-pointer w-28 py-[8px] justify-center items-center px-6 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-btnBackground hover:bg-btnBackground hover:border-indigo-600 text-white hover:text-white sm:inline-block"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flex: "0 0 auto", // Ensures the span doesn't grow or shrink
                      }}
                    />
                  </div>

                  <p className="text-red-500 text-sm mt-1">
                    {errorMsg && errorMsg}
                  </p>
                </div>
                <p className="text-slate-400 max-w-xl mt-2">
                  When shipping with Movssy, you will always know where your
                  parcel is! Locate your package during transit using your
                  shipment’s unique tracking number. All of our logistics
                  partners work with digital tracking systems, allowing you to
                  track your parcels online in real-time.
                </p>
              </div>
            </div>
            <div className="lg:col-span-5 md:col-span-6">
              <div className="grid grid-cols-2 gap-6 items-center">
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img
                      src={"https://placehold.co/600x400/EEE/31343C"}
                      className="shadow rounded-md"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:py-18 py-12 ">
        {/* <div className=" grid grid-cols-1 items-center ">
          <div className="relative grid grid-cols-1 pb-8 ">
            <h3 className=" text-indigo-600 text-xl md:leading-normal leading-normal font-semibold  text-center">
              TRACK & TRACE
            </h3>
            <h3 className="mb-2 md:text-3xl text-xl md:leading-normal leading-normal font-bold text-center">
              Track your Movssy parcel
            </h3>
            <div
              id="mainchart"
              className="px-6 pb-6 mt-2  md:mx-28 mx-8  py-2 mb-4 rounded"
            >
              <div className="flex flex-col ">
                <ul
                  className="text-xl"
                  style={{
                    listStyleType: "disc",
                    marginLeft: 20,
                    marginBottom: 10,
                  }}
                >
                  <li>
                    <span className="font-bold">Copy the tracking number</span>{" "}
                    sent to you by e-mail
                  </li>
                  <li>
                    Insert the parcel tracking number in the tool below and
                    click <span className="font-bold">Track</span>
                  </li>
                </ul>

                <div className="flex border border-black border-spacing-3 rounded-sm">
                  <input
                    type="text"
                    {...eventHandlers}
                    placeholder="Input tracking or order number"
                    onChange={(e) => setTrackingNumber(e.target.value)}
                    className="w-full bg-white px-3 h-14 bg-transparent dark:bg-slate-900 dark:text-slate-200 border outline-none focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                  />
                  <input
                    onClick={TrackingAPi}
                    value={"Track"}
                    className="cursor-pointer w-28 py-[8px] justify-center items-center px-6 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600/5 hover:bg-indigo-600 hover:border-indigo-600 text-indigo-600 hover:text-white sm:inline-block"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: "0 0 auto", // Ensures the span doesn't grow or shrink
                    }}
                  />
                </div>

                <p className="text-red-500 text-sm mt-1">
                  {errorMsg && errorMsg}
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="container relative md:my-8 my-6 duration-500 ">
          <div className=" grid md:col-span-5  grid-cols-1 items-center gap-[30px] mx-auto">
            <div className="">
              {/* <img src={contact} alt="" /> */}
              {service !== "" ? (
                <div className="bg-lightGrey shadow-sm  mt-4  lg:leading-normal leading-normal  mb-5 text-black dark:bg-slate-800">
                  <div className="px-4 pt-8">
                    <span className="text-xl lg:text-2xl font-bold dark:text-white">
                      Shipment Details
                    </span>
                    <div>
                      <div className="mt-2 rounded-xl text-black  items-center max-w-xl flex    dark:text-white">
                        <span>Tracking Code: </span>
                        <p className="text-btnBackground ml-1 ">
                          {code && code}
                        </p>
                      </div>
                      <div className="mt-2 rounded-xl text-black  items-center max-w-xl flex dark:text-white   ">
                        <span>This shipment is handle by: </span>
                        <p className="text-black font-bold ml-1 dark:text-gray-400">
                          {service && service}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" border-b-2 border-b-stone-500 py-2" />
                  <div className="px-4 py-4">
                    <span className="text-xl lg:text-2xl font-bold text-green-600">
                      {status && status}
                    </span>
                    <div className="mt-2 rounded-xl text-black  items-center max-w-xl flex  dark:text-white">
                      <span>
                        {date && date} {showtime && showtime} Local time,
                        Service Area: {originServiceArea && originServiceArea}{" "}
                      </span>
                    </div>
                    <div className="mt-2 rounded-xl text-black  items-center max-w-xl flex    ">
                      <span className="text-sm font-bold text-black dark:text-white">
                        Origin Service Area:{" "}
                        {originServiceArea && originServiceArea}
                      </span>
                    </div>
                    <div className="mt-2 rounded-xl text-black dark:text-white items-center max-w-xl flex    ">
                      <span className="text-sm font-bold text-black">
                        Destination Service Area:{" "}
                        {destinationAreaa && destinationAreaa}
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
