import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { authReducer } from "./slices/authSlice";
import { addressReducer } from "./slices/addressSlice";
import { pathReducer } from "./slices/pathSlice";
import storage from "redux-persist/lib/storage";
import { walletReducer } from "./slices/walletSlice";
import { ticketReducer } from "./slices/ticketSlice";
import { languageReducer } from "./slices/languageSlice";
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth", "walletDetail", "multiLangauge"],
};
const rootreducer = combineReducers({
  auth: authReducer,
  path: pathReducer,
  addressDetail: addressReducer,
  Ticket: ticketReducer,
  walletDetail: walletReducer,
  multiLangauge: languageReducer,
});
const persistedReducer = persistReducer(persistConfig, rootreducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
