import React, { useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import "./assets/libs/@mdi/font/css/materialdesignicons.min.css";
import Navbar from "./component/Navbar/navbar";
import AddressBooks from "./pages/dashboard/AddressBook";
import UserNotification from "./pages/accounts/userNotification";
import UserPayment from "./pages/accounts/userPayment";
import ViewSingleUserOrder from "./pages/dashboard/ViewSingleOrder";
import UserProfile from "./pages/accounts/userProfile";
import UserSetting from "./pages/accounts/userSetting";

import Chats from "./pages/dashboard/chat";
import IndexConsulting from "./pages/index/indexConsulting";
import Helpcenter from "./pages/helpcenter/helpcenter";
import HelpcenterSupport from "./pages/helpcenter/helpcenterSupport";
import HelpcenterFaqs from "./pages/helpcenter/helpcenterFaqs";
import CheckLanguage from "./pages/dashboard/CheckLanguage";
import EmailPasswordReset from "./pages/email-template/emailPasswordReset";
import EmailInvoice from "./pages/email-template/emailInvoice";
import EmailConfirmation from "./pages/email-template/emailConfirmation";
import PaymentHistoryDashboard from "./pages/dashboard/paymentHistory";
import ProfileSetting from "./pages/user-profile/profileSetting";
import AuthSignupSuccess from "./pages/auth/authSignupSuccess";
import AuthRePassword from "./pages/auth/authRePassword";
import AuthLogin from "./pages/auth/authLogin";
import AuthLockScreen from "./pages/auth/authLockScreen";
import HelpcenterGuides from "./pages/helpcenter/helpcenterGuides";
import Switch from "./component/Switch";
import ContactUs from "./pages/contact/ContactUs";
import AuthOption from "./pages/auth/AuthOption";
import Package from "./pages/ServicesPages/Pacakge";
import Pallete from "./pages/ServicesPages/Pallete";
import Document from "./pages/ServicesPages/Document";
import VanDelivery from "./pages/ServicesPages/VanDelivery";
import FtlAndLtl from "./pages/ServicesPages/FtlAndLtl";
import LtlTransport from "./pages/ServicesPages/LtlTransport";
import FtlTransport from "./pages/ServicesPages/FtlTransport";
import OrderDetails from "./pages/Booking/OrderDetails";
import Addresses from "./pages/Booking/Addresses";
import Safety from "./pages/Booking/Safety";
import UpdateTicket from "./pages/dashboard/UpdateTicket";
import Payment from "./pages/Booking/Payment";
import AboutUs from "./pages/company/AboutUs";
import OtpVerificationScreen from "./pages/auth/OtpVerificationScreen";
import ContinueWithEmail from "./pages/auth/ContinueWithEmail";
import Topnav from "./component/topnav";
import Sidebar from "./component/sidebar";
import SidebarLight from "./component/sidebarLight";
import IndexTracking from "./pages/Tracking/tracking";
import SidebarColored from "./component/sidebarColored";
import DashBoard from "./pages/dashboard/dashboard";
import MyDeatils from "./pages/user-profile/mydetails";
import ChangePassword from "./pages/user-profile/changePassword";
import NewOrder from "./pages/dashboard/newOrder";
import OrderList from "./pages/dashboard/orderList";
import WalletDashboard from "./pages/dashboard/wallet";
import PaymentMethodDashboard from "./pages/dashboard/paymentMethod";
import Tickets from "./pages/dashboard/Ticket";
import ComposeTicket from "./pages/dashboard/ComposeTicket";

import LoginForm from "./pages/auth/Login";
import SignUp from "./pages/auth/signUp";
import ResetPassword from "./pages/auth/ResetPassword";
import ShowAddress from "./pages/dashboard/ShowAddress";
import UpdateAddressBook from "./pages/dashboard/UpdateAddressBook";
import Label from "./component/Table/Label";
import ProtectiveRoute from "./pages/ProtectiveRoute/protectiveRoute";
import ProtectiveLoginRoute from "./pages/ProtectiveRoute/protectiveLoginRoute";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import RemovalandRecolation from "./pages/ServicesOnRequest/relocation&removals";
import Step2ContactInfo from "./pages/ServicesOnRequest/Step2ContactInfo";
import Confirmation from "./pages/ServicesOnRequest/confirmation";
import PageError from "./pages/special/pageError";
const stripePromise = loadStripe(
  "pk_test_51OpDlnFwXQXOM9zvp2GjDiQgoChsXbDQzZ0qYKmkzdsgCaRv0b3cKd5skawUiqdKfnIfflwF3ejGDDn6ZTLW6M6p00U8HdPaAz"
);
export default function App() {
  const [toggle, setToggle] = useState(true);
  let location = useLocation();

  return (
    <>
      <Elements stripe={stripePromise}>
        {location.pathname === "/" ||
        location.pathname === "/index" ||
        location.pathname === "/register/business" ||
        location.pathname === "/contact-info" ||
        location.pathname === "/parcel-tracking" ||
        location.pathname === "/aboutus" ||
        location.pathname === "/register/personal" ||
        location.pathname === "/login" ||
        location.pathname === "/user-notification" ||
        location.pathname === "/user-payment" ||
        location.pathname === "/user-setting" ||
        location.pathname === "/navbar" ||
        location.pathname === "/shop-about" ||
        location.pathname === "/pacakge" ||
        location.pathname === "/document" ||
        location.pathname === "/ftl-transport" ||
        location.pathname === "/ltl-transport" ||
        location.pathname === "/frieght-transport" ||
        location.pathname === "/van-delivery" ||
        location.pathname === "/pallete" ||
        location.pathname === "/signup" ||
        location.pathname === "/register" ||
        location.pathname === "/contact-us" ||
        location.pathname === "/auth-signup-success" ||
        location.pathname === "/order-confirmation" ||
        location.pathname === "/register-option" ||
        location.pathname === "/order-payment" ||
        location.pathname === "/order-safety" ||
        location.pathname === "/order-details" ||
        location.pathname === "/relocation&removal" ||
        location.pathname === "/order-address" ||
        location.pathname === "/email-confirmation" ||
        location.pathname === "/email-invoice" ||
        location.pathname === "/email-password-reset" ||
        location.pathname === "/helpcenter-faqs" ||
        location.pathname === "/auth-lock-screen" ||
        location.pathname === "/auth-login" ||
        location.pathname === "/auth-re-password" ||
        location.pathname === "/continue-with-email" ||
        location.pathname === "/Otp-Verification" ||
        location.pathname === "/label" ||
        location.pathname === "/user-dashboard/order-language" ||
        location.pathname === "/helpcenter-overview" ||
        location.pathname === "/helpcenter-guides" ||
        location.pathname === "/helpcenter-support" ||
        location.pathname.startsWith("/reset/") ? (
          <Routes>
            <Route path="/" element={<Navigate to="/index" />} />
            <Route exact path="/index" element={<IndexConsulting />} />
            <Route
              exact
              path="/login"
              element={
                <ProtectiveLoginRoute>
                  <LoginForm />
                </ProtectiveLoginRoute>
              }
            />
            <Route exact path="/parcel-tracking" element={<IndexTracking />} />
            <Route
              exact
              path="/reset/:token"
              element={
                <ProtectiveLoginRoute>
                  <ResetPassword />
                </ProtectiveLoginRoute>
              }
            />
            <Route
              exact
              path="/user-notification"
              element={<UserNotification />}
            />
            <Route exact path="/user-payment" element={<UserPayment />} />
            <Route exact path="/user-profile" element={<UserProfile />} />
            <Route exact path="/user-setting" element={<UserSetting />} />
            <Route exact path="/navbar" element={<Navbar />} />
            <Route exact path="/aboutus" element={<AboutUs />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/register" element={<AuthOption />} />
            <Route exact path="/pallete" element={<Pallete />} />
            <Route exact path="/van-delivery" element={<VanDelivery />} />
            <Route exact path="/frieght-transport" element={<FtlAndLtl />} />
            <Route exact path="/ltl-transport" element={<LtlTransport />} />
            <Route
              exact
              path="/ftl-transport"
              element={<FtlTransport />}
            />{" "}
            <Route exact path="/document" element={<Document />} />
            <Route exact path="/pacakge" element={<Package />} />
            <Route
              exact
              path="/order-address"
              element={
                <ProtectiveRoute>
                  <Addresses />
                </ProtectiveRoute>
              }
            />
            <Route
              exact
              path="/order-details"
              element={
                <ProtectiveRoute>
                  <OrderDetails />
                </ProtectiveRoute>
              }
            />
            <Route
              exact
              path="/order-safety"
              element={
                <ProtectiveRoute>
                  <Safety />
                </ProtectiveRoute>
              }
            />
            <Route
              exact
              path="/order-payment"
              element={
                <ProtectiveRoute>
                  <Payment />
                </ProtectiveRoute>
              }
            />
            <Route
              exact
              path="/order-confirmation"
              element={<Confirmation />}
            />
            <Route exact path="/helpcenter-overview" element={<Helpcenter />} />
            <Route
              exact
              path="/Otp-Verification"
              element={<OtpVerificationScreen />}
            />
            <Route
              exact
              path="/continue-with-email"
              element={<ContinueWithEmail />}
            />
            <Route
              exact
              path="/helpcenter-support"
              element={<HelpcenterSupport />}
            />
            <Route exact path="/helpcenter-faqs" element={<HelpcenterFaqs />} />
            <Route
              exact
              path="/helpcenter-guides"
              element={<HelpcenterGuides />}
            />
            <Route
              exact
              path="/email-password-reset"
              element={
                <ProtectiveLoginRoute>
                  <EmailPasswordReset />
                </ProtectiveLoginRoute>
              }
            />
            <Route
              exact
              path="/relocation&removal"
              element={
                <ProtectiveRoute>
                  <RemovalandRecolation />
                </ProtectiveRoute>
              }
            />
            <Route
              exact
              path="/contact-info"
              element={
                <ProtectiveRoute>
                  <Step2ContactInfo />
                </ProtectiveRoute>
              }
            />
            <Route exact path="/email-invoice" element={<EmailInvoice />} />
            <Route
              exact
              path="/email-confirmation"
              element={<EmailConfirmation />}
            />
            <Route
              exact
              path="/register/:role"
              element={
                <ProtectiveLoginRoute>
                  <SignUp />
                </ProtectiveLoginRoute>
              }
            />
            <Route
              exact
              path="/auth-signup-success"
              element={
                <ProtectiveLoginRoute>
                  <AuthSignupSuccess />
                </ProtectiveLoginRoute>
              }
            />
            <Route exact path="/label" element={<Label />} />
            <Route
              exact
              path="/auth-re-password"
              element={
                <ProtectiveLoginRoute>
                  <AuthRePassword />
                </ProtectiveLoginRoute>
              }
            />
            <Route
              exact
              path="/auth-login"
              element={
                <ProtectiveLoginRoute>
                  <AuthLogin />
                </ProtectiveLoginRoute>
              }
            />
            <Route
              exact
              path="/auth-lock-screen"
              element={
                <ProtectiveLoginRoute>
                  <AuthLockScreen />
                </ProtectiveLoginRoute>
              }
            />
            <Route
              exact
              path="/user-dashboard/order-language"
              element={<CheckLanguage />}
            />
          </Routes>
        ) : location.pathname.includes("/user-dashboard/chat") ||
          location.pathname === "/user-dashboard/my-details" ||
          location.pathname === "/user-dashboard/payment-history" ||
          location.pathname === "/user-dashboard/order-list" ||
          location.pathname === "/user-dashboard/new-order" ||
          location.pathname === "/user-dashboard/ticket" ||
          location.pathname === "/user-dashboard/wallet-topup" ||
          location.pathname.includes("/user-dashboard/update-ticket/") ||
          location.pathname === "/user-dashboard/profile-setting" ||
          location.pathname === "/user-dashboard/address-list" ||
          location.pathname === "/user-dashboard/cards" ||
          location.pathname === "/user-dashboard/address-list" ||
          location.pathname.includes("/user-dashboard/update-address/") ||
          location.pathname.includes("/user-dashboard/Order-details/") ||
          location.pathname === "/user-dashboard/compose-ticket" ||
          location.pathname === "/user-dashboard/change-password" ||
          location.pathname === "/user-dashboard" ||
          location.pathname === "/user-dashboard/address" ? (
          <div className={`page-wrapper  ${toggle ? "toggled" : ""}`}>
            {location.pathname === "/index-sidebar-light" ? (
              <SidebarLight />
            ) : location.pathname === "/index-sidebar-colored" ? (
              <SidebarColored />
            ) : location.pathname === "/email-confirmation" ? (
              ""
            ) : (
              <Sidebar />
            )}

            <main className="page-content bg-gray-50 dark:bg-slate-800">
              <Topnav toggle={toggle} setToggle={setToggle} />
              <Routes>
                <Route
                  path="/user-dashboard"
                  element={
                    <ProtectiveRoute>
                      <DashBoard />
                    </ProtectiveRoute>
                  }
                />
                <Route
                  path="/user-dashboard/my-details"
                  element={
                    <ProtectiveRoute>
                      <MyDeatils />
                    </ProtectiveRoute>
                  }
                />
                <Route
                  path="/user-dashboard/payment-history"
                  element={
                    <ProtectiveRoute>
                      <PaymentHistoryDashboard />
                    </ProtectiveRoute>
                  }
                />
                <Route
                  path="/user-dashboard/ticket"
                  element={
                    <ProtectiveRoute>
                      <Tickets />
                    </ProtectiveRoute>
                  }
                />

                <Route
                  path="/user-dashboard/new-order"
                  element={
                    <ProtectiveRoute>
                      <NewOrder />
                    </ProtectiveRoute>
                  }
                />
                <Route
                  path="/user-dashboard/order-list"
                  element={
                    <ProtectiveRoute>
                      <OrderList />
                    </ProtectiveRoute>
                  }
                />

                <Route
                  path="/user-dashboard/chat/:id"
                  element={
                    <ProtectiveRoute>
                      <Chats />
                    </ProtectiveRoute>
                  }
                />

                <Route
                  path="/user-dashboard/wallet-topup"
                  element={
                    <ProtectiveRoute>
                      <WalletDashboard />
                    </ProtectiveRoute>
                  }
                ></Route>
                <Route
                  path="/user-dashboard/update-ticket/:id"
                  element={
                    <ProtectiveRoute>
                      <UpdateTicket />
                    </ProtectiveRoute>
                  }
                ></Route>
                <Route
                  path="/user-dashboard/cards"
                  element={
                    <ProtectiveRoute>
                      <PaymentMethodDashboard />
                    </ProtectiveRoute>
                  }
                />
                <Route
                  path="/user-dashboard/address-list"
                  element={
                    <ProtectiveRoute>
                      <ShowAddress />
                    </ProtectiveRoute>
                  }
                />
                <Route
                  path="/user-dashboard/update-address/:id"
                  element={
                    <ProtectiveRoute>
                      <UpdateAddressBook />
                    </ProtectiveRoute>
                  }
                />
                <Route
                  path="/user-dashboard/address"
                  element={
                    <ProtectiveRoute>
                      <AddressBooks />
                    </ProtectiveRoute>
                  }
                />

                <Route
                  path="/user-dashboard/change-password"
                  element={
                    <ProtectiveRoute>
                      <ChangePassword />
                    </ProtectiveRoute>
                  }
                />
                <Route
                  path="/user-dashboard/Order-details/:id"
                  element={
                    <ProtectiveRoute>
                      <ViewSingleUserOrder />
                    </ProtectiveRoute>
                  }
                />
                <Route
                  path="/user-dashboard/compose-ticket"
                  element={
                    <ProtectiveRoute>
                      <ComposeTicket />
                    </ProtectiveRoute>
                  }
                />
                <Route
                  path="/user-dashboard/profile-setting"
                  element={
                    <ProtectiveRoute>
                      <ProfileSetting />
                    </ProtectiveRoute>
                  }
                />
              </Routes>
            </main>
          </div>
        ) : (
          <Routes>
            <Route path="*" element={<PageError />} />
          </Routes>
        )}
      </Elements>
    </>
  );
}
