import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaHandHoldingDollar } from "react-icons/fa6";
import imageP from "../../assets/images/consulting/1.jpg";
import image1 from "../../assets/images/consulting/2.jpg";
import image2 from "../../assets/images/consulting/3.jpg";
import ab03 from "../../assets/images/about/ab03.jpg";
import ab02 from "../../assets/images/about/ab02.jpg";
import ab01 from "../../assets/images/about/ab01.jpg";
import pro1 from "../../assets/images/portfolio/pro1.jpg";
import pro2 from "../../assets/images/portfolio/pro2.jpg";
import pro3 from "../../assets/images/portfolio/pro3.jpg";
import pro4 from "../../assets/images/portfolio/pro4.jpg";
import pro5 from "../../assets/images/portfolio/pro5.jpg";
import pro6 from "../../assets/images/portfolio/pro6.jpg";
import image3 from "../../assets/images/client/01.jpg";
import image4 from "../../assets/images/client/02.jpg";
import image5 from "../../assets/images/client/03.jpg";
import image6 from "../../assets/images/client/04.jpg";
import image7 from "../../assets/images/client/05.jpg";
import image8 from "../../assets/images/client/06.jpg";
import Select from "react-select";
import { CiDeliveryTruck } from "react-icons/ci";
import { FaMap } from "react-icons/fa";
import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";
import CookieModal from "../../component/cookieModal";
import ManagementTeam from "../../component/managementTeam";
import CompanyLogo from "../../component/companyLogo";
import { IoInformationCircleOutline } from "react-icons/io5";
import * as Icon from "react-feather";
import { FaArrowRight, FiAirplay } from "../../assets/icons/icons";

import TinySlider from "tiny-slider-react";
import CountUp from "react-countup";
import { FaStar } from "react-icons/fa6";
import {
  FaRegBuilding,
  LiaUniversitySolid,
  LuPizza,
  AiOutlineCheckCircle,
  BsCheckCircle,
} from "../../assets/icons/icons";

import DimensionAndWidthCard from "../../component/DimensionAndWidthCard";
import Button from "../../component/Button";
const settings = {
  container: ".tiny-single-item",
  items: 1,
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  speed: 400,
  gutter: 16,
};
export default function VanDelivery() {
  const [activeIndex, setActiveIndex] = useState(1);
  const AboutData = [
    {
      image: imageP,
      title: "About the pickup",
      desc: "All our services offer door-to-door delivery, with parcels delivered to the address of your choice.",
      number: 1,
    },
    {
      image: image1,
      title: "About the delivery",
      desc: "Parcels are picked up between 9 am and 6 pm, with some services offering same-day collection.",
      number: 2,
    },
  ];
  const project = [
    { value: "Austria", label: "Austria" },
    { value: "Belgium", label: "Belgium" },
    { value: "England", label: "England" },
    { value: "SouthAfrica", label: "SouthAfrica" },
  ];

  const OrderingAs = [
    { value: "Individual", label: "Individual" },
    { value: "Business", label: "Business" },
  ];
  const servicesData = [
    { value: "Document", label: "Document" },
    { value: "Package", label: "Package" },
    { value: "Pallet", label: "Pallet" },
    { value: "Van Delivery", label: "Van Delivery" },
    { value: "FTL & LTL", label: "FTL & LTL" },
    { value: "CustomOffer", label: "CustomOffer" },
  ];
  const accordionData = [
    {
      id: 1,
      title: "Correlation Analysis",
      content:
        "Receive heartfelt contributions instantly & straight to your bank. Guests can choose any amount of money to give",
    },
    {
      id: 2,
      title: "Drag-and-Drop Dashboard",
      content:
        "Receive heartfelt contributions instantly & straight to your bank. Guests can choose any amount of money to give",
    },
    {
      id: 3,
      title: "What do I need to do to start selling",
      title: "Smart Trend Detection ?",
      content:
        "Receive heartfelt contributions instantly & straight to your bank. Guests can choose any amount of money to give",
    },
    {
      id: 4,
      title: "What happens when I receive an order ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 5,
      title: "How does it work ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 6,
      title: "Do I need a designer to use Techwind ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 7,
      title: "What do I need to do to start selling ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      id: 8,
      title: "What happens when I receive an order ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
  ];
  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };

  const accordionData1 = accordionData.filter((x) => x.id < 4);

  const services = [
    {
      feedback:
        '" Techwind made the processes so easy. Techwind instantly increased the amount of interest and ultimately saved us over $10,000. " ',
      name: "Christa Smith",
      role: "Manager",
      image: image3,
    },
    {
      feedback:
        '" I highly recommend Techwind as the new way to sell your home "by owner". My home sold in 24 hours for the asking price. Best $400 you could spend to sell your home. "',
      name: "Christa Smith",
      role: "Manager",
      image: image4,
    },
    {
      feedback:
        '" My favorite part about selling my home myself was that we got to meet and get to know the people personally. This made it so much more enjoyable! "',
      name: "Christa Smith",
      role: "Manager",
      image: image5,
    },
    {
      feedback: '" Great experience all around! Easy to use and efficient. "',
      name: "Christa Smith",
      role: "Manager",
      image: image6,
    },
    {
      feedback:
        '" Techwind made selling my home easy and stress free. They went above and beyond what is expected. "',
      name: "Christa Smith",
      role: "Manager",
      image: image7,
    },
    {
      feedback:
        '" Techwind is fair priced, quick to respond, and easy to use. I highly recommend their services! "',
      name: "Christa Smith",
      role: "Manager",
      image: image8,
    },
  ];
  const services2 = [
    {
      icon: LiaUniversitySolid,
      desc: "To send a large number of packages at once",
    },
    {
      icon: LuPizza,
      title: "Food & Commodities",
      desc: "When items are too bulky to be sent in packages",
    },
    {
      icon: FaRegBuilding,
      title: "Real estate",
      desc: "For moving furniture and large household appliances",
    },
  ];
  const ratingData = [
    {
      icon: pro1,
      name: "Ali",
      title: "You deliver in time",
      desc: "It is cheaper, l don’t have to print invoice and adress, you deliver in time ( so far).",
    },
    {
      icon: pro2,
      name: "Ahmed",
      title: "Very good communication",
      desc: "User-friendly booking system and very good communication for the pick-up and delivery.",
    },
    {
      icon: pro3,
      name: "Hammad",

      title: "Easy to navigate",
      desc: "Straight forward to use. Had my order done in minutes without fuss.",
    },
  ];
  const infoData = [
    {
      icon: "https://placehold.co/200x200/EEE/31343C",
      title: "Box van",
      desc: "465 x 165 x 180 cm or 13 cubic metres and 1000 kg capacity",
    },
    {
      icon: "https://placehold.co/200x200/EEE/31343C",
      title: "Curtain-side van",
      desc: "410 x 210 x 230 cm or 19 cubic metres and 1000 kg capacity (with tail-lift only 700 kg)",
    },
  ];
  const advantagesData = [
    {
      icon: Icon.Codesandbox,
      title: "Instant quotes ",
      desc: " for pallet shipping",
    },
    {
      icon: Icon.Send,
      title: "Real-time tracking",
      desc: "via our online tool",
    },
    {
      icon: Icon.Star,
      title: "Insured transport, ",
      desc: "scalable to your needs",
    },
  ];
  const ChecklistData = [
    {
      icon: Icon.Codesandbox,
      title: "About the pick-up",
    },
    {
      icon: Icon.Send,
      title: "About the delivery",
      desc: "I shipped a pallet from Italy to Czech Republic. It arrived in good conditions and within the time…",
    },
  ];
  const shippingData = [
    {
      icon: Icon.BarChart,
      title: "Pallet Transport ",
      desc: "Ideal for 1-5 pallete, optional tail-lift and manual handler",
    },
    {
      icon: Icon.Send,
      title: "Shared Truck (LTL)",
      desc: "I shipped a pallet from Italy to Czech Republic. It arrived in good conditions and within the time…",
    },
    {
      icon: Icon.Star,
      title: "Full Truck FTL",
      desc: "Booking system on Movssy is very easy and straight forward. You are given an idea of the cost…",
    },
    {
      icon: Icon.Star,
      title: "Van Delivery",
      desc: "Booking system on Movssy is very easy and straight forward. You are given an idea of the cost…",
    },
  ];
  const servecies1 = [
    {
      image: imageP,
      title: "Profitable Marketing",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 1,
    },
    {
      image: image1,
      title: "SEO Specialists",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 2,
    },
    {
      image: image2,
      title: "Audience Analysis",
      desc: "We develop digital strategies, products and services appreciated by clients.",
      number: 3,
    },
  ];
  const productData = [
    {
      image: pro1,
      title: "Techwind Personal Portfolio",
      name: "Creative",
    },
    {
      image: pro2,
      title: "Techwind Minimal Portfolio",
      name: "Minimal",
    },
    {
      image: pro3,
      title: "Techwind NFT Market",
      name: "Crypto",
    },
    {
      image: pro4,
      title: "Techwind Portfolio",
      name: "Portfolio",
    },
    {
      image: pro5,
      title: "Techwind Constuction",
      name: "Corporate",
    },
    {
      image: pro6,
      title: "Techwind SEO",
      name: "SEO",
    },
  ];
  return (
    <>
      <Navbar navClass="nav-light" />
      <section className="relative md:py-50 py-44  bg-right bg-no-repeat bg-cover">
        <div className="absolute inset-0  dark:bg-slate-900"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 items-center">
            <h4 className="font-bold lg:leading-normal leading-normal text-2xl lg:text-4xl mb-2 text-black dark:text-white">
              Van transport service for deliveries in Europe
            </h4>
            <span className="text-gray-400 font-semibold mb-4 text-lg">
              Express Transport made easy
            </span>
            <div className="py-4 md:flex md:justify-between md:items-center gap-[15px] ">
              <div className="flex justify-normal w-full items-center ">
                <FaHandHoldingDollar className="text-btnBackground w-7 h-7" />
                <span className="ml-4 font-bold text-textHighlight">
                  Dedicated van for your loads
                </span>
              </div>
              <div className="flex justify-normal w-full items-center ">
                <CiDeliveryTruck className="text-btnBackground w-7 h-7" />
                <span className="ml-4 font-bold text-textHighlight">
                  Direct transport within Europe
                </span>
              </div>
              <div className="flex justify-normal w-full items-center ">
                <FaMap className="text-btnBackground w-7 h-7" />
                <span className="ml-4 font-bold textHighlight">
                  Collections in 2-3h in urban areas
                </span>
              </div>
            </div>

            <p className="text-black text-lg text-justify dark:text-gray-400">
              Move your load across Europe with our reliable Van Delivery
              Service. Get fast, direct delivery with no stopovers. Use the tool
              below to get an instant quote and professional assistance from
              experts in the logistics field!
              <div className="flex text-xl font-medium text-amber-400">
                <p className="text-green-600">30,000+</p>
                <ul className="text-xl ml-2 font-medium text-amber-400 list-none mb-2">
                  <li className="inline">
                    <i className="mdi mdi-star"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star"></i>
                  </li>
                </ul>
              </div>
            </p>

            <div className=" relative">
              <div className="grid grid-cols-1 justify-center">
                <div className="relative mt-10">
                  <div className="grid grid-cols-1">
                    <div>
                      <form className="p-6 bg-bgGrey dark:bg-slate-900 rounded-xl shadow-md dark:shadow-gray-700">
                        <div className="registration-form text-dark text-start">
                          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 space-x-3 lg:gap-0 gap-6">
                            <div>
                              <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                {/* <LuSearch className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                                <Select
                                  className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                  options={project}
                                  placeholder="Pick up"
                                />
                              </div>
                            </div>

                            <div>
                              <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                {/* <AiOutlineHome className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                                <Select
                                  className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                  options={project}
                                  placeholder="Delivery"
                                />
                              </div>
                            </div>

                            <div>
                              <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                {/* <AiOutlineDollar className="absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/> */}
                                <Select
                                  className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                  options={servicesData}
                                  placeholder={"Services"}
                                />
                              </div>
                            </div>
                            <Link to="/order-details">
                              <div className="">
                                <input
                                  type="submit"
                                  id="search-buy"
                                  name="search"
                                  className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
                                  value="Get a qoute"
                                />
                              </div>
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative justify-center md:py-12 py-8 lg:py-16 dark:bg-slate-800">
        <div className="container relative text-center  ">
          <div className="relative grid grid-cols-1  text-center z-1">
            <h3 className="md:text-3xl text-textHighlight text-2xl md:leading-normal leading-normal font-semibold  dark:text-white">
              Van transport services with Movssy
            </h3>
          </div>

          <div className="relative grid md:grid-cols-3 grid-cols-1 items-center  gap-[30px] z-1">
            <div className="counter-box text-center justify-center flex flex-col">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-textHighlight dark:text-white">
                <CountUp className="counter-value" start={1010} end={3000} />+
              </h1>
              <h5 className="counter-head text-lg font-medium text-textHighlight">
                Van and truck drivers
              </h5>
            </div>

            <div className="counter-box text-center items-center justify-center flex flex-col">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-textHighlight dark:text-white">
                <CountUp className="counter-value" start={2} end={60} />
                K+
              </h1>
              <h5 className="counter-head text-textHighlight text-lg font-medium">
                Business users
              </h5>
            </div>

            <div className="counter-box text-center  justify-center flex flex-col">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-textHighlight dark:text-white">
                <CountUp className="counter-value" start={0} end={95} />
                %+
              </h1>
              <h5 className="counter-head text-lg text-textHighlight font-medium">
                Success rate
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section className="relative md:py-12 py-8 lg:py-16">
        <div className="container relative">
          <div className="absolute inset-0 opacity-25 dark:opacity-50  bg-no-repeat bg-center bg-cover"></div>

          <div className="relative grid grid-cols-1 text-center">
            <p className="text-btnBackground leading-medium font-bold max-w-xl mx-auto">
              SERVICE DETAILS
            </p>
            <h3 className=" md:text-3xl text-textHighlight leading-medium text-2xl font-semibold">
              Van Delivery Service: pick-up and delivery
            </h3>
          </div>

          <div className="relative rounded-sm grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 mt-2 gap-[30px]">
            {ChecklistData.map((item, index) => {
              let Icons = item.icon;
              return (
                <div
                  key={index}
                  className="group px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800  border-gray-400 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 border"
                >
                  <div className="content text-xl font-semibold">
                    {item?.title}
                  </div>
                  <ul className="list-none ml-4 text-textHighlight mt-4">
                    <li className="mb-1 flex  items-center">
                      <BsCheckCircle className="text-btnBackground text-base me-2" />{" "}
                      Digital Marketing Solutions for Tomorrow
                    </li>
                    <li className="mb-1 flex text-textHighlight items-center">
                      <BsCheckCircle className="text-btnBackground text-base me-2" />{" "}
                      Our Talented & Experienced Marketing Agency
                    </li>
                    <li className="mb-1 flex text-textHighlight items-center">
                      <BsCheckCircle className="text-btnBackground text-base me-2" />{" "}
                      Create your own skin to match your brand
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="relative md:py-12 py-8 lg:py-16">
        <div className="container relative  ">
          <div className="absolute inset-0 opacity-25 dark:opacity-50  bg-no-repeat bg-center bg-cover"></div>

          <div className="relative grid grid-cols-1 pb-8 text-center">
            <h3 className=" md:text-3xl leading-medium text-2xl font-semibold">
              Testimonials
            </h3>

            <p className="text-slate-400 leading-medium max-w-xl mx-auto">
              We’ve asked our customers what they think about our services.
            </p>
          </div>

          <div className="relative rounded-sm grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-2 gap-[30px]">
            {ratingData.map((item, index) => {
              let Icons = item.icon;
              return (
                <div
                  key={index}
                  className="group px-6 py-8 bg-lightGrey dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800  border-gray-400 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 border"
                >
                  <div className="flex text-xl font-medium text-amber-400">
                    <div className="flex items-center ">
                      <img
                        src={item.icon}
                        alt=""
                        className="h-12 w-12 rounded-full"
                      />
                      <p className="ml-4">{item.name}</p>
                    </div>
                  </div>
                  <div className="content text-xl font-semibold">
                    {item?.title}
                  </div>
                  <div className="content text-xl font-thin">
                    {item.desc}

                    {/* <p className="text-slate-400 mt-4">{item.desc}</p> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="relative justify-center md:py-12 py-8 lg:py-16 ">
        <div className="container relative   w-8/12 justify-center">
          <div className="relative grid grid-cols-1  text-left mx-auto">
            <h3 className="  leading-medium  text-2xl font-bold">
              Load capacity of the Express Van Service
            </h3>

            <ul className=" list-item mt-3">
              <li className="mb-1 flex items-center">
                - There are no packing requirements. We still recommend
                protecting your items before transport, especially fragile
                goods.
              </li>
              <li className="mb-1 flex items-center">
                - Suitable for any type of load. Shippers use the van courier
                service for transporting a wide selection of goods: personal
                belongings, motorcycles, palletised goods and much more.
              </li>
              <li className="mb-1 flex items-center">
                - Longer items can be placed diagonally to fit the vehicle.
                Please ensure you measure the dimensions of your shipment
                carefully so that we can arrange the right van for you.
              </li>
            </ul>

            <div className="relative rounded-sm grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 mt-2 gap-[30px]">
              {infoData.map((item, index) => {
                let Icons = item.icon;
                return (
                  <div
                    key={index}
                    className="group px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg rounded mt-3 dark:hover:shadow-gray-800  border-gray-400 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 border"
                  >
                    <div className="flex text-xl font-medium text-amber-400">
                      <div className="flex items-center ">
                        <img
                          src={item.icon}
                          alt=""
                          className="rounded-sm h-20 w-20"
                        />
                      </div>
                    </div>
                    <div className="content text-xl font-semibold mt-2">
                      {item?.title}
                    </div>
                    <div className="content text-xl font-thin">
                      {item.desc}

                      {/* <p className="text-slate-400 mt-4">{item.desc}</p> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="relative md:py-12 py-8 lg:py-16 dark:bg-slate-800">
        <div className="container relative  ">
          <div className="absolute inset-0  opacity-25 dark:opacity-50  bg-no-repeat bg-center bg-cover"></div>

          <div className="relative grid grid-cols-1 text-center">
            <h6 className=" md:text-2xl text-textHighlight leading-medium text-xl font-bold ">
              Get all the help you need with your Express Van Delivery
            </h6>
            <p className=" text-btnBackground text-xl font-semibold leading-medium max-w-xl mx-auto">
              Efficiency is our priority.
            </p>
            <p className=" text-white/80 leading-medium text-justify max-w-xl mx-auto dark:text-gray-400">
              We work with a vast network of van shipping companies & drivers
              all over Europe to make sure every major urban area is covered for
              last-minute requests. Collections can be organised within 2-3
              hours in most European locations, with express delivery by van to
              the chosen destination.
            </p>
          </div>
        </div>
      </section>
      <section className="relative  dark:bg-slate-800 md:py-12 py-8 lg:py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <div className="lg:ms-5">
                {/* <div className="flex mb-4">
                  <span className="text-indigo-600 text-2xl font-bold mb-0">
                    <CountUp
                      className="counter-value text-6xl font-bold"
                      start={1}
                      end={15}
                    ></CountUp>
                    +
                  </span>
                  <span className="self-end font-semibold ms-2">
                    Years <br /> Experience
                  </span>
                </div> */}

                <h3 className="mb-6 text-textHighlight md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                  Get all the help you need with your Express Van Delivery
                </h3>

                <p className="text-slate-400 max-w-xl">
                  No need to stress about lifting heavy objects. During the
                  booking process, simply request full or partial loading
                  assistance and specialised equipment like tail lifts, and
                  we’ll take care of the rest!
                </p>
              </div>
            </div>
            <div className="lg:col-span-5 md:col-span-6">
              <div className="grid grid-cols-2 gap-6 items-center">
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    {" "}
                    <ul className="list-none ml-4 text-textHighlight">
                      <li className="mb-1 flex items-center font-bold text-xl text-black mt-4 dark:text-white">
                        <BsCheckCircle className="text-btnBackground  text-base me-4 " />{" "}
                        Tail-lift
                      </li>
                      <li className="mb-1 flex items-center font-bold dark:text-white text-xl text-black  mt-4">
                        <BsCheckCircle className="text-btnBackground text-base me-4" />{" "}
                        Partial loading assistance
                      </li>
                      <li className="mb-1 flex items-center dark:text-white font-bold text-xl text-black mt-4">
                        <BsCheckCircle className="text-btnBackground text-base me-4" />{" "}
                        Full loading service
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative justify-center md:py-12 py-8 lg:py-16 ">
        <div className="container relative   ">
          <div className="relative grid grid-cols-1  text-left ">
            <p className=" text-btnBackground text-xl font-semibold leading-medium max-w-xl mx-auto">
              WHY Movssy?
            </p>
            <h6 className=" md:text-2xl text-textHighlight leading-medium text-xl font-bold mx-auto">
              Benefits of booking a van delivery with us
            </h6>
            <div className="py-4 md:flex text-textHighlight md:justify-between md:items-center gap-[15px] ">
              <div className="flex flex-col  w-full items-center my-1 ">
                <FaHandHoldingDollar className="text-btnBackground w-7 h-7" />
                <span className="ml-4 font-bold">
                  Ideal for time-sensitive shipments
                </span>
              </div>
              <div className="flex flex-col  w-full items-center my-1">
                <CiDeliveryTruck className="text-btnBackground w-7 h-7" />
                <span className="ml-4 font-bold">
                  Loading/unloading help on request
                </span>
              </div>
              <div className="flex flex-col w-full items-center my-1">
                <FaMap className="text-btnBackground w-7 h-7" />
                <span className="ml-4 font-bold">
                  Dedicated support from experts
                </span>
              </div>
            </div>
            <div className="flex text-btnBackground hover:text-blue-600 cursor-pointer items-center mx-auto">
              <Icon.ArrowRight className=" me-2" />
              <p className="">More about Movssy</p>
            </div>
            <div className="container gap-3 relative grid grid-cols-1 rounded md:py-12 py-8 lg:py-16 text-center dark:bg-slate-800">
              <div className="mx-auto mt-2">
                <div className="px-4 py-1 border border-black rounded-full bg-bgGrey">
                  <p>Easy to use</p>
                </div>
              </div>
              <h1 className=" text-textHighlight font-bold text-2xl leading-medium max-w-xl mx-auto">
                Lorem Ipsum
              </h1>
              <p className=" md:text-xl leading-medium  text-justify">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <div className="mx-auto mt-2">
                <div className="px-4 py-1 border text-xl font-bold border-btnBackground text-white rounded-full bg-btnBackground">
                  <p>Try it for free</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:py-12 py-8 lg:py-16">
        <div className="container relative  ">
          <div className="absolute inset-0  opacity-25 dark:opacity-50  bg-no-repeat bg-center bg-cover"></div>

          <div className="relative grid grid-cols-1 pb-8 text-center">
            <p className=" text-btnBackground leading-medium max-w-xl mx-auto">
              NEED MORE INFORMATION?
            </p>
            <h6 className=" md:text-xl text-textHighlight leading-medium text-2xl font-semibold">
              FAQ about sending important documents internationally
            </h6>
          </div>
          <div className="relative rounded-sm grid grid-cols-1 mt-2 gap-[30px]">
            <div>
              {accordionData1.map((item, index) => (
                <div
                  key={index}
                  className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4"
                >
                  <h2
                    className="text-base font-semibold"
                    id="accordion-collapse-heading-1"
                  >
                    <button
                      type="button"
                      onClick={() => toggleAccordion(item.id)}
                      className={`flex justify-between items-center p-5 w-full font-medium text-start ${
                        activeIndex === item.id
                          ? "bg-lightGrey dark:bg-slate-800 text-btnBackground"
                          : ""
                      }`}
                      data-accordion-target="#accordion-collapse-body-1"
                      aria-expanded="true"
                      aria-controls="accordion-collapse-body-1"
                    >
                      <span>{item.title}</span>
                      <svg
                        data-accordion-icon
                        className={`${
                          activeIndex === item.id ? "rotate-180" : "rotate-270"
                        } w-4 h-4 shrink-01`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </h2>
                  {activeIndex === item.id && (
                    <div>
                      <div className="p-5 bg-lightGrey">
                        <p className="text-btnBackground dark:text-gray-400">
                          There are many variations of passages of Lorem Ipsum
                          available, but the majority have suffered alteration
                          in some form.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <CookieModal />
    </>
  );
}
