import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../store/slices/languageSlice";

function CheckLanguage() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  const handlechangeLanguage = (value, lang) => {
    dispatch(changeLanguage(value));
    changeLanguagei18(value);
  };
  const changeLanguagei18 = (data) => {
    i18n
      .changeLanguage(data)
      .then(() => console.log(data))
      .catch((err) => console.log(err));
  };
  const languages = [
    { code: "en", lang: "English" },
    { code: "fr", lang: "French" },
  ];
  return (
    <div className="container">
      <div className=" flex  gap-9 justify-center items-center">
        {languages.map((lang) => {
          return (
            <button
              className={`border p-2  w-32${
                lang.code === languageCode ? " bg-slate-500" : ""
              }`}
              key={lang.code}
              onClick={() => handlechangeLanguage(lang.code, lang.lang)}
            >
              {lang.lang}
            </button>
          );
        })}
      </div>
      <div className="flex flex-col gap-4 justify-center items-center">
        <h1>{t("Welcome")}</h1>
      </div>
    </div>
  );
}

export default CheckLanguage;
