import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Icon from "react-feather";
import UserProfileTab from "../../component/dashboard/userProfileTab";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { changeLanguagei18 } from "../utility/changeLanguagei18N";
const ChangePassword = () => {
  const token = useSelector((state) => state.auth.token);
  const [disable, setDisabled] = React.useState(false);
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old password is required"),
      newPassword: Yup.string()
        .required("New password is required")
        .min(8, "Password must be at least 8 characters"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: (values) => {
      // Your API call logic goes here
      setDisabled(true);
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      var formdata = new FormData();
      formdata.append("old_password", values.oldPassword);
      formdata.append("password", values.newPassword);
      formdata.append("password_confirmation", values.confirmPassword);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch("https://movssy.com/admin/api/change_password", requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response?.status === true) {
            toast.success(response?.message);

            setDisabled(false);
          } else {
            toast.error(response?.message);
            setDisabled(false);
          }
        })
        .catch((error) => {
          console.log("error", error);
          setDisabled(false);
        });
      console.log("Form submitted with values:", values);
    },
  });
  React.useEffect(() => {
    changeLanguagei18(languageCode);
  }, []);
  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="grid grid-cols-1">
          <div className="profile-banner relative text-transparent rounded-md shadow dark:shadow-gray-700 overflow-hidden">
            <input
              id="pro-banner"
              name="profile-banner"
              type="file"
              className="hidden"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1">
          <UserProfileTab />
          <div className="xl:col-span-9 lg:col-span-8 md:col-span-8 ">
            <div className="grid grid-cols-1 gap-6">
              <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <div className="grid lg:grid-cols-1 grid-cols-1 gap-6">
                  <div>
                    <h5 className="text-lg text-btnBackground font-semibold mb-4">
                      {t("Change_password")} :
                    </h5>
                    <form>
                      <div>
                        <label className="form-label text-btnBackground font-medium">
                          {t("Old_password")} :
                        </label>
                        <div className="form-icon relative mt-2">
                          <Icon.Key className="w-4 h-4 absolute top-3 start-4" />
                          <input
                            type="password"
                            name="oldPassword"
                            value={formik.values.oldPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="Old password"
                            required=""
                          />
                          {formik.touched.oldPassword &&
                            formik.errors.oldPassword && (
                              <p className="text-red-500 mt-1 text-sm">
                                {formik.errors.oldPassword}
                              </p>
                            )}
                        </div>
                      </div>

                      <div>
                        <label className="form-label text-btnBackground font-medium">
                          {t("New_password")} :
                        </label>
                        <div className="form-icon relative mt-2">
                          <Icon.Key className="w-4 h-4 absolute top-3 start-4" />
                          <input
                            type="password"
                            name="newPassword"
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="New password"
                            required=""
                          />
                          {formik.touched.newPassword &&
                            formik.errors.newPassword && (
                              <p className="text-red-500 mt-1 text-sm">
                                {formik.errors.newPassword}
                              </p>
                            )}
                        </div>
                      </div>

                      <div>
                        <label className="form-label text-btnBackground font-medium">
                          {t("Confirm_password")} :
                        </label>
                        <div className="form-icon relative mt-2">
                          <Icon.Key className="w-4 h-4 absolute top-3 start-4" />
                          <input
                            type="password"
                            name="confirmPassword"
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="Confirm password"
                            required=""
                          />
                        </div>
                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword && (
                            <p className="text-red-500 mt-1 text-sm">
                              {formik.errors.confirmPassword}
                            </p>
                          )}
                      </div>

                      <button
                        type="submit"
                        onClick={formik.handleSubmit}
                        disabled={disable}
                        className="py-2 px-5 bg-btnBackground inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md mt-5"
                      >
                        {t("Save_password")}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
