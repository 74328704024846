import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateAddress, modifyAddress } from "../../store/slices/addressSlice";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  deliveryStreetName: Yup.string().required("Street no field is required"),
  deliveryUserName: Yup.string().required("This field is required"),
  deliveryPostalCode: Yup.string().required("This field is required"),
  deliveryCity: Yup.string().required("This field is required"),
  deliveryContactNo: Yup.string().required("This field is required"),
});
export default function UpdateAddressBook() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
  }, []);

  const ShowAddress = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    await fetch(
      `https://movssy.com/admin/api/address_book/show/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("🚀 ~ .then ~ address_book:", res);
        if (res.status === true) {
          if (res.address_book === null) {
            setData(null);
          } else {
            setData(res.address_book);
            formik.setValues({
              deliveryUserName: res.address_book?.full_name,
              deliveryStreetName: res.address_book?.street,
              deliveryCity: res.address_book?.city,
              deliveryPostalCode: res.address_book?.postal_code,
              deliveryContactNo: res.address_book?.contact,
            });
          }
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => console.error(error));
  };
  const formik = useFormik({
    initialValues: {
      deliveryUserName: "",
      deliveryStreetName: "",
      deliveryCity: "",
      deliveryPostalCode: "",
      deliveryContactNo: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Your login logic here
      const formdata = new FormData();
      formdata.append("full_name", values?.deliveryUserName);
      formdata.append("street", values?.deliveryStreetName);
      formdata.append("city", values?.deliveryCity);
      formdata.append("postal_code", values?.deliveryPostalCode);
      formdata.append("contact", values?.deliveryContactNo);

      dispatch(updateAddress({ formData: formdata, id: id }))
        .unwrap()
        .then((res) => {
          if (res?.status === true) {
            toast.success("Address update successfully");
            dispatch(modifyAddress(res.updated_address_book));
            navigate("/user-dashboard/address-list");
          } else {
            toast.error(res?.message);
          }
        })
        .catch((error) => console.error(error));
    },
  });

  useEffect(() => {
    ShowAddress();
  }, [navigate]);
  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="grid grid-cols-1">
          <div className="profile-banner relative text-transparent rounded-md shadow dark:shadow-gray-700 overflow-hidden">
            <input
              id="pro-banner"
              name="profile-banner"
              type="file"
              className="hidden"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1">
          <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 ">
            <div className="grid grid-cols-1 gap-6">
              <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <h5 className="text-lg font-semibold mb-4">Delivery Details</h5>
                <form onSubmit={formik.handleSubmit}>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 ">
                    <div>
                      <label className="form-label font-medium">
                        Full Name
                      </label>
                      <div className="form-icon relative mt-2">
                        <input
                          name="deliveryUserName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.deliveryUserName}
                          type="text"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                        />
                      </div>
                      {formik.touched.deliveryUserName &&
                        formik.errors.deliveryUserName && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.deliveryUserName}
                          </p>
                        )}
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        Street No
                      </label>
                      <div className="form-icon relative mt-2">
                        <input
                          name="deliveryStreetName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.deliveryStreetName}
                          type="text"
                          className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                        />
                      </div>
                      {formik.touched.deliveryStreetName &&
                        formik.errors.deliveryStreetName && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.deliveryStreetName}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-4">
                    <div>
                      <label className="form-label font-medium">
                        Post Code
                      </label>
                      <div className="form-icon relative mt-2">
                        <input
                          name="deliveryPostalCode"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.deliveryPostalCode}
                          type="number"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                        />
                      </div>
                      {formik.touched.deliveryPostalCode &&
                        formik.errors.deliveryPostalCode && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.deliveryPostalCode}
                          </p>
                        )}
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        Town/City
                      </label>
                      <div className="form-icon relative mt-2">
                        <input
                          name="deliveryCity"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.deliveryCity}
                          type="text"
                          className="form-input  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                        />
                      </div>
                      {formik.touched.deliveryCity &&
                        formik.errors.deliveryCity && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.deliveryCity}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="grid  grid-cols-1 gap-5 mt-4">
                    <div>
                      <label className="form-label font-medium">
                        Contact Number
                      </label>
                      <div className="form-icon relative mt-2">
                        <input
                          name="deliveryContactNo"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.deliveryContactNo}
                          type="number"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                        />
                      </div>
                      {formik.touched.deliveryContactNo &&
                        formik.errors.deliveryContactNo && (
                          <p className="text-red-500 text-sm mt-1">
                            {formik.errors.deliveryContactNo}
                          </p>
                        )}
                    </div>
                  </div>
                  <input
                    type="submit"
                    value={"Update"}
                    className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
