import React from "react";
import { Link } from "react-router-dom";

import logo_icon_64 from "../../assets/images/logo-icon-64.png";
import { FaGoogle } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
export default function AuthLogin() {
  return (
    <>
      <section className="md:h-screen py-36 flex items-center bg-[url('../../assets/images/cta.jpg')] bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
        <div className="container relative">
          <div className="flex justify-center">
            <div className="max-w-[400px] w-full m-auto p-6 bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
              <Link to="/index">
                <img
                  src={logo_icon_64}
                  className="w-auto h-28 mx-auto "
                  alt=""
                />
              </Link>
              <h5 className="my-6 text-xl font-semibold">Login</h5>
              <form className="text-start">
                <div className="grid grid-cols-1">
                  <div className="mb-4">
                    <label className="font-semibold" htmlFor="LoginEmail">
                      Email Address:
                    </label>
                    <input
                      id="LoginEmail"
                      type="email"
                      className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                      placeholder="name@example.com"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="font-semibold" htmlFor="LoginPassword">
                      Password:
                    </label>
                    <input
                      id="LoginPassword"
                      type="password"
                      className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                      placeholder="Password:"
                    />
                  </div>

                  <div className="flex justify-between mb-4">
                    <div className="flex items-center mb-0">
                      <input
                        className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2"
                        type="checkbox"
                        value=""
                        id="RememberMe"
                      />
                      <label
                        className="form-checkbox-label text-slate-400"
                        htmlFor="RememberMe"
                      >
                        Remember me
                      </label>
                    </div>
                    <p className="text-slate-400 mb-0">
                      <Link to="/auth-re-password" className="text-slate-400">
                        Forgot password ?
                      </Link>
                    </p>
                  </div>

                  <div className="mb-4">
                    <Link to="/user-dashboard">
                      <input
                        type="submit"
                        className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full"
                        value="Login / Sign in"
                      />
                    </Link>
                  </div>
                  <div className="mb-4">
                    <Link to="/continue-with-email">
                      <input
                        type="submit"
                        className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full"
                        value="Continue With Email"
                      />
                    </Link>
                  </div>
                  <div className="py-2 px-5  font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-slate-100 hover:bg-indigo-600 border-slate-600 hover:text-white hover:border-indigo-700 text-black rounded-md w-full mb-4 justify-center flex items-center">
                    <FaGoogle className="w-4 h-4" />{" "}
                    <p className="ml-2">Continue with Google</p>
                  </div>
                  <div className="py-2 px-5   font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-slate-100 hover:bg-indigo-600 border-slate-600  hover:text-white hover:border-indigo-700 text-black rounded-md w-full mb-4 justify-center flex items-center">
                    <FaFacebook className="w-4 h-4" />{" "}
                    <p className="ml-2">Continue with Facebook</p>
                  </div>
                  <div className="text-center">
                    <span className="text-slate-400 me-2">
                      Don't have an account ?
                    </span>{" "}
                    <Link
                      to="/register"
                      className="text-black dark:text-white font-bold inline-block"
                    >
                      Sign Up
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
