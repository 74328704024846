import React from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import logo_icon_64 from "../assets/images/logo-icon-64.png";
import { useSelector } from "react-redux";
function RelocationHeaderBar({ title }) {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  return (
    <>
      <div className=" z-999 fixed w-full h-[76px] dark:bg-slate-900  bg-white shadow-sm items-center px-8 top-0">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-1">
            <Link to="#" className="xl:hidden block me-2">
              <img
                src={logo_icon_64}
                className="md:hidden block mx-auto  h-8 "
                alt=""
              />
            </Link>

            <div className="ps-1.5">
              <div className=" relative sm:block hidden font-bold text-2xl">
                <img
                  src={logo_icon_64}
                  className="inline-block  mx-auto h-20"
                  alt=""
                />
              </div>
            </div>
            <div className="ps-1.5">
              <div
                className={`relative sm:block hidden  ${
                  title === "Shipment details"
                    ? "font-bold text-black  dark:text-white"
                    : "font-semibold text-gray-400"
                }`}
              >
                Shipment details
              </div>
            </div>
            <div className="ps-1.5">
              <div className=" relative sm:block hidden text-gray-400">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="ps-1.5">
              <div
                className={`relative sm:block hidden  ${
                  title === "Contact Information"
                    ? "font-bold text-black dark:text-white"
                    : "font-semibold text-gray-400"
                }`}
              >
                Contact information
              </div>
            </div>
            <div className="ps-1.5">
              <div className=" relative sm:block hidden text-gray-400">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="ps-1.5">
              <div
                className={`relative sm:block hidden  ${
                  title === "Confirmation"
                    ? "font-bold text-black  dark:text-white"
                    : "font-semibold text-gray-400"
                }`}
              >
                Confirmation
              </div>
            </div>
          </div>

          <ul className="list-none mb-0 space-x-1">
            {isAuthorized === false ? (
              <li className="dropdown inline-block relative">
                <Link to="/login" className="ms-1">
                  <span className="py-[7px] px-6 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white rounded-md sm:inline-block hidden">
                    Login
                  </span>
                </Link>
              </li>
            ) : null}

            {/* <li className="dropdown inline-block relative">
              <div
                className={`dropdown-menu absolute end-0 m-0 mt-4 z-10 w-60 rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-700  ${
                  cart ? "" : "hidden"
                }`}
              >
                <ul className="py-3 text-start">
                  <li>
                    <Link
                      to="#"
                      className="flex items-center justify-between py-1.5 px-4"
                    >
                      <span className="flex items-center">
                        <img
                          src={shopItem1}
                          className="rounded shadow dark:shadow-gray-700 w-9"
                          alt=""
                        />
                        <span className="ms-3">
                          <span className="block font-semibold">
                            T-shirt (M)
                          </span>
                          <span className="block text-sm text-slate-400">
                            $320 X 2
                          </span>
                        </span>
                      </span>

                      <span className="font-semibold">$640</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="flex items-center justify-between py-1.5 px-4"
                    >
                      <span className="flex items-center">
                        <img
                          src={shopItem2}
                          className="rounded shadow dark:shadow-gray-700 w-9"
                          alt=""
                        />
                        <span className="ms-3">
                          <span className="block font-semibold">Bag</span>
                          <span className="block text-sm text-slate-400">
                            $50 X 5
                          </span>
                        </span>
                      </span>

                      <span className="font-semibold">$250</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="flex items-center justify-between py-1.5 px-4"
                    >
                      <span className="flex items-center">
                        <img
                          src={shopItem3}
                          className="rounded shadow dark:shadow-gray-700 w-9"
                          alt=""
                        />
                        <span className="ms-3">
                          <span className="block font-semibold">
                            Watch (Men)
                          </span>
                          <span className="block text-sm text-slate-400">
                            $800 X 1
                          </span>
                        </span>
                      </span>

                      <span className="font-semibold">$800</span>
                    </Link>
                  </li>

                  <li className="border-t border-gray-100 dark:border-gray-800 my-2"></li>

                  <li className="flex items-center justify-between py-1.5 px-4">
                    <h6 className="font-semibold mb-0">Total($):</h6>
                    <h6 className="font-semibold mb-0">$1690</h6>
                  </li>

                  <li className="py-1.5 px-4 space-x-1">
                    <Link
                      to="#"
                      className="py-[5px] px-4 inline-block font-semibold tracking-wide align-middle duration-500 text-sm text-center rounded-md bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white"
                    >
                      View Cart
                    </Link>
                    <Link
                      to="#"
                      className="py-[5px] px-4 inline-block font-semibold tracking-wide align-middle duration-500 text-sm text-center rounded-md bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white"
                    >
                      Checkout
                    </Link>
                    <p className="text-sm text-slate-400 mt-1">*T&C Apply</p>
                  </li>
                </ul>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}

export default RelocationHeaderBar;
