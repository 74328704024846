import React, { useState } from "react";
import "./tooltip.css";
function Tooltip({ text, children }) {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div
      className="tooltip-container"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div className="tooltip pl-4 w-60 py-8 pr-9  shadow-md ">
          <h2 className=" text-start text-lg font-bold  text-black ">
            Package
          </h2>
          <img
            src={"https://placehold.co/350x350/EEE/31343C"}
            alt=""
            className="object-cover mt-2"
          />

          <h2 className="mt-3 text-start text-sm font-bold  text-indigo-600 ">
            Regular
          </h2>
          <p className="mt-1 text-start text-base font-semibold  text-black ">
            Max Length:<span className="font-bold"> 175 cm</span>
          </p>
          <p className="mt-1 text-start text-base font-semibold  text-black ">
            Length + girth ≤<span className="font-bold"> 300 cm</span>
          </p>
          <p className="mt-1 text-start text-base font-semibold  text-black ">
            Max Weight:<span className="font-bold"> 30 kg</span>
          </p>
          <h2 className="mt-3 text-start text-sm font-bold  text-indigo-600 ">
            Regular Plus
          </h2>
          <p className="mt-1 text-start text-base font-semibold  text-black ">
            Max Length:<span className="font-bold"> 274 cm</span>
          </p>
          <p className="mt-1 text-start text-base font-semibold  text-black ">
            Length + girth ≤<span className="font-bold"> 400 cm</span>
          </p>
          <p className="mt-1 text-start text-base font-semibold  text-black ">
            Max Weight:<span className="font-bold"> 30 kg</span>
          </p>
          <h2 className="mt-3 text-start text-sm font-bold  text-indigo-600 ">
            Express
          </h2>
          <p className="mt-1 text-start text-base font-semibold  text-black ">
            One dimensions can exceed
            <span className="font-bold"> 120 cm </span>
          </p>
          <p className="mt-1 text-start text-base font-semibold  text-black ">
            (up to max 300 cm)
          </p>
          <p className="mt-1 text-start text-base font-semibold  text-black ">
            Max Weight:<span className="font-bold"> 70 kg</span>
          </p>
        </div>
      )}
    </div>
  );
}

export default Tooltip;
