import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeLanguagei18 } from "../../pages/utility/changeLanguagei18N";
import { useTranslation } from "react-i18next";
import { FiArrowRight } from "../../assets/icons/icons";

export default function Dimensions() {
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);

  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [error, setError] = useState("none");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleWeightChange = (e) => {
    setWeight(e.target.value);
  };
  const handleLengthChange = (e) => {
    setLength(e.target.value);
  };
  const handleWidthChange = (e) => {
    setWidth(e.target.value);
  };
  const handleHeightChange = (e) => {
    setHeight(e.target.value);
  };
  const handleNavigate = (e) => {
    e.preventDefault();
    navigate("/user-dashboard/new-order");
  };
  const mapRef = useRef();
  const checkDimensions = (e) => {
    e.preventDefault();
    if (length < 15 || width < 11 || height < 1 || weight < 1) {
      setError("error");
      console.log("ERROR");
    } else {
      setError("clear");
    }
  };
  const eventHandlers = React.useMemo(
    () => ({
      onKeyUp: () => setError("none"),
    }),
    []
  );
  useEffect(() => {
    changeLanguagei18(languageCode);
  }, []);

  return (
    <div className="grid lg:grid-cols-12 grid-cols-1 mt-6 gap-6 shadow-lg">
      <div className="lg:col-span-12">
        <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
          <div className="px-4 py-2 flex items-center justify-between  border-gray-100 dark:border-gray-800">
            <h6 className="text-lg font-semibold">
              {t("Check_dimensions_&_weight")}
            </h6>
          </div>
          <div id="mainchart" className="apex-chart px-4 pb-6 mt-2">
            <div>
              <h2 className="text-sm font-thin ">{t("Provide_dimension")}</h2>
            </div>
            <div className="flex flex-col  ">
              <div className="flex flex-row justify-between mt-2">
                <div className="">
                  <label className="form-label font-medium flex justify-between items-center">
                    {t("Length (cm)")} :{" "}
                  </label>
                  <input
                    type="number"
                    className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                    name="length"
                    {...eventHandlers}
                    onChange={handleLengthChange}
                  />
                </div>
                <div className="ml-2">
                  <label className="form-label font-medium flex justify-between items-center">
                    {t("Width")}:{" "}
                  </label>

                  <input
                    type="number"
                    className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                    name="width"
                    {...eventHandlers}
                    onChange={handleWidthChange}
                  />
                </div>
              </div>
              <div className="flex flex-row justify-between mt-2">
                <div>
                  <label className="form-label font-medium flex justify-between items-center">
                    {t("Height")}:{" "}
                  </label>
                  <input
                    type="number"
                    className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                    name="height"
                    {...eventHandlers}
                    onChange={handleHeightChange}
                  />
                </div>
                <div className="ml-2">
                  <label className="form-label font-medium flex justify-between items-center">
                    {t("Weight")}:{" "}
                  </label>
                  <input
                    type="number"
                    className="  w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                    name="weight"
                    {...eventHandlers}
                    onChange={handleWeightChange}
                  />
                </div>
              </div>
              <div className="mt-2">
                <button
                  onClick={checkDimensions}
                  className="relative inline-flex text-btnBackground items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500  hover:text-indigo-600 dark:hover:text-white after:bg-indigo-600  duration-500"
                >
                  {"Calculate"} <FiArrowRight className="ms-1" />
                </button>
                {/* <input
                  type="submit"
                  value={t("Check_Dimension")}
                  className="py-[7px]  px-6 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white rounded-md sm:inline-block "
                /> */}
              </div>
            </div>

            {error === "error" ? (
              <div className="flex flex-row  justify-between text-white text-sm bg-red-400 rounded items-center py-2 px-3 mt-3">
                <p>{t("Minimum_required")}</p>
                <span
                  onClick={handleNavigate}
                  className=" hover:bg-red-300 text-sm text-white cursor-pointer py-2 px-2 rounded-sm "
                >
                  {t("Add_to_order")}
                </span>
              </div>
            ) : error === "clear" ? (
              <div className="flex flex-row  justify-between text-white text-sm bg-green-500 rounded items-center py-2 px-3 mt-3">
                <p>{t("Perfect to ship as a package")}</p>
                <span
                  onClick={handleNavigate}
                  className=" hover:bg-green-400 text-sm text-white cursor-pointer py-2 px-2 rounded-sm "
                >
                  {t("Add_to_order")}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
