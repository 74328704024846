import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaWallet } from "react-icons/fa";
import { FaRegCreditCard } from "react-icons/fa";
import { IoLogoPaypal } from "react-icons/io5";
import "react-calendar/dist/Calendar.css";
import Swal from "sweetalert2";
import * as Icon from "react-feather";
import { FaGooglePay } from "react-icons/fa";
import { TailSpin } from "react-loader-spinner";
import { BsBank } from "react-icons/bs";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  creditWallet,
  fetchWallet,
  addWallet,
} from "../../store/slices/walletSlice";
import { changeLanguagei18 } from "../utility/changeLanguagei18N";
import { useTranslation } from "react-i18next";
import Button from "../../component/Button";
export default function WalletDashboard() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
  }, []);
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.token);
  const { data } = useSelector((state) => state.walletDetail);
  const navigate = useNavigate();
  const [paymentGateway, setPaymentGateway] = useState("CreditCard");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [disabled, setDisabled] = useState(false);
  const languageCode = useSelector((state) => state.multiLangauge.languageCode);
  const { t } = useTranslation();
  const paymentAmounts = [50, 100, 250, 350, 500];
  const stripe = useStripe();
  const elements = useElements();
  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const [isCustomAmount, setIsCustomAmount] = useState(false);

  const handleRadioChange = async (amount) => {
    await setSelectedAmount("");
    setSelectedAmount(amount);
  };
  const handleCustomAmount = () => {
    setSelectedAmount("");
    setIsCustomAmount(!isCustomAmount);
  };
  const WalletApi = (stripe_token) => {
    setDisabled(true);
    console.log("Calling...Api");
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append("amount", selectedAmount);
    formdata.append("transaction_type", "credit");
    formdata.append("token", stripe_token);
    formdata.append("type", "topup");
    formdata.append("payment_method", "stripe");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://movssy.com/admin/api/stripe", requestOptions)
      .then((response) => response.json())
      .then(({ status, data, message }) => {
        if (status === true) {
          dispatch(creditWallet(selectedAmount));
          dispatch(addWallet(data));
          Swal.fire("Success!", "Amount has been added to wallet", "success");
          navigate("/user-dashboard/payment-history");
          setDisabled(false);
        } else {
          Swal.fire("Error", message, "error");
          setDisabled(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setDisabled(false);
      });
  };
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.

        return;
      }

      const cardElement = await elements.getElement(CardElement);
      const { token, error } = await stripe.createToken(cardElement);

      if (error) {
        toast.error(error.message);
      } else {
        handlePayment(token.id);
      }
    } catch (error) {
      console.error("Error accessing Element:", error);
    }
  };

  React.useEffect(() => {
    dispatch(fetchWallet(1));
  }, [navigate]);
  const handlePayment = (tokenId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Pay it!",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        WalletApi(tokenId);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Thankyou!");
      }
    });
  };
  React.useEffect(() => {
    setTimeout(() => {
      setIsStripeLoading(false);
    }, 4000);
  }, []);
  React.useEffect(() => {
    changeLanguagei18(languageCode);
  }, [languageCode]);
  return (
    <div className=" grid grid-cols-12 ">
      <div className="container-fluid relative px-2 col-span-8">
        <div className="layout-specing">
          <div className="grid grid-cols-1  mt-6  bg-white dark:bg-slate-900 shadow-lg dark:shadow-gray-700 px-2 py-2">
            <div className=" justify-between items-center">
              <h1 className="text-xl font-bold ">{t("1. Enter Amount")}</h1>
            </div>

            <div className="md:flex justify-between w-full mt-3">
              {paymentAmounts.map((amount) => (
                <div
                  key={amount}
                  className={`border  p-3 items-center  ${
                    selectedAmount === amount
                      ? "border-btnBackground   dark:border-gray-400"
                      : ""
                  }`}
                >
                  <input
                    type="radio"
                    id={`amount-${amount}`}
                    name="paymentAmount"
                    value={selectedAmount}
                    className="text-btnBackground bg-btnBackground "
                    checked={selectedAmount === amount}
                    onChange={() => handleRadioChange(amount)}
                  />
                  <label
                    className="ml-2 "
                    htmlFor={`amount-${amount}`}
                  >{`$${amount}`}</label>
                </div>
              ))}
            </div>
            <div className="flex justify-between items-centers mt-4">
              {isCustomAmount === true ? (
                <div className="flex w-1/2   flex-col rounded-xl shadow-md">
                  <div className="flex py-2 px-2 rounded-xl  items-center space-x-2">
                    <input
                      type="number"
                      value={selectedAmount}
                      placeholder="Enter amount"
                      onChange={(e) => setSelectedAmount(e.target.value)}
                      className="w-full  rounded-sm bg-white px-3 bg-transparent dark:bg-slate-900 dark:text-slate-200  outline-none focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                    />
                  </div>
                </div>
              ) : null}
              <div>
                <Button
                  onClick={handleCustomAmount}
                  value={t("Custom Amount")}
                />
              </div>
            </div>

            {selectedAmount >= 20 && (
              <>
                <div className=" justify-between items-center mt-3">
                  <h1 className="text-xl font-bold ">
                    {t("2. Payment Methods")}
                  </h1>
                </div>

                <div className="mt-3  bg-white dark:bg-slate-800  md:flex lg:flex shadow-sm">
                  <div
                    className={`w-full cursor-pointer ${
                      paymentGateway === "CreditCard"
                        ? " bg-lightGrey dark:border-gray-500 "
                        : "dark:border-gray-400 border-lightGrey"
                    } cursor-pointer `}
                    onClick={() => setPaymentGateway("CreditCard")}
                  >
                    <div
                      className={`border-t-4 ${
                        paymentGateway === "CreditCard"
                          ? " border-btnBackground "
                          : " border-white"
                      }`}
                    ></div>

                    <div
                      className={`justify-center items-center flex flex-col my-2 ${
                        paymentGateway === "CreditCard"
                          ? " text-black font-bold"
                          : " "
                      }`}
                    >
                      <FaRegCreditCard />
                      {t("Credit Card")}
                    </div>
                  </div>
                  <div
                    className={`w-full ${
                      paymentGateway === "PayPal"
                        ? " bg-lightGrey "
                        : " border-lightGrey dark:border-gray-400"
                    } cursor-pointer `}
                    onClick={() => setPaymentGateway("PayPal")}
                  >
                    <div
                      className={`border-t-4 ${
                        paymentGateway === "PayPal"
                          ? " border-btnBackground "
                          : " border-white"
                      }`}
                    ></div>

                    <div
                      className={`justify-center items-center flex flex-col my-2 ${
                        paymentGateway === "PayPal"
                          ? " text-black font-bold"
                          : " "
                      }`}
                    >
                      <IoLogoPaypal />
                      {t("PayPal")}
                    </div>
                  </div>
                  <div
                    className={`w-full  ${
                      paymentGateway === "GooglePay"
                        ? " bg-lightGrey "
                        : " border-lightGrey dark:border-gray-400"
                    } cursor-pointer `}
                    onClick={() => setPaymentGateway("GooglePay")}
                  >
                    <div
                      className={`border-t-4 ${
                        paymentGateway === "GooglePay"
                          ? " border-btnBackground "
                          : " border-white"
                      }`}
                    ></div>

                    <div
                      className={`justify-center items-center flex flex-col my-2 ${
                        paymentGateway === "GooglePay"
                          ? " text-black font-bold"
                          : " "
                      }`}
                    >
                      <FaGooglePay />
                      {t("Google Pay")}
                    </div>
                  </div>
                  <div
                    className={`w-full cursor-pointer ${
                      paymentGateway === "BankTransfer"
                        ? " bg-lightGrey "
                        : " border-lightGrey dark:border-gray-400"
                    }  `}
                    onClick={() => setPaymentGateway("BankTransfer")}
                  >
                    <div
                      className={`border-t-4 ${
                        paymentGateway === "BankTransfer"
                          ? " border-btnBackground"
                          : " border-white"
                      }`}
                    ></div>

                    <div
                      className={`justify-center items-center flex flex-col my-2 ${
                        paymentGateway === "BankTransfer"
                          ? " text-black font-bold"
                          : " "
                      }`}
                    >
                      <BsBank />
                      {t("Bank Transfer")}
                    </div>
                  </div>
                </div>
                {paymentGateway === "CreditCard" ? (
                  <div className="bg-white dark:bg-slate-800 items-center  py-4 px-4 mt-6 shadow-sm">
                    {isStripeLoading ? (
                      <div className=" flex justify-center items-center py-4">
                        <TailSpin
                          visible={true}
                          height="60"
                          width="60"
                          color="rgb(67 56 202)"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    ) : (
                      <React.Fragment>
                        <CardElement />
                        <div className="mt-4">
                          <button
                            onClick={handleSubmit}
                            type="submit"
                            className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
                            disabled={disabled}
                          >
                            {t("Pay Secure")}
                          </button>
                        </div>
                      </React.Fragment>
                    )}

                    {/* Add more payment gateways as needed */}
                  </div>
                ) : paymentGateway === "PayPal" ? (
                  <div className="bg-white dark:bg-slate-800 items-center  py-4 px-4 mt-6 shadow-sm">
                    <h2 className="text-lg font-semibold mt-2">
                      {t("You will be redirected to the")}{" "}
                      <span className="font-bold ">{t("PayPal")}</span>{" "}
                      {t("website.")}
                    </h2>
                    <Link to="">
                      <div className="mt-4">
                        <input
                          type="submit"
                          id="search-buy"
                          name="search"
                          className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
                          value={t("Pay Pal")}
                        />
                      </div>
                    </Link>{" "}
                    {/* Add more payment gateways as needed */}
                  </div>
                ) : paymentGateway === "GooglePay" ? (
                  <div className="bg-white dark:bg-slate-800 items-center  py-4 px-4 mt-6 shadow-sm">
                    <h2 className="text-lg font-semibold mt-2">
                      {t("You will be redirected to the")}{" "}
                      <span className="font-bold ">{t("Google Pay")}</span>{" "}
                      {t("by clicking")}
                      {t("on the below button")}:
                    </h2>
                    <Link to="">
                      <div className="mt-4">
                        <input
                          type="submit"
                          id="search-buy"
                          name="search"
                          className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
                          value={t("Google Pay")}
                        />
                      </div>
                    </Link>{" "}
                    {/* Add more payment gateways as needed */}
                  </div>
                ) : paymentGateway === "BankTransfer" ? (
                  <div className="bg-white dark:bg-slate-800 items-center  py-4 px-4 mt-6 shadow-sm">
                    <h2 className="text-lg font-semibold mt-2">
                      {t("note_confirm")}{" "}
                    </h2>
                    <Link to="">
                      <div className="mt-4">
                        <input
                          type="submit"
                          id="search-buy"
                          name="search"
                          className="btn bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded"
                          value={t("Finish Order")}
                        />
                      </div>
                    </Link>{" "}
                    {/* Add more payment gateways as needed */}
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="col-span-4">
        <div className="layout-specing mt-6">
          <div className="relative overflow-hidden  shadow-lg dark:shadow-gray-700 bg-white dark:bg-slate-900">
            <div className="p-6 py-8 border-b border-gray-100 dark:border-gray-800">
              <div className="w-full flex flex-col justify-center items-center">
                <span className="flex justify-center border border-btnBackground items-center rounded-md p-2    shadow shadow-indigo-600/5 dark:shadow-indigo-600/10 text-btnBackground">
                  <Icon.ShoppingBag className="h-9 w-9" />
                </span>
                <h6 className="text-lg font-semibold">
                  {t("Your_Current_Balance")}
                </h6>
                <h6 className="text-lg font-semibold">${data.toFixed(2)}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
