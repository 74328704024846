import React from "react";

export default function Button(props) {
  return (
    <input
      type="submit"
      onClick={props.onClick}
      className="py-2 cursor-pointer px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-btnBackground hover:bg-indigo-700 border-btnBackground hover:border-indigo-700 text-white rounded-md w-full"
      value={props.value}
      disabled={props.disabled}
    />
  );
}
