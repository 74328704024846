import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthorized: false,
  user: null,
  token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authorize: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload;
      state.isAuthorized = true;
    },
    revoke: (state) => {
      state.isAuthorized = false;
      state.token = "";
      state.user = null;
    },
  },
});

export { authSlice };
export const { authorize, revoke } = authSlice.actions;
export const authReducer = authSlice.reducer;
